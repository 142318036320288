import { useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Grid, Paper, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import CSVersionTable from "./CSVersionTable";
import { handleError } from "utils/error";
import { useGetVersionHistoryMutation } from "api/CaseStudy/getVersionHistory";
import { PAGINATION } from "settings/constants/pagination";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { toast } from "react-toastify";
import { MISCurrentUser } from "utils/validations";
import Constants from "Constants";
import { downloadCaseStudyFile } from "../caseStudyModel";
import SearchBar from "components/Members/SearchBar";
import { get } from "lodash";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const { SERVER_URL } = Constants;

const CaseStudyVersions = () => {
  const { sessionToken } = MISCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const id = url.includes("case-study") && Number(url.replace(/\D+/g, ""));

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    versionHistoryData: { allTableRows: [], totalTableRowsCount: "", totalPageCount: "" },
    searchInput: "",
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
  });

  const { page, rowsPerPage, versionHistoryData, searchInput } = localState;
  const [getVersionHistory] = useGetVersionHistoryMutation();

  useEffect(() => {
    refreshTable(page, rowsPerPage, searchInput);
  }, [page, rowsPerPage]);

  const refreshTable = (page, rowsPerPage, search) => {
    getCaseStudyVersionTableData(id, page, rowsPerPage, search);
  };

  const getCaseStudyVersionTableData = (id, page, rowsPerPage, search) => {
    const caseStudyVersionDto = {
      text: search,
    };

    getVersionHistory({ id, page, rowPerPage: rowsPerPage, caseStudyVersionDto: caseStudyVersionDto })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({
          versionHistoryData: { allTableRows, totalTableRowsCount, totalPageCount },
        });
      })
      .catch(handleError);
  };

  const downloadVersionFile = async (id, role) => {
    const url = `${SERVER_URL}/caseStudy/download/version?caseStudyVersionId=${id}&role=${role}`;
    const headers = {
      Authorization: `Bearer ${sessionToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      downloadCaseStudyFile(response);
      toast.success(T.FILE_DOWNLOADED_SUCCESSFULLY);
    } catch (error) {
      handleError(error);
    }
  };
  // ############################ Search Things ###################################
  const handleSearchChange = (event) => {
    const { value, dataset } = event.currentTarget;
    const searchValue = value || get(dataset, "val", "");
    setLocalState({
      searchInput: searchValue,
    });
    if (event.key === "Enter" || event.currentTarget.nodeName === "svg" || searchValue === "") {
      refreshTable(page, rowsPerPage, searchValue);
    }
  };

  const handleSearchKeyChange = () => {
    setLocalState({
      page: INITIAL_PAGE,
    });
    refreshTable(INITIAL_PAGE, rowsPerPage, searchInput);
  };

  // ####################### Page handlers Things ##################
  // Page change handler
  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
  };

  const handleBack = () => {
    navigate("/app/case-study");
  };

  return (
    <Paper sx={{ p: "16px 16px 0" }}>
      <Box display="flex" alignItems="center" mb={1}>
        <Grid container>
          <Grid container md={2.5}>
            <Grid item md={1.5}>
              <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer", pt: 1 }} onClick={handleBack} />
            </Grid>
            <Grid item md={10}>
              <Typography variant="h5" fontWeight={600}>
                {T.VERSIONS_HISTORY}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <SearchBar searchInput={searchInput} handleKeyChange={handleSearchKeyChange} handleChange={handleSearchChange} />
          </Grid>
        </Grid>
      </Box>
      <CSVersionTable
        allTableRows={versionHistoryData.allTableRows}
        totalTableRowsCount={versionHistoryData.totalTableRowsCount}
        totalPageCount={versionHistoryData.totalPageCount}
        page={page}
        rowsPerPage={rowsPerPage}
        downloadVersionFile={downloadVersionFile}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  );
};

export default CaseStudyVersions;

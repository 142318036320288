import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPitchedUserStatus: build.mutation({
      query: () => ({
        url: "/selected/user/fetchAll/pitchedUserStatus",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetPitchedUserStatusMutation } = extendedApi;

import { api2 } from "api";

const extendedApi = api2.injectEndpoints({
  endpoints: (build) => ({
    postAiUserInfo: build.mutation({
      query: (body) => ({
        url: "/userinfo_question",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { usePostAiUserInfoMutation } = extendedApi;

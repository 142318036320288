import { Box, Chip, Stack, Typography } from "@mui/material";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import { REQUIREMENT_STATUS_LIST } from "../preSalesModel";
import PropTypes from "prop-types";

const RequirementStatusFilterChips = ({ selectedStatuses, onChange }) => {
  const handleClick = (status) => {
    // logic for multiple chipClasses, commented for future purposes
    // const updatedStatuses = selectedStatuses.includes(status)
    //   ? selectedStatuses.filter((selectedStatus) => selectedStatus !== status)
    //   : [...selectedStatuses, status];
    // onChange(updatedStatuses);

    if (selectedStatuses.length === 1 && selectedStatuses.includes(status)) {
      return;
    }

    const newStatus = selectedStatuses.includes(status)
      ? selectedStatuses.filter((selectedStatus) => selectedStatus !== status)
      : [status];
    onChange(newStatus);
  };

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        {REQUIREMENT_STATUS_LIST.filter((status) => status !== "Partially Closed").map((status) => (
          <Chip
            key={status}
            label={status}
            clickable
            onClick={() => handleClick(status)}
            sx={{
              backgroundColor: selectedStatuses.includes(status) ? NETSMARTZ_THEME_COLOR : "default",
              color: selectedStatuses.includes(status) ? "white" : "default",
              "&:hover": {
                backgroundColor: selectedStatuses.includes(status) ? NETSMARTZ_THEME_COLOR : "default",
                color: selectedStatuses.includes(status) ? "white" : "default",
              },
            }}
          />
        ))}
      </Stack>
    </Box>
  );
};

RequirementStatusFilterChips.propTypes = {
  selectedStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RequirementStatusFilterChips;

import MISDialog from "components/common/MISDialog";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Tooltip,
  Typography,
  Dialog,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TitleTypography, SubTitleTypography, MainTitleTypography } from "utils/members";
import T from "T";
import { BACKGROUND } from "theme/colors";
import { get, noop } from "lodash";
import styled from "@emotion/styled";
import { Children } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  textAlign: "center",
  ...theme.typography.subtitle1,
}));
export const KeyTypography = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "22px",
  whiteSpace: "nowrap",
}));

const LicenseDetailView = ({ licenseDetail = {}, handleLicenseDetail = noop, handleCloseLicense = noop }) => {
  const handleBack = () => {
    handleCloseLicense();
  };
  const licenseData = licenseDetail.licenseDetailData;
  return (
    <Dialog onClose={handleCloseLicense} open={licenseDetail.openLicenseDetail}>
      <Box sx={{ width: "auto", height: "370px" }}>
        <Box pl={1} pt={2} sx={{ backgroundColor: BACKGROUND.cardDefault }}>
          <Grid container stickyHeader>
            <Grid item md={1}>
              <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer", paddingTop: "5px" }} onClick={handleBack} />
            </Grid>
            <Grid item md={11}>
              <Typography variant="h6" fontWeight={700} textAlign={"center"}>
                {T.LICENSE_DETAIL.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" alignItems="center">
          <Table stickyHeader mt={1}>
            <TableHead>
              <TableRow sx={{ height: 30 }}>
                <StyledTableCell sx={{ border: "none", backgroundColor: BACKGROUND.header }}>
                  <KeyTypography>{T.S_NO}</KeyTypography>
                </StyledTableCell>
                <StyledTableCell sx={{ border: "none", backgroundColor: BACKGROUND.header }}>
                  <KeyTypography>{T.COST_CENTER}</KeyTypography>
                </StyledTableCell>
                <StyledTableCell sx={{ border: "none", backgroundColor: BACKGROUND.header }}>
                  <KeyTypography>{T.PERCENTAGE}</KeyTypography>
                </StyledTableCell>
                <StyledTableCell sx={{ border: "none", backgroundColor: BACKGROUND.header }}>
                  <KeyTypography>{T.COST_PRICE}</KeyTypography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Children.toArray(
                licenseData.map((costCenter, index) => {
                  const costCenterName = get(costCenter, "costCenter.costCenterName", "");
                  const percentage = get(costCenter, "percentage", "");
                  const costPrice = get(costCenter, "cost", 0);
                  return (
                    <TableRow sx={{ background: BACKGROUND.white }} key={index}>
                      <StyledTableCell>
                        <TitleTypography>{index + 1}</TitleTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TitleTypography>{costCenterName}</TitleTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TitleTypography>{`${percentage}%`}</TitleTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TitleTypography>{`$${costPrice}`}</TitleTypography>
                      </StyledTableCell>
                    </TableRow>
                  );
                }),
              )}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Dialog>
  );
};

export default LicenseDetailView;

import {
  Paper,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Card,
  CardContent,
  Grid,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import { useLocation, useNavigate } from "react-router-dom";
import RecommendedMembers from "containers/RecommendedMembers";
import ShortlistedMembers from "containers/ShortlistedMembers";
import PitchedMembers from "containers/PitchedMembers";
import { useEffect, useState } from "react";
import { useGetRecommendationsMutation } from "api/preSales/getPreSalesRecommendations";
import { handleError } from "utils/error";
import { useGetSelectedUsersMutation } from "api/preSales/getSelectedUsers";
// import MISLoader from "components/common/MISLoader";
import { capitalize, get } from "lodash";
import { useLazySendEmailToPartnerByIdQuery } from "api/preSales/sendEmailToPartner";
import Constants from "Constants";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import EmailIcon from "@mui/icons-material/Email";
import { useLazyGetPitchedUserQuery } from "api/pitchedCandidates/getPitchedCandidates";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import { toast } from "react-toastify";

const emptyRecommendedMembersProps = {
  jdId: null,
  recommendedUserIds: [],
  allTableRows: [],
  users: [],
};
const emptyShortlistedMembersProps = {
  allTableRows: [],
};
const emptyPitchedMembersProps = {
  allTableRows: [],
};

const ViewClient = () => {
  const [getRecommendations] = useGetRecommendationsMutation();
  const [getSelectedUsers] = useGetSelectedUsersMutation();
  const [getPitchedUser] = useLazyGetPitchedUserQuery();
  const [sendEmailToPartnerById] = useLazySendEmailToPartnerByIdQuery();

  const navigate = useNavigate();
  const location = useLocation();
  const { jdId, data } = location.state ? location.state : {};
  const [recommendedMembersProps, setRecommendedMembersProps] = useState(emptyRecommendedMembersProps);
  const [shortlistedMembersProps, setShortlistedMembersProps] = useState(emptyShortlistedMembersProps);
  const [pitchedMembersProps, setPitchedMembersProps] = useState(emptyPitchedMembersProps);
  const [isDesignationExpanded, setIsDesignationExpanded] = useState(false);
  const [isPreRequisitesExpanded, setIsPreRequisitesExpanded] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const { SERVER_URL } = Constants;
  const fileLink = `${SERVER_URL}/preSalesMapping/download/presalesMappingFile?preSalesMappingId=${jdId}`;
  const jdLink = get(data, "preSalesMappingFileLink", "");

  useEffect(() => {
    getRecommendedMembers();
    getSelectedMembers();
    getPitchedMembers();
  }, []);

  // Function to get recommended members
  const getRecommendedMembers = () => {
    getRecommendations({ id: jdId })
      .unwrap()
      .then((res) => {
        const recommendedUserIds = res.recommendedUsersResponseDtoList.map((result) => result.user.id);
        setRecommendedMembersProps({
          jdId: jdId,
          recommendedUserIds: recommendedUserIds,
          allTableRows: res.users,
        });
      })
      .catch((error) => {
        handleError(error);
      });
  };

  // Function to get shortlisted members
  const getSelectedMembers = () => {
    getSelectedUsers({ preSalesMappingId: jdId })
      .unwrap()
      .then((res) => {
        let results = res.results;
        const users = [];
        results.map((result, index) => {
          const cvLink = result.preSalesMapping.recommendedUser[index].cvLink;
          const cvLinkDoc = result.preSalesMapping.recommendedUser[index].cvLinkDoc;
          const pptLink = result.preSalesMapping.recommendedUser[index].pptLink;
          const isPitched = result.preSalesMapping.recommendedUser[index].selected;
          const user = result.user;
          users.push({ ...user, cvLink: cvLink, cvLinkDoc: cvLinkDoc, isPitched: isPitched, pptLink: pptLink });
        });
        setShortlistedMembersProps({
          allTableRows: users,
        });
      })
      .catch((error) => {
        setShortlistedMembersProps({
          allTableRows: [],
        });
        handleError(error);
      });
  };

  // Function to get pitched members
  const getPitchedMembers = () => {
    getPitchedUser({ preSalesMappingId: jdId })
      .unwrap()
      .then((res) => {
        setPitchedMembersProps({
          allTableRows: get(res, "results", []).map((record) => ({
            ...get(record, "user", {}),
            pitchedUserStatus: get(record, "pitchedUserStatus", null),
            pdfLink: get(record, "pdfLink", null),
            docLink: get(record, "docLink", null),
            pptLink: get(record, "recommendedUser.pptLink", null),
            recommendedUser: get(record, "recommendedUser", null),
            pitchedUserInterviewSchedule: get(record, "pitchedUserInterviewSchedule", null),
            pitchedUserNotified: get(record, "pitchedUserInterviewIntimation", null),
            pitchedUserScheduleMockInterview: get(record, "pitchedUserScheduleMockInterview", null),
          })),
        });
      })
      .catch(handleError);
  };
  const handleBack = () => {
    navigate(-1);
  };
  const primarySkills = get(data, "preSalesSkillsMapping", [])
    .filter((item) => get(item, "isPrimary", false))
    .map((item) => get(item, "tech.skillName", ""))
    .map((item) => capitalize(item))
    .join(", ");
  const secondarySkills = get(data, "preSalesSkillsMapping", [])
    .filter((item) => !get(item, "isPrimary", false))
    .map((item) => get(item, "tech.skillName", ""))
    .map((item) => capitalize(item))
    .join(", ");

  const handleEmailPartner = (id) => {
    sendEmailToPartnerById({ id })
      .unwrap()
      .then((resEmail) => {
        const { to, experience, shiftTimings, subject, leadName, tittle, designation, primarySkill, secondarySkill, jdLink } =
          resEmail;

        const secondarySkillText = secondarySkill !== "" ? `&& Secondary Skill - ${secondarySkill}` : "";
        const jdLinkUrl = jdLink !== null ? SERVER_URL + jdLink : designation;
        const body = `Hi Team,\n\nPlease find someone in our vendor pool with the requirement given below:\nClient Name: ${leadName}\nTitle: ${tittle}\nExperience: ${experience}${T.YEARS}\nShift Timings - ${shiftTimings}\nPrimary Skill - ${primarySkill} ${secondarySkillText}\n\nJD Requirement:\n${jdLinkUrl}`;

        const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
      });
  };

  const handleFileLinkOpen = (jdFileLink) => {
    window.location.href = jdFileLink;
    toast.success(T.JOB_DETAILS_FILE_DOWNLOADED_SUCCESSFULLY);
  };

  const toggleDesignation = () => {
    setIsDesignationExpanded(!isDesignationExpanded);
  };

  const togglePreRequisites = () => {
    setIsPreRequisitesExpanded(!isPreRequisitesExpanded);
  };

  const truncatedDesignation = get(data, "designation", "");
  const truncatedPreRequisites = get(data, "preRequisites", "");

  const maxWords = 17;

  const renderTruncatedText = (text, isExpanded, toggleFunction) => {
    const words = text.split(" ");
    if (words.length > maxWords) {
      if (!isExpanded) {
        return (
          <span>
            {words.slice(0, maxWords).join(" ")}{" "}
            <span style={{ color: NETSMARTZ_THEME_COLOR, cursor: "pointer" }} onClick={toggleFunction}>
              ...more
            </span>
          </span>
        );
      } else {
        return (
          <span>
            {text}{" "}
            <span style={{ color: NETSMARTZ_THEME_COLOR, cursor: "pointer" }} onClick={toggleFunction}>
              ...less
            </span>
          </span>
        );
      }
    } else {
      return text;
    }
  };

  const displayDesignation = renderTruncatedText(truncatedDesignation, isDesignationExpanded, toggleDesignation);
  const displayPreRequisites = renderTruncatedText(truncatedPreRequisites, isPreRequisitesExpanded, togglePreRequisites);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };
  return (
    <Paper sx={{ p: 3, borderRadius: 2, height: "74.5vh", overflow: "hidden" }}>
      {/* {(isFetchingRecommendations || isFetchingSelectedUsers || isFetchingPitchedUsers) && <MISLoader />} */}
      <Box display="flex" alignItems="center" mt={1} mb={3}>
        <IconButton fontSize="small" sx={{ cursor: "pointer", mr: 1 }} onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" fontWeight={600}>
          {T.PROJECT_DETAILS}
        </Typography>
      </Box>
      <Box
        sx={{
          overflow: "auto",
          maxHeight: "calc(74.5vh - 80px)",
        }}
      >
        <Card variant="outlined" sx={{ mx: 3, backgroundColor: "background.paper" }}>
          <Box
            sx={{ backgroundColor: "background.card", display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <Typography variant="h6" fontSize={"19.5px"} pb={1} pt={1.2} px={4} fontWeight={700}>
              {T.JOB_DESCRIPTION}
            </Typography>
            <Box>
              {jdLink && (
                <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }} onClick={() => handleFileLinkOpen(fileLink)}>
                  <Tooltip title={T.DOWNLOAD_JD} placement="top">
                    <DownloadForOfflineOutlinedIcon />
                  </Tooltip>
                </IconButton>
              )}
              <IconButton sx={{ marginRight: 2, color: NETSMARTZ_THEME_COLOR }} onClick={() => handleEmailPartner(data.id || "")}>
                <Tooltip title={T.EMAIL_TO_PARTNER} placement="top">
                  <EmailIcon />
                </Tooltip>
              </IconButton>
            </Box>
          </Box>
          <CardContent>
            <Grid container px={2} spacing={2}>
              <Grid item xs={12} sm={3}>
                <Typography>
                  <strong>{T.MUST_HAVE}:</strong> {primarySkills}
                </Typography>
                <Typography>
                  <strong>{T.GOOD_TO_HAVE}:</strong> {secondarySkills}
                </Typography>
                <Typography>
                  <strong>{T.EXPERIENCE_REQUIRED}:</strong>{" "}
                  {`${get(data, "requiredExperience.requiredExpMin", "")}-${get(data, "requiredExperience.requiredExpMax", "")} ${
                    T.YEARS
                  }`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography>
                  <strong>{T.JOB_OPENINGS}:</strong> {get(data, "numberOfPosts", "")}
                </Typography>
                <Typography>
                  <strong>{T.BUDGET}:</strong> {get(data, "budget", "s")}
                </Typography>
                <Typography>
                  <strong>{T.JD_STATUS}:</strong> {get(data, "preSalesMappingStatus", "")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3.5}>
                <Typography>
                  <strong>{T.DETAILED_JD}:</strong> {displayDesignation}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3.5}>
                <Typography>
                  <strong>{T.PREREQUISITES}:</strong> {displayPreRequisites}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card variant="outlined" sx={{ mt: 3, mx: 3, backgroundColor: "background.paper" }}>
          <Box sx={{ backgroundColor: "background.card" }}>
            <Accordion expanded={expandedAccordion === "pitched"} onChange={handleAccordionChange("pitched")}>
              <AccordionSummary sx={{ backgroundColor: "background.white" }} expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{T.PITCHED_MEMBERS_LIST}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "background.white", p: 0 }}>
                {jdId !== null ? (
                  <PitchedMembers jdId={jdId} getPitchedMembers={getPitchedMembers} {...pitchedMembersProps} />
                ) : null}
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expandedAccordion === "shortlisted"} onChange={handleAccordionChange("shortlisted")}>
              <AccordionSummary sx={{ backgroundColor: "background.white" }} expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{T.SHORTLISTED_MEMBERS_LIST}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "background.white", p: 0 }}>
                {jdId !== null ? (
                  <ShortlistedMembers
                    {...shortlistedMembersProps}
                    pitchedUsersCount={pitchedMembersProps.allTableRows}
                    getSelectedMembers={getSelectedMembers}
                    getPitchedMembers={getPitchedMembers}
                    getRecommendedMembers={getRecommendedMembers}
                    jdId={jdId}
                  />
                ) : null}
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expandedAccordion === "recommended"} onChange={handleAccordionChange("recommended")}>
              <AccordionSummary sx={{ backgroundColor: "background.white" }} expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{T.RECOMMENDED_MEMBERS_LIST}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "background.white", p: 0 }}>
                <RecommendedMembers
                  getRecommendedMembers={getRecommendedMembers}
                  getSelectedMembers={getSelectedMembers}
                  {...recommendedMembersProps}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Card>
      </Box>
    </Paper>
  );
};

export default ViewClient;

// //rough component of casestudy
import { useEffect, useReducer } from "react";
import { Paper, Box, Typography } from "@mui/material";
import TopBar from "components/Testimonial/TopBar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MISCurrentUser } from "utils/validations";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { PAGINATION } from "settings/constants/pagination";
import { get } from "utils/lodash.js";
import { isEqual } from "lodash";

// ///import { toast } from "react-toastify";
import T from "T.js";
import { handleError } from "utils/error.js";
import { useLazyGetTestimonialByIdQuery } from "api/Testimonial/getTestimonial.js";
import { useLazyGetTestimonialSearchListQuery } from "api/Testimonial/searchTestimonial.js";
import { useLazyGetProjectManagerQuery } from "api/projects/getProjectManager";
import { useGetCaseStudyByFilterMutation } from "api/CaseStudy/getCaseStudyByFilter.js";
import { useGetTestimonialByFilterMutation } from "api/Testimonial/getTestimonialByFilter.js";
import { testimonialFilterStore } from "slices/testimonialSlice.js";
import { useLazyReminderButtomQuery } from "api/Testimonial/reminderButton";
import { toast } from "react-toastify";
import AddTestimonial from "./TestimonialProject/AddTestimonial.js";
import TestimonialUploadModal from "./TestimonialProject/TestimonialUploadModal.js";
import TestimonialProject from "./TestimonialProject/index.js";

const FULL_ROWS_PER_PAGE = 1000;

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const Testimonial = () => {
  const { user } = MISCurrentUser();
  const id = get(user, "id", "");

  const dispatch = useDispatch();
  const emptyFilters = { projectManager: null, status: null };

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    pId: "",
    tabValue: "1",
    editId: "",
    skillEditId: "",
    locationEditId: "",
    isFilterEmpty: true,
    projectId: "",
    skillId: "",
    comments: "",
    locationId: "",
    startDate: null,
    endDate: null,
    searchInput: "",
    tableData: [],
    showOptions: false,
    pName: "",
    filters: emptyFilters,
    showCancelIcon: false,
    searchTableData: {},
    openAddTestimonial: false,
    openTestimonialLink: false,
    openRejectCaseStudy: false,
    openDeleteTechnology: false,
    openDeleteWorkLocation: false,
    exportLoading: false,
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    allTableRows: [],
    totalPageCount: null,
    totalTableRowsCount: null,
  });

  const {
    pId,
    tabValue,
    editId,
    startDate,
    isFilterEmpty,
    endDate,
    searchInput,
    showOptions,
    showCancelIcon,
    pName,
    totalPageCount,
    totalTableRowsCount,
    allTableRows,
    filters,
    searchTableData,
    openAddTestimonial,
    openTestimonialLink,
    page,
    rowsPerPage,
  } = localState;

  const [reminderButton] = useLazyReminderButtomQuery();
  const [getTestimonialById] = useLazyGetTestimonialByIdQuery();
  const [getTestimonialSearchList] = useLazyGetTestimonialSearchListQuery();
  const [getProjectManager, { data: projectManagers }] = useLazyGetProjectManagerQuery();
  const [getTestimonialByFilter] = useGetTestimonialByFilterMutation();

  const { storedFilters } = useSelector(
    (state) => ({
      storedFilters: get(state, "TestimonialFilterSlice.storedFilters", {}),
    }),
    shallowEqual,
  );

  const projectManagersList = get(projectManagers, "results", []);
  const refreshTable = () => {
    getTestimonialTableData();
  };
  useEffect(() => {
    getProjectManager({ page: INITIAL_PAGE, rowPerPage: FULL_ROWS_PER_PAGE });
    setLocalState({
      filters: storedFilters,
      isFilterEmpty: isEqual(storedFilters, emptyFilters),
    });
    refreshTable();
  }, []);

  const refreshSearchTableData = (search = searchInput) => {
    if (search === "") {
      return;
    }

    getTestimonialSearchData(search);
  };

  useEffect(() => {
    refreshTable(page, rowsPerPage);
    getTestimonialTableData();
  }, [id]);

  const getTestimonialTableData = () => {
    getTestimonialById({ id })
      .unwrap()
      .then((res) => {
        const {
          allTableRows: newAllTableRows,
          totalTableRowsCount: newtotalTableRowsCount,
          totalPageCount: newtotalPageCount,
        } = getCurrentTableParams(res);
        setLocalState({
          allTableRows: newAllTableRows,
          totalTableRowsCount: newtotalTableRowsCount,
          totalPageCount: newtotalPageCount,
        });
      })
      .catch(handleError);
  };
  const getTestimonialSearchData = (search) => {
    getTestimonialSearchList({ search })
      .unwrap()
      .then((res) => {
        const {
          allTableRows: newAllTableRows,
          totalTableRowsCount: newtotalTableRowsCount,
          totalPageCount: newtotalPageCount,
        } = getCurrentTableParams(res);
        setLocalState({
          allTableRows: newAllTableRows,
          totalTableRowsCount: newtotalTableRowsCount,
          totalPageCount: newtotalPageCount,
        });
      })
      .catch(handleError);
  };

  const resetSearch = () => {
    setLocalState({
      showOptions: false,
      searchInput: "",
      showCancelIcon: false,
    });
    refreshTable(page, rowsPerPage);
  };

  const handleSearchChange = (event) => {
    const { value, dataset } = event.currentTarget;
    const searchValue = value || get(dataset, "val", "");
    if (searchValue === "") {
      refreshTable(page, rowsPerPage);
    }

    setLocalState({
      showOptions: event.key !== "Enter", // event.key === "Enter" ? false : true,
      showCancelIcon: searchValue !== "",
      searchInput: searchValue,
    });
  };

  const handleSearchKeyChange = () => {
    refreshSearchTableData(searchInput);
  };

  const handleSearchClick = (value) => {
    setLocalState({
      showOptions: false,
      showCancelIcon: false,
      searchInput: value,
    });

    refreshSearchTableData(value);
  };

  const handleAddTestimonialDialog = (id = "") => {
    setLocalState({
      openAddTestimonial: !openAddTestimonial,
      editId: id,
    });
  };

  const handleTestimonialUploadDialog = (id = "", pName) => {
    setLocalState({
      openTestimonialLink: !openTestimonialLink,
      pName,
      pId: id,
    });
  };

  const handleReminderButton = (id) => {
    reminderButton({ id })
      .unwrap()
      .then((res) => {
        if (res.type === "Success") {
          toast.success("Reminder Has Been Sent");
        }
      })
      .catch(handleError);
  };

  const onhandleFilterChange = (newValue, item) => {
    setLocalState({ filters: { ...filters, [item]: newValue } });
  };

  const handleFilterApply = () => {
    if (!isEqual(filters, emptyFilters)) {
      getTestimonialFilterTableData();
    } else {
      getTestimonialTableData();
    }
    setLocalState({
      isFilterEmpty: isEqual(filters, emptyFilters),
    });
  };

  // Filter reset handler
  const handleFilterClose = () => {
    setLocalState({
      filters: emptyFilters,
      isFilterEmpty: true,
    });
    dispatch(testimonialFilterStore({ storedFilters: emptyFilters }));
    refreshTable(page, rowsPerPage);
  };

  const getTestimonialFilterTableData = () => {
    const payload = getFilterPayload();

    dispatch(testimonialFilterStore({ storedFilters: filters }));
    getTestimonialByFilter({ testimonialFilterRequestDto: payload })
      .unwrap()
      .then((res) => {
        const {
          allTableRows: newAllTableRows,
          totalTableRowsCount: newtotalTableRowsCount,
          totalPageCount: newtotalPageCount,
        } = getCurrentTableParams(res);
        setLocalState({
          allTableRows: newAllTableRows,
          totalTableRowsCount: newtotalTableRowsCount,
          totalPageCount: newtotalPageCount,
        });
      })
      .catch(handleError);
  };

  // Filter payload
  const getFilterPayload = () => {
    const payload = {
      projectManagerId: get(filters, "projectManager.id", ""),
      testimonialStatus: get(filters, "status", ""),
    };
    return payload;
  };

  return (
    <Paper display="block" justifyContent="flex-start" sx={{ borderRadius: 2 }}>
      <Typography variant="h5" fontWeight={600} mb={1} marginLeft={2} sx={{ pt: 2 }}>
        {T.TESTIMONIALS_VIEW}
      </Typography>

      <Box
        sx={{
          "& .MuiTabPanel-root": {
            p: 2,
            pt: 0,
          },
        }}
        style={{ padding: "4px" }}
      >
        <TopBar
          value={tabValue}
          isFilterEmpty={isFilterEmpty}
          startDate={startDate}
          endDate={endDate}
          searchInput={searchInput}
          showOptions={showOptions}
          showCancelIcon={showCancelIcon}
          searchTableData={searchTableData}
          projectManagers={projectManagersList}
          filters={filters}
          //  handleExport={handleExport}
          onClick={handleSearchClick}
          handleKeyChange={handleSearchKeyChange}
          handleChange={handleSearchChange}
          reset={resetSearch}
          onhandleFilterChange={onhandleFilterChange}
          handleFilterApply={handleFilterApply}
          handleFilterClose={handleFilterClose}
          handleAddTestimonialDialog={handleAddTestimonialDialog}
          handleTestimonialUploadDialog={handleTestimonialUploadDialog}
        />

        <TestimonialProject
          allTableRows={allTableRows}
          totalTableRowsCount={totalTableRowsCount}
          totalPageCount={totalPageCount}
          page={page}
          rowsPerPage={rowsPerPage}
          handleAddTestimonialDialog={handleAddTestimonialDialog}
          handleTestimonialUploadDialog={handleTestimonialUploadDialog}
          handleReminderButton={handleReminderButton}
        />
      </Box>
      <AddTestimonial
        editId={editId}
        refreshTable={refreshTable}
        openAddTestimonial={openAddTestimonial}
        handleAddTestimonialDialog={handleAddTestimonialDialog}
      />
      <TestimonialUploadModal
        pId={pId}
        refreshTable={refreshTable}
        openTestimonialLink={openTestimonialLink}
        pName={pName}
        handleTestimonialUploadDialog={handleTestimonialUploadDialog}
      />
    </Paper>
  );
};

export default Testimonial;

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    deleteSpecialIncident: build.mutation({
      query: ({ sirId }) => ({
        url: `/specialIncidentRegister/delete`,
        method: "POST",
        params: { sirId },
      }),
    }),
  }),
});

export const { useDeleteSpecialIncidentMutation } = extendedApi;

import PropTypes from "prop-types";
import noop from "lodash/noop";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { Typography, Grid, IconButton, Dialog, DialogTitle, DialogContent, Box, Tooltip } from "@mui/material";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'; // example icon
import MISDialog from "components/common/MISDialog";
import T from "T";
import { get } from "utils/lodash";
import { GET_SIZE } from "utils/responsive";
import { handleError } from "utils/error";
import { useState } from "react";
import { AudiotrackOutlined } from "@mui/icons-material";
import MISNoData from "components/common/MISNoData";


const RecordingListDialog = ({
  handleRecordingDialog = noop,
  openRecordingDialog = false,
  recordingData = [],
  recordingUser = ""
}) => {
  const { isMd, isLg } = GET_SIZE();
  const handleIconClick = async (video) => {
    window.open(video, "_blank");
  };
  return (
    <Dialog open={openRecordingDialog} onClose={() => handleRecordingDialog("", [])} maxWidth={isLg ? "lg" : isMd ? "md" : "sm"} fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={3} display="flex" alignItems="center">
            <AudiotrackOutlined sx={{ fontSize: 30, color: BACKGROUND.black, mr: 1 }} />
            <Typography variant="body1" sx={{ fontWeight: 650, fontSize: 20, color: BACKGROUND.black }}>
              Recordings
            </Typography>
          </Grid>
          <Grid item xs display="flex" justifyContent="center" flexGrow={1}>
            <Typography variant="body1" textAlign="center" sx={{ color: BACKGROUND.black, fontSize: 30, fontWeight: 650 }}>
              {recordingUser}
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} justifyContent="center" style={{ marginLeft: '0px', width: '100%' }}>
          {recordingData?.length > 0 ? (
            recordingData.map((record, index) => {
              const video = get(record, "filePlayLink", "");
              return (
                <Grid xs={12} sm={6} md={4} lg={2.4} sx={{ pt: 2 }} key={index} display="flex" justifyContent="center">
                  <Box sx={{ p: 2 }} textAlign="center">
                    <IconButton onClick={() => handleIconClick(video)}>
                      <AudiotrackOutlined sx={{ fontSize: 60, color: NETSMARTZ_THEME_COLOR }} />
                    </IconButton>
                    <Tooltip placement="top" title={get(record, "question", "")}>
                      <Typography
                        variant="body1"
                        fontWeight={550}
                        align="center"
                      >
                        {get(record, "question", "")}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Grid>
              );
            })
          ) :
            <Grid item xs={12}>
              <MISNoData />
            </Grid>}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

RecordingListDialog.propTypes = {
  openRecordingDialog: PropTypes.bool,
  confrmCancelModal: PropTypes.func,
  handleRecordingDialog: PropTypes.func,
  recordingData: PropTypes.array,
  recordingUser: PropTypes.string,
};

export default RecordingListDialog;

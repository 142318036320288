import { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Grid, Typography, Box } from "@mui/material";
import { toast } from "react-toastify";
import { get } from "utils/lodash";
import { format, isValid } from "date-fns";
import { BACKGROUND } from "theme/colors";

import MISFooterButton from "components/common/MISFooterButton";
import MISDatePicker from "components/common/MISDatePicker";
import MISTextField from "components/common/MISTextField";
import MISAutocomplete from "components/common/MISAutocomplete";
import MISDialog from "components/common/MISDialog";
import usePMFetch from "hooks/usePMFetch";
import T from "T";
import { PAGINATION } from "settings/constants/pagination";

import { handleError } from "utils/error";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { useLazyGetProjectByIdQuery } from "api/projects/getProjectById";
import { useLazyGetActiveProjectListQuery } from "api/projects/getActiveProjectList";
import { useCreateTestimonialMutation } from "api/Testimonial/createTestimonial";

const { INITIAL_PAGE } = PAGINATION;
const ROWS_PER_PAGE = 10000;

const AddTestimonial = ({
  id = "",
  openAddTestimonial = false,
  editId = "",
  handleAddTestimonialDialog = noop,
  refreshTable = noop,
}) => {
  // const [getProjectById] = useLazyGetProjectByIdQuery();

  const [getActiveProjectList, { data: activeProjectList }] = useLazyGetActiveProjectListQuery();
  const [createTestimonial] = useCreateTestimonialMutation();

  const projectListDetails = get(activeProjectList, "results", []);
  const occurrenceCount = {};
  for (let proj of projectListDetails) {
    if (occurrenceCount[proj.name]) {
      occurrenceCount[proj.name]++;
    } else {
      occurrenceCount[proj.name] = 1;
    }
  }
  useEffect(() => {
    getActiveProjectList({ page: INITIAL_PAGE, rowPerPage: ROWS_PER_PAGE }).unwrap().catch(handleError);
  }, [getActiveProjectList]);

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    comment: "",
    project: {},
  });
  const { comment, project, testimonialSubmissionDate } = localState;

  const emptyLocalState = {
    projectManager: "",
    projectStartDate: "",
    testimonialLink: "",
    project: {},
  };

  const { projectManagers } = usePMFetch();

  useEffect(() => {
    getActiveProjectList({ page: INITIAL_PAGE, rowPerPage: ROWS_PER_PAGE });
  }, [getActiveProjectList]);

  const pMResults = get(projectManagers, "results", []);
  const selectedProjectPM = pMResults.find((res) => res.name === get(project, "projectManager.name", ""));
  const projectStartDate = get(project, "startDate", "");

  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const onHandleAutoCompleteChange = (type, value) => {
    setLocalState({ [type]: value });
  };

  const resetState = () => {
    setLocalState(emptyLocalState);
  };

  const handleAddTestimonial = () => {
    const payload = {
      projectManagerId: project.projectManager.id,
      projectName: project.name,
      projectStartDate,
      testimonialLink: "https://example.com/Testimonial",
    };
    createTestimonial(payload)
      .unwrap()
      .then(() => {
        handleClose();
        refreshTable();
        toast.success(T.TESTIMONIAL_ADDED_SUCCESSFULLY);
      })
      .catch(handleError);
  };

  const handleClose = () => {
    resetState();
    handleAddTestimonialDialog();
    refreshTable();
  };

  return (
    <MISDialog open={openAddTestimonial}>
      <Box display="flex" alignItems="center" mb={1} pl={1} pt={2} sx={{ backgroundColor: BACKGROUND.cardDefault }}>
        <Typography variant="h6" fontWeight={700} ml={29}>
          {T.ADD_TESTIMONIALS.toUpperCase()}
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          p: 2,
          "& .MuiFormLabel-root": {
            "& .MuiTypography-root": {
              color: "text.popupLabel",
              fontSize: 14,
              fontWeight: 600,
            },
          },
          width: "50vw",
        }}
      >
        <Grid item md={6} xs={12}>
          <MISAutocomplete
            label={`${T.PROJECT.toUpperCase()} ${T.NAME.toUpperCase()}`}
            listDetails={projectListDetails}
            getByLabelText={(listItem) =>
              occurrenceCount[listItem.name] === 1
                ? `${listItem.name}`
                : `${listItem.name} | ${listItem.department} | ${listItem.projectType}`
            }
            value={project}
            required
            onHandleChange={(event, newValue) => onHandleAutoCompleteChange("project", newValue)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <MISAutocomplete
            label={T.PROJECT_MANAGER.toUpperCase()}
            value={selectedProjectPM}
            disabled
            required
            getByLabelText={(option) => get(option, "name", "")}
            onHandleChange={(event, newValue) => onHandleAutoCompleteChange("projectManager", get(newValue, "id", ""))}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <MISDatePicker
            label={T.START_DATE.toUpperCase()}
            placeholder={T.START_DATE}
            inputFormat="MM/DD/YYYY"
            value={projectStartDate}
            disabled
            required
            name="projectStartDate"
            renderInput={(params) => <MISTextField {...params} />}
          />
        </Grid>
      </Grid>
      <MISFooterButton
        proceedButtonText={T.SAVE}
        justify="center"
        size="medium"
        disableProceed={!project || !projectStartDate}
        sx={{ p: 1, m: 1 }}
        handleClose={() => {
          handleClose();
        }}
        handleSubmit={() => handleAddTestimonial()}
      />
    </MISDialog>
  );
};

AddTestimonial.propTypes = {
  openAddTestimonial: PropTypes.bool,
  editId: PropTypes.string,
  handleAddTestimonialDialog: PropTypes.func,
  refreshTable: PropTypes.func,
  onHandleAutoCompleteChange: PropTypes.func,
};

export default AddTestimonial;

import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import MasterPagination from "components/MasterSetting/MasterPagination";
import MISNoData from "components/common/MISNoData";
import { noop, orderBy } from "lodash";
import React, { useEffect, useReducer } from "react";

import { TOOLS_LICENCE_TABLE_HEADER } from "settings/constants/members";
import ToolsLicenceTableHeader from "./ToolsLicenceTableHeader";
import ToolsLicenceTableBody from "./ToolsLicenceTableBody";
import { SORTING_KEYS } from "../toolsModel";

const ToolsLicenceTable = ({
  totalTableRowsCount,
  totalPageCount,
  page,
  rowsPerPage,
  allTableRows = [],
  onPageChange,
  onRowsPerPageChange,
  handleOpenContractHistory = noop,
  refreshOnStatusChange = noop,
}) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    activeSort: "",
    sortDirection: 0,
    renderedRow: [],
  });

  const { activeSort, sortDirection, renderedRow } = localState;

  useEffect(() => {
    setLocalState({ renderedRow: allTableRows, activeSort: "", sortDirection: 0 });
  }, [allTableRows]);

  const handleToolsSorting = (val) => {
    setLocalState({ activeSort: val });
    let current = sortDirection;
    if (current === 0) current = 1;
    else if (current === 1) current = -1;
    else if (current === -1) current = 0;
    if (activeSort != val) current = 1;
    setLocalState({ sortDirection: current });

    if (current === 0) {
      setLocalState({ renderedRow: allTableRows });
      return;
    }

    const order = current === 1 ? "asc" : "desc";
    const sortedRows = orderBy(allTableRows, [SORTING_KEYS.find((item) => item.key === val).value], [order]);
    setLocalState({ renderedRow: sortedRows });
  };

  return (
    <Card
      elevation={0}
      sx={{
        // p: "0px 8px",
        // mt: 1,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <TableContainer
        sx={{
          height: "calc(100vh - 390px)",
          overflow: "auto",
        }}
      >
        {allTableRows.length !== 0 ? (
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            <ToolsLicenceTableHeader
              columns={TOOLS_LICENCE_TABLE_HEADER}
              activeSort={activeSort}
              sortDirection={sortDirection}
              handleToolsSorting={handleToolsSorting}
            />
            <ToolsLicenceTableBody
              allTableRows={renderedRow}
              handleOpenContractHistory={handleOpenContractHistory}
              refreshOnStatusChange={refreshOnStatusChange}
            />
          </TableView>
        ) : (
          <MISNoData />
        )}
      </TableContainer>

      <MasterPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

export default ToolsLicenceTable;

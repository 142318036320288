import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCourseById: builder.query({
      query: ({ id }) => ({
        url: `/course/get/courseById?courseId=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetCourseByIdQuery } = extendedApi;

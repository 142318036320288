import { Grid, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { get } from "lodash";
import PropTypes from "prop-types";

const PrimaryGrid = ({ record }) => {
  const openJobsList = get(record, "openJobsList", {});
  const wonJobsList = get(record, "wonJobsList", {});
  const lostJobsList = get(record, "lostJobsList", {});

  const mappedOpenJobsList = Object.entries(openJobsList).map(([id, info]) => ({
    id: parseInt(id),
    name: Object.keys(info)[0],
    value: info[Object.keys(info)[0]],
  }));
  const mappedWonJobsList = Object.entries(wonJobsList).map(([id, info]) => ({
    id: parseInt(id),
    name: Object.keys(info)[0],
    value: info[Object.keys(info)[0]],
  }));
  const mappedLostJobsList = Object.entries(lostJobsList).map(([id, info]) => ({
    id: parseInt(id),
    name: Object.keys(info)[0],
    value: info[Object.keys(info)[0]],
  }));

  return (
    <Grid container justifyContent={"space-between"}>
      {[
        {
          text: "Opportunities Open",
          number: get(record, "totalOpportunityOpen", 0),
          color: "#f39c12",
          tooltipContent: get(record, "openOppsList", []).join(", "),
        },
        {
          text: "Positions Open",
          number: get(record, "totalJobsOpen", 0),
          color: "#2e86de",
          tooltipContent: mappedOpenJobsList.map((job) => `${job.name}: ${job.value}`).join(", "),
        },
        {
          text: "Opportunities Won",
          number: get(record, "totalOpportunityWon", 0),
          color: "#d3a82c",
          tooltipContent: get(record, "wonOppsList", []).join(", "),
        },
        {
          text: "Positions Won",
          number: get(record, "totalJobsWon", 0),
          color: "#27ae60",
          tooltipContent: mappedWonJobsList.map((job) => `${job.name}: ${job.value}`).join(", "),
        },
        {
          text: "Opportunities Lost",
          number: get(record, "totalOpportunityLost", 0),
          color: "#d44c4b",
          tooltipContent: get(record, "lostOppsList", []).join(", "),
        },
        {
          text: "Positions Lost",
          number: get(record, "totalJobsLost", 0),
          color: "#FF884B",
          tooltipContent: mappedLostJobsList.map((job) => `${job.name}: ${job.value}`).join(", "),
        },
      ].map((box, index) => (
        <Tooltip title={box.tooltipContent} arrow key={index} placement="bottom-start">
          <Grid
            item
            xs={12}
            md={1.9}
            sx={{
              height: "70px",
              px: "25px",
              backgroundColor: box.color,
              display: "flex",
              justifyContent: "space-around",
              border: "1px solid #ccc",
              borderRadius: 2,
              alignItems: "center",
              "&:not(:last-child)": {
                marginRight: { md: 1.5 },
                marginBottom: { xs: 1, sm: 1.5 },
              },
            }}
          >
            <Typography fontSize={14} color={"white"} width={150}>
              {box.text}
            </Typography>
            <Typography fontSize={25} sx={{ marginLeft: "10px", fontWeight: "bold", color: "white" }}>
              {box.number}
            </Typography>
          </Grid>
        </Tooltip>
      ))}
    </Grid>
  );
};

PrimaryGrid.propTypes = {
  record: PropTypes.object,
};

export default PrimaryGrid;

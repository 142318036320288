import PropTypes from "prop-types";
import { TableCell, TableBody, styled, TableRow, Typography, Link } from "@mui/material";
import { memo, useState } from "react";
import get from "lodash/get";
import { noop } from "lodash";
import MISButton from "components/common/MISButton";
import T from "T";
import { canAddTechnicalInterview } from "utils/permissions";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  backgroundColor: "white",
  padding: "7px 8px 7px 24px",
  maxWidth: 130,
  fontSize: 13.5,
}));

const MISTableBody = ({ allTableRows, handleViewDetails = noop, handleViewResult = noop }) => {
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRowExpand = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((item) => item !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const reversedTableRecord = [...allTableRows].reverse();

  return (
    <TableBody>
      {reversedTableRecord.map((record, index) => {
        const finalResult = get(record, "finalResult", null);
        const topics = get(record, "topics", []);

        const isExpanded = expandedRows.includes(index);
        const status = get(record, "status", null);

        return (
          <TableRow key={index}>
            <StyledTableCell sx={{ pl: 4.5 }}>{index + 1}.</StyledTableCell>
            <StyledTableCell>{get(record, "techStack", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "experience", "-")} years</StyledTableCell>
            <StyledTableCell>{get(record, "createdAt", "-").split(" ")[0]}</StyledTableCell>
            <StyledTableCell>
              {topics.length > 0 ? (
                <>
                  {topics.slice(0, isExpanded ? topics.length : 3).join(", ")}
                  {topics.length > 3 && (
                    <Typography component="span" sx={{ fontSize: 13.5 }}>
                      <Link
                        sx={{ cursor: "pointer", color: "themeColor", textDecoration: "none" }}
                        onClick={() => toggleRowExpand(index)}
                      >
                        {isExpanded ? " ...Less" : " ...More"}
                      </Link>
                    </Typography>
                  )}
                </>
              ) : (
                "-"
              )}
            </StyledTableCell>
            <StyledTableCell>{get(record, "status", "-")}</StyledTableCell>
            <StyledTableCell>
              {finalResult ? (
                <Link sx={{ color: "themeColor", cursor: "pointer" }} onClick={() => handleViewResult(finalResult)}>
                  {T.RESULT}
                </Link>
              ) : (
                <Typography>{"-"}</Typography>
              )}
            </StyledTableCell>
            {!canAddTechnicalInterview() && (
              <StyledTableCell sx={{ textAlign: "center" }}>
                <MISButton
                  disabled={finalResult || status === T.PROGRESSING || status === T.COMPLETED}
                  onClick={() => handleViewDetails(record)}
                >
                  {T.START}
                </MISButton>
              </StyledTableCell>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

MISTableBody.propTypes = {
  allTableRows: PropTypes.array,
  handleViewDetails: PropTypes.func,
  handleViewResult: PropTypes.func,
};

export default memo(MISTableBody);

import PropTypes from "prop-types";
import { TableCell, TableBody, styled, TableRow, IconButton, Tooltip } from "@mui/material";
import memo from "utils/react";
import get from "lodash/get";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import { noop } from "lodash";
import T from "T";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import { MISCurrentUser } from "utils/validations";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  backgroundColor: "white",
  padding: "8px 8px 8px 24px",
  fontSize: 13.2,
  maxWidth: 150,
}));

const MISTableBody = ({ allTableRows, page, size, id, handleAddRecommendation = noop }) => {
  const { user } = MISCurrentUser();
  const role = get(user, "role", null);
  return (
    <TableBody>
      {allTableRows.map((record, index) => {
        const techs = get(record, "techs", "-");
        const referralCount = get(record, "referralCount", "-");
        const techStack = techs.map((skill) => skill.skills.skillName);
        const serialNumber = index + 1 + page * size;

        return (
          <TableRow key={index}>
            <StyledTableCell sx={{ pl: 4.5 }}>{serialNumber}.</StyledTableCell>
            <StyledTableCell>{get(record, "empName", "-")}</StyledTableCell>
            <StyledTableCell>
              <Tooltip placement="top" title={get(record, "emailId", "-")}>
                <div> {get(record, "emailId", "-")}</div>
              </Tooltip>
            </StyledTableCell>{" "}
            {/* <StyledTableCell>{techStack.join(", ")}</StyledTableCell> */}
            <StyledTableCell sx={{ pl: 14 }}>{referralCount}</StyledTableCell>
            <StyledTableCell>{get(record, "exp", "-")} years </StyledTableCell>
            {/* <StyledTableCell>{get(record, "lastRequest", "-")} </StyledTableCell> */}
            {role === T.TA && (
              <StyledTableCell sx={{ pl: 0 }}>
                <Tooltip placement="top" title={T.ADD_RECOMMENDATION}>
                  <IconButton
                    sx={{ color: NETSMARTZ_THEME_COLOR }}
                    onClick={() => handleAddRecommendation(id ? false : true, record.userId)}
                  >
                    <AssignmentIcon />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

MISTableBody.propTypes = {
  allTableRows: PropTypes.array,
  handleAddRecommendation: PropTypes.func,
  size: PropTypes.number,
  page: PropTypes.number,
  id: PropTypes.number,
};

export default memo(MISTableBody);

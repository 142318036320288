import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateCostCenter: build.mutation({
      query: (body) => ({
        url: "/costCenter/update",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const { useUpdateCostCenterMutation } = extendedApi;

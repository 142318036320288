import { Add } from "@mui/icons-material";
import { Grid } from "@mui/material";
import T from "T";
import MISSearchBar from "components/common/MISSearchBar";
import { BACKGROUND } from "theme/colors";
import PropTypes from "prop-types";
import MISButton from "components/common/MISButton";

const GroupsTopBar = ({ search, handleCreateGroup, handleChange, refreshTable }) => {
  return (
    <Grid container sx={{ justifyContent: "space-between", p: 2, backgroundColor: BACKGROUND.card }}>
      <Grid item sm={"auto"} xs={12}>
        <MISSearchBar
          searchValue={search}
          handleChange={handleChange}
          handleSubmit={refreshTable}
          showCancelIcon={search !== ""}
        />
      </Grid>
      <Grid item sm={"auto"} xs={12}>
        <MISButton variant="contained" size="medium" startIcon={<Add />} onClick={handleCreateGroup} fullWidth>
          {T.NEW_GROUP}
        </MISButton>
      </Grid>
    </Grid>
  );
};

GroupsTopBar.propTypes = {
  search: PropTypes.string.isRequired,
  handleCreateGroup: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  refreshTable: PropTypes.func.isRequired,
};

export default GroupsTopBar;

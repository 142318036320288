import { Tab, Icon, styled, useMediaQuery, Box, useTheme, Badge } from "@mui/material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { BACKGROUND } from "theme/colors";
import { shallowEqual, useSelector } from "react-redux";
import { get } from "lodash";
import T from "T";
import { Style } from "@mui/icons-material";

const NavIcon = ({ icon, isActive, isTablet }) => (
  <Icon
    sx={{
      display: "flex",
      height: "inherit",
      width: "inherit",
      mr: 1,
      mb: 0.6,
      filter: isActive
        ? "invert(50%) sepia(94%) saturate(887%) hue-rotate(348deg) brightness(101%) contrast(92%)"
        : BACKGROUND.black,
    }}
  >
    <Box component="img" src={icon} margin="auto" pl={1} width={!isTablet ? 20 : 30} height={!isTablet ? 20 : 30} />
  </Icon>
);

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 10,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const BadgeNavIcon = ({ icon, isActive, isTablet, badgeCount }) => {
  return (
    <Icon
      sx={{
        display: "flex",
        height: "inherit",
        width: "inherit",
        mr: 1,
        mb: 0.6,
      }}
    >
      <StyledBadge badgeContent={badgeCount} color="info">
        <Box
          sx={{
            filter: isActive
              ? "invert(50%) sepia(94%) saturate(887%) hue-rotate(348deg) brightness(101%) contrast(92%)"
              : BACKGROUND.black,
          }}
          component="img"
          src={icon}
          margin="auto"
          pl={1}
          width={!isTablet ? 20 : 30}
          height={!isTablet ? 20 : 30}
        />
      </StyledBadge>
    </Icon>
  );
};

const StyledTab = styled(Tab)(({ theme, isactive }) => ({
  textTransform: "capitalize",
  display: "block",
  color: isactive === "true" ? theme.palette.themeColor : theme.palette.background.black,
  fontSize: "14px",
  padding: 1.5,
  minWidth: "unset",
  minHeight: "unset",
  opacity: 1,
  margin: "0 0.5vw",
}));

const NavItem = ({ label, icon, to, isActive = false, index, badgeCount }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", 1110));

  return (
    <StyledTab
      isactive={isActive.toString()}
      iconPosition={!isTablet ? "start" : "top"}
      icon={
        label !== T.TASKBOX || badgeCount <= 0 ? (
          <NavIcon icon={icon} isActive={isActive} isTablet={isTablet} />
        ) : (
          <BadgeNavIcon icon={icon} isActive={isActive} isTablet={isTablet} badgeCount={badgeCount} />
        )
      }
      component={Link}
      label={!isTablet && label}
      to={to}
      value={index}
    />
  );
};

NavItem.propTypes = {
  label: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  index: PropTypes.number,
};

export default NavItem;

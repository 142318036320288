import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Grid, Box, styled, Tooltip } from "@mui/material";
import SearchBar from "components/Members/SearchBar";
import MISDatePicker from "components/common/MISDatePicker";
import MISTextField from "components/common/MISTextField";
import CSV from "assets/CSV.png";
// import { canAddMasterSettings, canTrackerView } from "utils/permissions";
import { GET_SIZE } from "utils/responsive";
import T from "T";
import PreSalesFilterOptions from "../InterviewTrackerFilters/InterviewTrackerFilterOptions";
import InterviewTrackerFilters from "../InterviewTrackerFilters";
export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const TopBar = ({
  startDate = null,
  endDate = null,
  filters = {},
  searchInput = "",
  showOptions = false,
  showCancelIcon = false,
  isFilterEmpty = false,
  searchTableData = {},
  skillList = {},
  companyNames = [],
  pitchedUserStatusList = [],
  projectManagersList = [],
  preSalesManagersList = [],
  onhandleFilterChange = noop,
  handleFilterSubmit = noop,
  handleFilterClose = noop,
  onClick = noop,
  handleKeyChange = noop,
  handleChange = noop,
  reset = noop,
  onClickOutside = noop,
  onHandleDateChange = noop,
  handleExport = noop,
}) => {
  const { isLg } = GET_SIZE();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={isLg ? "space-between" : "center"}
      sx={{
        mt: 1,
        p: 1,
        backgroundColor: "background.card",
        "& .MuiOutlinedInput-input": {
          fontSize: 14,
        },
      }}
    >
      <Grid item md={3} xs={12}>
        <SearchBar
          records={searchTableData}
          searchInput={searchInput}
          showOptions={showOptions}
          showCancelIcon={showCancelIcon}
          onClick={onClick}
          handleKeyChange={handleKeyChange}
          handleChange={handleChange}
          reset={reset}
          onClickOutside={onClickOutside}
        />
      </Grid>
      <Grid item md={2} />
      <Grid item md={2} xs={12}>
        <InterviewTrackerFilters
          companyNames={companyNames}
          filters={filters}
          skillList={skillList}
          preSalesManagersList={preSalesManagersList}
          projectManagersList={projectManagersList}
          pitchedUserStatusList={pitchedUserStatusList}
          isFilterEmpty={isFilterEmpty}
          handleFilterClose={handleFilterClose}
          handleFilterSubmit={handleFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
        />
      </Grid>
      <Grid item md={1.5} xs={12}>
        <MISDatePicker
          placeholder={T.START_DATE}
          inputFormat="MM/DD/YYYY"
          value={startDate}
          name="startDate"
          maxDate={endDate}
          handleChange={onHandleDateChange}
          renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>
      <Grid item md={1.5} xs={12}>
        <MISDatePicker
          placeholder={T.END_DATE}
          inputFormat="MM/DD/YYYY"
          value={endDate}
          minDate={startDate}
          name="endDate"
          handleChange={onHandleDateChange}
          renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>

      <Grid item>
        <Box display="flex" alignItems="center">
          <Tooltip title={T.CSV} placement="top">
            <StyledBox component="img" src={CSV} onClick={handleExport} />
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

TopBar.propTypes = {
  value: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  searchInput: PropTypes.string,
  filters: PropTypes.object,
  skillList: PropTypes.object,
  projectManagers: PropTypes.object,
  preSalesManagersList: PropTypes.array,
  showOptions: PropTypes.bool,
  showCancelIcon: PropTypes.bool,
  isFilterEmpty: PropTypes.bool,
  searchTableData: PropTypes.object,
  handleExport: PropTypes.func,
  onClick: PropTypes.func,
  handleKeyChange: PropTypes.func,
  handleChange: PropTypes.func,
  reset: PropTypes.func,
  onClickOutside: PropTypes.func,
  onHandleDateChange: PropTypes.func,
  onhandleFilterChange: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleFilterClose: PropTypes.func,
};

export default TopBar;

import { Children } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { TableRow, TableCell, TableBody, styled } from "@mui/material";
import { BACKGROUND } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import { GET_SIZE } from "utils/responsive";
import { getMemberColumnData, handleHeaderClass } from "utils/pitchedMembers";
import MISNoData from "components/common/MISNoData";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 24px",
}));

const MISTableBody = ({
  columns = [],
  lockedColumns = [],
  records = [],
  handleExport = noop,
  handleScheduleInterview = noop,
  handleCancelInterview = noop,
  handleCodeAssessment = noop,
  handleSubmitAssessmentDialog = noop,
  handleViewAssessmentDialog = noop,
  handleInterviewChecklist = noop,
  handleTrackStatusDialog = noop,
  handlePreInterviewDialog = noop,
  handleScheduleMockInterview = noop,
}) => {
  const { isXs } = GET_SIZE();
  return (
    <TableBody>
      {Children.toArray(
        records.map((record) => {
          return (
            <TableRow sx={{ background: BACKGROUND.white }}>
              {Children.toArray(
                columns.map((column, index) => {
                  const colKey = get(column, "columnKey", "");
                  const { columnData } = getMemberColumnData(
                    record,
                    isXs,
                    colKey,
                    handleExport,
                    handleCodeAssessment,
                    handleScheduleInterview,
                    handleCancelInterview,
                    handleSubmitAssessmentDialog,
                    handleInterviewChecklist,
                    handleViewAssessmentDialog,
                    handleTrackStatusDialog,
                    handlePreInterviewDialog,
                    handleScheduleMockInterview,
                  );
                  const isRowLocked = get(column, "locked", false);
                  const isSticky = index < 1;
                  return (
                    <>
                      <StyledTableCell
                        sx={{
                          position: isSticky ? "sticky" : "inherit",
                          left: isSticky ? 0 : "inherit",
                          zIndex: isSticky ? 2 : "inherit",
                          backgroundColor: "white",
                        }}
                        className={handleHeaderClass(index, isRowLocked, lockedColumns)}
                      >
                        {columnData}
                      </StyledTableCell>
                    </>
                  );
                }),
              )}
            </TableRow>
          );
        }),
      )}

      {records && records.length === 0 && (
        <TableRow>
          <StyledTableCell colSpan="10%" sx={{ border: "none" }}>
            <MISNoData />
          </StyledTableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

MISTableBody.propTypes = {
  columns: PropTypes.array,
  lockedColumns: PropTypes.array,
  records: PropTypes.array,
  handleExport: PropTypes.func,
  handleScheduleInterview: PropTypes.func,
  handleCodeAssessment: PropTypes.func,
  handleCancelInterview: PropTypes.func,
  handleInterviewChecklist: PropTypes.func,
  handleSubmitAssessmentDialog: PropTypes.func,
  handleViewAssessmentDialog: PropTypes.func,
  navigate: PropTypes.func,
  handleTrackStatusDialog: PropTypes.func,
  handlePreInterviewDialog: PropTypes.func,
  handleScheduleMockInterview: PropTypes.func,
};

export default memo(MISTableBody);

import { useReducer } from "react";
import PropTypes from "prop-types";
import { get, noop } from "lodash";
import { Grid, Typography, Box, Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { isString, isUrl } from "utils/validations";
import MISFooterButton from "components/common/MISFooterButton";
import MISTextField from "components/common/MISTextField";
import T from "T";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { useUpdateCaseStudyDocMutation } from "api/CaseStudy/UpdateCaseStudyDoc.js";

import { BACKGROUND, ERROR, NETSMARTZ_THEME_COLOR, SUCCESS } from "theme/colors";
import WithInputLabel from "components/common/WithInputLabel";
import MISLoader from "components/common/MISLoader";
import { canEditCaseStudy } from "utils/permissions";
import { ArrowBack } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import MISDialog from "components/common/MISDialog";

const CaseStudyUploadFileModal = ({
  pId = "",
  openCaseStudyUpload = false,
  userRole = "",
  handleCloseCaseStudyUpload = noop,
  refreshTable = noop,
}) => {
  const defaultLocalState = {
    comment: "",
    file: "",
    fileName: "",
    publishLink: "",
  };

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), defaultLocalState);
  const { comment, file, fileName, publishLink } = localState;
  const [updateCaseStudyDoc, { isLoading: isFileUploading }] = useUpdateCaseStudyDocMutation();

  const onHandleChange = (event, type) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const formatFileForBE = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    const base64 = await convertBase64(file);
    return { fileName, base64 };
  };

  const handleFileUpload = async (event) => {
    const { name } = event.target;
    const { fileName, base64 } = await formatFileForBE(event);
    const parts = fileName.split(".");
    const extension = parts[parts.length - 1];
    if (extension !== "DOC" && extension !== "doc" && extension !== "PDF" && extension !== "pdf") {
      toast.error("Please upload a DOC or PDF file.");
      event.target.value = null;
      return;
    }
    if (!base64) {
      toast.error("File is empty");
      event.target.value = null;
      return;
    }
    setLocalState({ [name]: fileName, file: base64 });
  };
  const handleDeleteFile = (event) => {
    const { name } = event.target;
    setLocalState({ fileName: "", file: "" });
  };

  const payloadMap = [
    { role: T.PM, payload: { file: file, fileName: fileName, pmComment: comment }, message: T.CASE_STUDY_UPLOADED_SUCCESSFULLY },
    { role: T.MT, payload: { file: file, fileName: fileName, mtComment: comment }, message: T.CASE_STUDY_UPLOADED_SUCCESSFULLY },
    { role: T.PMO, payload: { pmoComment: comment, caseStudyApproval: false }, message: T.CASE_STUDY_REJECTED },
    {
      role: T.KRA,
      payload: {
        isPublished: true,
        publishedCaseStudyLink: publishLink,
        publishedComment: comment,
      },
      message: T.CASE_STUDY_PUBLISHED,
    },
  ];

  const handleSubmit = async () => {
    const payload = payloadMap.find((item) => item.role === userRole);
    updateCaseStudyDoc({ id: pId, document: payload.payload, role: payload.role.toLowerCase() })
      .unwrap()
      .then(() => {
        handleClose();
        toast.success(get(payload, "message", ""));
      });
  };

  const resetState = () => {
    setLocalState(defaultLocalState);
  };

  const handleClose = () => {
    resetState();
    handleCloseCaseStudyUpload();
    refreshTable();
  };

  const disableCondition = () => {
    const foundItem = payloadMap.find((item) => item.role === userRole);
    if (!foundItem) return true; // If no role is found, disable by default

    const { payload } = foundItem;

    // Define your validation rules based on the payload structure
    // const REGEX = /^[A-Za-z0-9.,]+(\s+[A-Za-z0-9.,]+)*$/;
    // const REGEX = /^[^\s].*[^\s]$/;
    const REGEX = /^\s*$/;
    const validationRules = {
      pmComment: (value) => !REGEX.test(value),
      mtComment: (value) => !REGEX.test(value),
      pmoComment: (value) => !REGEX.test(value),
      caseStudyApproval: (value) => typeof value === "boolean",
      isPublished: (value) => typeof value === "boolean",
      publishedCaseStudyLink: isUrl,
      publishedComment: (value) => !REGEX.test(value),
      message: (value) => !REGEX.test(value),
    };

    return Object.entries(payload).some(([key, value]) => {
      const validate = validationRules[key];
      return validate ? !validate(value) : !value;
    });
  };

  return (
    <>
      {isFileUploading && <MISLoader />}
      <MISDialog open={openCaseStudyUpload} handleClose={handleClose}>
        <DialogTitle width={"400px"}>
          <Grid container>
            <Grid item md={1} mt={0.5}>
              <ArrowBack onClick={handleClose} />
            </Grid>
            <Grid item md={11}>
              <Typography variant="h6" fontWeight={600} ml={2}>
                {canEditCaseStudy()
                  ? `Comment By ${userRole}`
                  : userRole === T.KRA
                  ? `Publish By ${userRole}`
                  : `Upload Case-Study By ${userRole}`}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ height: "inherit", px: 3 }}>
          {[T.KRA].includes(userRole) && (
            <Grid item xs={6} mb={2}>
              <MISTextField
                fullWidth
                required
                label={T.PUBLISH_LINK}
                placeholder={T.PUBLISH_LINK}
                size="small"
                variant="outlined"
                name="publishLink"
                value={publishLink}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderBottom: publishLink && `3px solid ${isUrl(publishLink) ? SUCCESS.main : ERROR.main}`,
                  },
                }}
                onChange={onHandleChange}
              />
            </Grid>
          )}
          {[T.PM, T.MT].includes(userRole) && (
            <Grid item xs={6} mb={2}>
              <WithInputLabel label={T.UPLOAD_CONTARCT}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={"auto"}>
                    <input
                      name="fileName"
                      style={{
                        display: "none",
                      }}
                      type="file"
                      key={fileName}
                      onChange={(e) => handleFileUpload(e)}
                      id="contractFileInput"
                    />
                    <label htmlFor="contractFileInput">
                      <Button
                        variant="contained"
                        component="span"
                        style={{
                          backgroundColor: fileName ? "transparent" : NETSMARTZ_THEME_COLOR,
                          color: fileName ? NETSMARTZ_THEME_COLOR : "white",
                          maxWidth: fileName ? "100%" : "",
                          marginTop: 10,
                          textTransform: "none",
                          borderRadius: "5px",
                        }}
                        startIcon={<UploadIcon />}
                      >
                        {fileName ? "Replace File" : "Upload File"}
                      </Button>
                    </label>
                  </Grid>
                  {fileName && (
                    <Grid item xs={12} md={"auto"}>
                      <div style={{ display: "flex", alignItems: "center", color: NETSMARTZ_THEME_COLOR }}>
                        <span style={{ fontSize: 13, margin: 10 }}>{`Chosen File: ${fileName}`}</span>
                        <IconButton onClick={(e) => handleDeleteFile(e)} style={{ color: NETSMARTZ_THEME_COLOR }}>
                          <CancelIcon />
                        </IconButton>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </WithInputLabel>
            </Grid>
          )}
          <Grid item xs={[T.PM, T.MT, T.KRA].includes(userRole) ? 6 : 12}>
            <MISTextField
              fullWidth
              label={T.COMMENT}
              placeholder={T.COMMENT}
              size="small"
              multiline
              rows={3}
              variant="outlined"
              name="comment"
              value={comment}
              required
              onChange={onHandleChange}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <MISFooterButton
            proceedButtonText={T.SAVE}
            justify="center"
            disableProceed={disableCondition()}
            size="medium"
            showLoader={isFileUploading}
            sx={{ p: 1, m: 1 }}
            handleClose={() => handleClose()}
            handleSubmit={() => handleSubmit()}
          />
        </DialogActions>
      </MISDialog>
    </>
  );
};

CaseStudyUploadFileModal.propTypes = {
  openCaseStudyUploadLinkModal: PropTypes.bool,
  editId: PropTypes.string,
  handleCaseStudyUploadLinkModalDialog: PropTypes.func,
  refreshTable: PropTypes.func,
  handleCaseStudyUploadLinkDialog: PropTypes.func,
  openCaseStudyLink: PropTypes.bool,
  pName: PropTypes.string,
  pId: PropTypes.string,
};

export default CaseStudyUploadFileModal;

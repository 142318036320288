import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
  Unstable_Grid2,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import MISDialog from "components/common/MISDialog";
import MISAutocomplete from "components/common/MISAutocomplete";

import { useAssignRoleMutation } from "api/members/assignRole";
import { useLazyGetUserByIdQuery } from "api/members/getUserById";
import { useRemoveRoleMutation } from "api/members/removeRole";
import { useLazyGetSubroleByEmailQuery } from "api/members/getSubroleOfMember";

import T from "T";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";
import { handleError } from "utils/error";
import { toast } from "react-toastify";
import MISFooterButton from "components/common/MISFooterButton";
import { useLazyGetRoleListQuery } from "api/roles/getRoleList";
import { PAGINATION } from "settings/constants/pagination";
import MISButton from "components/common/MISButton";
import usePMFetch from "hooks/usePMFetch";
const AssignRole = ({
  editAssign = false,
  assign = false,
  userId = "",
  memberId = "",
  subRolesMapping = [],
  handleDialog = noop,
  handleCloseDialog = noop,
  refreshTable = noop,
}) => {
  const initialState = {
    role: {
      userId: null,
      roleId: null,
      roleName: "",
      subRoles: [],
      memberId: null,
    },
    memberEmail: "",
    subRoleAccess: {},
    departments: [],
    expandedModule: NaN, //current module
  };
  const { INITIAL_PAGE } = PAGINATION;
  const ROWS_PER_PAGE = 10000;
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), initialState);

  const { role, subRoleAccess, memberEmail, departments, expandedModule } = localState;
  const { departmentList } = usePMFetch();
  const deptList = get(departmentList, "results", []);

  const [getRoleList, { data: roles }] = useLazyGetRoleListQuery();
  const [assignRole] = useAssignRoleMutation();
  const [getSubroleByEmail] = useLazyGetSubroleByEmailQuery();
  const [getUserById] = useLazyGetUserByIdQuery();
  const [removeRole] = useRemoveRoleMutation();

  const getRoles = (page, rowsPerPage) => {
    getRoleList({ page, rowsPerPage });
  };

  useEffect(() => {
    if (userId && editAssign) {
      setLocalState({ memberEmail: "" });
      getUserById({ id: userId })
        .unwrap()
        .then((res) => {
          setLocalState({
            role: {
              ...role,
              memberId: get(res, "results.member.id", null),
            },
            memberEmail: get(res, "results.member.email", ""),
          });
        });
    }
  }, [userId, editAssign]);

  useEffect(() => {
    if (memberEmail && editAssign) {
      getSubroleByEmail({ email: memberEmail })
        .unwrap()
        .then((res) => {
          let mappedSubRoles = [];
          const mappedSubRoleAccess = {};
          const subRoles = res.subRoles;
          const isAnySubRole = subRoles[0].subRole !== null;
          if (subRoles?.length && isAnySubRole) {
            for (let key of subRoles) {
              mappedSubRoles.push(key.subRole);
              mappedSubRoleAccess[key.subRole.id] = key.subRoleAccess.map((access) => access.id);
            }
          }
          setLocalState({
            role: {
              ...role,
              roleId: get(res, "roles.id", ""),
              roleName: get(res, "roles.roleName", ""),
              subRoles: mappedSubRoles,
            },
            subRoleAccess: mappedSubRoleAccess,
            departments: get(res, "departmentList", []),
          });
        });
    }
  }, [memberEmail]);

  useEffect(() => {
    getRoles(INITIAL_PAGE, ROWS_PER_PAGE);
  }, []);

  const handleChange = (event, newValue) => {
    const roleId = get(newValue, "roleId", "");
    const roleName = get(newValue, "roleName", "");
    if (roleName === T.TRAINEE) {
      setLocalState({ departments: [] });
    }
    setLocalState({
      role: { ...role, roleId, roleName },
    });
  };

  const handleSubRoleChange = (event, newValue, index) => {
    const updatedObj = { ...subRoleAccess };
    newValue.forEach((subRole) => {
      // if (!updatedObj[subRole.id]) {
      const accesses = subRole.subRoleAccessMapping
        .map((item) =>
          ["view", "list", "add", "delete", "edit"].includes(item.subRoleAccess.operation) ? item.subRoleAccess.id : null,
        )
        .filter((id) => id !== null);
      // updatedObj[subRole.id] = [
      //   subRole.subRoleAccessMapping.find((item) => ["view", "list"].includes(item.subRoleAccess.operation)).subRoleAccess.id,
      // ];
      updatedObj[subRole.id] = accesses;
      // }
    });
    setLocalState({
      role: { ...role, subRoles: newValue },
      subRoleAccess: updatedObj,
    });
  };

  const handleDepartmentChange = (event, newValue) => {
    setLocalState({ departments: newValue });
  };

  const handleSubRoleAccessChange = (event, subRoleId, accessId) => {
    setLocalState({
      subRoleAccess: {
        ...subRoleAccess,
        [subRoleId]: subRoleAccess[subRoleId]?.includes(accessId)
          ? subRoleAccess[subRoleId]?.filter((id) => id !== accessId)
          : [...subRoleAccess[subRoleId], accessId],
      },
    });
  };

  const handleExpand = (roleId) => (event, isExpanded) => {
    setLocalState({
      expandedModule: isExpanded ? roleId : NaN,
    });
  };

  const handleRemoveRole = (id) => {
    removeRole({ memberId: id })
      .unwrap()
      .then(() => {
        toast.success(T.ROLE_REMOVED_STATEMENT);
        refreshTable();
        setLocalState(initialState);
        handleCloseDialog();
      })
      .catch(handleError);
  };

  const handleRoleAssign = (e) => {
    const payload = {
      userId: userId,
      roleId: role.roleId,
      subRole: role.subRoles.map((subRole) => ({
        subRoleId: subRole.id,
        subRoleAccess: subRoleAccess[subRole.id],
      })),
      departmentId: departments.map((department) => department.id),
    };
    assignRole(payload)
      .unwrap()
      .then(() => {
        toast.success(T.ROLE_ASSIGNED_SUCCESSFULLY);
        setLocalState(initialState);
        refreshTable();
        handleCloseDialog();
      })
      .catch(handleError);
  };

  const filtered = get(roles, "results", []).map((role) => ({
    roleName: role.roleName,
    roleId: role.id,
  }));

  return (
    <MISDialog
      open={assign || editAssign}
      handleClose={() => {
        setLocalState(initialState);
        handleCloseDialog();
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        <Typography variant="h6">{editAssign ? `${T.EDIT_ROLE}` : `${T.ASSIGN} ${T.ROLE}`}</Typography>
      </DialogTitle>
      <DialogContent sx={{ width: "700px" }}>
        <Grid item xs={12}>
          <MISAutocomplete
            label={T.PRIMARY_ROLE}
            listDetails={filtered}
            getByLabelText={(option) => get(option, "roleName", "")}
            value={filtered.find((val) => val.roleName === role.roleName)}
            sx={{
              padding: "10px",
              ".MuiAutocomplete-popper": {
                zIndex: 1100,
              },
            }}
            onHandleChange={(event, newValue) => {
              handleChange(event, newValue);
            }}
          />

          <MISAutocomplete
            label={T.DEPARTMENT}
            multiple
            showSelectAll={true}
            listDetails={deptList}
            getByLabelText={(option) => get(option, "departmentName", "")}
            sx={{
              padding: "10px",
              ".MuiAutocomplete-popper": {
                zIndex: 1100,
              },
            }}
            value={departments}
            disabled={role.roleName === T.TRAINEE}
            onHandleChange={(event, newValue) => handleDepartmentChange(event, newValue)}
            limitTags={6}
            ListboxProps={{ style: { maxHeight: 150 } }}
          />

          <MISAutocomplete
            label={T.MODULES}
            showSelectAll={true}
            multiple
            listDetails={subRolesMapping}
            placeholder={T.SELECT}
            getByLabelText={(option) => get(option, "subRoleName", "")}
            value={role.roleName === T.TRAINEE ? (role.subRoles = []) : role.subRoles}
            disabled={role.roleName === T.TRAINEE}
            sx={{
              padding: "10px",
              ".MuiAutocomplete-popper": {
                zIndex: 1100,
              },
            }}
            onHandleChange={(event, newValue) => {
              handleSubRoleChange(event, newValue);
            }}
          />
        </Grid>
        <Grid sx={{ alignItems: "center" }} md={12} xs={12} ml={2}>
          {role.subRoles && role.subRoles.length ? (
            role.subRoles?.map((subRole, index) => {
              return (
                <Accordion key={subRole.id} expanded={subRole.id === expandedModule} onChange={handleExpand(subRole.id)}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`module-${subRole.id}-content`}
                    id={`module-${subRole.id}-header`}
                  >
                    <Unstable_Grid2 container sx={{ width: "95%", justifyContent: "space-between", alignItems: "center" }}>
                      <Unstable_Grid2
                        container
                        sm={"auto"}
                        xs={12}
                        sx={{ justifyContent: "space-between", alignItems: "center" }}
                      >
                        <Typography variant="subtitle1">{subRole.subRoleName}</Typography>
                      </Unstable_Grid2>
                      <Unstable_Grid2 sm={"auto"} xs={12}>
                        {`Selected: ${subRoleAccess[subRole.id]?.length}/${subRole.subRoleAccessMapping.length}`}
                      </Unstable_Grid2>
                    </Unstable_Grid2>
                  </AccordionSummary>
                  <AccordionDetails>
                    {subRole.id === expandedModule && (
                      <Grid md={12} xs={12}>
                        {subRole?.subRoleAccessMapping.map((access) => (
                          <FormControlLabel
                            key={access.subRoleAccess.id}
                            control={
                              <Checkbox
                                checked={subRoleAccess[subRole.id]?.includes(access.subRoleAccess.id)}
                                onChange={(event) => handleSubRoleAccessChange(event, subRole.id, access.subRoleAccess.id)}
                              />
                            }
                            label={access.subRoleAccess.operation.toUpperCase()}
                          />
                        ))}
                      </Grid>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            <div>
              <i>{"Access is not defined"}</i>
            </div>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <MISFooterButton
          proceedButtonText={T.ASSIGN}
          justify="center"
          sx={{ p: 1.5 }}
          size="medium"
          handleClose={() => {
            setLocalState(initialState);
            handleCloseDialog();
          }}
          handleSubmit={handleRoleAssign}
        />
        {editAssign && (
          <MISButton size="medium" onClick={() => handleRemoveRole(role.memberId)}>
            {T.REMOVE_ROLE}
          </MISButton>
        )}
      </DialogActions>
    </MISDialog>
  );
};

AssignRole.propTypes = {
  subRolesMapping: PropTypes.array,
  editAssign: PropTypes.bool,
  assign: PropTypes.bool,
  userId: PropTypes.string,
  handleDialog: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  refreshTable: PropTypes.func,
  memberId: PropTypes.string,
};

export default AssignRole;

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    addSwotTraining: build.mutation({
      query: ({ userId, swotId, payload }) => ({
        url: "/swotAnalysis/employee/assignTraining",
        method: "POST",
        body: payload,
        params: { userId, swotId },
      }),
    }),
  }),
});

export const { useAddSwotTrainingMutation } = extendedApi;

import { createSlice } from "@reduxjs/toolkit";

const videoInterviewSlice = createSlice({
  name: "VideoInterviewSlice",
  initialState: {
    receivedTechSet1: false,
    receivedTechSet2: false,
    videoResponses: [],
    videoResponsesFormData: {},
    participantDetails: {},
    formSubmitted: false,
    videoInterviewUserRegistered: false,
    openInstructionModal: false,
    responseArr: [],
    techQuestions: [],
    startTime: null,
    timeLeft: 20 * 60,
    isVideoInterviewAssigned: null,
  },
  reducers: {
    videoInterviewTechQuestionStore: (state, action) => {
      state.techQuestions = action.payload.techQuestions;
    },
    videoInterviewTechSet1Recieved: (state, action) => {
      state.receivedTechSet1 = action.payload.receivedTechSet1;
    },
    videoInterviewTechSet2Recieved: (state, action) => {
      state.receivedTechSet2 = action.payload.receivedTechSet2;
    },
    videoInterviewStore: (state, action) => {
      state.videoResponses = action.payload.videoResponses;
    },
    videoInterviewFormDataStore: (state, action) => {
      state.videoResponsesFormData = action.payload.videoResponsesFormData;
    },
    videoInterviewParticipantIdStore: (state, action) => {
      state.participantDetails = action.payload.participantDetails;
    },
    videoInterviewStore1: (state, action) => {
      state.formSubmitted = action.payload.formSubmitted;
    },
    manageVideoInterviewUserLogin: (state, action) => {
      state.videoInterviewUserRegistered = action.payload.videoInterviewUserRegistered;
    },
    videoInterviewResponseArrStore: (state, action) => {
      state.responseArr = action.payload.responseArr;
    },
    videoInterviewInstructionModal: (state, action) => {
      state.openInstructionModal = action.payload.openInstructionModal;
    },
    setStartTime: (state, action) => {
      state.startTime = action.payload.startTime;
    },
    updateTimeLeft: (state, action) => {
      state.timeLeft = action.payload.timeLeft;
    },
    videoInterviewStatus: (state, action) => {
      state.isVideoInterviewAssigned = action.payload.isVideoInterviewAssigned;
    },
  },
});
export const {
  videoInterviewTechSet1Recieved,
  videoInterviewTechSet2Recieved,
  videoInterviewTechQuestionStore,
  videoInterviewStore,
  videoInterviewFormDataStore,
  videoInterviewParticipantIdStore,
  videoInterviewStore1,
  manageVideoInterviewUserLogin,
  videoInterviewResponseArrStore,
  videoInterviewInstructionModal,
  setStartTime,
  updateTimeLeft,
  videoInterviewStatus,
} = videoInterviewSlice.actions;
export default videoInterviewSlice.reducer;

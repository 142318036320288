import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { PAGINATION } from "settings/constants/pagination";
import Typography from "@mui/material/Typography";
import T from "T";
import { Container, styled } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import AssignedCourseTable from "./AssignedCourseTable";

const HeaderTypography = styled(Typography)(() => ({
  fontWeight: "bold",
}));

const SubHeaderTypography = styled(Typography)(() => ({
  fontWeight: "normal",
  opacity: 0.5,
}));

const NetsmartzWarningAmberRoundedIcon = styled(WarningAmberRoundedIcon)(({ theme }) => ({
  color: theme.palette.themeColor,
  fontSize: "40px",
}));

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const AssignedCourseTab = ({
  page = INITIAL_PAGE,
  onPageChange,
  rowsPerPage = ROWS_PER_PAGE,
  allTableRows = [],
  onRowsPerPageChange,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  // handleReview = noop,
}) => {
  const isHavingTraining = allTableRows.length > 0;

  return (
    <AssignedCourseTable
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onRowsPerPageChange}
      totalTableRowsCount={totalTableRowsCount}
      totalPageCount={totalPageCount}
      allTableRows={allTableRows}
    />
  );
};

AssignedCourseTab.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  allTableRows: PropTypes.array,
};

export default AssignedCourseTab;

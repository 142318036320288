import { createSlice } from "@reduxjs/toolkit";

const memberActiveSlice = createSlice({
  name: "MemberActiveSlice",
  initialState: {
    storedActive: true,
  },
  reducers: {
    memberActiveStore: (state, action) => {
      state.storedActive = action.payload.storedActive;
    },
  },
});
export const { memberActiveStore } = memberActiveSlice.actions;

export default memberActiveSlice.reducer;

import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ListIcon from "@mui/icons-material/List";
import MenuItem from "@mui/material/MenuItem";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Menu from "@mui/material/Menu";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import PropTypes from "prop-types";
import T from "T";
import { useTheme } from "@emotion/react";

const ActionIconButton = ({
  userId,
  id,
  handleCodeAssessment,
  handleSubmitAssessmentDialog,
  handleViewAssessmentDialog,
  handleScheduleInterview,
  handleCancelInterview,
  handleInterviewChecklist,
  handlePreInterviewDialog,
  handleScheduleMockInterview,
  hasCodeAssessment,
  codeAssessmentSubmission,
  hasInterviewSchedules,
  interviewRound,
  currentDateTime,
  managerChecklistVisible,
  pitchedUserNotified,
  hasMockInterviewSchedule,
  interview1DateTime,
  interview2DateTime,
  hasRound2,
}) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const theme = useTheme();

  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleMenuItemClick = (action) => {
    handleMenuClose();
    switch (action) {
      case "codeAssessment":
        handleCodeAssessment(userId);
        break;
      case "submitAssessment":
        handleSubmitAssessmentDialog(userId);
        break;
      case "viewAssessment":
        handleViewAssessmentDialog(userId);
        break;

      case "schedule1":
        handleScheduleInterview(id, "schedule1");
        break;
      case "reschedule1":
        handleScheduleInterview(id, "reschedule1");
        break;
      case "cancelInterview1":
        handleCancelInterview(id, "1");
        break;
      case "cancelInterview2":
        handleCancelInterview(id, "2");
        break;
      case "preInterviewDialog":
        handlePreInterviewDialog(id);
        break;
      case "scheduleMockInterview":
        handleScheduleMockInterview(id);
        break;
      case "schedule2":
        handleScheduleInterview(id, "schedule2");
        break;
      case "reschedule2":
        handleScheduleInterview(id, "reschedule2");
        break;
      case "interviewChecklist":
        handleInterviewChecklist(id, managerChecklistVisible);
        break;

      default:
        break;
    }
  };
  return (
    <div>
      <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }} onClick={handleMenuClick}>
        {menuAnchor ? <MenuOpenIcon /> : <ListIcon />}
      </IconButton>
      <Menu
        sx={{
          zIndex: theme.zIndex.modal + 3,
          "& .MuiMenu-list": {
            pt: 0,
            pb: 0,
            minWidth: 150,
          },

          "& .MuiMenuItem-root": {
            "&:hover": {
              backgroundColor: NETSMARTZ_THEME_COLOR,
              color: BACKGROUND.white,

              img: {
                filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(254deg) brightness(106%) contrast(101%)",
              },
            },
          },
        }}
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {!hasCodeAssessment && <MenuItem onClick={() => handleMenuItemClick("codeAssessment")}>{T.CODE_ASSESSMENT}</MenuItem>}
        {hasCodeAssessment && !codeAssessmentSubmission && (
          <MenuItem onClick={() => handleMenuItemClick("submitAssessment")}>{"Submit Assessment"}</MenuItem>
        )}
        {codeAssessmentSubmission && (
          <MenuItem onClick={() => handleMenuItemClick("viewAssessment")}>{"Assessment Details"}</MenuItem>
        )}
        {hasInterviewSchedules && !interviewRound?.includes(2) && (
          <MenuItem onClick={() => handleMenuItemClick("reschedule1")}>{T.RESCHEDULE_INTERVIEW}</MenuItem>
        )}
        {hasInterviewSchedules && interviewRound?.includes(2) && (
          <MenuItem onClick={() => handleMenuItemClick("reschedule2")}>{T.RESCHEDULE_INTERVIEW_2}</MenuItem>
        )}
        {!interviewRound?.includes(2) && !hasInterviewSchedules && (
          <MenuItem onClick={() => handleMenuItemClick("schedule1")}>{T.SCHEDULE_INTERVIEW}</MenuItem>
        )}
        {hasInterviewSchedules && interview1DateTime > currentDateTime && !hasRound2 && (
          <MenuItem onClick={() => handleMenuItemClick("cancelInterview1")}>{T.CANCEL_INTERVIEW + " Round 1"} </MenuItem>
        )}
        {interviewRound?.includes(1) && !interviewRound?.includes(2) && (
          <MenuItem onClick={() => handleMenuItemClick("schedule2")}>{T.SCHEDULE_INTERVIEW_2}</MenuItem>
        )}
        {hasInterviewSchedules && interview2DateTime > currentDateTime && hasRound2 && (
          <MenuItem onClick={() => handleMenuItemClick("cancelInterview2")}>{T.CANCEL_INTERVIEW + " Round 2"} </MenuItem>
        )}
        {managerChecklistVisible && (
          <MenuItem onClick={() => handleMenuItemClick("interviewChecklist")}>{T.INTERVIEW_CHECKLIST}</MenuItem>
        )}
        {!pitchedUserNotified && (
          <MenuItem onClick={() => handleMenuItemClick("preInterviewDialog")}>{T.PRE_INTERVIEW_NOTIFICATION}</MenuItem>
        )}
        {!hasMockInterviewSchedule && hasInterviewSchedules && (
          <MenuItem onClick={() => handleMenuItemClick("scheduleMockInterview")}>{T.SCHEDULE_MOCK_INTERVIEW}</MenuItem>
        )}
      </Menu>
    </div>
  );
};
ActionIconButton.propTypes = {
  handleMenuAnchor: PropTypes.func,
  handleCodeAssessment: PropTypes.func,
  handleSubmitAssessmentDialog: PropTypes.func,
  handleViewAssessmentDialog: PropTypes.func,
  handleScheduleInterview: PropTypes.func,
  handleCancelInterview: PropTypes.func,
  handleInterviewChecklist: PropTypes.func,
  handlePreInterviewDialog: PropTypes.func,
  handleScheduleMockInterview: PropTypes.func,
  menuAnchor: PropTypes.object,
  userId: PropTypes.number,
  id: PropTypes.number,
  hasCodeAssessment: PropTypes.bool,
  codeAssessmentSubmission: PropTypes.any,
  hasInterviewSchedules: PropTypes.bool,
  interviewRound: PropTypes.array,
  currentDateTime: PropTypes.object,
  interviewDateTime: PropTypes.object,
  managerChecklistVisible: PropTypes.bool,
  pitchedUserNotified: PropTypes.any,
  hasMockInterviewSchedule: PropTypes.any,
  interview1DateTime: PropTypes.object,
  interview2DateTime: PropTypes.object,
  hasRound2: PropTypes.bool,
};

export default ActionIconButton;

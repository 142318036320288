import { Children } from "react";
import PropTypes from "prop-types";
import { noop, isEmpty } from "lodash";
import { Typography, Divider, Card, Button, Box, Tooltip } from "@mui/material";
import MISAutocomplete from "components/common/MISAutocomplete";
import { ClickAwayListener } from "@mui/material";
import { INSIDE_SALES_FILTER_OPTIONS } from "settings/constants/filter";
import { NETSMARTZ_THEME_COLOR, BACKGROUND } from "theme/colors";
import { GET_SIZE } from "utils/responsive";
import { isNumber } from "utils/validations";
import T from "T";
import { get } from "utils/lodash";
import MISTextField from "components/common/MISTextField";
import { toast } from "react-toastify";

const FilterOptions = ({
  filters = {},
  skillList = {},
  handleFilterClose = noop,
  handleFilterSubmit = noop,
  onhandleFilterChange = noop,
  handleClose = noop,
}) => {
  const technologyList = get(skillList, "results", []);
  const { isXs } = GET_SIZE();

  const getFilterRecords = (val) => {
    switch (val) {
      case T.TECHNOLOGY:
        return technologyList;
      case T.EXPERIENCE:
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      default:
    }
  };

  const getLabelData = (option, index) => {
    const optionVal = isEmpty(option) ? "" : option;

    switch (index) {
      case 0:
        return get(option, "skillName", "");
      case 1:
        return optionVal;
      default:
    }
  };

  const handleFilterSubmitClick = () => {
    const { minExp, maxExp } = filters;

    if ((minExp && !maxExp) || (!minExp && maxExp)) {
      toast.error("Fill both min and max experience");
      return;
    }

    handleFilterSubmit();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Card
        sx={{
          mt: 1,
          position: "absolute",
          width: isXs ? "80%" : "25%",
          zIndex: 400,
        }}
      >
        <Box
          sx={{
            maxHeight: "calc(100vh - 250px)",
            overflowY: "auto",
          }}
        >
          <Typography variant="subtitle1" p={1} textAlign="center">
            {T.FILTER}
          </Typography>
          <Divider />

          {Children.toArray(
            INSIDE_SALES_FILTER_OPTIONS.map((item, index) => {
              const lists = getFilterRecords(item);

              const multiple = [T.TECHNOLOGY].includes(item);

              return (
                <Box display="flex" p="10px 20px" justifyContent="space-between">
                  <Tooltip title={item} placement="top">
                    <Typography variant="subtitle1" noWrap>
                      {item}
                    </Typography>
                  </Tooltip>

                  {item === `${T.EXPERIENCE} (In Years)` && (
                    <Box display="flex">
                      <MISTextField
                        required
                        placeholder={T.MIN}
                        size="small"
                        variant="outlined"
                        name="minExp"
                        sx={{ width: 60 }}
                        value={Object.keys(filters).length > 0 ? filters.minExp : ""}
                        onChange={(event) => {
                          const { value } = event.target;

                          if (value && !isNumber(value)) {
                            toast.error(T.ONLY_NUMBERS_ALLOWED);
                            return;
                          }

                          onhandleFilterChange(value, "minExp");
                        }}
                      />

                      <MISTextField
                        required
                        placeholder={T.MAX}
                        size="small"
                        variant="outlined"
                        name="maxExp"
                        sx={{ ml: 8, width: 60 }}
                        value={Object.keys(filters).length > 0 ? filters.maxExp : ""}
                        onChange={(event) => {
                          const { value } = event.target;
                          if (value && !isNumber(value)) {
                            toast.error(T.ONLY_NUMBERS_ALLOWED);
                            return;
                          }

                          onhandleFilterChange(value, "maxExp");
                        }}
                      />
                    </Box>
                  )}

                  {item !== `${T.EXPERIENCE} (In Years)` && (
                    <MISAutocomplete
                      multiple={multiple}
                      listDetails={lists}
                      size="small"
                      placeholder={T.SELECT}
                      sx={{
                        width: 185,
                      }}
                      value={Object.keys(filters).length > 0 ? filters[item] : []}
                      getByLabelText={(option) => getLabelData(option, index)}
                      onHandleChange={(event, newValue) => {
                        onhandleFilterChange(newValue, item);
                      }}
                    />
                  )}
                </Box>
              );
            }),
          )}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderColor: BACKGROUND.black,
              color: BACKGROUND.black,

              "&:hover": {
                borderColor: NETSMARTZ_THEME_COLOR,
                color: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterClose}
          >
            {T.RESET}
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterSubmitClick}
          >
            {T.APPLY}
          </Button>
        </Box>
      </Card>
    </ClickAwayListener>
  );
};

FilterOptions.propTypes = {
  skillList: PropTypes.object,
  filters: PropTypes.object,
  onhandleFilterChange: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleFilterClose: PropTypes.func,
  handleClose: PropTypes.func,
};

export default FilterOptions;

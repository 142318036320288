import styled from "@emotion/styled";
import { TableBody, TableRow, TableCell } from "@mui/material";
import PropTypes from "prop-types";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  borderBottom: "none",
  overflow: "hidden",
  fontSize: 13,
  paddingBottom: 0,
}));

const SIRTableBody = ({ sirData }) => (
  <TableBody>
    {sirData.map((row, index) => (
      <TableRow key={row.id}>
        <StyledTableCell>{index + 1}.</StyledTableCell>
        <StyledTableCell>{row.sirCategories.categoryName}</StyledTableCell>
        <StyledTableCell>{row.sirCategories.groupName}</StyledTableCell>
        <StyledTableCell>{row.description}</StyledTableCell>
        <StyledTableCell>{row.createdBy.name}</StyledTableCell>
        <StyledTableCell>{new Date(row.createdAt).toLocaleDateString()}</StyledTableCell>
      </TableRow>
    ))}
  </TableBody>
);
SIRTableBody.propTypes = {
  sirData: PropTypes.array,
};
export default SIRTableBody;

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    askForReferrals: build.mutation({
      query: ({ user_id }) => ({
        url: `/talentAcquisition/sendEmailForReferral`,
        method: "POST",
        params: { user_id },
      }),
    }),
  }),
});

export const { useAskForReferralsMutation } = extendedApi;

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    deleteRecordings: build.mutation({
      query: (mock_id) => ({
        url: "ai/mock/interview/delete/audioFiles",
        method: "GET",
        params: { mock_id: mock_id },
      }),
    }),
  }),
});

export const { useDeleteRecordingsMutation } = extendedApi;

import T from "T";

export const SOURCE_TYPES = [T.INSIDE_SALES, T.SELF_PROSPECTING, T.EXISTING_CLIENT, T.OTHERS];

export const YES_OR_NO = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
export const REQUIREMENT_STATUS_LIST = [T.SELECTED, T.LOST, T.ON_HOLD, T.OPEN, T.PARTIALLY_CLOSED, T.WON];

export const BUDGET_TYPES = [T.LOW, T.MID, T.AT_PAR];
export const EXPERIENCE_RANGE_LIST = ["0-2", "2-5", "5-8", "8-10", "10-15"];
export const NO_OF_OPENINGS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
export const SELECTION_PROCESS_LIST = ["Process 1", "Process 2", "Process 3", "Process 4"];
export const SHIFT_TIMINGS = ["10am-7pm", "11am-8pm", "12pm-9pm", "1pm-10pm", "2pm-11pm"];

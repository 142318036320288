import { useReducer, useState } from "react";
import { Grid, Paper, Box, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { format, isValid } from "date-fns";
import { toast } from "react-toastify";
import { useCodeAssessmentMutation } from "api/preSales/codeAssessment";
import { useLocation, useNavigate } from "react-router-dom";
import T from "T";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { handleError } from "utils/error";
import ConfirmCancel from "components/PreSales/ConfirmCancel";
import MISDatePicker from "components/common/MISDatePicker";
import MISTextField from "components/common/MISTextField";
import MISFooterButton from "components/common/MISFooterButton";
import MISDragDrop from "components/common/MISDragDrop";

const CodeAssessment = () => {
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [codeAssessment] = useCodeAssessmentMutation();
  const location = useLocation();
  const userId = location.state ? location.state.userId : null;
  const navigate = useNavigate();

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    date: null,
    files: [],
    instructions: "",
  });

  const { date, files, instructions } = localState;
  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "date" && value !== null) {
      const validDate = new Date(value);
      if (isValid(validDate)) {
        setLocalState({ [name]: getBEDateFormat(validDate) });
        return;
      }
    }

    setLocalState({ [name]: value });
  };

  const handleFilesChange = (updatedFiles) => {
    setLocalState({ files: updatedFiles });
  };

  const handleCloseConfirmCancel = (proceed) => {
    setOpenConfirmCancel(false);
    if (proceed) {
      handleBack();
    }
  };
  const handleCancel = () => {
    setOpenConfirmCancel(true);
  };

  const handleSubmit = () => {
    if (date && files.length > 0 && instructions) {
      const formData = new FormData();

      formData.append("submissionDate", date);
      formData.append("instructions", instructions);

      files.forEach((file) => {
        formData.append("files", file);
      });
      codeAssessment({ userId, formData })
        .then((res) => {
          if (res.error) {
            handleError(res.error);
          } else {
            toast.success("Code Assessment successfully sent");
            navigate(-1);
          }
        })
        .catch(handleError);
    } else {
      toast.error("Please fill all the Required Fields");
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Paper sx={{ p: 3, pb: 5 }}>
      <Box mb={3.5} mt={1.5}>
        <Box display="flex" alignItems="center">
          <IconButton fontSize="small" sx={{ cursor: "pointer", mr: 1 }} onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" fontWeight={600}>
            {T.CODE_ASSESSMENT}
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={8} px={4}>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MISDatePicker
                placeholder="Select Date"
                inputFormat="MM/DD/YYYY"
                value={date}
                label="Submission Deadline"
                name="date"
                disablePast
                handleChange={(newValue) => handleChange({ target: { name: "date", value: newValue } })}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <MISTextField
                label="Special Instructions"
                fullWidth
                multiline
                rows={8}
                required
                size="small"
                variant="outlined"
                name="instructions"
                value={instructions}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} mt={3} justifyContent={"center"}>
          <MISDragDrop files={files} setFiles={handleFilesChange} multiple={true} />
        </Grid>
      </Grid>

      <Grid display="flex" justifyContent="flex-end" mt={4} item md={12} xs={12}>
        <MISFooterButton handleSubmit={handleSubmit} handleClose={handleCancel} />
      </Grid>
      <ConfirmCancel
        openConfirmCancel={openConfirmCancel}
        confrmCancelModal={() => handleCloseConfirmCancel(false)}
        handleClose={() => handleCloseConfirmCancel(true)}
      />
    </Paper>
  );
};

export default CodeAssessment;

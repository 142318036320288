import React, { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography, Box, IconButton, Tooltip } from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import memo from "utils/react";
import { get } from "utils/lodash";
import { MISCurrentUser } from "utils/validations";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import T from "T";
import { noop } from "lodash";
import { TASKBOX_HEADER_COLUMNS } from ".";

const TaskboxHeader = ({
  columns = TASKBOX_HEADER_COLUMNS,
  //   handleAssignSorting = noop,
  //   memberNameSorting = false,
  //   courseNameSorting = false,
  //   courseDurationSorting = false,
}) => {
  return (
    <TableHead>
      <TableRow sx={{ height: 30 }}>
        {Children.toArray(
          columns.map((column, ind) => {
            const columnName = get(column, "label", "").toUpperCase();
            return (
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: BACKGROUND.header,
                  padding: "0px 8px 0px 24px",
                }}
              >
                <Typography variant="body1" fontSize={14} noWrap fontWeight={600}>
                  {columnName}
                </Typography>
              </TableCell>
            );
          }),
        )}
      </TableRow>
    </TableHead>
  );
};

TaskboxHeader.propTypes = {
  columns: PropTypes.array,
};

export default memo(TaskboxHeader);

import { createSlice } from "@reduxjs/toolkit";

const recommendationsTabSlice = createSlice({
  name: "RecommendationsTabSlice",
  initialState: {
    storedTab: "",
    currentPage: 0,
  },

  reducers: {
    recommendationsTabStore: (state, action) => {
      state.storedTab = action.payload.storedTab;
      state.currentPage = action.payload.currentPage;
    },
  },
});

export const { recommendationsTabStore } = recommendationsTabSlice.actions;
export default recommendationsTabSlice.reducer;

import { Typography, Box, Card, Paper } from "@mui/material";
import MISDialog from "components/common/MISDialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import PropTypes from "prop-types";

const ViewAssessment = ({
  handleViewAssessmentDialog,
  handleCodeAssessmentFile,
  pitchedUserId,
  assessmentDetails,
  openViewDialog = false,
}) => {
  return (
    <MISDialog open={openViewDialog}>
      <Box sx={{ display: "flex", flexDirection: "column", p: 1, width: 580, height: 285 }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
            padding: "5px",
          }}
        >
          <Box display="flex" alignItems="center" pl={1} pt={1.5}>
            <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={() => handleViewAssessmentDialog()} />
            <Typography variant="h6" fontWeight={700} ml={1}>
              {T.VIEW_CODE_ASSESMENT}
            </Typography>
          </Box>
        </Box>

        <Paper
          sx={{
            p: 1.4,
            m: 1,
            mt: 1,
            backgroundColor: "background.card",
            boxShadow: "none",
            flexGrow: 1,
            borderRadius: 1,
          }}
        >
          {assessmentDetails && (
            <Card sx={{ padding: 2, borderRadius: 0 }}>
              <Box sx={{ display: "flex", flexDirection: "row", p: 1 }}>
                <Typography fontWeight={600}>{T.SUBMISSION_DEADLINE}:</Typography>
                <Typography variant="body1" sx={{ marginLeft: 1 }}>
                  {assessmentDetails.submissionDeadline}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", p: 1 }}>
                <Typography fontWeight={600}>{T.SUBMISSION_DATE}:</Typography>
                <Typography variant="body1" sx={{ marginLeft: 1 }}>
                  {assessmentDetails.submissionDate}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", p: 1 }}>
                <Typography fontWeight={600}>{T.COMMENTS_FEEDBACK}:</Typography>
                <Typography variant="body1" sx={{ marginLeft: 1 }}>
                  {assessmentDetails.comments}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", p: 1 }}>
                <Typography fontWeight={600}>{T.ASSESSMENT_LINK}:</Typography>
                {assessmentDetails.link && (
                  <Typography
                    onClick={() => handleCodeAssessmentFile(pitchedUserId)}
                    variant="body1"
                    sx={{
                      marginLeft: 1,
                      color: "blue",
                      fontSize: 16,
                      textDecoration: "underline",
                      cursor: "pointer",
                      wordBreak: "break-word",
                    }}
                  >
                    Click here {/* {assessmentDetails.link} */}
                  </Typography>
                )}
              </Box>
            </Card>
          )}
        </Paper>
      </Box>
    </MISDialog>
  );
};

ViewAssessment.propTypes = {
  handleViewAssessmentDialog: PropTypes.func,
  handleCodeAssessmentFile: PropTypes.func,
  pitchedUserId: PropTypes.number,
  openViewDialog: PropTypes.bool,
  assessmentDetails: PropTypes.object,
};
export default ViewAssessment;

import PropTypes from "prop-types";
import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import { MANAGER_ASSIGNMENTS_TABLE_HEADER, MEMBER_ASSIGNMENTS_TABLE_HEADER } from "settings/constants/members";
import { PAGINATION } from "settings/constants/pagination";
import MemberAssignmentTableBody from "./MemberAssignmentTableBody";
import MISNoData from "components/common/MISNoData";
import TableHeader from "./TableHeader";
import { canAddTechAssignment } from "utils/permissions";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const MemberAssignmentTable = ({
  page = INITIAL_PAGE,
  onPageChange,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  allTableRows = [],
}) => {
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mt: 1.5,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          sx={{
            height: canAddTechAssignment() ? "calc(100vh - 260px)" : "calc(100vh - 200px)",
            overflow: "auto",
          }}
        >
          {allTableRows.length !== 0 ? (
            <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
              <TableHeader
                columns={canAddTechAssignment() ? MANAGER_ASSIGNMENTS_TABLE_HEADER : MEMBER_ASSIGNMENTS_TABLE_HEADER}
              />
              <MemberAssignmentTableBody
                page={page}
                onPageChange={onPageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={onRowsPerPageChange}
                totalTableRowsCount={totalTableRowsCount}
                totalPageCount={totalPageCount}
                allTableRows={allTableRows}
              />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
      </Box>
    </Card>
  );
};

MemberAssignmentTable.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalTableRowsCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  allTableRows: PropTypes.array,
};
export default MemberAssignmentTable;

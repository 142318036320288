import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MISCurrentUser } from "utils/validations";
import T from "T";
import MISDialog from "components/common/MISDialog";
import { TitleTypography, SubTitleTypography, MainTitleTypography } from "utils/members";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { get } from "lodash";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import styled from "@emotion/styled";
import Constants from "Constants";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { turnToArray } from "utils/commonFunction";
const { SERVER_URL } = Constants;

export const KeyTypography = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "22px",
  whiteSpace: "nowrap",
}));

export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const ViewSubCourse = ({ openSubCourse = false, record = {}, handleSubCourse = noop }) => {
  const { sessionToken } = MISCurrentUser();

  const handleBack = () => {
    handleSubCourse();
  };
  const handleFileLinkOpen = (subCourseLink) => {
    toast.success(T.JOB_DETAILS_FILE_DOWNLOADED_SUCCESSFULLY);
    window.location.href = subCourseLink;
  };
  const handleExport = async (Id) => {
    try {
      const response = await fetch(`${SERVER_URL}/subCourse/get/subCourseFileLink?resourceFileLinkId=${Id}`, {
        headers: {
          method: "GET",
          Authorization: `Bearer ${sessionToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      handleFileLinkOpen(responseData.subCourseFileLink);
    } catch (error) {
      handleError();
    }
  };

  const subsCourseName = get(record, "subsCourseName", "");
  const subDescription = get(record, "description", "");
  const resourceLink = get(record, "resourceLink", []);
  const fileLink = get(resourceLink[0], "fileLink", "");
  const training_resource_link_id = get(resourceLink[0], "training_resource_link_id", "");
  const resourceLinks = turnToArray(get(resourceLink[0], "resourceLink"));
  return (
    <MISDialog open={openSubCourse}>
      <Box sx={{ width: "500px" }}>
        <Box display="flex" alignItems="center" mb={1} pl={1} pt={2} sx={{ backgroundColor: BACKGROUND.cardDefault }}>
          <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack} />
          <Typography variant="h6" fontWeight={700} ml={1}>
            {T.SUB_COURSE.toUpperCase()}
          </Typography>
        </Box>

        <Grid container sx={{ p: 2 }} spacing={2}>
          <Grid item xs={12} sx={{ pt: 2 }}>
            <Box display="flex" whiteSpace="nowrap" mb={3}>
              <KeyTypography>{T.SUB_COURSE_NAME}:</KeyTypography>
              <Tooltip title={subsCourseName} placement="top">
                <TitleTypography ml={1}>{subsCourseName}</TitleTypography>
              </Tooltip>
            </Box>

            <Box display="flex" whiteSpace="nowrap" mb={3}>
              <KeyTypography>{T.DESCRIPTION}:</KeyTypography>
              <TitleTypography ml={1}>{subDescription}</TitleTypography>
            </Box>
            <Box display="flex" whiteSpace="nowrap" mb={3}>
              <KeyTypography>{T.RESOURCE_LINKS}:</KeyTypography>
              <br></br>
              <MainTitleTypography>
                {resourceLinks
                  ? resourceLinks.map((link, index) => (
                      <div key={index}>
                        <Box display="flex" whiteSpace="nowrap">
                          <SubTitleTypography>
                            {T.LINK}
                            {` ${index + 1}`}:
                          </SubTitleTypography>
                          <TitleTypography textOverflow="" ml={1}>
                            <a href={link} target="_blank" rel="noreferrer">
                              {link}
                            </a>
                          </TitleTypography>
                        </Box>
                      </div>
                    ))
                  : null}
              </MainTitleTypography>
            </Box>
            <Tooltip title={T.DOWNLOAD} placement="top">
              <IconButton
                onClick={() => handleExport(training_resource_link_id)}
                disabled={!fileLink}
                sx={{
                  mr: 1,
                  height: 40,
                  cursor: "pointer",
                  color: NETSMARTZ_THEME_COLOR,
                }}
              >
                <FileDownloadOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </MISDialog>
  );
};

ViewSubCourse.propTypes = {
  openSubCourse: PropTypes.bool,
  record: PropTypes.object,
  handleSubCourse: PropTypes.func,
};

export default ViewSubCourse;

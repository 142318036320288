import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    licenseStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/tools/license/status?toolsUserMappingId=${id}&status=${status}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useLicenseStatusMutation } = extendedApi;

import { useReducer } from "react";
import { BACKGROUND, ERROR, NETSMARTZ_THEME_COLOR, SUCCESS } from "theme/colors";
import { SubTitleTypography, TitleTypography } from "utils/members";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Box, Grid, Paper, Button, Card, CircularProgress, IconButton, Divider } from "@mui/material";
import { GET_SIZE } from "utils/responsive";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import { toast } from "react-toastify";
import MISTextField from "components/common/MISTextField";
import WithInputLabel from "components/common/WithInputLabel";
import MISDragDrop from "components/common/MISDragDrop";
import MISFooterButton from "components/common/MISFooterButton";
import { useSubmitAssignmentMutation } from "api/Assignment/submitAssignment";
import { get } from "lodash";
import { MISCurrentUser, isEmptyString, isNumberNegative } from "utils/validations";
import { handleError } from "utils/error";
import { useSubmitSolutionMutation } from "api/Assignment/submitSolution";
import { useUpdateStatusMutation } from "api/Assignment/updateStatus";
import MISLoader from "components/common/MISLoader";
import { canAddTechAssignment } from "utils/permissions";
import DownloadIcon from "@mui/icons-material/Download";
import Constants from "Constants";

const Assignment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = MISCurrentUser();
  const empCode = get(user, "user.employeeCode", "");
  const { state } = location;
  const assignedBy = get(state, "assignedBy.name", "");
  const status = get(state, "status", "");
  const technology = get(state, "technology", "");
  const assignmentDate = get(state, "assignedDate", "");
  const submissionDate = get(state, "submissionDate", null);
  const totalTimeTaken = get(state, "timeTaken", null);
  const problemStatement = get(state, "assessmentQuestions", "");
  const assignmentId = get(state, "id", "");
  const assignmentDuration = get(state, "assignedDuration", "");
  const assignmentResponse = get(state, "assessmentAnswerObject", null);
  const [submitAssignment, { isLoading: isSubmittingAssignment }] = useSubmitAssignmentMutation();
  const [submitSolution, { isLoading: isSubmittingSolution }] = useSubmitSolutionMutation();
  const [updateStatus, { isLoading: isUpdatingStatus }] = useUpdateStatusMutation();
  const { SERVER_URL } = Constants;

  const { isLg } = GET_SIZE();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    completionFile: [],
    timeTaken: "",
    Data: location.state,
    assignmentSultionStarted: false,
  });
  const { completionFile, timeTaken, assignmentSultionStarted } = localState;

  const validateSubmission = () => completionFile.length === 0 || !timeTaken || isEmptyString(timeTaken);
  const checkIfNumberIsNegative = () => isNumberNegative(timeTaken);
  const handleBack = () => {
    navigate(-1);
  };
  const onHandleChange = (event) => {
    const { value } = event.target;

    setLocalState({ timeTaken: value });
  };

  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  const handleFilesChange = async (updatedFiles) => {
    setLocalState({ completionFile: updatedFiles });
  };

  const handlefetchSubmissionResponses = () => {
    const formData = new FormData();
    formData.append("question_text", problemStatement);
    formData.append("file", completionFile[0]);
    formData.append("time_taken", timeTaken);
    formData.append("time_allotted", assignmentDuration);
    formData.append("employee_id", empCode);

    submitSolution(formData)
      .unwrap()
      .then((res) => {
        const result = get(res, "Result", "");
        const error = get(res, "Error", null);
        if (error) {
          toast.error(error);
        } else {
          handleSaveAssignment(result);
        }
      })
      .catch(handleError);
  };

  const handleSaveAssignment = async (result) => {
    if (validateSubmission()) {
      toast.error(T.SUBMISSION_FILE_OR_TIME_TAKEN_MISSING);
    } else if (checkIfNumberIsNegative()) {
      toast.error(T.TIME_TAKEN_CAN_NOT_BE_NEGATIVE);
    } else {
      const completionFileBase64 = await convertBase64(completionFile[0]);
      const payload = {
        timeTaken: timeTaken,
        fileSubmitted: completionFileBase64,
        fileName: get(completionFile[0], "name", ""),
        answer: result,
      };
      const asignmentPayloadData = {
        assignmentId,
        payload,
      };
      submitAssignment(asignmentPayloadData)
        .unwrap()
        .then(() => {
          navigate(-1);
          setTimeout(() => {
            toast.success(T.ASSIGNMENT_SUBMITTED_SUCCESSFULLY);
          }, 300);
        })
        .catch(handleError);
    }
  };

  const handleSubmitAssignment = () => {
    handlefetchSubmissionResponses();
  };
  function convertStringWithNewLines(inputString) {
    return inputString?.split("\n");
  }

  const updateAssignmentStatus = () => {
    const payload = { status: T.PROGRESSING };
    const asignmentPayloadData = {
      assignmentId,
      payload,
    };
    updateStatus(asignmentPayloadData)
      .unwrap()
      .then(() => {
        toast.success(T.ASSIGNMENT_STARTED);
        setLocalState({ assignmentSultionStarted: true });
      })
      .catch(handleError);
  };
  const handleStartSolution = () => {
    updateAssignmentStatus();
  };

  const handleDownloadFile = () => {
    const url = `${SERVER_URL}/ai/assessment/download/aiAssessmentFile?assessment_id=${assignmentId}`;
    try {
      window.location.href = url;
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <Card sx={{ p: 2, height: "calc(100vh - 150px)" }}>
      <Box display="flex" alignItems="center" mb={1}>
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack} />
        <Typography variant="h6" fontWeight={700} ml={1}>
          {T.ASSIGNMENT_DETAILS.toUpperCase()}
        </Typography>
      </Box>

      <Grid spacing={2} container direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 3 }}>
        <Grid item>
          <Box display="flex">
            <SubTitleTypography>{T.TECHNOLOGY}:</SubTitleTypography>
            <TitleTypography ml={1}>{technology}</TitleTypography>
          </Box>
          {totalTimeTaken && (
            <Box display="flex">
              <SubTitleTypography>{T.TIME_TAKEN}:</SubTitleTypography>
              <TitleTypography ml={1}>{totalTimeTaken} minutes</TitleTypography>
            </Box>
          )}
        </Grid>
        <Grid item>
          <Box display="flex">
            <SubTitleTypography>{T.ASSIGNED_BY}:</SubTitleTypography>
            <TitleTypography ml={1}>{assignedBy}</TitleTypography>
          </Box>
          <Box display="flex">
            <SubTitleTypography>{T.ASSIGN_DATE}:</SubTitleTypography>
            <TitleTypography ml={1}>{assignmentDate}</TitleTypography>
          </Box>
          {submissionDate && (
            <Box display="flex">
              <SubTitleTypography>{T.SUBMISSION_DATE}:</SubTitleTypography>
              <TitleTypography ml={1}>{submissionDate}</TitleTypography>
            </Box>
          )}
        </Grid>
      </Grid>

      <Paper
        sx={{
          boxShadow: "0px 1px 0px -4px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0),0px 1px 3px 0px rgba(0,0,0,0.12)",
          height: "calc(100vh - 234px)",
          overflowY: "scroll",
          my: 1,
        }}
      >
        <Grid
          container
          justifyContent={isLg ? "space-between" : "center"}
          sx={{
            "& .MuiOutlinedInput-input": {
              fontSize: 14,
            },
          }}
        >
          <Grid item xs={12} sx={{ backgroundColor: BACKGROUND.grey, mb: 1, textAlign: "center" }}>
            <Typography variant="h6" color="themeColor" sx={{ fontWeight: "bold", mr: 4 }}>
              Problem Statement
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ px: 3, py: 1, mb: 2 }}>
            {convertStringWithNewLines(problemStatement).map((str, index) => {
              return (
                <Box key={index}>
                  <Typography
                    variant="string"
                    sx={{
                      width: "inherit",
                      flexWrap: "wrap",
                      textAlign: "start",
                      fontWeight: 600,
                    }}
                  >
                    <span>
                      {str}
                      <br />
                    </span>
                  </Typography>
                </Box>
              );
            })}
          </Grid>
          {(assignmentSultionStarted || (status === "In Progress" && !canAddTechAssignment())) && (
            <>
              {(isSubmittingAssignment || isSubmittingSolution) && <MISLoader />}
              <Grid item xs={12} sx={{ backgroundColor: BACKGROUND.grey, margin: "8px 0", textAlign: "center" }}>
                <Typography variant="h6" color="themeColor" sx={{ fontWeight: "bold" }}>
                  Submit Solution Here
                </Typography>
              </Grid>
              <Grid item md={5} xs={12} sx={{ px: 3, pt: 1 }}>
                <WithInputLabel label={T.COMPLETION_FILE}>
                  <MISDragDrop
                    size={"small"}
                    files={completionFile}
                    setFiles={(file) => handleFilesChange(file)}
                    multiple={false}
                    fileTypes={["txt"]}
                  />
                </WithInputLabel>
              </Grid>
              <Grid item md={4} xs={12} pt={1}>
                <MISTextField
                  label={`${T.TIME_TAKEN} (${T.IN_MINUTES})`}
                  fullWidth
                  placeholder={`${T.TIME_TAKEN} (${T.IN_MINUTES})`}
                  type="number"
                  autoComplete="on"
                  size="small"
                  variant="outlined"
                  name="timeTaken"
                  value={timeTaken}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderBottom: timeTaken && `3px solid ${timeTaken ? SUCCESS.main : ERROR.main}`,
                    },
                  }}
                  onChange={onHandleChange}
                />
              </Grid>

              <Grid item md={3} xs={12} sx={{ display: "flex", justifyContent: "end" }} />
            </>
          )}
          {assignmentResponse && canAddTechAssignment() && (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: BACKGROUND.grey,
                  margin: "8px 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" color="themeColor" sx={{ fontWeight: "bold" }}>
                  Assignment Response
                </Typography>
                <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }} aria-label="Download" onClick={handleDownloadFile}>
                  <DownloadIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12} sx={{ px: 3, py: 1, mb: 3 }}>
                <Typography variant="body1" mb={1}>
                  <strong>Approach:</strong>
                </Typography>
                <Typography variant="body1" mb={1}>
                  {assignmentResponse.approach}
                </Typography>
                <Divider sx={{ mb: 1.5 }} />
                <Typography variant="body1" mb={1}>
                  <strong>Feedback:</strong>
                </Typography>
                <Typography variant="body1" mb={1}>
                  {assignmentResponse.feedback}
                </Typography>
                <Divider sx={{ mb: 1.5 }} />
                <Typography variant="body1" mb={1}>
                  <strong>Logic:</strong>
                </Typography>
                <Typography variant="body1" mb={1}>
                  {assignmentResponse.logic}
                </Typography>
                <Divider sx={{ mb: 1.5 }} />
                <Typography variant="body1" mb={1}>
                  <strong>Technical Parameter:</strong>
                </Typography>
                <Typography variant="body1" mb={1}>
                  {assignmentResponse.technical_parameter}
                </Typography>
              </Grid>
            </>
          )}
          {!assignmentSultionStarted && status === T.TO_DO && !canAddTechAssignment() && (
            <Grid item xs={12} display="flex" justifyContent={"center"}>
              <Button
                variant="contained"
                size={"medium"}
                sx={{
                  ml: 2,
                  mb: 2,
                  minWidth: 80,
                  bgcolor: NETSMARTZ_THEME_COLOR,
                  "&:hover": {
                    bgcolor: NETSMARTZ_THEME_COLOR,
                  },
                  cursor: isUpdatingStatus ? "not-allowed" : "pointer",
                  pointerEvents: isUpdatingStatus ? "none" : "auto",
                }}
                onClick={handleStartSolution}
              >
                {isUpdatingStatus ? (
                  <>
                    Starting.. <CircularProgress size={20} sx={{ color: "white", marginLeft: 1 }} />
                  </>
                ) : (
                  T.LETS_START
                )}
              </Button>
            </Grid>
          )}
          {(assignmentSultionStarted || (status === "In Progress" && !canAddTechAssignment())) && (
            <Grid item xs={12} display="flex" justifyContent={"end"}>
              <Box sx={{ position: "absolute", bottom: 60, right: 60, backgroundColor: "white" }}>
                <MISFooterButton
                  proceedButtonText={T.SUBMIT}
                  justify="end"
                  handleClose={handleBack}
                  handleSubmit={handleSubmitAssignment}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Card>
  );
};

export default Assignment;

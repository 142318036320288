import { api4 } from "api";

const extendedApi = api4.injectEndpoints({
  endpoints: (build) => ({
    getInterviewQuestions: build.mutation({
      query: ({ payload }) => ({
        url: "tech_question_generate",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useGetInterviewQuestionsMutation } = extendedApi;

import PropTypes from "prop-types";
import { TableCell, TableBody, styled, TableRow, Typography, Tooltip, IconButton } from "@mui/material";
import memo from "utils/react";
import get from "lodash/get";
import { ERROR, NETSMARTZ_THEME_COLOR, SUCCESS } from "theme/colors";
import { noop } from "lodash";
import AssignmentIcon from "@mui/icons-material/Assignment";
import T from "T";
import { MISCurrentUser } from "utils/validations";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  backgroundColor: "white",
  padding: "12px 8px 12px 24px",
  fontSize: 13.2,
  maxWidth: 130,
  overflowWrap: "break-word",
}));

const MISTableBody = ({ allTableRows, page, size, handleDetails = noop, handleAddRecommendation = noop }) => {
  const { user } = MISCurrentUser();
  const role = get(user, "role", null);

  const allRows = allTableRows.flatMap((data) => Object.values(data).flat());
  const rows = allTableRows.flatMap((data) => Object.values(data).flat()[0]);

  const findMatchingRecords = (record) => {
    const linkedinUrl = get(record, "linkedinUrl");
    const phoneNumber = get(record, "phoneNumber");
    const email = get(record, "email");

    return allRows.filter(
      (r) =>
        (linkedinUrl && get(r, "linkedinUrl") === linkedinUrl) ||
        (phoneNumber && get(r, "phoneNumber") === phoneNumber) ||
        (email && get(r, "email") === email),
    );
  };

  return (
    <TableBody>
      {rows.map((record, index) => {
        const cooRecommended = get(record, "cooRecommended", null);
        const taRecommended = get(record, "taRecommended", null);
        const vpRecommended = get(record, "vpRecommended", null);
        const serialNumber = index + 1 + page * size;
        const skills = record?.skills.map((skill) => skill?.skillName);
        const matchingRecords = findMatchingRecords(record);
        return (
          <TableRow key={index}>
            <TableCell
              sx={{
                backgroundColor: "white",
                borderLeft: cooRecommended
                  ? `5px solid ${SUCCESS.main}`
                  : cooRecommended === false || taRecommended === false || vpRecommended === false
                  ? `5px solid ${ERROR.main}`
                  : taRecommended !== null || vpRecommended !== null
                  ? `5px solid #FFBF00`
                  : "none",
                pl: 4,
              }}
            >
              {serialNumber}.
            </TableCell>
            <StyledTableCell>
              <Typography
                variant="subtitle1"
                sx={{ color: NETSMARTZ_THEME_COLOR, textDecoration: "none", fontWeight: 600, cursor: "pointer" }}
                onClick={() => handleDetails(matchingRecords)}
              >
                {get(record, "name", "-")}
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              {record?.referrerName && (
                <div>
                  <span>1.</span> {get(record, "referrerName", "-")}
                </div>
              )}
              {record?.secondReferrerName && (
                <div>
                  <span>2.</span> {get(record, "secondReferrerName", "-")}
                </div>
              )}
            </StyledTableCell>
            <StyledTableCell>{get(record, "createdBy.userName", "-")}</StyledTableCell>
            <StyledTableCell>{skills.join(", ")}</StyledTableCell>
            <StyledTableCell>{get(record, "experience", "-")} years</StyledTableCell>
            <StyledTableCell>{get(record, "company.companyName", "-")}</StyledTableCell>
            {role === T.TA && (
              <StyledTableCell sx={{ pl: 0 }}>
                <Tooltip placement="top" title={T.ADD_RECOMMENDATION}>
                  <IconButton
                    sx={{ color: NETSMARTZ_THEME_COLOR }}
                    disabled={record?.secondReferrerName}
                    onClick={() => handleAddRecommendation(true, true, record)}
                  >
                    <AssignmentIcon />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

MISTableBody.propTypes = {
  allTableRows: PropTypes.object,
  page: PropTypes.number,
  size: PropTypes.number,
  handleDetails: PropTypes.func,
  handleAddRecommendation: PropTypes.func,
};

export default memo(MISTableBody);

import { Box, Chip, Stack, IconButton } from "@mui/material";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import PropTypes from "prop-types";
import T from "T";
import styled from "@emotion/styled";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useRef, useState, useEffect } from "react";
import { GET_SIZE } from "utils/responsive";

const RectangularChip = styled(Chip)({
  borderRadius: 0,
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  padding: "14px 14px",
  fontSize: "0.8rem",
});

const ChipsFilter = ({ selectedHistories, onChange }) => {
  const scrollContainerRef = useRef(null);
  const { isXs } = GET_SIZE();

  const handleClick = (history) => {
    if (selectedHistories.length === 1 && selectedHistories.includes(history)) {
      return;
    }

    const newStatus = selectedHistories.includes(history)
      ? selectedHistories.filter((selectedHistory) => selectedHistory !== history)
      : [history];
    onChange(newStatus);
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -100,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 100,
        behavior: "smooth",
      });
    }
  };

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeftStart, setScrollLeftStart] = useState(0);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleMouseDown = (e) => {
      setIsDragging(true);
      setStartX(e.pageX - scrollContainer.offsetLeft);
      setScrollLeftStart(scrollContainer.scrollLeft);
    };

    const handleMouseMove = (e) => {
      if (!isDragging) {
        return;
      }
      const x = e.pageX - scrollContainer.offsetLeft;
      const walk = (x - startX) * 1; //scroll-fast
      scrollContainer.scrollLeft = scrollLeftStart - walk;
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleMouseLeave = () => {
      setIsDragging(false);
    };

    scrollContainer.addEventListener("mousedown", handleMouseDown);
    scrollContainer.addEventListener("mousemove", handleMouseMove);
    scrollContainer.addEventListener("mouseup", handleMouseUp);
    scrollContainer.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      scrollContainer.removeEventListener("mousedown", handleMouseDown);
      scrollContainer.removeEventListener("mousemove", handleMouseMove);
      scrollContainer.removeEventListener("mouseup", handleMouseUp);
      scrollContainer.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [isDragging, startX, scrollLeftStart]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", overflow: "hidden" }}>
      {!isXs && (
        <IconButton aria-label="scroll left" onClick={scrollLeft}>
          <KeyboardArrowLeftIcon />
        </IconButton>
      )}
      <Box
        ref={scrollContainerRef}
        sx={{
          flex: 1,
          overflowX: "auto",
          cursor: isDragging ? "grabbing" : "grab",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <Stack direction="row" spacing={2}>
          {HISTORY.filter((status) => status !== "Partially Closed").map((history) => (
            <RectangularChip
              key={history}
              label={history.toUpperCase()}
              clickable
              onClick={() => handleClick(history)}
              sx={{
                backgroundColor: selectedHistories.includes(history) ? NETSMARTZ_THEME_COLOR : "default",
                color: selectedHistories.includes(history) ? "white" : "gray",
                "&:hover": {
                  backgroundColor: selectedHistories.includes(history) ? NETSMARTZ_THEME_COLOR : "default",
                  color: selectedHistories.includes(history) ? "white" : "default",
                },
              }}
            />
          ))}
        </Stack>
      </Box>
      {!isXs && (
        <IconButton aria-label="scroll right" onClick={scrollRight}>
          <KeyboardArrowRightIcon />
        </IconButton>
      )}
    </Box>
  );
};

ChipsFilter.propTypes = {
  selectedHistories: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ChipsFilter;

export const HISTORY = [
  T.INTERVIEW_HISTORY,
  T.FEEDBACK_HISTORY,
  T.TRAINING_HISTORY,
  T.PROJECT_HISTORY,
  T.NC_HISTORY,
  T.SWOT_ANALYSIS,
  T.SIR,
  T.MOCK_ASSESSMENT,
];

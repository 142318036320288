import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getConfigurator: builder.query({
      query: () => ({
        url: "tools/license/get/configuration",
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetConfiguratorQuery } = extendedApi;

import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    submitAssignment: build.mutation({
      query: (submitAssignmentPayload) => ({
        url: `/ai/assessment/update?assessment_id=${submitAssignmentPayload.assignmentId}`,
        method: "POST",
        body: submitAssignmentPayload.payload,
      }),
    }),
  }),
});

export const { useSubmitAssignmentMutation } = extendedApi;

import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Grid, Box, styled, Tooltip, IconButton } from "@mui/material";
import SearchBar from "components/Members/SearchBar";
import { canAddISTools, canViewISTools } from "utils/permissions";
import T from "T";
import MISButton from "components/common/MISButton";
import AddIcon from "@mui/icons-material/Add";
import { GET_SIZE } from "utils/responsive";
import ISToolsFilters from "../ISToolsFilters";
import MISDatePicker from "components/common/MISDatePicker";
import CSV from "assets/CSV.png";
import { get } from "utils/lodash";
import { getFEDateFormat } from "utils/members";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

// const minDate = "2023-08-01";     use If Required

const TopBar = ({
  tabValue = "",
  filters = {},
  searchInput = "",
  isFilterEmpty = false,
  toolsList = [],
  costCenterList = [],
  renewalStartDate = null,
  renewalEndDate = null,
  handleKeyChange = noop,
  handleChange = noop,
  onhandleFilterChange = noop,
  onHandleDateFilterChange = noop,
  handleFilterSubmit = noop,
  handleFilterClose = noop,
  onHandleDateChange = noop,
  handleExport = noop,
  handleOpenEmailConfiguration = noop,
  handleToolsLicenceAdd = noop,
}) => {
  const StyledBox = styled(Box)(() => ({
    margin: "auto",
    maxWidth: "inherit",
    width: 45,
    height: 45,
    cursor: "pointer",
  }));

  const { isLg } = GET_SIZE();

  const exportFilter = {
    profitCenterName: get(filters, "profitCenter.profitCenterName", ""),
    costCenterName: get(filters, "costCenter.costCenterName", ""),
    autoRenewal: filters.autoRenewal ? filters.autoRenewal.value : null,
    tenure: get(filters, "tenure.tenureName", ""),
    renewalStartDate,
    renewalEndDate,
    purchaseStartDate: get(filters, "purchaseStartDate", null),
    purchaseEndDate: get(filters, "purchaseEndDate", null),
    text: searchInput,
  };
  return (
    <Grid
      container
      alignItems="center"
      justifyContent={isLg ? "space-between" : "center"}
      sx={{
        mt: 1,
        mb: 1,
        pl: 3,
        pr: 3,
        "& .MuiOutlinedInput-input": {
          fontSize: 14,
        },
      }}
    >
      {/* {tabValue === "1" && ( */}
      <Grid item md={2.5} xs={12}>
        <SearchBar searchInput={searchInput} handleKeyChange={handleKeyChange} handleChange={handleChange} />
      </Grid>
      {/* )} */}
      {tabValue === "1" && (
        <>
          <Grid item md={2} xs={12}>
            <MISDatePicker
              placeholder={T.RENEWAL_DATE_FROM}
              inputFormat="DD/MM/YYYY"
              // value={renewalStartDate && getFEDateFormat(renewalStartDate)}
              value={renewalStartDate}
              name="renewalStartDate"
              // minDate={dayjs(minDate)}
              maxDate={renewalEndDate}
              handleChange={onHandleDateChange}
              renderInput={(params) => <MISTextField {...params} />}
            />
          </Grid>
          -
          <Grid item md={2} xs={12}>
            <MISDatePicker
              placeholder={T.RENEWAL_DATE_TO}
              inputFormat="DD/MM/YYYY"
              // value={renewalEndDate && getFEDateFormat(renewalEndDate)}
              value={renewalEndDate}
              name="renewalEndDate"
              minDate={renewalStartDate}
              handleChange={onHandleDateChange}
              renderInput={(params) => <MISTextField {...params} />}
            />
          </Grid>
        </>
      )}
      {tabValue === "1" && (
        <Grid item md={2} xs={12}>
          <ISToolsFilters
            filters={filters}
            isFilterEmpty={isFilterEmpty}
            toolsList={toolsList}
            costCenterList={costCenterList}
            handleFilterClose={handleFilterClose}
            handleFilterSubmit={handleFilterSubmit}
            onhandleFilterChange={onhandleFilterChange}
            onHandleDateFilterChange={onHandleDateFilterChange}
          />
        </Grid>
      )}
      {tabValue === "2" && <Grid item md={6} />}
      {tabValue === "1" && canViewISTools() && (
        <Grid item md={0.5} xs={12} display="flex" justifyContent="flex-end">
          <Tooltip title={T.EMAIL_CONFIGURATOR} placement="top">
            <SettingsSuggestIcon
              sx={{
                cursor: "pointer",
                color: "themeColor",
                margin: "auto",
                width: 40,
                height: 40,
                m: 1,
              }}
              onClick={() => handleOpenEmailConfiguration()}
            />
          </Tooltip>
        </Grid>
      )}
      {(tabValue === "1" || tabValue === "2") && (
        <Grid item display="flex" justifyContent="flex-end">
          <Tooltip title={T.CSV} placement="top">
            <StyledBox
              component="img"
              src={CSV}
              onClick={() =>
                tabValue === "1" ? handleExport(exportFilter, "licence") : handleExport({ toolsName: searchInput }, "dashboard")
              }
            />
          </Tooltip>
        </Grid>
      )}
      {tabValue === "1" && canAddISTools() && (
        <Grid item md={1.5} xs={12} display="flex" justifyContent="flex-end">
          <MISButton size="medium" startIcon={<AddIcon sx={{ width: 19 }} />} onClick={() => handleToolsLicenceAdd()}>
            {T.ADD_LICENCE}
          </MISButton>
        </Grid>
      )}
    </Grid>
  );
};

export default TopBar;

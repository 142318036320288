import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllPitchedMembersFilter: builder.mutation({
      query: ({ page, size, interviewTrackerFilterDto }) => ({
        url: `/preSalesMapping/get/allPitchedUSer?page=${page}&size=${size}`,
        method: "POST",
        body: interviewTrackerFilterDto,
      }),
    }),
  }),
});

export const { useGetAllPitchedMembersFilterMutation } = extendedApi;

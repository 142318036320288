import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    shortListMembers: build.mutation({
      query: ({ recommendedUsersRequestDto }) => ({
        url: `/selected/user/save`,
        method: "POST",
        body: recommendedUsersRequestDto,
      }),
    }),
  }),
});

export const { useShortListMembersMutation } = extendedApi;

import { useState } from "react";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { Card, Typography } from "@mui/material";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";

import T from "T";
import { Box } from "@mui/system";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import CaseStudyFilterOptions from "./CaseStudyFilterOptions";
import { useDispatch } from "react-redux";
import { savedFilterStore } from "slices/savedFilterSlice";

const CaseStudyFilters = ({
  projectManagers = [],
  projectList = {},
  filters = {},
  isFilterEmpty = true,
  handleFilterClose = noop,
  handleFilterApply = noop,
  onhandleFilterChange = noop,
}) => {
  const dispatch = useDispatch();
  const [filterOpen, setFilterOpen] = useState(false);

  const handleFilterClick = () => {
    setFilterOpen(!filterOpen);
  };

  const onFilterClose = () => {
    dispatch(savedFilterStore({ selectedFilterId: "" }));
    setFilterOpen(false);
    handleFilterClose();
  };

  const onFilterSubmit = () => {
    handleFilterApply();
    setFilterOpen(false);
  };
  return (
    <Box>
      <Card
        elevation={0}
        variant="outlined"
        onClick={handleFilterClick}
        sx={{
          p: 1,
          backgroundColor: "background.white",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          sx={!isFilterEmpty ? { color: NETSMARTZ_THEME_COLOR, fontWeight: 600 } : {}}
          fontSize={14}
        >{`${T.FILTER}s`}</Typography>
        <FilterAltOffOutlinedIcon fontSize="small" />
      </Card>

      {filterOpen && (
        <CaseStudyFilterOptions
          filters={filters}
          projectManagers={projectManagers}
          projectList={projectList}
          handleFilterClose={onFilterClose}
          handleFilterApply={onFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
          handleClose={() => setFilterOpen(false)}
        />
      )}
    </Box>
  );
};

CaseStudyFilters.propTypes = {
  projectManagers: PropTypes.array,
  filters: PropTypes.object,
  isFilterEmpty: PropTypes.bool,
  onhandleFilterChange: PropTypes.func,
  handleFilterClose: PropTypes.func,
  handleFilterApply: PropTypes.func,
};

export default CaseStudyFilters;

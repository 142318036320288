import { AppBar, Box } from "@mui/material";
import NavigationMenu from "./NavigationMenu";
import PropTypes from "prop-types";

const VideoInterviewHeader = ({ boxRef }) => {
  return (
    <Box display="block" mb={5}>
      <AppBar ref={boxRef} position="fixed" sx={{ backgroundColor: "background.white" }}>
        <NavigationMenu />
      </AppBar>
    </Box>
  );
};

VideoInterviewHeader.propTypes = {
  boxRef: PropTypes.object,
};

export default VideoInterviewHeader;

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  DialogActions,
  Box,
  Skeleton,
} from "@mui/material";
import { BACKGROUND } from "theme/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import { noop } from "lodash";
import PropTypes from "prop-types";
import MasterPagination from "components/MasterSetting/MasterPagination";
import MISNoData from "components/common/MISNoData";

const ActivityLog = ({
  handleToggleDialog = noop,
  openModal,
  activityLog,
  totalTableRowsCount,
  totalPageCount,
  page,
  size,
  handlePageChange,
  handleRowsPerPageChange,
  isLoading,
}) => {
  return (
    <>
      <Dialog open={openModal} fullWidth>
        <DialogTitle
          sx={{
            fontFamily: "Arial, sans-serif",
            fontWeight: 600,
            backgroundColor: BACKGROUND.cardDefault,
            display: "flex ",
            alignItems: "center",
            pt: 2,
            pb: 1,
          }}
        >
          <IconButton fontSize="small" sx={{ cursor: "pointer" }} onClick={handleToggleDialog}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" fontWeight={600}>
            {T.ACTIVITY_LOG}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </TableCell>
                  </TableRow>
                ) : activityLog.length === 0 ? (
                  <Box py={5}>
                    <MISNoData />
                  </Box>
                ) : (
                  activityLog?.map((entry, index) => {
                    const serialNumber = index + 1 + page * size;

                    return (
                      <TableRow key={entry.logsId} style={{ backgroundColor: index % 2 === 0 ? BACKGROUND.card : "inherit" }}>
                        <TableCell>{serialNumber}.</TableCell>
                        <TableCell style={{ fontSize: "0.9rem" }}>{entry.description}</TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={{ px: 2.8 }}>
          <MasterPagination
            totalTableRowsCount={totalTableRowsCount}
            totalPageCount={totalPageCount}
            page={page}
            rowsPerPage={size}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

ActivityLog.propTypes = {
  handleToggleDialog: PropTypes.func,
  openModal: PropTypes.bool,
  activityLog: PropTypes.array,
  totalTableRowsCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  page: PropTypes.number,
  size: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ActivityLog;

import { Children, useState } from "react";
import PropTypes from "prop-types";
import { noop, isEmpty, camelCase, orderBy } from "lodash";
import { Typography, Divider, Card, Button, Box, Checkbox, FormControl, FormControlLabel, Grid } from "@mui/material";
import MISAutocomplete from "components/common/MISAutocomplete";
import { ClickAwayListener } from "@mui/material";
import { NETSMARTZ_THEME_COLOR, BACKGROUND } from "theme/colors";
import { GET_SIZE } from "utils/responsive";
import T from "T";
import { TRAINING_FILTER_OPTIONS } from "settings/constants/filter";
import { get } from "utils/lodash";
import { OFFLINE_TRAINING_STATUS_LIST, TRAINING_STATUS_LIST } from "../trainingModal";
import { canKraTraining } from "utils/permissions";

const trainingTypes = [
  {
    trainingType: "Appraisal Linked Learning Path",
    label: "Appraisal Linked Learning Path",
  },
  {
    trainingType: "New Joiner Learning Path",
    label: "New Joiner Learning Path",
  },
  {
    trainingType: "Training",
    label: "Others",
  },
];

const CourseFilterOptions = ({
  filters = {},
  departmentList = [],
  handleFilterClose = noop,
  handleFilterSubmit = noop,
  onhandleFilterChange = noop,
  handleClose = noop,
  courseType = [],
}) => {
 
  const { isXs } = GET_SIZE();

  const getFilterRecords = (val) => {
    switch (val) {
      case T.DEPARTMENT:
        return orderBy(departmentList, ["departmentName"], ["asc"]);
      case T.COURSE_TYPE:
        return courseType;
      default:
    }
  };

  const getLabelData = (option, index) => {
    switch (index) {
      case 0:
        return get(option, "departmentName", "");
      case 1:
        return get(option, "courseAssignmentType", "");

      default:
    }
  };

  const COURSE_FILTER_OPTIONS = [T.DEPARTMENT, T.COURSE_TYPE];

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Card
        sx={{
          mt: 1,
          position: "absolute",
          width: isXs ? "80%" : "25%",
          zIndex: 400,
        }}
      >
        <Box
          sx={{
            maxHeight: "calc(100vh - 250px)",
            overflowY: "auto",
          }}
        >
          <Box fullWidth py={"2px"}>
            <Typography variant="subtitle2" textAlign="center">
              {T.FILTERS}
            </Typography>
          </Box>
          <Divider />

          {Children.toArray(
            COURSE_FILTER_OPTIONS.map((item, index) => {
              const lists = getFilterRecords(item);
              const key = camelCase(item);
              return (
                <Box
                  display="flex"
                  p="10px 20px"
                  justifyContent="space-between"
                  pb={index === COURSE_FILTER_OPTIONS.length - 1 && "10px"}
                >
                  <Typography variant="subtitle1" noWrap>
                    {item}
                  </Typography>
                  <MISAutocomplete
                    listDetails={lists}
                    size="small"
                    placeholder={T.SELECT}
                    sx={{
                      width: 185,
                    }}
                    value={filters[key]}
                    getByLabelText={(option) => getLabelData(option, index)}
                    onHandleChange={(event, newValue) => {
                      onhandleFilterChange(newValue, key);
                    }}
                  />
                </Box>
              );
            }),
          )}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderColor: BACKGROUND.black,
              color: BACKGROUND.black,

              "&:hover": {
                borderColor: NETSMARTZ_THEME_COLOR,
                color: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={() => handleFilterClose()}
          >
            {T.RESET}
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterSubmit}
          >
            {T.APPLY}
          </Button>
        </Box>
      </Card>
    </ClickAwayListener>
  );
};

CourseFilterOptions.propTypes = {
  filters: PropTypes.object,
  departmentList: PropTypes.array,
  handleFilterClose: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  onhandleFilterChange: PropTypes.func,
  handleClose: PropTypes.func,
  setTrainingState: PropTypes.func,
  courseType: PropTypes.array,
};

export default CourseFilterOptions;

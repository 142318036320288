import PropTypes from "prop-types";
import { noop } from "lodash";
import { PAGINATION } from "settings/constants/pagination";
import CourseTable from "./CourseTable";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const CourseTab = ({
  page = INITIAL_PAGE,
  onPageChange,
  rowsPerPage = ROWS_PER_PAGE,
  allTableRows = [],
  TrackRecord = [],
  onRowsPerPageChange,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  handleCourseSorting = noop,
  handleSubCourse = noop,
}) => {
  return (
    <CourseTable
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onRowsPerPageChange}
      totalTableRowsCount={totalTableRowsCount}
      totalPageCount={totalPageCount}
      allTableRows={allTableRows}
      TrackRecord={TrackRecord}
      handleSubCourse={handleSubCourse}
      handleCourseSorting={handleCourseSorting}
    />
  );
};

CourseTab.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  TrackRecord: PropTypes.array,
  allTableRows: PropTypes.array,
  handleSubCourse: PropTypes.func,
  totalTableRowsCount: PropTypes.number,
  handleCourseSorting: PropTypes.func,
};

export default CourseTab;

import { Children } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { MISCurrentUser } from "utils/validations";
import UploadIcon from "@mui/icons-material/Upload";

import { TableRow, TableCell, Typography, TableBody, styled, Button, IconButton, Tooltip } from "@mui/material";

import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";

import T from "T";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import { PAGINATION } from "settings/constants/pagination";
import MISNoData from "components/common/MISNoData";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const TestimonialTableBody = ({
  allTableRows = [],
  pName,
  handleTestimonialUploadDialog = noop,
  handleReminderButton = noop,
}) => {
  const { user } = MISCurrentUser();
  const userRole = get(user, "role", "");
  const disableCondition = (record) => record.testimonialStatus.status === "submitted";

  return (
    <TableBody>
      {Children.toArray(
        allTableRows?.map((record, index) => (
          <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
            <StyledTableCell>{index + 1}.</StyledTableCell>
            <StyledTableCell>{get(record, "projectName", "")}</StyledTableCell>
            <StyledTableCell>
              {userRole === T.PMO ? (
                <Tooltip title={T.REMINDER_BUTTON} placement="top">
                  <IconButton
                    size="small"
                    sx={{
                      color: NETSMARTZ_THEME_COLOR,
                      "&:hover": {
                        color: "#460000",
                      },
                    }}
                    disabled={disableCondition(record)}
                    onClick={() => handleReminderButton(get(record, "id", ""))}
                  >
                    <AddAlertIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
            </StyledTableCell>
            <StyledTableCell>{get(record, "projectManagerId.name", "")}</StyledTableCell>
            <StyledTableCell>{get(record, "projectStartDate", "")}</StyledTableCell>
            <StyledTableCell align="center">
              {get(record, "testimonialStatus.status", "") === "rejected" ||
              get(record, "testimonialStatus.status", "") === "pending" ? (
                userRole === T.PM ? (
                  <UploadIcon
                    onClick={() => {
                      handleTestimonialUploadDialog(get(record, "id", ""), get(record, "projectName", ""));
                    }}
                    sx={{
                      mr: 1,
                      height: 17,
                      cursor: "pointer",
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                  />
                ) : (
                  ""
                )
              ) : (
                <a
                  href={
                    get(record, "testimonialLink", "").includes("https")
                      ? get(record, "testimonialLink", "")
                      : `https://${get(record, "testimonialLink", "")}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    maxWidth: "250px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                  }}
                >
                  {get(record, "testimonialLink", "")}
                </a>
              )}
            </StyledTableCell>
            <StyledTableCell align="center">{get(record, "testimonialStatus.status", "")}</StyledTableCell>
            <StyledTableCell align="center">{get(record, "comment", "")}</StyledTableCell>
            <StyledTableCell align="center">{get(record, "testimonialSubmissionDate", "")}</StyledTableCell>
          </StyledTableRow>
        )),
      )}
    </TableBody>
  );
};

TestimonialTableBody.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  allTableRows: PropTypes.array,
  handleAddEditCaseStudyDialog: PropTypes.func,
  handleRejectCaseStudyDialog: PropTypes.func,
  handleTestimonialUploadDialog: PropTypes.func,
  handleReminderButton: PropTypes.func,
  pName: PropTypes.string,
};

export default memo(TestimonialTableBody);

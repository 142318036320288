import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSubroleByEmail: builder.query({
      query: ({ email }) => ({
        url: `role/getAll/memberSubRoles?memberEmail=${email}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetSubroleByEmailQuery } = extendedApi;

import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { orderBy, noop } from "lodash";
import { Grid, MenuItem, Typography, Box, IconButton } from "@mui/material";
import SelectCheck from "@mui/icons-material/Check";
// import UploadFileIcon from "@mui/icons-material/UploadFile";
import MISTextField from "components/common/MISTextField";
import MISDatePicker from "components/common/MISDatePicker";
import MISAutocomplete from "components/common/MISAutocomplete";
import T from "T";
import { ERROR, SUCCESS } from "theme/colors";
import { MISCurrentUser, isEmail, isMobileNo, isString } from "utils/validations";
import { get } from "utils/lodash";
import CloseIcon from "@mui/icons-material/Close";
import MISDialog from "components/common/MISDialog";
import { EMP_MODE_LIST, WORK_MODE } from "./memberModel";
import MISButton from "components/common/MISButton";
import MISDragDrop from "components/common/MISDragDrop";
import { useEffect } from "react";
// import WithInputLabel from "components/common/WithInputLabel";

const BasicDetails = ({
  id = "",
  empStatus = "",
  fullName = "",
  empCode = "",
  departmentId,
  email = "",
  phone = "",
  designation = "",
  reportingManager,
  reportingManagerName,
  functionalHeadId,
  empMode = "",
  reSelectedPM,
  joiningDateInNetsmartz = null,
  careerStartDate = null,
  totalExpAsOnDate = "",
  workLocation = "",
  workMode = "",
  workModeExceptionReason = "",
  linkedInUrl = "",
  profileLinkWord = "",
  profileLinkPdf = "",
  profileDocFile,
  departmentList = {},
  projectManagers = {},
  functionalManagers = {},
  workLocationList = {},
  empCodeExist = false,
  mobileNoExist = false,
  emailExist = false,
  open,
  isRecordAvailable = noop,
  onHandleChange = noop,
  // onHandleFileChange = noop,
  onHandleDateChange = noop,
  onHandleAutoCompleteChange = noop,
  onHandleNewAutoCompleteChange = noop,
  handleRedirectFeedback = noop,
  handleDialogClose = noop,
  files = [],
  HrbpList = [],
  WorkGroupList = [],
  hrbpId = "",
  workGroupId = "",
  handleFilesChange = noop,
  updateRm = noop,
}) => {
  const location = useLocation();
  const { user } = MISCurrentUser();
  const storedReSelectedPM = location.state ? location.state.reSelectedPM : null;
  const deptListResults = get(departmentList, "results", []);

  const selectedDept = deptListResults.find((dept) => dept.id === departmentId) || {};
  useEffect(() => {
    if (storedReSelectedPM !== null) {
      updateRm(storedReSelectedPM);
    }
  }, []);
  const activeWorkLocationList = get(workLocationList, "results", []);
  const selectedWorkLocation = activeWorkLocationList.find((loc) => loc.id === workLocation);
  const selectedWorkLocationName = get(selectedWorkLocation, "workLocationName", "");
  const disableCondition = () => id && empStatus !== T.STABLE && empStatus !== T.YET_TO_JOIN && empStatus !== T.RESIGNED;
  const pMResults = get(projectManagers, "results", []);
  const isAdd = location.pathname.split("/").includes("add");
  const selectedPM = pMResults.find((res) => res.id === reportingManager) || null;
  const fMResults = get(functionalManagers, "results", []);
  const selectedFM = fMResults.find((res) => res.id === functionalHeadId) || {};
  const fileName = profileDocFile ? profileDocFile.split("/").pop() : null;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize={16} fontWeight={600}>
          {T.PERSONAL_DETAILS}
        </Typography>
      </Grid>
      <Grid item md={4} xs={12}>
        {!location.pathname.includes("app/member/add") && (
          <MISDialog open={open} fullWidth maxWidth="sm">
            <Box p={3}>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography variant="h6">Share Feedback Before Updating Reporting Manager</Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleDialogClose}
                  sx={{
                    color: "grey",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Box textAlign="center">
                <MISButton
                  onClick={() => {
                    handleRedirectFeedback(id);
                  }}
                >
                  {T.SUBMIT_FEEDBACK}
                </MISButton>
              </Box>
            </Box>
          </MISDialog>
        )}

        <MISTextField
          label={T.FULL_NAME}
          required
          fullWidth
          disabled={disableCondition()}
          placeholder={T.FULL_NAME}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="fullName"
          value={fullName}
          onChange={onHandleChange}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.EMP_CODE}
          required
          fullWidth
          disabled={disableCondition()}
          placeholder={T.EMP_CODE}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="empCode"
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: empCode && `3px solid ${!empCodeExist ? SUCCESS.main : ERROR.main}`,
            },
          }}
          value={empCode}
          onChange={onHandleChange}
          onKeyDown={isRecordAvailable}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.DEPARTMENT}
          listDetails={orderBy(deptListResults, ["departmentName"], ["asc"])}
          value={selectedDept}
          disabled={disableCondition()}
          required
          getByLabelText={(option) => get(option, "departmentName", "")}
          onHandleChange={(event, newValue) =>
            onHandleAutoCompleteChange("", "departmentId", get(newValue, "id", ""), T.DEPARTMENT)
          }
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.DESIGNATION}
          fullWidth
          placeholder={T.DESIGNATION}
          disabled={disableCondition()}
          required
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: designation && `3px solid ${isString(designation) ? SUCCESS.main : ERROR.main}`,
            },
          }}
          size="small"
          variant="outlined"
          name="designation"
          value={designation}
          onChange={onHandleChange}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.EMAIL}
          fullWidth
          required
          placeholder={T.EMAIL}
          disabled={disableCondition()}
          size="small"
          autoComplete="on"
          variant="outlined"
          name="email"
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: email && `3px solid ${isEmail(email) && !emailExist ? SUCCESS.main : ERROR.main}`,
            },
          }}
          value={email}
          onChange={onHandleChange}
          onKeyDown={isRecordAvailable}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.PHONE}
          fullWidth
          placeholder={T.PHONE}
          disabled={disableCondition()}
          size="small"
          variant="outlined"
          name="phone"
          value={phone}
          type="number"
          required
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: phone && `3px solid ${isMobileNo(phone) && !mobileNoExist ? SUCCESS.main : ERROR.main}`,
            },
          }}
          onChange={onHandleChange}
          // onKeyDown={isRecordAvailable}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.REPORTING_MANAGER}
          listDetails={orderBy(pMResults, ["name"], ["asc"])}
          value={storedReSelectedPM === null ? (reSelectedPM !== null ? reSelectedPM : selectedPM) : storedReSelectedPM}
          disabled={(isAdd ? false : disableCondition() || reportingManagerName !== user.user.userName) && user?.role !== "HR"}
          required
          getByLabelText={(option) => get(option, "name", "")}
          onHandleChange={(event, newValue) =>
            onHandleAutoCompleteChange("", "reportingManager", get(newValue, "id", ""), T.REPORTING_MANAGER)
          }
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.FUNCTIONAL_MANAGER}
          listDetails={orderBy(fMResults, ["name"], ["asc"])}
          value={selectedFM}
          disabled={disableCondition()}
          required
          getByLabelText={(option) => get(option, "name", "")}
          onHandleChange={(event, newValue) =>
            onHandleAutoCompleteChange("", "functionalHeadId", get(newValue, "id", ""), T.FUNCTIONAL_MANAGER)
          }
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.HRBP}
          listDetails={orderBy(HrbpList, ["name"], ["asc"])}
          value={HrbpList.find((item) => item.id === hrbpId)}
          disabled={disableCondition()}
          getByLabelText={(option) => get(option, "name", "")}
          onHandleChange={(event, newValue) => onHandleNewAutoCompleteChange("hrbpId", get(newValue, "id", ""))}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.EMPLOYMENT_MODE}
          fullWidth
          select
          required
          disabled={disableCondition()}
          size="small"
          variant="outlined"
          name="empMode"
          value={empMode}
          onChange={onHandleChange}
        >
          {EMP_MODE_LIST.map((val) => (
            <MenuItem value={val}>
              <Typography noWrap>{val}</Typography>
            </MenuItem>
          ))}
        </MISTextField>
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.WORK_GROUP}
          listDetails={orderBy(WorkGroupList, ["name"], ["asc"])}
          value={WorkGroupList.find((item) => item.id === workGroupId)}
          disabled={disableCondition()}
          getByLabelText={(option) => get(option, "name", "")}
          onHandleChange={(event, newValue) => onHandleNewAutoCompleteChange("workGroupId", get(newValue, "id", ""))}
        />
      </Grid>
      {/* <Grid item md={4} xs={12}>
        <WithInputLabel label={T.ATTACH_RESUME}>
          <Button component="label" sx={{ minWidth: 0, p: 0 }}>
            <input type="file" name="resume" onChange={onHandleFileChange}/>
            <UploadFileIcon fontSize="large" sx={{ color: TEXT.label }} />
          </Button>
        </WithInputLabel>
      </Grid> */}
      <Grid item xs={12}>
        <Typography fontSize={16} fontWeight={600} mt={2}>
          {T.PROFESSIONAL_DETAILS}
        </Typography>
      </Grid>
      <Grid item md={4} xs={12}>
        <MISDatePicker
          label={T.CAREER_START_DATE}
          placeholder={T.CAREER_START_DATE}
          disabled={disableCondition()}
          inputFormat="MM/DD/YYYY"
          value={careerStartDate}
          required
          name="careerStartDate"
          disableFuture
          handleChange={onHandleDateChange}
          renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISDatePicker
          label={T.JOINING_DATE_IN_NTZ}
          placeholder={T.JOINING_DATE_IN_NTZ}
          disabled={disableCondition()}
          required
          inputFormat="MM/DD/YYYY"
          value={joiningDateInNetsmartz}
          minDate={careerStartDate || T.NETSMARTZ_ESTABLISHMENT_DATE}
          name="joiningDateInNetsmartz"
          handleChange={onHandleDateChange}
          renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.TOTAL_EXP_AS_ON_DATE}
          fullWidth
          disabled
          placeholder={T.TOTAL_EXP_AS_ON_DATE}
          size="small"
          variant="outlined"
          name="totalExpAsOnDate"
          value={`${totalExpAsOnDate} ${T.YEARS.toLowerCase()}`}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.WORK_LOCATION}
          fullWidth
          select
          disabled={disableCondition()}
          size="small"
          variant="outlined"
          name="workLocation"
          value={[workLocation]}
          required
          onChange={onHandleChange}
        >
          <MenuItem value="">{T.SELECT_OPTION}</MenuItem>
          {activeWorkLocationList.map((val) => (
            <MenuItem value={get(val, "id", "")}>
              <Typography noWrap>{get(val, "workLocationName", "")}</Typography>

              {workLocation === val.id && (
                <SelectCheck
                  sx={{
                    width: "20px",
                    height: "20px",
                    display: "none",
                  }}
                />
              )}
            </MenuItem>
          ))}
        </MISTextField>
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.WORK_MODE}
          fullWidth
          select
          disabled={disableCondition()}
          size="small"
          variant="outlined"
          name="workMode"
          value={[selectedWorkLocationName === T.WFH ? T.WFH : workMode]}
          required
          onChange={onHandleChange}
        >
          <MenuItem value="">{T.SELECT_OPTION}</MenuItem>
          {WORK_MODE.map((val) => (
            <MenuItem value={val}>
              <Typography noWrap>{val}</Typography>
            </MenuItem>
          ))}
        </MISTextField>
      </Grid>

      {((workMode && workMode !== T.WFO) || selectedWorkLocationName === T.WFH) && (
        <Grid item md={4} xs={12}>
          <MISTextField
            label={T.WORK_MODE_EXCEPTION_REASON}
            fullWidth
            disabled={disableCondition()}
            placeholder={T.WORK_MODE_EXCEPTION_REASON}
            size="small"
            variant="outlined"
            autoComplete="on"
            name="workModeExceptionReason"
            value={workModeExceptionReason}
            onChange={onHandleChange}
          />
        </Grid>
      )}

      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.LINKED_IN_URL}
          fullWidth
          placeholder={T.LINKED_IN_URL}
          disabled={disableCondition()}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="linkedInUrl"
          value={linkedInUrl}
          onChange={onHandleChange}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.PROFILE_LINK_WORD_FORMAT}
          fullWidth
          placeholder={T.PROFILE_LINK_WORD_FORMAT}
          disabled={disableCondition()}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="profileLinkWord"
          value={profileLinkWord}
          onChange={onHandleChange}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.PROFILE_LINK_PDF_FORMAT}
          fullWidth
          placeholder={T.PROFILE_LINK_PDF_FORMAT}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="profileLinkPdf"
          value={profileLinkPdf}
          onChange={onHandleChange}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <Typography variant="body1" sx={{ mb: 0.3 }} color="text.label">
          {"Upload Resume"}
        </Typography>
        <MISDragDrop
          size={"small"}
          files={files}
          setFiles={handleFilesChange}
          multiple={false}
          fileName={fileName}
          fileTypes={["docx", "doc"]}
        />
      </Grid>
    </Grid>
  );
};

BasicDetails.propTypes = {
  id: PropTypes.string,
  empStatus: PropTypes.string,
  fullName: PropTypes.string,
  empCode: PropTypes.string,
  departmentId: PropTypes.number,
  email: PropTypes.string,
  phone: PropTypes.string,
  reportingManager: PropTypes.number,
  reportingManagerName: PropTypes.string,
  functionalHeadId: PropTypes.number,
  empMode: PropTypes.number,
  designation: PropTypes.string,
  joiningDateInNetsmartz: PropTypes.string,
  careerStartDate: PropTypes.string,
  reSelectedPM: PropTypes.object,
  totalExpAsOnDate: PropTypes.string,
  workLocation: PropTypes.string,
  workMode: PropTypes.string,
  workModeExceptionReason: PropTypes.string,
  linkedInUrl: PropTypes.string,
  profileLinkWord: PropTypes.string,
  profileLinkPdf: PropTypes.string,
  profileDocFile: PropTypes.string,
  departmentList: PropTypes.object,
  projectManagers: PropTypes.object,
  functionalManagers: PropTypes.object,
  workLocationList: PropTypes.object,
  isRecordAvailable: PropTypes.func,
  onHandleChange: PropTypes.func,
  // onHandleFileChange: PropTypes.func,
  onHandleDateChange: PropTypes.func,
  onHandleAutoCompleteChange: PropTypes.func,
  handleRedirectFeedback: PropTypes.func,
  handleDialogClose: PropTypes.func,
  handleFilesChange: PropTypes.func,
  updateRm: PropTypes.func,
  empCodeExist: PropTypes.bool,
  mobileNoExist: PropTypes.bool,
  emailExist: PropTypes.bool,
  open: PropTypes.bool,
  files: PropTypes.array,
};

export default BasicDetails;

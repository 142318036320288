import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    reviewCourse: build.mutation({
      query: (body) => ({
        url: "/training/review/assignTraining",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useReviewCourseMutation } = extendedApi;

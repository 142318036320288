import { createSlice } from "@reduxjs/toolkit";

const caseStudyFilterSlice = createSlice({
  name: "CaseStudyFilterSlice",
  initialState: {
    storedFilters: {
      projectManager: null,
      status: null,
    },
    storedSearch: "",
  },

  reducers: {
    caseStudyFilterStore: (state, action) => {
      state.storedFilters = action.payload.storedFilters;
      state.storedSearch = action.payload.storedSearch;
    },
  },
});

export const { caseStudyFilterStore } = caseStudyFilterSlice.actions;
export default caseStudyFilterSlice.reducer;

import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLicenseView: builder.query({
      query: ({ toolsId, profitCenterId }) => ({
        url: `/tools/license/cost?profitCenterId=${profitCenterId}&toolId=${toolsId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetLicenseViewQuery } = extendedApi;

import { ArrowBack } from "@mui/icons-material";
import { Button, Grid, IconButton, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import FeedbackForm from "components/Training/Feedack/Form";
import React, { useEffect, useState } from "react";
import TaskboxTable from "./TaskboxTable";
import T from "T";
import { PAGINATION } from "settings/constants/pagination";
import { useLazyGetAllTasksQuery } from "api/taskbox/getAllTasks";
import { handleError } from "utils/error";
import { MISCurrentUser } from "utils/validations";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { setBadgeCount } from "slices/taskboxSlice";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const Taskbox = () => {
  const { user } = MISCurrentUser();
  const [showFeedback, setShowFeedback] = useState(false);
  const [pagination, setPagination] = useState({
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    totalTableRowsCount: INITIAL_PAGE,
    totalPageCount: INITIAL_PAGE,
  });
  const { page, rowsPerPage, totalPageCount, totalTableRowsCount } = pagination;
  const [getAllTasks] = useLazyGetAllTasksQuery();
  const [feedbacks, setFeedbacks] = useState([]);
  const [currentFeedBack, setCurrentFeedBack] = useState(null);
  const dispatch = useDispatch();

  const handleShowFeedback = () => {
    setShowFeedback((prev) => !prev);
  };

  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setPagination((prev) => ({ ...prev, page: INITIAL_PAGE, rowsPerPage: value }));
  };

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const getAllTaskbox = () => {
    const payload = {
      isReportingManger: null,
      text: null,
      userEmail: user?.user?.userEmailId ?? null,
    };

    getAllTasks({
      page: page,
      rowPerPage: rowsPerPage,
      payload,
    })
      .unwrap()
      .then((res) => {
        if (res) {
          setFeedbacks(res?.results ?? []);
          setPagination((prev) => ({
            ...prev,
            totalTableRowsCount: get(res, "numberOfRecords", 0),
            totalPageCount: get(res, "numberOfPages", 0),
          }));
          dispatch(setBadgeCount({ badgeCount: get(res, "numberOfRecords", 0) }));
        }
      })
      .catch(handleError);
  };

  useEffect(() => {
    if (!showFeedback) {
      getAllTaskbox();
    }
  }, [showFeedback]);

  return (
    <Paper display="block" sx={{ borderRadius: 2, p: 2, height: "80vh", overflowY: "scroll" }}>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent={"start"}>
            <Typography variant="h5" fontWeight={600}>
              {"Taskbox"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <TaskboxTable
        allTableRows={feedbacks}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
        handleAct={handleShowFeedback}
        setCurrentFeedBack={setCurrentFeedBack}
      />

      <FeedbackForm showDialog={showFeedback} handleShowDialog={handleShowFeedback} feedback={currentFeedBack} />
    </Paper>
  );
};

export default Taskbox;

export const TASKBOX_HEADER_COLUMNS = [
  { label: T.S_NO },
  {
    label: "EMPLOYEE NAME",
  },
  {
    label: T.TYPE,
  },
  {
    label: "COURSE NAME",
  },
  {
    label: "TASK",
  },
  {
    label: "Trigger Date",
  },
  {
    label: "Due Date",
  },
  {
    label: "Actions",
  },
];

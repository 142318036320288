import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Paper, Typography } from "@mui/material";
import MISDialog from "components/common/MISDialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/system";
import T from "T";
import DeletedNcTable from "./DeletedNcTable";

const DeletedNcList = ({
  openDeleteNcCountList = false,
  selectedUserName = "",
  userId = "",
  endDate = "",
  startDate = "",
  selectedMonth = "",
  typeOfNc = "",
  handleDeleteNcCountDialog = noop,
}) => {
  const handleBack = () => {
    handleDeleteNcCountDialog();
  };

  return (
    <MISDialog open={openDeleteNcCountList}>
      <Paper display="block" justifyContent="flex-start" sx={{ borderRadius: 2, p: 2 }}>
        <Box display="flex" alignItems="center" mb={1}>
          <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer", p: 1 }} onClick={handleBack} />
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>{`${selectedUserName} (${T.DELETED} ${T.NCS})`}</Typography>
        </Box>
        <DeletedNcTable
          userId={userId}
          selectedMonth={selectedMonth}
          endDate={endDate}
          startDate={startDate}
          typeOfNc={typeOfNc}
        />
      </Paper>
    </MISDialog>
  );
};

DeletedNcList.propTypes = {
  openDeleteNcCountList: PropTypes.bool,
  selectedUserName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  typeOfNc: PropTypes.string.isRequired,
  handleDeleteNcCountDialog: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  selectedMonth: PropTypes.string,
};

export default DeletedNcList;

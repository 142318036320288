import noop from "lodash/noop";
import PropTypes from "prop-types";
import { Card, Typography } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";

import T from "T";
import { Box } from "@mui/system";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import FilterOptions from "./FilterOptions";

const GroupsFilters = ({
  filterOpen,
  setFilterOpen,
  filterData,
  filters = {},
  isFilterStoredEmpty = true,
  handleFilterReset = noop,
  handleFilterSubmit = noop,
  handleFilterChange = noop,
}) => {
  const { projectManagers, workLocationList, skillList, departmentList, projectList } = filterData;
  const handleFilterClick = () => {
    setFilterOpen(!filterOpen);
  };

  const onFilterReset = () => {
    setFilterOpen(false);
    handleFilterReset();
  };

  const onFilterSubmit = () => {
    handleFilterSubmit();
    setFilterOpen(false);
  };
  return (
    <Box sx={{ position: "relative" }}>
      <Card
        elevation={0}
        variant="outlined"
        onClick={handleFilterClick}
        sx={{
          p: 1,
          backgroundColor: "background.white",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          sx={isFilterStoredEmpty ? { fontSize: 14 } : { fontWeight: 600, fontSize: 14, color: NETSMARTZ_THEME_COLOR }}
        >{`${T.FILTER}s`}</Typography>
        {isFilterStoredEmpty ? <FilterAltOutlinedIcon fontSize="small" /> : <FilterAltOffOutlinedIcon fontSize="small" />}
      </Card>

      {filterOpen && (
        <FilterOptions
          filters={filters}
          projectManagers={projectManagers}
          workLocationList={workLocationList}
          skillList={skillList}
          departmentList={departmentList}
          projectList={projectList}
          handleFilterReset={onFilterReset}
          handleFilterSubmit={onFilterSubmit}
          handleFilterChange={handleFilterChange}
          handleClose={() => setFilterOpen(false)}
        />
      )}
    </Box>
  );
};

GroupsFilters.propTypes = {
  filterData: PropTypes.object,
  projectManagers: PropTypes.object,
  workLocationList: PropTypes.object,
  skillList: PropTypes.object,
  departmentList: PropTypes.object,
  projectList: PropTypes.object,
  filters: PropTypes.object,
  isFilterStoredEmpty: PropTypes.bool,
  filterOpen: PropTypes.bool,
  setFilterOpen: PropTypes.func,
  handleFilterChange: PropTypes.func,
  handleFilterReset: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
};

export default GroupsFilters;

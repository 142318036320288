import { useEffect, useReducer } from "react";
import { Box, Tab, Paper, styled } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { filter, get, isEqual, set } from "lodash";
import { format, isValid } from "date-fns";
import { useNavigate } from "react-router-dom";
import TopBar from "./Topbar";
import MISLoader from "components/common/MISLoader";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { handleError } from "utils/error";
import { MISCurrentUser } from "utils/validations";
import { downloadFile } from "utils/file";
import { toast } from "react-toastify";
import usePMFetch from "hooks/usePMFetch";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { PAGINATION } from "settings/constants/pagination";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { useLazyGenerateCertificateQuery } from "api/training/assignCourse/generateCertificate";
import { useGetCourseFilterMutation } from "api/training/courseDetail/courseFilters";
import { useLazyGetAllAssignedTrainingQuery } from "api/training/assignCourse/getAllAssignList";
import { useGetAssignCourseFilterMutation } from "api/training/assignCourse/filterAssignCourse";
import { useReviewCourseMutation } from "api/training/assignCourse/assignmentReview";
import { useRevokeCourseMutation } from "api/training/assignCourse/revokeCourse";
import { assignTrainingFilterStore } from "slices/assignTrainingFilterSlice";
import { tabsStore } from "slices/tabsSlice";
import Constants from "Constants";
import T from "T";
import CourseTab from "./CourseDetail/CourseTab";
import AssignTab from "./AssignedTraining/AssignTab";
import ReviewModal from "./AssignedTraining/ReviewModal";
import AssignedCourseTab from "./CourseAssigned/AssignedCourseTab";
import { canViewTraining } from "utils/permissions";
import ViewSubCourse from "./CourseDetail/ViewSubCourse";
import { filtersStore } from "slices/filtersSlice";
import { attendanceTypeList } from "./trainingModal";
import { useGetAssignedCourseFilterMutation } from "api/training/courseAssigned/assignedCourseFilter";
import { canReviewTraining } from "utils/permissions";
import { useLazyCourseTypesQuery } from "api/training/typeOfCourse/courseType";
import { courseDetailFilterStore } from "slices/courseDetailFilterSlice";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: "14px",
  alignItems: "center",
  justifyContent: "space-between",
  color: theme.palette.background.black,
  minHeight: "unset",
  border: `1px solid ${theme.palette.border.tabsGrey}`,
}));

const Training = () => {
  const { sessionToken, user } = MISCurrentUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { storedTab } = useSelector(
    (state) => ({
      storedTab: get(state, "TabsSlice.storedTab", ""),
    }),
    shallowEqual,
  );

  if (storedTab === "") {
    const setvalue = canViewTraining() ? "1" : "3";
    dispatch(tabsStore({ storedTab: setvalue }));
  }

  const { storedFilters } = useSelector(
    (state) => ({
      storedFilters: get(state, "AssignTrainingFilterSlice.storedFilters", {}),
    }),
    shallowEqual,
  );

  const courseDetailFilterSelector = useSelector(
    (state) => ({
      storedFilters: get(state, "courseDetailFilterSlice.storedFilters", {}),
    }),
    shallowEqual,
  );

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    tabValue: storedTab ? storedTab : canViewTraining() ? "1" : "3",
    filters: storedFilters,
    startDate: null,
    endDate: null,
    searchInput: "",
    attendanceType: "",
    courseTableData: { allTableRows: [], totalTableRowsCount: "", totalPageCount: "" },
    assignTableData: { allTableRows: [], totalTableRowsCount: "", totalPageCount: "" },
    allAssignTableData: { allTableRows: [], totalTableRowsCount: "", totalPageCount: "" },
    assignedTrainingTableData: { allTableRows: [], totalTableRowsCount: "", totalPageCount: "" },
    showOptions: false,
    showCancelIcon: false,
    searchTableData: {},
    subCourse: {},
    assignData: {},
    openSubCourse: false,
    exportLoading: false,
    openReview: false,
    reviewData: [],
    assignmentReview: {
      subCourseIds: [],
      rating: "",
      comment: "",
    },
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    courseTypeLists: [],
    courseDetailFilter: courseDetailFilterSelector?.storedFilters,
  });

  const {
    tabValue,
    filters,
    startDate,
    endDate,
    searchInput,
    attendanceType,
    showOptions,
    showCancelIcon,
    courseTableData,
    allAssignTableData,
    assignTableData,
    assignedTrainingTableData,
    subCourse,
    assignData,
    openSubCourse,
    openReview,
    searchTableData,
    exportLoading,
    page,
    rowsPerPage,
    reviewData,
    assignmentReview,
    courseTypeLists,
    courseDetailFilter,
  } = localState;

  const { SERVER_URL } = Constants;
  const [getAllAssignTraining, { isFetching: isAllAssignFetching }] = useLazyGetAllAssignedTrainingQuery();
  const [getCourseFilter, { isFetching: isCourseFilterFetching }] = useGetCourseFilterMutation();
  const [getAssignCourseFilter, { isFetching: isAssignFilterFetching }] = useGetAssignCourseFilterMutation();
  const [getAssignedCourseFilter, { isFetching: isAssignedFilterFetching }] = useGetAssignedCourseFilterMutation();
  const [reviewCourse] = useReviewCourseMutation();
  const [revokeCourse] = useRevokeCourseMutation();
  const [generateCertificate] = useLazyGenerateCertificateQuery();
  const [courseTypes] = useLazyCourseTypesQuery();

  const { projectManagers, departmentList } = usePMFetch();

  const pMResults = get(projectManagers, "results", []);
  const departList = get(departmentList, "results", []);

  const emptyFilters = {
    isOfflineSelected: false,
    isReviewerSelected: false,
    status: null,
    assignedBy: null,
    department: null,
    reportingManager: null,
    trainingType: null,
  };

  const emptyCourseDetailFilter = {
    department: null,
    courseType: null,
  };

  const isFilterEmpty = isEqual(storedFilters, emptyFilters);
  const isCourseDetailFilterEmpty = isEqual(courseDetailFilterSelector?.storedFilters, emptyCourseDetailFilter);
  //To be changed
  useEffect(() => {
    courseTypes()
      .unwrap()
      .then((res) => {
        setLocalState({ courseTypeLists: res });
      })
      .catch(handleError);
    // getCourseFilterTableData(page, rowsPerPage, searchInput, startDate, endDate);
    // getAssignedFilterTableData(page, rowsPerPage, searchInput);
    getAllAssignedTrainingData(page, rowsPerPage); // for track Status
    // if (user.role !== T.PM) {
    //   getAssignFilterTableData(page, rowsPerPage, searchInput, storedFilters, startDate, endDate);
    // }
  }, []);

  useEffect(() => {
    setLocalState({ filters: storedFilters });
    setLocalState({ courseDetailFilter: courseDetailFilterSelector?.storedFilters });
    refreshTable(page, rowsPerPage, tabValue, searchInput, filters, startDate, endDate, courseDetailFilter);
    dispatch(tabsStore({ storedTab: tabValue }));
  }, [page, rowsPerPage, tabValue]);

  useEffect(() => {
    // Check if both startDate and endDate are defined and are not null
    if (startDate !== null && endDate !== null) {
      refreshTable(page, rowsPerPage, tabValue, searchInput, filters, startDate, endDate, courseDetailFilter);
    } else {
      if (tabValue === "1") {
        refreshTable(page, rowsPerPage, tabValue, searchInput, filters, null, null, courseDetailFilter);
      }
    }
  }, [startDate, endDate, page, rowsPerPage]);

  // useEffect(() => {
  //   if ([T.PM, T.VP].includes(user.role)) {
  //     onhandleFilterChange(
  //       { name: get(user, "user.userName", ""), emailId: get(user, "user.userEmailId", "") },
  //       "reportingManager",
  //     );
  //     dispatch(assignTrainingFilterStore({ storedFilters: filters }));
  //     refreshTable(page, rowsPerPage, tabValue, searchInput, filters, startDate, endDate);
  //   } else {
  //     refreshTable(page, rowsPerPage, tabValue, searchInput, filters, startDate, endDate);
  //   }
  // }, [tabValue]);

  const refreshTable = (page, rowPerPage, tab, search, filters, startDate, endDate, courseDetailFilter) => {
    if (tab === "1" && canViewTraining()) {
      getCourseFilterTableData(page, rowPerPage, search, startDate, endDate, courseDetailFilter);
    }
    if (tab === "2" && (canViewTraining() || canReviewTraining())) {
      getAssignFilterTableData(page, rowPerPage, search, filters, startDate, endDate);
    }
    if (tab === "3") {
      getAssignedFilterTableData(page, rowPerPage, search);
    }
  };

  const handleExport = async (type) => {
    setLocalState({ exportLoading: true });
    if (tabValue === "1") {
      fetch(`${SERVER_URL}/course/export`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      })
        .then((res) => res.blob())
        .then((response) => {
          downloadFile(response, type);
          setLocalState({ exportLoading: false });
        })
        .catch(handleError);
    } else {
      fetch(`${SERVER_URL}/training/export`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          assignedBy: get(filters, "assignedBy.name", ""),
          department: get(filters, "department.departmentName", ""),
          projectManagerEmail: get(filters, "reportingManager.emailId", ""),
          startDate,
          endDate,
          text: searchInput,
          trackStatus: get(filters, "status", ""),
          isOffline: get(filters, "isOfflineSelected", false),
          isReviewer: get(filters, "isReviewerSelected", false),
        }),
      })
        .then((res) => res.blob())
        .then((response) => {
          downloadFile(response, type);
          setLocalState({ exportLoading: false });
        })
        .catch(handleError);
    }
  };

  const handleSubCourse = (subCourseData) => {
    setLocalState({ openSubCourse: !openSubCourse, subCourse: subCourseData });
  };

  const handleCourseAdd = () => {
    navigate("add-course");
  };

  const handleReview = (assignData) => {
    setLocalState({ openReview: !openReview, assignData: assignData });
    const labelList = attendanceTypeList.map((attendanceType) => attendanceType.label);
    const attendanceStatus = labelList.includes(get(assignData, "attendance", ""));
    const comments = get(assignData, "comments", "");
    const skillRate = get(assignData, "skillRate", 0);
    const attendanceTypeName = attendanceStatus ? get(assignData, "attendance", "") : "";
    const data = assignData?.subsCourses.map((value) => {
      return {
        id: value.id,
        checked: false,
      };
    });
    setLocalState({
      attendanceType: attendanceTypeName,
      reviewData: data,
      assignmentReview: {
        ...assignmentReview,
        rating: skillRate,
        comment: comments,
      },
    });
  };

  const handleDeleteCourse = (trainingAssignmentId, userId) => {
    revokeCourse({ trainingAssignmentId: trainingAssignmentId })
      .unwrap()
      .then(() => {
        toast.success(T.COURSE_REVOKED);
        // Refresh table to show change in 3rd tab
        refreshTable(
          page,
          rowsPerPage,
          tabValue,
          searchInput,
          storedFilters,
          startDate,
          endDate,
          courseDetailFilterSelector?.storedFilters,
        );
      })
      .catch(handleError);
  };

  const handleComplete = (index, value, reviewData) => {
    const updatedReviewData = [...reviewData];
    updatedReviewData[index]["checked"] = value;
    const newValueToAdd = updatedReviewData[index].id;

    if (value) {
      const updatedAssignmentReview = {
        ...assignmentReview,
        subCourseIds: [...assignmentReview.subCourseIds, newValueToAdd],
      };
      setLocalState({
        ...localState,
        assignmentReview: updatedAssignmentReview,
      });
    } else {
      const idToRemove = updatedReviewData[index].id;
      // Create a copy of the assignmentReview object
      const updatedAssignmentReview = {
        ...assignmentReview,
        subCourseIds: assignmentReview.subCourseIds.filter((id) => id !== idToRemove),
      };
      setLocalState({
        ...localState,
        assignmentReview: updatedAssignmentReview,
      });
    }
    setLocalState({ reviewData: updatedReviewData });
  };

  const handleSaveReviewDialog = () => {
    const updatedSubCourseIds = assignData.subsCourses.filter((value) => value.status === T.COMPLETED).map((value) => value.id);

    const updatedAssignmentReview = {
      ...assignmentReview,
      subCourseIds: [...assignmentReview.subCourseIds, ...updatedSubCourseIds],
    };

    setLocalState({
      ...localState,
      assignmentReview: updatedAssignmentReview,
    });

    const payload = {
      subCourseAssignmentMappingIds: assignmentReview.subCourseIds,
      trainingAssignmentId: assignData.id,
      offlineTrainingReview: attendanceType,
      skillRate: assignmentReview.rating ? parseFloat(assignmentReview.rating) : 0,
      comments: assignmentReview.comment,
      isSaved: true,
    };

    reviewCourse(payload)
      .unwrap()
      .then(() => {
        setLocalState({
          assignmentReview: {
            subCourseIds: [],
            rating: "",
            comment: "",
          },
          attendanceType: "",
        });
        toast.success(T.COURSE_REVIEWED_SUCCESSFULLY);
        refreshTable(
          page,
          rowsPerPage,
          tabValue,
          searchInput,
          storedFilters,
          startDate,
          endDate,
          courseDetailFilterSelector?.storedFilters,
        );
        handleReview();
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({
      ...localState,
      assignmentReview: { ...assignmentReview, [name]: value },
    });
  };

  const generate_Certificate = (id) => {
    generateCertificate({ id })
      .unwrap()
      .then(() => {
        toast.success(T.CERTIFICATE_GENERATED_SUCCESSFULLY);
      })
      .catch(handleError);
  };

  const handleAttendanceRadioChange = (event) => {
    setLocalState({ attendanceType: event.target.value });
  };

  const handleCompleteReview = () => {
    const updatedSubCourseIds = assignData.subsCourses.filter((value) => value.status === T.COMPLETED).map((value) => value.id);

    const updatedAssignmentReview = {
      ...assignmentReview,
      subCourseIds: [...assignmentReview.subCourseIds, ...updatedSubCourseIds],
    };

    setLocalState({
      ...localState,
      assignmentReview: updatedAssignmentReview,
    });

    const payload = {
      subCourseAssignmentMappingIds: assignmentReview.subCourseIds,
      trainingAssignmentId: assignData.id,
      offlineTrainingReview: attendanceType,
      skillRate: assignmentReview.rating ? parseFloat(assignmentReview.rating) : 0,
      comments: assignmentReview.comment,
      isSaved: false,
    };

    reviewCourse(payload)
      .unwrap()
      .then(() => {
        setLocalState({
          assignmentReview: {
            subCourseIds: [],
            rating: "",
            comment: "",
          },
          attendanceType: "",
        });
        toast.success(T.COURSE_REVIEWED_SUCCESSFULLY);
        refreshTable(
          page,
          rowsPerPage,
          tabValue,
          searchInput,
          storedFilters,
          startDate,
          endDate,
          courseDetailFilterSelector?.storedFilters,
        );
        handleReview();
      });
  };
  // ###################### Without Filter API #####################
  // This api use for the track status Below
  const getAllAssignedTrainingData = (page, size) => {
    getAllAssignTraining({ page, rowPerPage: size })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({
          allAssignTableData: { allTableRows, totalTableRowsCount, totalPageCount },
        });
      })
      .catch();
  };

  // ####################### Tab handlers Things ##################
  const handleTabChange = (event, newValue) => {
    setLocalState({
      tabValue: newValue,
      page: INITIAL_PAGE,
      rowsPerPage: ROWS_PER_PAGE,
      searchInput: "",
    });
  };

  // ####################### Page handlers Things ##################
  // Page change handler
  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
  };

  // ################### Search Handler Things #####################
  const handleSearchChange = (event) => {
    const { value, dataset } = event.currentTarget;
    const searchValue = value || get(dataset, "val", "");
    setLocalState({
      showOptions: event.key !== "Enter",
      showCancelIcon: searchValue !== "",
      searchInput: searchValue,
    });
    if (event.key === "Enter" || event.currentTarget.nodeName === "svg" || searchValue === "") {
      refreshTable(
        page,
        rowsPerPage,
        tabValue,
        searchValue,
        storedFilters,
        startDate,
        endDate,
        courseDetailFilterSelector?.storedFilters,
      );
    }
  };

  const handleSearchKeyChange = () => {
    setLocalState({
      page: INITIAL_PAGE,
    });
    refreshTable(
      INITIAL_PAGE,
      rowsPerPage,
      tabValue,
      searchInput,
      filters,
      startDate,
      endDate,
      courseDetailFilterSelector?.storedFilters,
    );
  };

  const handleSearchClick = (value) => {
    setLocalState({
      showOptions: false,
      showCancelIcon: false,
      searchInput: value,
    });
  };

  const handleClickOutside = () => {
    setLocalState({
      showOptions: false,
    });
  };

  // ################## Date Change Things #########################
  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const onHandleDateChange = (newValue, type) => {
    const validDate = newValue ? new Date(newValue) : null;
    setLocalState({
      [type]: validDate && isValid(validDate) ? getBEDateFormat(validDate) : null,
    });
  };

  //####################### Filter Things#########################
  //Filter change handler
  const onhandleFilterChange = (newValue, item) => {
    setLocalState({ filters: { ...filters, [item]: newValue } });
  };

  const onHandleCourseDetialFilterChange = (newValue, item) => {
    setLocalState({ courseDetailFilter: { ...courseDetailFilter, [item]: newValue } });
  };

  //Filter reset handler
  const handleFilterClose = () => {
    dispatch(assignTrainingFilterStore({ storedFilters: emptyFilters }));
    setLocalState({ filters: emptyFilters, startDate: null, endDate: null });
    refreshTable(page, rowsPerPage, tabValue, searchInput, emptyFilters, null, null, emptyCourseDetailFilter);
  };
  const handleCourseDetialFilterClose = () => {
    //todo empty filters
    dispatch(courseDetailFilterStore({ storedFilters: emptyCourseDetailFilter }));
    setLocalState({ courseDetailFilter: emptyCourseDetailFilter });
    refreshTable(page, rowsPerPage, tabValue, searchInput, emptyFilters, null, null, emptyCourseDetailFilter);
  };

  //Filter submit handler
  const handleFilterSubmit = () => {
    dispatch(assignTrainingFilterStore({ storedFilters: filters }));
    refreshTable(page, rowsPerPage, tabValue, searchInput, filters, startDate, endDate, courseDetailFilter);
  };

  const handleCourseDetailFilterSubmit = () => {
    dispatch(courseDetailFilterStore({ storedFilters: courseDetailFilter }));
    refreshTable(page, rowsPerPage, tabValue, searchInput, filters, startDate, endDate, courseDetailFilter);
  };

  //get filtered Course data
  const getCourseFilterTableData = (page, rowsPerPage, search, startDate, endDate, courseDetailFilter) => {
    getCourseFilter({
      page,
      rowPerPage: rowsPerPage,
      courseFilterDto: {
        assignmentType: get(courseDetailFilter, "courseType.courseAssignmentType", null),
        departmentName: get(courseDetailFilter, "department.departmentName", null),
        text: search,
      },
    })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({
          courseTableData: { allTableRows, totalTableRowsCount, totalPageCount },
        });
      });
  };

  //get filtered Assign Course data
  const getAssignFilterTableData = (page, rowsPerPage, search, filters, startDate, endDate) => {
    getAssignCourseFilter({
      page,
      rowPerPage: rowsPerPage,
      assignTrainingFilterDto: {
        assignedBy: get(filters, "assignedBy.name", ""),
        department: get(filters, "department.departmentName", ""),
        projectManagerEmail: get(filters, "reportingManager.emailId", ""), ///
        startDate,
        endDate,
        text: search,
        trackStatus: get(filters, "status", ""),
        isOffline: get(filters, "isOfflineSelected", false),
        isReviewer: get(filters, "isReviewerSelected", false),
        trainingType: get(filters, "trainingType.trainingType", null),
      },
    })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({
          assignTableData: { allTableRows, totalTableRowsCount, totalPageCount },
        });
      })
      .catch(handleError);
  };

  //get filtered trainee data
  const getAssignedFilterTableData = (page, rowsPerPage, search) => {
    getAssignedCourseFilter({
      page,
      rowPerPage: rowsPerPage,
      assignedTrainingFilterDto: { text: search },
    })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({
          assignedTrainingTableData: { allTableRows, totalTableRowsCount, totalPageCount },
        });
      })
      .catch(handleError);
  };
  // ########################################################################
  const onHandleClose = () => {
    setLocalState({
      assignmentReview: {
        subCourseIds: [],
        rating: "",
        comment: "",
      },
    });
    handleReview();
  };

  const TrackRecord = allAssignTableData.allTableRows.map((row) => ({
    coursename: row?.trainingCourse?.courseName,
    trackstatus: row?.trackStatus,
  }));

  return (
    <Paper display={"block"} sx={{ borderRadius: 2 }}>
      {(isCourseFilterFetching || isAllAssignFetching || isAssignFilterFetching || isAssignedFilterFetching || exportLoading) && (
        <MISLoader />
      )}
      <TabContext value={tabValue}>
        <TabList
          variant="fullWidth"
          indicatorColor="false"
          onChange={handleTabChange}
          aria-label="lab API tabs example"
          sx={{
            "& .MuiTab-root.Mui-selected": {
              color: BACKGROUND.white,
              backgroundColor: NETSMARTZ_THEME_COLOR,
            },
            "& .MuiTab-root": {
              fontWeight: 600,
            },
          }}
        >
          {canViewTraining() && (
            <StyledTab sx={{ borderTopLeftRadius: "10px" }} label={T.COURSE_DETAIL.toUpperCase()} value="1" />
          )}
          {(canViewTraining() || canReviewTraining()) && <StyledTab label={T.TRAINING_DETAIL.toUpperCase()} value="2" />}
          {canViewTraining() || canReviewTraining() ? (
            <StyledTab sx={{ borderTopRightRadius: "10px" }} label={T.COURSE_ASSIGNED.toUpperCase()} value="3" />
          ) : (
            <StyledTab
              sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
              disabled
              label={T.COURSE_ASSIGNED.toUpperCase()}
              value="3"
            />
          )}
        </TabList>

        <Box
          sx={{
            "& .MuiTabPanel-root": {
              p: 2,
              pt: 0,
            },
          }}
        >
          <TopBar
            filters={filters}
            courseDetailFilter={courseDetailFilter}
            value={tabValue}
            startDate={startDate}
            endDate={endDate}
            searchInput={searchInput}
            showOptions={showOptions}
            showCancelIcon={showCancelIcon}
            searchTableData={searchTableData}
            projectManagerList={pMResults}
            departmentList={departList}
            isFilterEmpty={isFilterEmpty}
            handleExport={handleExport}
            onClick={handleSearchClick}
            handleKeyChange={handleSearchKeyChange}
            handleChange={handleSearchChange}
            onClickOutside={handleClickOutside}
            onHandleDateChange={onHandleDateChange}
            handleCourseAdd={handleCourseAdd}
            handleFilterSubmit={handleFilterSubmit}
            onhandleFilterChange={onhandleFilterChange}
            handleFilterClose={handleFilterClose}
            setTrainingState={setLocalState}
            courseType={courseTypeLists}
            handleCourseDetailFilterSubmit={handleCourseDetailFilterSubmit}
            onHandleCourseDetialFilterChange={onHandleCourseDetialFilterChange}
            handleCourseDetialFilterClose={handleCourseDetialFilterClose}
            isCourseDetailFilterEmpty={isCourseDetailFilterEmpty}
          />
          {canViewTraining() && (
            <TabPanel value="1">
              <CourseTab
                allTableRows={courseTableData.allTableRows}
                totalTableRowsCount={courseTableData.totalTableRowsCount}
                totalPageCount={courseTableData.totalPageCount}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                handleSubCourse={handleSubCourse}
                TrackRecord={TrackRecord}
              />
            </TabPanel>
          )}
          {(canViewTraining() || canReviewTraining()) && (
            <TabPanel value="2">
              <AssignTab
                allTableRows={assignTableData.allTableRows}
                totalTableRowsCount={assignTableData.totalTableRowsCount}
                totalPageCount={assignTableData.totalPageCount}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                handleReview={handleReview}
                handleDeleteCourse={handleDeleteCourse}
                generate_Certificate={generate_Certificate}
              />
            </TabPanel>
          )}
          <TabPanel value="3">
            <AssignedCourseTab
              allTableRows={assignedTrainingTableData.allTableRows}
              totalTableRowsCount={assignedTrainingTableData.totalTableRowsCount}
              totalPageCount={assignedTrainingTableData.totalPageCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </TabPanel>
        </Box>
      </TabContext>
      <ViewSubCourse openSubCourse={openSubCourse} record={subCourse} handleSubCourse={handleSubCourse} />
      <ReviewModal
        openReview={openReview}
        record={assignData}
        reviewData={reviewData}
        assignmentReview={assignmentReview}
        attendanceType={attendanceType}
        generate_Certificate={generate_Certificate}
        handleSaveReviewDialog={handleSaveReviewDialog}
        handleAttendanceRadioChange={handleAttendanceRadioChange}
        handleReview={handleReview}
        handleComplete={handleComplete}
        handleCompleteReview={handleCompleteReview}
        handleChange={handleChange}
        onHandleClose={onHandleClose}
      />
    </Paper>
  );
};

export default Training;

import { createSlice } from "@reduxjs/toolkit";

const psCardSlice = createSlice({
  name: "PSCardSlice",
  initialState: {
    selectedCardId: null,
    selectedCard: [],
    scrollPosition: 0,
  },

  reducers: {
    psCardStore: (state, action) => {
      state.selectedCardId = action.payload.selectedCardId;
      state.selectedCard = action.payload.selectedCard;
      state.scrollPosition = action.payload.scrollPosition;
    },
  },
});

export const { psCardStore } = psCardSlice.actions;
export default psCardSlice.reducer;

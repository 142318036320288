import PropTypes from "prop-types";
import { Table as TableView, TableContainer } from "@mui/material";
import { ASSIGNED_COURSE_TABLE_HEADER } from "settings/constants/members";
import TrainingHistoryHeader from "./TrainingHistoryHeader";
import TrainingHistoryBody from "./TrainingHistoryBody";
import MISNoData from "components/common/MISNoData";
import { Box } from "@mui/system";

const TrainingTable = ({ tableRecord = [] }) => {
  const TRAINING_HISTORY_TABLE_HEADER = ASSIGNED_COURSE_TABLE_HEADER.slice(0, -1);
  return (
    <TableContainer
      sx={{
        overflow: "auto",
        height: 220,
      }}
    >
      {tableRecord.length > 0 ? (
        <TableView stickyHeader>
          <TrainingHistoryHeader columns={TRAINING_HISTORY_TABLE_HEADER} />
          <TrainingHistoryBody tableRecord={tableRecord} />
        </TableView>
      ) : (
        <Box>
          <MISNoData />
        </Box>
      )}
    </TableContainer>
  );
};

TrainingTable.propTypes = {
  tableRecord: PropTypes.array,
};

export default TrainingTable;

import { useEffect, useReducer } from "react";
import { Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import T from "T";
import Table from "./Table";
import { useGetPitchedUserStatusMutation } from "api/preSales/interviewTracker/getPitchedUserStatus";
import { handleError } from "utils/error";
import { PAGINATION } from "settings/constants/pagination";
import TopBar from "./TopBar";
import PrmiaryGrid from "./PrimaryGrid";
import { get, isEqual } from "lodash";
import { useGetReportCardAnalysisMutation } from "api/preSales/getReportCard";
import { endOfMonth, format, isValid, startOfMonth } from "date-fns";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { MISCurrentUser } from "utils/validations";
import usePMFetch from "hooks/usePMFetch";
import MISLoader from "components/common/MISLoader";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { reportCardFilterStore } from "slices/reportCardFilterSlice";
import SecondaryGrid from "./SecondaryGrid";
import RequirementStatusFilterChips from "./RequirementStatusFilterChips";
import { useGetReportCardStatsMutation } from "api/preSales/getReportCardStats";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const ReportCard = () => {
  const { storedFilters } = useSelector(
    (state) => ({
      storedFilters: get(state, "ReportCardFilterSlice.storedFilters", {}),
    }),
    shallowEqual,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentDate = new Date();
  const firstDayOfMonth = startOfMonth(currentDate);
  const lastDayOfMonth = endOfMonth(currentDate);
  const { user } = MISCurrentUser();
  const isUserPM = get(user, "role", null) === "PM";

  const defaultFilters = {
    clientName: "",
    bytManager: isUserPM ? { name: get(user, "user.userName", "") } : null,
    techStack: "",
    status: "",
  };
  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    status: [],
    startDate: getBEDateFormat(firstDayOfMonth),
    endDate: getBEDateFormat(lastDayOfMonth),
    tableData: [],
    primaryGridData: {},
    secondaryGridData: {},
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    totalTableRowsCount: INITIAL_PAGE,
    totalPageCount: INITIAL_PAGE,
    clientName: location?.state,
    filters: defaultFilters,
    selectedStatuses: ["Open"],
  });
  const [pitchedUserStatus] = useGetPitchedUserStatusMutation();
  const [getReportCard, { isLoading: isDataLoading }] = useGetReportCardAnalysisMutation();
  const [getReportCardStats, { isLoading: isStatsLoading }] = useGetReportCardStatsMutation();
  const { bytManagers, skillList } = usePMFetch();
  const {
    status,
    startDate,
    endDate,
    tableData,
    primaryGridData,
    secondaryGridData,
    page,
    rowsPerPage,
    totalTableRowsCount,
    totalPageCount,
    clientName,
    filters,
    selectedStatuses,
  } = localState;

  const emptyFilters = {
    clientName: "",
    bytmanager: isUserPM ? { name: get(user, "user.userName", "") } : null,
    techStack: "",
    status: "",
  };
  const isFilterEmpty = isEqual(storedFilters, emptyFilters);

  useEffect(() => {
    pitchedUserStatus()
      .unwrap()
      .then((res) => {
        setLocalState({
          status: res,
        });
      })
      .catch((error) => {
        handleError(error);
      });
    pitchedUserStatus();
  }, []);

  useEffect(() => {
    getReportData({ page, rowsPerPage, appliedFilters: filters });
  }, [startDate, endDate, selectedStatuses]);

  const getReportData = ({ page, rowsPerPage, appliedFilters }) => {
    const payload = {
      clientName: get(appliedFilters, "clientName", ""),
      status: get(appliedFilters, "status", ""),
      reportingManager: "",
      bytManager: get(appliedFilters, "bytManager.name", ""),
      startDate,
      endDate,
      techStack: get(appliedFilters, "techStack.skillName", ""),
      text: "",
      requirementStatus: selectedStatuses.length > 0 ? selectedStatuses : [""],
    };
    getReportCard({ page, size: rowsPerPage, payload })
      .unwrap()
      .then((res) => {
        setLocalState({
          tableData: get(res, "results", []),
          totalTableRowsCount: get(res, "numberOfRecords", 0),
          totalPageCount: get(res, "numberOfPages", 0),
        });
      })
      .catch((err) => {
        handleError(err);
      });
    getReportCardStats({ page, size: rowsPerPage, payload })
      .unwrap()
      .then((res) => {
        const primaryGridData = {
          totalJobsClosed: get(res, "totalJobsClosed", 0),
          totalJobsLost: get(res, "totalJobsLost", 0),
          totalJobsOnHold: get(res, "totalJobsOnHold", 0),
          totalJobsWon: get(res, "totalJobsWon", 0),
          totalOpportunityClosed: get(res, "totalOpportunityClosed", 0),
          totalOpportunityLost: get(res, "totalOpportunityLost", 0),
          totalOpportunityOnHold: get(res, "totalOpportunityOnHold", 0),
          totalOpportunityWon: get(res, "totalOpportunityWon", 0),
          totalJobsOpen: get(res, "totalJobsOpen", 0),
          totalOpportunityOpen: get(res, "totalOpportunityOpen", 0),
          openOppsList: get(res, "openOppsList", []),
          wonOppsList: get(res, "wonOppsList", []),
          lostOppsList: get(res, "lostOppsList", []),
          openJobsList: get(res, "openJobsList", {}),
          wonJobsList: get(res, "wonJobsList", []),
          lostJobsList: get(res, "lostJobsList", []),
        };
        const secondaryGridData = {
          successRate: get(res, "successRate", 0),
          totalPitchedCandidates: get(res, "totalPitchedCandidates", 0),
          totalRejectedEmployees: get(res, "totalRejectedEmployees", 0),
          totalSelectedEmployees: get(res, "totalSelectedEmployees", 0),
        };
        setLocalState({
          primaryGridData,
          secondaryGridData,
        });
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const onHandleChange = (type, value) => {
    setLocalState({ [type]: value });
  };
  const onHandleDateChange = (newValue, type) => {
    const validDate = newValue ? new Date(newValue) : null;

    setLocalState({
      [type]: validDate && isValid(validDate) ? getBEDateFormat(validDate) : null,
    });
  };
  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    getReportData({ page: newPage, rowsPerPage, appliedFilters: filters });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    getReportData({ page: INITIAL_PAGE, rowsPerPage: value, appliedFilters: filters });
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const onhandleFilterChange = (newValue, item) => {
    setLocalState({ filters: { ...filters, [item]: newValue } });
  };

  const handleFilterSubmit = () => {
    dispatch(reportCardFilterStore({ storedFilters: filters }));
    getReportData({ page: INITIAL_PAGE, rowsPerPage, appliedFilters: filters });
    setLocalState({
      page: INITIAL_PAGE,
      startDate: null,
      endDate: null,
      tableData: [],
    });
  };

  const handleFilterClose = () => {
    dispatch(reportCardFilterStore({ storedFilters: isUserPM ? defaultFilters : emptyFilters }));
    setLocalState(
      {
        filters: defaultFilters,
        startDate: getBEDateFormat(firstDayOfMonth),
        endDate: getBEDateFormat(lastDayOfMonth),
      },
      () => {
        // Callback function ensures API call is made after state update
        // This is crucial because state updates are asynchronous
        // Without the callback, the API call might happen before the state is updated
        // Resulting in fetching data with old filters
        getReportData({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE, appliedFilters: defaultFilters });
      },
    );
  };

  const handleChipChange = (updatedStatuses) => {
    setLocalState({ page: INITIAL_PAGE, selectedStatuses: updatedStatuses });
  };

  return (
    <Paper display="block" sx={{ borderRadius: 2, p: 2, height: "76vh", overflowY: "scroll" }}>
      {(isDataLoading || isStatsLoading) && <MISLoader />}
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box display="flex" alignItems="center">
            <IconButton sx={{ mr: 1 }} onClick={handleBack}>
              <ArrowBackIcon fontSize="small" />
            </IconButton>
            <Typography variant="h5" fontWeight={600} width={400}>
              {T.REPORT_CARD}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={7.9}>
          <TopBar
            skillList={skillList}
            onHandleChange={onHandleChange}
            onHandleDateChange={onHandleDateChange}
            status={status}
            startDate={startDate}
            endDate={endDate}
            pMResults={bytManagers}
            clientName={clientName}
            filters={filters}
            isFilterEmpty={isFilterEmpty}
            onhandleFilterChange={onhandleFilterChange}
            handleFilterSubmit={handleFilterSubmit}
            handleFilterClose={handleFilterClose}
          />
        </Grid>
      </Grid>
      <Box px={1.4} py={1}>
        <PrmiaryGrid record={primaryGridData} />
        <Divider sx={{ mt: 0.8 }} />
        <Grid container justifyContent={"space-between"} alignItems={"center"} pt={2} pb={1}>
          <Grid item xs={12} md={4}>
            <RequirementStatusFilterChips selectedStatuses={selectedStatuses} onChange={handleChipChange} />
          </Grid>
          <Grid item xs={12} md={8}>
            <SecondaryGrid record={secondaryGridData} />
          </Grid>
        </Grid>
      </Box>

      <Table
        allTableRows={tableData}
        onPageChange={handlePageChange}
        totalPageCount={totalPageCount}
        totalTableRowsCount={totalTableRowsCount}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </Paper>
  );
};

export default ReportCard;

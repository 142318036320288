import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography } from "@mui/material";
import memo from "utils/react";
import { get } from "utils/lodash";
import { BACKGROUND } from "theme/colors";
import { Box } from "@mui/system";

const TrainingHistoryHeader = ({ columns = [] }) => (
  <TableHead>
    <TableRow>
      {Children.toArray(
        columns.map((column, ind) => (
          <TableCell
            sx={{
              border: "none",
              backgroundColor: BACKGROUND.cardDefault,
              padding: "10px 24px",
              textAlign: "center",
            }}
          >
            <Typography variant="body1" noWrap fontWeight={600}>
              {column.label}
            </Typography>
          </TableCell>
        )),
      )}
    </TableRow>
  </TableHead>
);

TrainingHistoryHeader.propTypes = {
  columns: PropTypes.array,
};

export default TrainingHistoryHeader;

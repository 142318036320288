import { createSlice } from "@reduxjs/toolkit";

const filtersSlice = createSlice({
  name: "FiltersSlice",
  initialState: {},
  reducers: {
    filtersStore: (state, action) => {
      for (let key in action.payload) {
        if (key && action.payload[key]) {
          state[key] = action.payload[key];
        }
      }
    },
  },
});
export const { filtersStore } = filtersSlice.actions;

export default filtersSlice.reducer;

import { TableCell, TableBody, styled, TableRow, Tooltip } from "@mui/material";
import memo from "utils/react";
import { get } from "lodash";

const StyledTableCell = styled(TableCell)(({}) => ({
  borderTop: "inherit",
  overflow: "hidden",
  backgroundColor: "white",
  padding: "10px 8px 10px 24px",
  height: "30px",
  fontSize: "13px",
  maxWidth: "150px",
}));
const MISTableBody = ({ allTableRows }) => {
  return (
    <TableBody>
      {allTableRows.map((record, index) => (
        <TableRow key={index}>
          <StyledTableCell sx={{ pl: 4.5 }}>{index + 1}</StyledTableCell>
          <StyledTableCell>{get(record, "vpName", "-")}</StyledTableCell>
          <StyledTableCell>{get(record, "department", "-")}</StyledTableCell>
          <StyledTableCell>{get(record, "vpInReviews", "-")}</StyledTableCell>
          <StyledTableCell>{get(record, "vpRejected", "-")}</StyledTableCell>
          <StyledTableCell>{get(record, "vpApproved", "-")}</StyledTableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default memo(MISTableBody);

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    isPublishedCaseStudy: build.mutation({
      query: ({ payload, csId }) => ({
        url: `/caseStudy/kra/update?caseStudyId=${csId}`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useIsPublishedCaseStudyMutation } = extendedApi;

import { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import T from "T";
import GroupsTopBar from "./GroupsTobBar/GroupsTopBar";
import GroupsList from "./GroupsList/GroupsList";
import GroupsModal from "./GroupsModal/GroupsModal";
import AssignedCourseDialog from "components/Training/AssignedTraining/AssignedCourseDialog";
import { PAGINATION } from "settings/constants/pagination";
import { useLazyGetAllGroupsQuery } from "api/members/Groups/getAllGroups";
import { useApplyFiltersMutation } from "api/members/filters/applyFilters";
import { useCreateGroupMutation } from "api/members/Groups/createGroup";
import { useDeleteFromGroupMutation } from "api/members/Groups/deleteFromGroup";
import usePMFetch from "hooks/usePMFetch";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { handleError } from "utils/error";
import { getFilterPayload } from "utils/filters";
import { camelCase, isEqual } from "lodash";
import { toast } from "react-toastify";
import RemoveConfirmation from "./RemoveConfirmation";
import AddSingleUser from "./AddSingleUser";
import { useAddMembersToGroupMutation } from "api/members/Groups/addMembersToGroup";
import ArrowBack from "@mui/icons-material/ArrowBack";
import MISLoader from "components/common/MISLoader";
import { shallowEqual, useSelector } from "react-redux";
import { get } from "utils/lodash";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const Groups = () => {
  const [applyFilters, { isLoading: isUserFetching }] = useApplyFiltersMutation();
  const [getAllGroups, { isFetching: isGroupsFetching }] = useLazyGetAllGroupsQuery();
  const [createGroup] = useCreateGroupMutation();
  const [deleteFromGroup] = useDeleteFromGroupMutation();
  const [addMembersToGroup] = useAddMembersToGroupMutation();
  const filterData = usePMFetch();
  const navigate = useNavigate();

  const { user } = useSelector(
    (state) => ({
      user: get(state, "LoginSlice.user", null),
    }),
    shallowEqual,
  );
  const { username: userEmail, role } = user;

  const initialState = {
    //basic
    search: "",
    columns: [
      { label: "", key: "checkBox" },
      { label: "Name", key: "userName" },
      { label: "Employee Code", key: "employeeCode" },
      { label: "Email", key: "userEmailId" },
      { label: "", key: "actions" },
    ],
    groupName: "",
    fileType: "",
    excelFile: "",
    userEmailId: "",
    //list
    groupsList: [],
    expandedGroup: NaN, //current group
    //updating
    groupDetails: {},
    currentMembers: [],
    selectedMembers: [],
    allSelected: false,
    addAllReportees: false,
    //modal
    isModalOpen: false,
    dialogTitle: "",
    memberList: [],
    filters: {},
    appliedFilters: {},
    //assign
    isAssignDialogOpen: false,
    isConfirmationOpen: false,
    isAddSingleUserDialogOpen: false,
  };
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), initialState);
  const {
    groupName,
    fileType,
    excelFile,
    groupsList,
    userEmailId,
    expandedGroup,
    columns,
    groupDetails,
    currentMembers,
    selectedMembers,
    allSelected,
    addAllReportees,
    search,
    isModalOpen,
    dialogTitle,
    memberList,
    filters,
    appliedFilters,
    isAssignDialogOpen,
    isConfirmationOpen,
    isAddSingleUserDialogOpen,
  } = localState;

  useEffect(() => {
    refreshTable();
  }, []);

  useEffect(() => {
    // if (isModalOpen) {
    //   getMemberFilterTableData(INITIAL_PAGE, 1000);
    // }
    if (dialogTitle === T.ADD_MEMBERS) {
      getGroupDetails();
    }
  }, [isModalOpen]);

  useEffect(() => {
    getGroupDetails();
  }, [expandedGroup]);

  useEffect(() => {
    if (
      addAllReportees &&
      Object.keys(filters).length === 2 &&
      filters.projectManager.length > 0 &&
      filters.empMode === T.INHOUSE
    ) {
      // length is for number of filters applied
      handleSelectAll({ target: { checked: true } });
    } else {
      setLocalState({ addAllReportees: false });
      handleSelectAll({ target: { checked: false } });
    }
  }, [memberList]);

  const getGroupDetails = () => {
    if (expandedGroup) {
      const selectedGroup = groupsList.find((group) => group.groupId === expandedGroup);
      setLocalState({
        groupDetails: selectedGroup,
        groupName: selectedGroup.groupName,
        currentMembers: selectedGroup.usersList?.map((ele) => ele.user.id),
      });
    } else {
      setLocalState({ groupDetails: {}, groupName: "", currentMembers: [] });
    }
  };

  const refreshTable = async (searchText = search) => {
    await getGroupsList(INITIAL_PAGE, ROWS_PER_PAGE, searchText);
  };

  const getMemberFilterTableData = async (page, rowsPerPage, filtersApplied = filters) => {
    const payload = getFilterPayload(page, rowsPerPage, filtersApplied);
    applyFilters(payload)
      .unwrap()
      .then((res) => {
        const filteredMembers =
          dialogTitle === T.ADD_MEMBERS ? res.results.filter((ele) => !currentMembers.includes(ele.id)) : res.results;
        setLocalState({
          memberList: filteredMembers,
        });
      })
      .catch(handleError);
  };

  const getGroupsList = async (page, rowsPerPage, searchText) => {
    const params = {
      page,
      size: rowsPerPage,
      text: searchText,
    };

    getAllGroups(params)
      .unwrap()
      .then((response) => {
        setLocalState({
          groupsList: response.results,
        });
      })
      .catch(handleError);
  };

  const handleExpand = (groupId) => (event, isExpanded) => {
    setLocalState({
      expandedGroup: isExpanded ? groupId : NaN,
      selectedMembers: [],
      allSelected: false,
    });
  };

  const handleSelectMember = (event, memberId, location) => {
    const updatedArr = [...selectedMembers].includes(memberId)
      ? [...selectedMembers].filter((id) => id !== memberId)
      : [...selectedMembers, memberId];
    setLocalState({
      selectedMembers: updatedArr,
      allSelected:
        location === "Groups" ? updatedArr.length === groupDetails.usersList.length : updatedArr.length === memberList.length,
    });
  };

  const handleSelectAll = (event, location) => {
    const selectedGroup = groupsList.find((group) => group.groupId === expandedGroup);
    const { checked } = event.target;
    setLocalState({
      allSelected: checked,
      selectedMembers: checked
        ? location === "Groups"
          ? selectedGroup?.usersList?.map((users) => users.user.id)
          : memberList.map((member) => member.id)
        : [],
    });
  };

  const handleSelectAllReportees = (event) => {
    const { checked } = event.target;
    const newFilters = {};
    if (checked && role === T.PM) {
      newFilters.projectManager = [filterData.projectManagers.results.find((ele) => ele.emailId === userEmail)];
      newFilters.empMode = T.INHOUSE;
    } else if (checked && [T.HR, T.PMO, T.VP].includes(role)) {
      newFilters.projectManager = [...filterData.projectManagers.results];
      newFilters.empMode = T.INHOUSE;
    }
    setLocalState({ addAllReportees: checked, filters: newFilters });
    handleFilterSubmit(newFilters);
  };

  const handleCreateGroup = () => {
    setLocalState({
      isModalOpen: true,
      dialogTitle: T.CREATE_GROUP,
      allSelected: false,
      selectedMembers: [],
      groupName: "",
      fileType: "",
      excelFile: "",
    });
  };

  const handleEditGroup = () => {
    setLocalState({ isModalOpen: true, dialogTitle: T.ADD_MEMBERS, groupName: groupDetails.groupName });
  };

  const handleCloseModal = () => {
    setLocalState({ isModalOpen: false, filters: {}, appliedFilters: {}, memberList: [], addAllReportees: false });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const formatFileForBE = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    const base64 = await convertBase64(file);
    return { fileName, base64 };
  };

  const handleXLFileUpload = async (event) => {
    const { fileName, base64 } = await formatFileForBE(event);
    setLocalState({ fileType: fileName, excelFile: base64 });
  };

  const handleDeleteFile = (event) => {
    setLocalState({ fileType: "", excelFile: "" });
  };

  const handleChange = (event) => {
    const {
      target: { name, value },
      currentTarget: { nodeName },
    } = event;
    if (nodeName === "svg") {
      setLocalState({ search: "" });
      refreshTable("");
    } else {
      setLocalState({ [camelCase(name)]: value });
    }
  };

  const handleFilterReset = () => {
    setLocalState({ filters: {}, appliedFilters: {} });
    getMemberFilterTableData(INITIAL_PAGE, 1000, {});
  };

  const handleFilterChange = (newValue, item) => {
    setLocalState({ filters: { ...filters, [camelCase(item)]: newValue } });
  };

  const handleFilterSubmit = (newFilters = filters) => {
    // if (!newFilters.projectManager?.find((ele) => ele.emailId === userEmail)) {
    //   setLocalState({ addAllReportees: false });
    // }
    setLocalState({ appliedFilters: newFilters, allSelected: false, selectedMembers: [] });
    if (!isEqual(newFilters, {})) {
      getMemberFilterTableData(INITIAL_PAGE, 1000, newFilters);
    }
  };

  const handleSubmit = () => {
    const body = {
      groupId: expandedGroup,
      fileType: fileType,
      excelFile: excelFile,
      groupName: groupName,
      userIds: selectedMembers,
    };
    setLocalState({ allSelected: false, selectedMembers: [] });
    createGroup(body)
      .unwrap()
      .then(() => {
        refreshTable();
        handleCloseModal();
      })
      .catch(handleError);
  };

  const handleAddMembers = () => {
    const body = {
      groupId: expandedGroup,
      userEmailId,
      userIds: selectedMembers,
    };
    addMembersToGroup(body)
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        refreshTable();
        try {
          if (userEmailId) {
            toggleAddSingleMember();
          } else {
            handleCloseModal();
          }
        } finally {
          setLocalState({ userEmailId: "", allSelected: false, selectedMembers: [] });
        }
      })
      .catch(handleError);
  };

  const handleDelete = () => {
    const body = {
      groupId: expandedGroup,
      userIds: selectedMembers,
    };
    deleteFromGroup(body)
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        refreshTable();
        handleCloseConfDialog();
      })
      .catch(handleError);
  };

  const handleAssignTraining = () => {
    setLocalState({ isAssignDialogOpen: !isAssignDialogOpen });
  };

  const handleOpenConfDialog = (userId = "") => {
    if (userId) {
      setLocalState({ selectedMembers: [userId] });
    }
    setLocalState({ isConfirmationOpen: true });
  };

  const handleCloseConfDialog = () => {
    setLocalState({ isConfirmationOpen: false, allSelected: false, selectedMembers: [] });
  };

  const toggleAddSingleMember = () => {
    setLocalState({ isAddSingleUserDialogOpen: !isAddSingleUserDialogOpen });
  };

  const getGroupsTopBarProps = () => ({
    search,
    refreshTable,
    handleChange,
    handleCreateGroup,
  });

  const getGroupsListProps = () => ({
    columns,
    groupsList,
    expandedGroup,
    allSelected,
    selectedMembers,
    handleExpand,
    handleOpenConfDialog,
    toggleAddSingleMember,
    handleEditGroup,
    handleAssignTraining,
    handleSelectMember,
    handleSelectAll,
  });

  const getGroupsModalProps = () => ({
    filters,
    appliedFilters,
    columns,
    memberList,
    selectedMembers,
    allSelected,
    addAllReportees,
    isModalOpen,
    isUserFetching,
    dialogTitle,
    groupName,
    fileType,
    excelFile,
    filterData,
    handleChange,
    handleXLFileUpload,
    handleDeleteFile,
    handleCloseModal,
    handleSubmit,
    handleAddMembers,
    handleSelectMember,
    handleSelectAll,
    handleSelectAllReportees,
    handleFilterReset,
    handleFilterSubmit,
    handleFilterChange,
  });

  return (
    <>
      <Paper sx={{ p: 2, height: "calc(100svh - 150px)" }}>
        <Box display={"inline-flex"} alignItems={"center"}>
          <IconButton aria-label="back_button" onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h5">{T.GROUPS}</Typography>
        </Box>
        <GroupsTopBar {...getGroupsTopBarProps()} />
        {isGroupsFetching ? <MISLoader /> : <GroupsList {...getGroupsListProps()} />}
      </Paper>

      <GroupsModal {...getGroupsModalProps()} />
      <AssignedCourseDialog
        isDialogOpen={isAssignDialogOpen}
        userId={selectedMembers}
        handleAssignedCourseDialog={handleAssignTraining}
        selectedUserName={groupName}
      />
      <RemoveConfirmation isDialogOpen={isConfirmationOpen} handleClose={handleCloseConfDialog} handleSubmit={handleDelete} />
      <AddSingleUser
        isDialogOpen={isAddSingleUserDialogOpen}
        groupName={groupName}
        userEmailId={userEmailId}
        handleChange={handleChange}
        handleClose={toggleAddSingleMember}
        handleSubmit={handleAddMembers}
      />
    </>
  );
};

export default Groups;

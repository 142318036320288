import { Children } from "react";
import PropTypes from "prop-types";

import { TableRow, TableHead, TableCell, Typography } from "@mui/material";
import memo from "utils/react";
import { get } from "utils/lodash";
import { handleHeaderClass } from "utils/members";
import { BACKGROUND } from "theme/colors";

const TableHeader = ({ columns = [], lockedColumns = [] }) => (
  <TableHead>
    <TableRow sx={{ height: 30 }}>
      {Children.toArray(
        columns.map((column, ind) => {
          const isLocked = get(column, "locked", false);
          const columnName = get(column, "label", "").toUpperCase();
          return (
            <TableCell
              sx={{
                border: "none",
                backgroundColor: BACKGROUND.header,
                padding: "0px 8px 0px 24px",
                position: column.label === "" || column.label === "Personal Info" ? "sticky" : "none",
                left: column.label === "" ? 0 : column.label === "Personal Info" ? 72 : "auto",
                zIndex: column.label === "" ? 3 : column.label === "Personal Info" ? 3 : "auto",
              }}
              className={handleHeaderClass(ind, isLocked, lockedColumns)}
            >
              <Typography variant="body1" fontSize={14} noWrap fontWeight={600}>
                {columnName}
              </Typography>
            </TableCell>
          );
        }),
      )}
    </TableRow>
  </TableHead>
);

TableHeader.propTypes = {
  columns: PropTypes.array,
  lockedColumns: PropTypes.array,
};

export default memo(TableHeader);

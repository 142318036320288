import ToolsLicenceTab from "./ToolsLicence/ToolsLicenceTab";
import { Box, Tab, Paper, styled, Typography, Switch, Tooltip, Grid } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import T from "T";
import MISLoader from "components/common/MISLoader";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { useEffect, useReducer } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { PAGINATION } from "settings/constants/pagination";
import { handleError } from "utils/error";
import TopBar from "./TopBar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { get } from "utils/lodash";
import { isEqual } from "lodash";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { useGetToolsListMutation } from "api/ISTools/getToolsList";
import { useLazyGetProfitCenterListQuery } from "api/ISTools/getProfitCenterList";
import { useLazyGetCostCenterListQuery } from "api/ISTools/getCostCenterList";
import { useGetAssignToolsMutation } from "api/ISTools/ToolsLicence/getAssignTools";
import { useLazyGetLicenseViewQuery } from "api/ISTools/ToolsDashboard/viewLicense";
import { useLazyGetToolsDashboardQuery } from "api/ISTools/ToolsDashboard/viewToolsDashboard";
import { useGetFilterToolsDashboardMutation } from "api/ISTools/ToolsDashboard/filterToolsDashboard";
import { licenseToolsFilterStore } from "slices/licenseToolsFilterSlice";
import Constants from "Constants";
import ToolsDashboardTab from "./ToolsDashboard/ToolsDashboardTab";
import { format, isValid } from "date-fns";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { MISCurrentUser } from "utils/validations";
import { downloadFile } from "utils/file";
import LicenseDetailView from "./ToolsDashboard/LicenseDetailView";
import EmailConfigurator from "./ISToolsDialogs/EmailConfigurator";
import { useLazyGetConfiguratorQuery } from "api/ISTools/ToolsLicence/getConfigurator";
import CostGrid from "./ToolsLicence/CostGrid";
import ContractHistory from "./ISToolsDialogs/ContractHistory";
import { useGetContractHistoryMutation } from "api/ISTools/ToolsLicence/getContractHistory";
import { useDeleteContractMutation } from "api/ISTools/ToolsLicence/deleteContractById";
import { toast } from "react-toastify";
import { formatFileForBE } from "./toolsModel";
import { useUploadContractMutation } from "api/ISTools/ToolsLicence/uploadContract";

const { SERVER_URL } = Constants;
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const FULL_ROWS_PER_PAGE = 10000;

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: "14px",
  alignItems: "center",
  justifyContent: "space-between",
  color: theme.palette.background.black,
  minHeight: "unset",
  border: `1px solid ${theme.palette.border.tabsGrey}`,
}));

const ISTools = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    tabValue: "1",
    showActive: true,
    searchInput: "",
    showOptions: false,
    showCancelIcon: false,
    filters: {
      autoRenewal: null,
      tenure: null,
      costCenter: [],
      purchaseStartDate: null,
      purchaseEndDate: null,
    },
    renewalStartDate: null,
    renewalEndDate: null,
    toolsList: [],
    profitCenterList: [],
    costCenterList: [],
    ToolsLicenseTableData: {
      allTableRows: [],
      totalTableRowsCount: null,
      totalPageCount: null,
      monthlyCost: 0,
      quarterlyCost: 0,
      biAnnuallyCost: 0,
      yearlyCost: 0,
      onetimeCost: 0,
    },
    dashboardData: [],
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    isOpenEmailConfigurator: false,
    licenseId: "",
    contractId: "",
    isOpenContractHistory: false,
    isOpenContractDelete: false,
    exportLoading: false,
    licenseDetail: {
      toolsId: "",
      profitCenterId: "",
      openLicenseDetail: false,
      licenseDetailData: [],
    },
    configuratorData: {},
    contractHistoryData: [],
  });
  const {
    tabValue,
    showActive,
    searchInput,
    showOptions,
    showCancelIcon,
    filters,
    renewalStartDate,
    renewalEndDate,
    toolsList,
    profitCenterList,
    costCenterList,
    ToolsLicenseTableData,
    dashboardData,
    page,
    rowsPerPage,
    isOpenEmailConfigurator,
    licenseId,
    contractId,
    isOpenContractHistory,
    isOpenContractDelete,
    exportLoading,
    licenseDetail,
    configuratorData,
    contractHistoryData,
  } = localState;

  const [getAssignTools, { isFetching: isAssignToolFetching }] = useGetAssignToolsMutation();
  const [getToolsList, { isFetching: isToolsFetching }] = useGetToolsListMutation();
  const [getProfitCenterList, { isFetching: isProfitCenterFetching }] = useLazyGetProfitCenterListQuery();
  const [getCostCenterList, { isFetching: isCostCenterFetching }] = useLazyGetCostCenterListQuery();
  const [getLicenseView, { isFetching: isLicenseViewFetching }] = useLazyGetLicenseViewQuery();
  const [getToolsDashboard] = useLazyGetToolsDashboardQuery();
  const [getFilterToolsDashboard] = useGetFilterToolsDashboardMutation();
  const [getConfigurator] = useLazyGetConfiguratorQuery();
  const [getContractHistory] = useGetContractHistoryMutation();
  const [deleteContract] = useDeleteContractMutation();
  const [uploadContract] = useUploadContractMutation();
  const { sessionToken } = MISCurrentUser();

  const emptyFilters = {
    autoRenewal: null,
    tenure: null,
    costCenter: [],
    purchaseStartDate: null,
    purchaseEndDate: null,
  };
  const defaultLicenseDetail = {
    toolsId: "",
    profitCenterId: "",
    openLicenseDetail: false,
    licenseDetailData: [],
  };
  const { storedFilters } = useSelector(
    (state) => ({
      storedFilters: get(state, "LicenseToolsFilterSlice.storedFilters", {}),
    }),
    shallowEqual,
  );
  const { storedSearch } = useSelector(
    (state) => ({
      storedSearch: get(state, "LicenseToolsFilterSlice.storedSearch", ""),
    }),
    shallowEqual,
  );
  const { storedDate } = useSelector(
    (state) => ({
      storedDate: get(state, "LicenseToolsFilterSlice.storedDate", ""),
    }),
    shallowEqual,
  );
  const { storedStatus } = useSelector(
    (state) => ({
      storedStatus: get(state, "LicenseToolsFilterSlice.storedStatus", false),
    }),
    shallowEqual,
  );

  const isFilterEmpty = isEqual(storedFilters, emptyFilters);
  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  useEffect(() => {
    getToolsListData();
    getProfitCenterListData();
    getCostCenterListData();
  }, []);

  useEffect(() => {
    setLocalState({
      filters: storedFilters,
      searchInput: storedSearch,
      renewalStartDate: storedDate.startDate,
      renewalEndDate: storedDate.endDate,
      showActive: storedStatus,
    });
    refreshTable(page, rowsPerPage, storedSearch, storedFilters, storedDate.startDate, storedDate.endDate, storedStatus);
  }, [page, rowsPerPage, tabValue]);

  useEffect(() => {
    // Check if both renewalStartDate and renewalEndDate are defined and are not null
    // if (renewalStartDate !== null || renewalEndDate !== null) {
    //effect will run when startDate or endDate change
    refreshTable(page, rowsPerPage, storedSearch, storedFilters, renewalStartDate, renewalEndDate, storedStatus);
    dispatch(
      licenseToolsFilterStore({
        storedSearch: storedSearch,
        storedFilters: storedFilters,
        storedDate: { startDate: renewalStartDate, endDate: renewalEndDate },
        storedStatus: storedStatus,
      }),
    );
    // }
  }, [renewalStartDate, renewalEndDate, page, rowsPerPage]);

  const refreshTable = (page, rowsPerPage, search, filters, start, end, status) => {
    if (tabValue === "1") {
      getAssignToolsListTableData(page, rowsPerPage, search, filters, start, end, status);
    }
    if (tabValue === "2") {
      if (search === "" && !search) {
        getToolsDashboardData();
      } else {
        getFilterToolsDashboardData(search);
      }
    }
  };

  const getAssignToolsListTableData = (page, rowsPerPage, search, filters, start, end, status) => {
    const costCenterLength = get(filters, "costCenter", []).length;
    const toolsUserFilterRequestDto = {
      costCenterList: costCenterLength > 0 ? get(filters, "costCenter", []).map((item) => get(item, "costCenterId", "")) : [],
      // costCenterName: get(filters, "costCenter.costCenterName", ""),
      // autoRenewal: get(filters, "autoRenewal.value", null),   // Can do if we change value from bool to string
      autoRenewal: filters.autoRenewal ? filters.autoRenewal.value : null,
      tenure: get(filters, "tenure.tenureName", ""),
      text: search,
      renewalStartDate: start,
      renewalEndDate: end,
      purchaseStartDate: get(filters, "purchaseStartDate", null),
      purchaseEndDate: get(filters, "purchaseEndDate", null),
      isActive: status,
    };
    getAssignTools({ page, rowsPerPage, toolsUserFilterRequestDto })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({
          ToolsLicenseTableData: {
            allTableRows,
            totalTableRowsCount,
            totalPageCount,
            monthlyCost: get(res, "monthlyCost", 0),
            quarterlyCost: get(res, "quarterlyCost", 0),
            biAnnuallyCost: get(res, "biAnnuallyCost", 0),
            yearlyCost: get(res, "yearlyCost", 0),
            oneTimeCost: get(res, "oneTimeCost", 0),
          },
        });
      })
      .catch(handleError);
  };

  const getToolsDashboardData = () => {
    getToolsDashboard()
      .unwrap()
      .then((res) => {
        setLocalState({ dashboardData: res.results.toolsDashboardViews });
      })
      .catch(handleError);
  };

  const getFilterToolsDashboardData = (search) => {
    getFilterToolsDashboard({ search })
      .unwrap()
      .then((res) => {
        setLocalState({ dashboardData: res.results.toolsDashboardViews });
      })
      .catch(handleError);
  };

  const getListData = (getListFunction, listType) => {
    getListFunction({ page: INITIAL_PAGE, rowsPerPage: FULL_ROWS_PER_PAGE })
      .unwrap()
      .then((res) => {
        const { allTableRows } = getCurrentTableParams(res);
        setLocalState({ [`${listType}List`]: allTableRows });
      })
      .catch(handleError);
  };

  const getToolsListData = () => getListData(getToolsList, "tools");
  const getProfitCenterListData = () => getListData(getProfitCenterList, "profitCenter");
  const getCostCenterListData = () => getListData(getCostCenterList, "costCenter");

  const getConfiguratorData = () => {
    getConfigurator()
      .unwrap()
      .then((res) => {
        setLocalState({ configuratorData: res });
      })
      .catch(handleError);
  };
  const getContractHistoryData = (id) => {
    getContractHistory(id)
      .unwrap()
      .then((res) => {
        setLocalState({ contractHistoryData: res });
      })
      .catch(handleError);
  };
  const handleTabChange = (event, newValue) => {
    setLocalState({
      tabValue: newValue,
      page: INITIAL_PAGE,
      rowsPerPage: ROWS_PER_PAGE,
      searchInput: "",
    });
    dispatch(
      licenseToolsFilterStore({
        storedSearch: "",
        storedFilters: storedFilters,
        storedDate: storedDate,
        storedStatus: storedStatus,
      }),
    );
  };

  const handleOpenEmailConfiguration = () => {
    getConfiguratorData();
    setLocalState({ isOpenEmailConfigurator: true });
  };

  const handleCloseEmailConfiguration = () => {
    setLocalState({ isOpenEmailConfigurator: false, configuratorData: {} });
  };
  //#####################Contract Functions#################
  const handleOpenContractHistory = (id) => {
    getContractHistoryData(id);
    setLocalState({ isOpenContractHistory: true, licenseId: id });
  };

  const handleCloseContractHistory = () => {
    setLocalState({ isOpenContractHistory: false, contractHistoryData: [], licenseId: "" });
  };

  const handleOpenConfirmDelete = (id) => {
    setLocalState({ isOpenContractHistory: false, isOpenContractDelete: true, contractId: id });
  };

  const handleCloseConfirmDelete = () => {
    setLocalState({ isOpenContractHistory: true, isOpenContractDelete: false, contractId: "" });
    getContractHistoryData(licenseId);
  };

  const handleContractDelete = () => {
    deleteContract({ id: contractId, licenseId: licenseId })
      .unwrap()
      .then(() => {
        toast.success(T.CONTRACT_DELETED_SUCESSFULLY);
        handleCloseConfirmDelete();
      })
      .catch(handleError);
  };

  const handleUploadContract = async (event) => {
    const { fileName, base64 } = await formatFileForBE(event);
    const parts = fileName.split(".");
    const extension = parts[parts.length - 1];
    const allowedExtensions = ["DOC", "doc", "DOCX", "docx", "PDF", "pdf"];
    if (!allowedExtensions.includes(extension)) {
      toast.error("Please upload a DOC, DOCX or PDF file.");
      event.target.value = null;
      return;
    }
    const payload = { contractFile: base64, contractFileName: fileName };
    uploadContract({payload, licenseId})
      .unwrap()
      .then(() => {
        toast.success(T.CONTRACT_UPLOAD_SUCESSFULLY);
        getContractHistoryData(licenseId);
      })
      .catch(handleError);
  };

  //=================================================================
  const handleToolsLicenceAdd = () => {
    navigate("add-license");
  };

  const handleStatusSwitch = () => {
    dispatch(
      licenseToolsFilterStore({
        storedSearch: "",
        storedFilters: emptyFilters,
        storedDate: { startDate: null, endDate: null },
        storedStatus: !showActive,
      }),
    );
    setLocalState({
      showActive: !showActive,
      page: INITIAL_PAGE,
      rowsPerPage: ROWS_PER_PAGE,
      renewalStartDate: null,
      renewalEndDate: null,
      filters: emptyFilters,
      searchInput: "",
    });
    refreshTable(page, rowsPerPage, "", emptyFilters, null, null, !showActive);
  };
  // ########################## Handeling License Detail ##########################
  const handleLicenseDetail = (toolsId, profitCenterId) => {
    if (!licenseDetail.openLicenseDetail) {
      getLicenseView({ toolsId, profitCenterId })
        .unwrap()
        .then((res) => {
          setLocalState({
            licenseDetail: {
              toolsId,
              profitCenterId,
              openLicenseDetail: true,
              licenseDetailData: res,
            },
          });
        })
        .catch(handleError);
    } else {
      setLocalState({ licenseDetail: defaultLicenseDetail });
    }
  };

  const handleCloseLicense = () => {
    setLocalState({ licenseDetail: defaultLicenseDetail });
  };
  // ############################ Export File Things ##############################
  const handleExport = async (exportFilter, type) => {
    setLocalState({ exportLoading: true });
    const requestDto = { ...exportFilter };
    if (type === "licence") {
      fetch(`${SERVER_URL}/tools/license/export`, {
        method: "POST",
        body: JSON.stringify(requestDto),
        headers: {
          Authorization: `Bearer ${sessionToken}`,
          "content-type": "application/json",
        },
      })
        .then((res) => res.blob())
        .then((response) => {
          downloadFile(response, T.CSV);
          setLocalState({ exportLoading: false });
        })
        .catch(handleError);
    } else {
      fetch(`${SERVER_URL}/toolsDashboard/export`, {
        method: "POST",
        body: JSON.stringify(requestDto),
        headers: {
          Authorization: `Bearer ${sessionToken}`,
          "content-type": "application/json",
        },
      })
        .then((res) => res.blob())
        .then((response) => {
          downloadFile(response, T.CSV);
          setLocalState({ exportLoading: false });
        })
        .catch(handleError);
    }
  };

  // ############################ Pagination Things ##############################
  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  // ############################ Date Filter Things ###################################
  const onHandleDateChange = (newValue, type) => {
    const validDate = newValue ? new Date(newValue) : null;
    setLocalState({
      [type]: validDate && isValid(validDate) ? getBEDateFormat(validDate) : null,
    });
    // refreshTable(page, rowsPerPage, storedSearch, storedFilters, renewalStartDate, renewalEndDate);    //calls whenever any one date change
  };

  // ############################ Search Things ###################################
  const handleSearchChange = (event) => {
    const { value, dataset } = event.currentTarget;
    const searchValue = value || get(dataset, "val", "");
    setLocalState({
      showOptions: event.key !== "Enter",
      showCancelIcon: searchValue !== "",
      searchInput: searchValue,
    });
    if (event.key === "Enter" || event.currentTarget.nodeName === "svg" || searchValue === "") {
      dispatch(
        licenseToolsFilterStore({
          storedSearch: "",
          storedFilters: storedFilters,
          storedDate: storedDate,
          storedStatus: storedStatus,
        }),
      );
      refreshTable(page, rowsPerPage, searchValue, storedFilters, storedDate.startDate, storedDate.endDate, storedStatus);
    }
  };

  // This is not in use
  const handleSearchClick = (value) => {
    setLocalState({
      showOptions: false,
      showCancelIcon: false,
      searchInput: value,
    });
  };

  const handleSearchKeyChange = () => {
    setLocalState({
      page: INITIAL_PAGE,
    });
    dispatch(
      licenseToolsFilterStore({
        storedSearch: searchInput,
        storedFilters: storedFilters,
        storedDate: storedDate,
        storedStatus: storedStatus,
      }),
    );
    refreshTable(INITIAL_PAGE, rowsPerPage, searchInput, filters, storedDate.startDate, storedDate.endDate, storedStatus);
  };

  // This is not in use
  const handleClickOutside = () => {
    setLocalState({
      showOptions: false,
    });
  };

  // ############################ Filter Things ###################################

  const onhandleFilterChange = (newValue, item) => {
    setLocalState({ filters: { ...filters, [item]: newValue } });
  };

  const onHandleDateFilterChange = (newValue, type) => {
    const validDate = newValue ? new Date(newValue) : null;
    setLocalState({ filters: { ...filters, [type]: validDate && isValid(validDate) ? getBEDateFormat(validDate) : null } });
  };

  const handleFilterSubmit = () => {
    dispatch(
      licenseToolsFilterStore({
        storedSearch: storedSearch,
        storedFilters: filters,
        storedDate: storedDate,
        storedStatus: storedStatus,
      }),
    );
    refreshTable(page, rowsPerPage, storedSearch, filters, storedDate.startDate, storedDate.endDate, storedStatus);
  };

  const handleFilterClose = () => {
    dispatch(
      licenseToolsFilterStore({
        storedSearch: storedSearch,
        storedFilters: emptyFilters,
        storedDate: { startDate: null, endDate: null },
        storedStatus: storedStatus,
      }),
    );
    setLocalState({ filters: emptyFilters, renewalStartDate: null, renewalEndDate: null });
    refreshTable(page, rowsPerPage, storedSearch, emptyFilters, null, null, storedStatus);
  };
  const refreshOnStatusChange = () => {
    // setLocalState({ showActive: true });
    refreshTable(page, rowsPerPage, storedSearch, storedFilters, storedDate.startDate, storedDate.endDate, storedStatus);
  };

  return (
    <Paper display={"block"} sx={{ borderRadius: 2 }}>
      {(isAssignToolFetching ||
        isToolsFetching ||
        isProfitCenterFetching ||
        isCostCenterFetching ||
        isLicenseViewFetching ||
        exportLoading) && <MISLoader />}
      <TabContext value={tabValue}>
        <TabList
          variant="fullWidth"
          indicatorColor="false"
          onChange={handleTabChange}
          aria-label="lab API tabs example"
          sx={{
            "& .MuiTab-root.Mui-selected": {
              color: BACKGROUND.white,
              backgroundColor: NETSMARTZ_THEME_COLOR,
            },
            "& .MuiTab-root": {
              fontWeight: 600,
            },
          }}
        >
          <StyledTab sx={{ borderTopLeftRadius: "10px" }} label={T.TOOLS_LICENCE.toUpperCase()} value="1" />
          <StyledTab sx={{ borderTopRightRadius: "10px" }} label={T.TOOLS_DASHBOARD.toUpperCase()} value="2" />
        </TabList>
        <Box
          sx={{
            "& .MuiTabPanel-root": {
              p: 2,
              pt: 0,
            },
          }}
        >
          <TopBar
            tabValue={tabValue}
            filters={filters}
            isFilterEmpty={isFilterEmpty}
            searchInput={searchInput}
            showOptions={showOptions}
            showCancelIcon={showCancelIcon}
            renewalStartDate={renewalStartDate}
            renewalEndDate={renewalEndDate}
            toolsList={toolsList}
            costCenterList={costCenterList}
            handleChange={handleSearchChange}
            onClick={handleSearchClick}
            handleKeyChange={handleSearchKeyChange}
            onClickOutside={handleClickOutside}
            handleFilterSubmit={handleFilterSubmit}
            onHandleDateFilterChange={onHandleDateFilterChange}
            onhandleFilterChange={onhandleFilterChange}
            handleFilterClose={handleFilterClose}
            onHandleDateChange={onHandleDateChange}
            handleExport={handleExport}
            handleOpenEmailConfiguration={handleOpenEmailConfiguration}
            handleToolsLicenceAdd={handleToolsLicenceAdd}
          />
          <TabPanel value="1">
            <Grid container justifyContent={"space-between"} alignItems={"center"} pt={2} pb={1}>
              <Grid item xs={12} md={"auto"}>
                <Typography variant="h5">
                  {showActive ? `${T.ACTIVE} ${T.LICENSE}` : `${T.INACTIVE} ${T.LICENSE}`}
                  <Tooltip
                    placement="top"
                    title={showActive ? T.TOGGLE_TO_SHOW_INACTIVE_LICENSE : T.TOGGLE_TO_SHOW_ACTIVE_LICENSE}
                  >
                    <Switch name="show_active" color="warning" checked={showActive} onChange={handleStatusSwitch} />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={12} md={"auto"}>
                <CostGrid
                  record={{
                    monthlyCost: ToolsLicenseTableData.monthlyCost,
                    quarterlyCost: ToolsLicenseTableData.quarterlyCost,
                    biAnnuallyCost: ToolsLicenseTableData.biAnnuallyCost,
                    yearlyCost: ToolsLicenseTableData.yearlyCost,
                    oneTimeCost: ToolsLicenseTableData.oneTimeCost,
                  }}
                />
              </Grid>
            </Grid>

            <ToolsLicenceTab
              allTableRows={ToolsLicenseTableData.allTableRows}
              totalTableRowsCount={ToolsLicenseTableData.totalTableRowsCount}
              totalPageCount={ToolsLicenseTableData.totalPageCount}
              page={page}
              rowsPerPage={rowsPerPage}
              handleOpenContractHistory={handleOpenContractHistory}
              refreshOnStatusChange={refreshOnStatusChange}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </TabPanel>
          <TabPanel value="2">
            <ToolsDashboardTab
              dashboardData={dashboardData}
              toolsList={toolsList}
              profitCenterList={profitCenterList}
              handleLicenseDetail={handleLicenseDetail}
            />
          </TabPanel>
        </Box>
      </TabContext>
      <LicenseDetailView licenseDetail={licenseDetail} handleCloseLicense={handleCloseLicense} />
      <EmailConfigurator
        isOpenEmailConfigurator={isOpenEmailConfigurator}
        configuratorData={configuratorData}
        handleClose={handleCloseEmailConfiguration}
        refreshOnStatusChange={refreshOnStatusChange}
      />
      <ContractHistory
        isOpenContractHistory={isOpenContractHistory}
        isOpenContractDelete={isOpenContractDelete}
        contractHistoryData={contractHistoryData}
        licenseData={get(ToolsLicenseTableData, "allTableRows", []).find((item) => item.toolsUserMappingId === licenseId)}
        handleUploadContract={handleUploadContract}
        handleClose={handleCloseContractHistory}
        handleOpenConfirmDelete={handleOpenConfirmDelete}
        handleCloseConfirmDelete={handleCloseConfirmDelete}
        handleContractDelete={handleContractDelete}
      />
    </Paper>
  );
};

export default ISTools;

import { ArrowBack } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import T from "T";
import MISButton from "components/common/MISButton";
import PropTypes from "prop-types";
import { GET_SIZE } from "utils/responsive";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { noop } from "lodash";
import { get } from "utils/lodash";

const CostCenterTable = ({
  columns = [],
  costCenterList = [],
  isOpenCostCenterDialog = bool,
  openAddEditCostCenterMutation = noop,
  handleClose = noop,
  handleDeleteCostCenter = noop,
}) => {
  const { isMd, isLg } = GET_SIZE();
  return (
    <Dialog onClose={handleClose} open={isOpenCostCenterDialog} maxWidth={isLg ? "lg" : isMd ? "md" : "sm"}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            <IconButton onClick={handleClose}>
              <ArrowBack />
            </IconButton>
            {T.ADD_EDIT_COST_CENTER}
          </Box>
          <MISButton onClick={() => openAddEditCostCenterMutation()} startIcon={<AddIcon sx={{ width: 19 }} />}>
            {T.NEW}
          </MISButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: "inherit" }}>
        <TableContainer component={Paper} sx={{ maxHeight: "calc(100svh - 350px)", overflow: "auto" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                {columns &&
                  columns.length &&
                  columns.map((column) => {
                    return <TableCell key={column.key}>{column.label}</TableCell>;
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {costCenterList.map((costCenter, index) => {
                return (
                  <TableRow key={costCenter.costCenterId} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    {columns.map((column) => (
                      <TableCell key={`${costCenter.costCenterId}-${column.key}`}>
                        {column.key === "actions" ? (
                          <>
                            <IconButton
                              sx={{ color: NETSMARTZ_THEME_COLOR }}
                              onClick={() => {
                                openAddEditCostCenterMutation(costCenter.costCenterId);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              sx={{ color: NETSMARTZ_THEME_COLOR }}
                              onClick={() => {
                                handleDeleteCostCenter(costCenter.costCenterId);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        ) : column.key === "id" ? (
                          index + 1
                        ) : column.key === "createdBy" ? (
                          get(costCenter[column.key], "userName", "")
                        ) : (
                          costCenter[column.key]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

CostCenterTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  costCenterList: PropTypes.arrayOf(PropTypes.object),
  isOpenCostCenterDialog: PropTypes.bool,
  openAddEditCostCenterMutation: PropTypes.func,
  handleClose: PropTypes.func,
  handleDeleteCostCenter: PropTypes.func,
};

export default CostCenterTable;

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCodeAssessmentDetails: builder.query({
      query: ({ pitchedUserId }) => ({
        url: `/pitchedUser/codeAssessment/codeAssessment/submissionDetails`,
        method: "GET",
        params: { pitchedUserId },
      }),
    }),
  }),
});

export const { useLazyGetCodeAssessmentDetailsQuery } = extendedApi;

import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAssignTools: builder.mutation({
      query: ({ page, rowsPerPage, toolsUserFilterRequestDto }) => ({
        url: `/tools/license/filter?page=${page}&size=${rowsPerPage}`,
        method: "POST",
        body: toolsUserFilterRequestDto,
      }),
    }),
  }),
});

export const { useGetAssignToolsMutation } = extendedApi;

import { Children, useState } from "react";
import PropTypes from "prop-types";
import { noop, isEmpty, camelCase } from "lodash";
import { Typography, Divider, Card, Button, Box, Checkbox, FormControl, FormControlLabel } from "@mui/material";
import MISAutocomplete from "components/common/MISAutocomplete";
import { ClickAwayListener } from "@mui/material";
import { NETSMARTZ_THEME_COLOR, BACKGROUND } from "theme/colors";
import { GET_SIZE } from "utils/responsive";
import T from "T";
// import { TRAINING_FILTER_OPTIONS } from "settings/constants/filter";
import { IS_TOOLS_FILTER_OPTIONS } from "settings/constants/filter";
import { get } from "utils/lodash";
// import { OFFLINE_TRAINING_STATUS_LIST, TRAINING_STATUS_LIST } from "../trainingModal";
import { YES_NO_VALUES, tenureList } from "../toolsModel";
import MISDatePicker from "components/common/MISDatePicker";
import MISNestedAutocomplete from "components/common/MISNestedAutocomplete";
const ISToolsFilterOptions = ({
  filters = {},
  toolsList = [],
  costCenterList = [],
  handleFilterClose = noop,
  handleFilterSubmit = noop,
  onhandleFilterChange = noop,
  onHandleDateFilterChange = noop,
  handleClose = noop,
}) => {
  const { isXs } = GET_SIZE();
  const getFilterRecords = (val) => {
    switch (val) {
      case T.COST_CENTER:
        return costCenterList;
      case T.AUTO_RENEWAL:
        return YES_NO_VALUES;
      case T.TENURE:
        return tenureList;
      default:
    }
  };

  const formatData = (list) => {
    const neastedList = [];
    for (let item of list) {
      if (!item.costCenterName.includes("-")) {
        const newItem = { ...item, children: [] };
        neastedList.push(newItem);
        const ind = neastedList.length - 1;
        let prefix = item.costCenterName + "-";
        if (prefix === "CareSmartz-") prefix = "CS-";
        for (let item2 of list) {
          if (item2.costCenterName.includes(prefix) && item2.costCenterName !== prefix) neastedList[ind].children.push(item2);
        }
      }
    }
    return neastedList;
  };
  const getLabelData = (option, index) => {
    const optionVal = isEmpty(option) ? "" : option;

    switch (index) {
      case 0:
        return get(option, "costCenterName", "");
      case 1:
        return get(option, "label", "");
      case 2:
        return get(option, "tenureName", "");
      default:
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Card
        sx={{
          mt: 1,
          position: "absolute",
          width: isXs ? "80%" : "30%",
          zIndex: 400,
        }}
      >
        <Box
          sx={{
            maxHeight: "calc(100vh - 250px)",
            overflowY: "auto",
          }}
        >
          <Typography variant="subtitle2" p={1} textAlign="center">
            {T.FILTERS}
          </Typography>
          <Divider />
          {Children.toArray(
            IS_TOOLS_FILTER_OPTIONS.map((item, index) => {
              const lists = getFilterRecords(item);
              const key = camelCase(item);
              return (
                <Box display="flex" p="10px 20px" justifyContent="space-between" pb={index >= 7 ? "15px" : "0px"}>
                  <Typography variant="subtitle1" noWrap>
                    {item}
                  </Typography>
                  {![T.PURCHASE_START_DATE, T.PURCHASE_END_DATE].includes(item) ? (
                    [T.COST_CENTER].includes(item) ? (
                      <MISNestedAutocomplete
                        listDetails={formatData(lists)}
                        multiple={true}
                        size="small"
                        placeholder={T.SELECT}
                        sx={{
                          width: 240,
                        }}
                        value={filters[key]}
                        getByLabelText={(option) => getLabelData(option, index)}
                        onHandleChange={(event, newValue) => {
                          onhandleFilterChange(newValue, key);
                        }}
                        limitTags={3}
                      />
                    ) : (
                      <MISAutocomplete
                        listDetails={lists}
                        size="small"
                        placeholder={T.SELECT}
                        sx={{
                          width: 240,
                        }}
                        value={filters[key]}
                        getByLabelText={(option) => getLabelData(option, index)}
                        onHandleChange={(event, newValue) => {
                          onhandleFilterChange(newValue, key);
                        }}
                      />
                    )
                  ) : (
                    <MISDatePicker
                      width="240px"
                      inputFormat="DD/MM/YYYY"
                      name={key}
                      value={filters[key]}
                      handleChange={onHandleDateFilterChange}
                    />
                  )}
                </Box>
              );
            }),
          )}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderColor: BACKGROUND.black,
              color: BACKGROUND.black,

              "&:hover": {
                borderColor: NETSMARTZ_THEME_COLOR,
                color: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={() => handleFilterClose()}
          >
            {T.RESET}
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterSubmit}
          >
            {T.APPLY}
          </Button>
        </Box>
      </Card>
    </ClickAwayListener>
  );
};

ISToolsFilterOptions.propTypes = {
  filters: PropTypes.object,
  projectManagerList: PropTypes.array,
  departmentList: PropTypes.array,
  handleFilterClose: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  onhandleFilterChange: PropTypes.func,
  handleClose: PropTypes.func,
  setTrainingState: PropTypes.func,
};

export default ISToolsFilterOptions;

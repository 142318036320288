import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography } from "@mui/material";
import memo from "utils/react";
import { BACKGROUND } from "theme/colors";

const TableHeader = ({ columns }) => (
  <TableHead>
    <TableRow sx={{ height: 30 }}>
      {Children.toArray(
        columns.map((column) => (
          <TableCell
            sx={{
              border: "none",
              backgroundColor: BACKGROUND.header,
              padding: "0px 8px 0px 24px",
              textAlign: column.label === "Actions" ? "center" : "none",
            }}
          >
            <Typography fontSize={14} noWrap fontWeight={600} variant="body1" textTransform={"uppercase"}>
              {column.label}
            </Typography>
          </TableCell>
        )),
      )}
    </TableRow>
  </TableHead>
);

TableHeader.propTypes = {
  columns: PropTypes.array,
};

export default memo(TableHeader);

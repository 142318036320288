import { ArrowBack } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import T from "T";
import PrimaryGrid from "./PrimaryGrid";
import SecondaryGrid from "./SecondaryGrid";
import Table from "./Table";
import { useLazyGetReferralStatsQuery } from "api/Referrals/stats";
import { handleError } from "utils/error";
import { get } from "lodash";
import MISLoader from "components/common/MISLoader";

const RefferalReportCard = () => {
  const navigate = useNavigate();
  const [getStats, { isLoading }] = useLazyGetReferralStatsQuery();
  const [record, setRecord] = useState();
  useEffect(() => {
    getStats()
      .unwrap()
      .then((res) => setRecord(res))
      .catch(handleError);
  }, []);

  return (
    <Paper display="block" sx={{ borderRadius: 2, p: 2, height: "80vh", overflowY: "scroll" }}>
      {isLoading && <MISLoader />}
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box display="flex" alignItems="center">
            <IconButton
              sx={{ mr: 1 }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack fontSize="small" />
            </IconButton>
            <Typography variant="h5" fontWeight={600} width={400}>
              {T.RECOMMENDATION_REPORT}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box px={1.4} py={1}>
        <PrimaryGrid record={record} />
        {/* <Divider sx={{ mt: 0.8, mb: 2 }} />
        <SecondaryGrid record={record} /> */}
        <Divider sx={{ mt: 0.8, mb: 2 }} />
        <Table allTableRows={get(record, "taReportCardGridDtos", [])} />
      </Box>
    </Paper>
  );
};

export default RefferalReportCard;

import { Typography, Box, styled, Badge, Link, Tooltip, IconButton } from "@mui/material";
import { COL_KEYS } from "settings/constants/members";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FRONTEND_DATE_FORMAT } from "settings/constants/date";
import { intervalToDuration, format } from "date-fns";
import PDF from "assets/PDF.png";
import DOC from "assets/DOC.png";
import { get } from "utils/lodash";
import T from "T";
import { toast } from "react-toastify";
import { Stack } from "@mui/system";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";

export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 25,
  height: 25,
  cursor: "pointer",
}));

export const MainTitleTypography = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 14,
  lineHeight: "22px",
  whiteSpace: "nowrap",
}));

export const SubTitleTypography = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "22px",
  whiteSpace: "nowrap",
}));
export const TitleTypography = styled(Typography)(({ rest }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "22px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: 200,
  paddingRight: rest?.paddingRight,
}));

const copyContent = (val, name) => {
  navigator.clipboard.writeText(val);

  toast.success(`${name} ${T.COPIED}`, {
    position: toast.POSITION.BOTTOM_LEFT,
  });
};

export const StyledContentCopyIcon = (val, name) => (
  <IconButton aria-label="copy" onClick={() => copyContent(val, name)} sx={{ ml: 1, p: 0 }}>
    <ContentCopyIcon fontSize="small" />
  </IconButton>
);

export const handleHeaderClass = (ind, isLocked, lockedColumns) => {
  if (lockedColumns.length === ind + 1) {
    return "sticky-col add-shadow";
  }

  if (isLocked) {
    return "sticky-col";
  }

  return "";
};

const getEmpStatus = (status) => {
  switch (status) {
    case T.STABLE:
      return "success";
    case T.DID_NOT_JOIN:
      return "secondary";
    case T.RESIGNED:
      return "warning";
    case T.ABSCONDED:
      return "error";
    case T.YET_TO_JOIN:
      return "primary";
    default:
      return "primary";
  }
};

export const getFEDateFormat = (val) => {
  if (val) {
    return format(new Date(val.split(" ")[0]), FRONTEND_DATE_FORMAT);
  }
  return "-";
};

export const getMemberColumnData = (record, isXs, column, index, handleExport, page, rowsPerPage) => {
  const joiningDate = get(record, "joiningDateTime", null);

  const empStatus = get(record, "empStatus", "");

  switch (column) {
    case COL_KEYS.serialnumber: {
      const serialNumber = index + 1 + page * rowsPerPage;
      return {
        columnData: (
          <TitleTypography sx={{ width: "40px", textAlign: "center", position: "sticky", left: 70, zIndex: 1 }}>
            {serialNumber}.
          </TitleTypography>
        ),
      };
    }
    case COL_KEYS.info: {
      const fullName = get(record, "userName", "");
      const empCode = get(record, "employeeCode", "");
      const department = get(record, "userDepartment.departmentName", "");
      const designation = get(record, "userDesignation", "");
      const linkedInUrl = get(record, "linkedInUrl", "");
      const profileLinkPdf = get(record, "profileLinkPdf", "");
      const profileLinkWord = get(record, "profileLinkWord", "");
      const profileDocFile = get(record, "profileDocFile", "");
      const isBytLead = get(record, "isBytLead", false);
      const email = get(record, "userEmailId", "");

      return {
        columnData: (
          <>
            <MainTitleTypography sx={{ display: "inline-flex", alignItems: "center", color: NETSMARTZ_THEME_COLOR }}>
              {fullName}
              <Tooltip title={empStatus} placement="top">
                <Badge variant="dot" color={getEmpStatus(empStatus)} sx={{ ml: 1, cursor: "pointer" }} />
              </Tooltip>
              {isBytLead && <StarOutlinedIcon fontSize="small" sx={{ ml: 0.5, color: NETSMARTZ_THEME_COLOR }} />}
            </MainTitleTypography>

            {empCode && (
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.EMP_CODE}:</SubTitleTypography>
                <TitleTypography ml={1}>{empCode}</TitleTypography>
                {StyledContentCopyIcon(empCode, T.EMP_CODE)}
              </Box>
            )}

            {department && (
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.DEPARTMENT}:</SubTitleTypography>
                <TitleTypography ml={1}>{department}</TitleTypography>
              </Box>
            )}
            {designation && (
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.DESIGNATION}:</SubTitleTypography>
                <Tooltip title={designation} placement="top">
                  <TitleTypography ml={1}>{designation}</TitleTypography>
                </Tooltip>
              </Box>
            )}
            {linkedInUrl && (
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.LINKED_IN_URL}:</SubTitleTypography>
                <Tooltip title={linkedInUrl} placement="top">
                  <TitleTypography ml={1}>{linkedInUrl}</TitleTypography>
                </Tooltip>
                {linkedInUrl && StyledContentCopyIcon(linkedInUrl, T.LINKED_IN_URL)}
              </Box>
            )}

            {(profileDocFile || profileLinkWord || profileLinkPdf) && (
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.PROFILE_LINK}:</SubTitleTypography>
                <Stack sx={{ display: "inlineFlex", ml: "5px" }}>
                  {profileDocFile && (
                    <Tooltip title={T.DOC} placement="top">
                      <StyledBox
                        component="img"
                        src={DOC}
                        onClick={() => {
                          handleExport(email);
                        }}
                        px={1}
                      />
                    </Tooltip>
                  )}
                  {profileLinkPdf && (
                    <Tooltip title={T.PDF} placement="top">
                      <Link aria-label="pdf_download" href={profileLinkPdf} target="_blank" sx={{ display: "block" }}>
                        <StyledBox component="img" src={PDF} px={1} />
                      </Link>
                    </Tooltip>
                  )}
                  {profileLinkWord && !profileDocFile && (
                    <Tooltip title={T.DOC} placement="top">
                      <Link aria-label="doc_download" href={profileLinkWord} target="_blank" sx={{ display: "block" }}>
                        <StyledBox component="img" src={DOC} px={1} />
                      </Link>
                    </Tooltip>
                  )}
                </Stack>
              </Box>
            )}
          </>
        ),
      };
    }

    case COL_KEYS.experience: {
      const totalDuration = joiningDate
        ? intervalToDuration({
            start: new Date(joiningDate.split(" ")[0]),
            end: new Date(),
          })
        : "";

      return {
        columnData: (
          <>
            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{T.TOTAL_EXP_AS_ON_DATE}:</SubTitleTypography>
              <TitleTypography ml={1}>
                {`${get(record, "expInYears", 0)}.${get(record, "expInMonths", 0)} ${T.YEARS.toLowerCase()}`}
              </TitleTypography>
            </Box>

            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{T.TENURE_WITH_NETSMARTZ}:</SubTitleTypography>
              <TitleTypography ml={1}>{`${get(totalDuration, "years", "")}.${get(
                totalDuration,
                "months",
                "",
              )} years`}</TitleTypography>
            </Box>
          </>
        ),
      };
    }
    case COL_KEYS.technology: {
      const skills = get(record, "userSkills", []);
      const primarySkills = skills
        .filter((skill) => get(skill, "primary", false))
        .map((skill) => get(skill, "skills.skillName", "-"))
        .join(", ");
      const secondarySkills = skills
        .filter((skill) => get(skill, "secondary", false))
        .map((skill) => get(skill, "skills.skillName", "-"))
        .join(", ");
      return {
        columnData: (
          <>
            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{`${T.PRIMARY_SKILL}s`}:</SubTitleTypography>
              <Tooltip title={primarySkills} placement="top">
                <TitleTypography ml={1}>{primarySkills}</TitleTypography>
              </Tooltip>
            </Box>
            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{T.SECONDARY_SKILLS}:</SubTitleTypography>
              <Tooltip title={secondarySkills} placement="top">
                <TitleTypography ml={1}>{secondarySkills}</TitleTypography>
              </Tooltip>
            </Box>
          </>
        ),
      };
    }
    case COL_KEYS.reportingManager: {
      const reportingManager = get(record, "reportingManager.name", "-");

      return {
        columnData: <TitleTypography>{reportingManager}</TitleTypography>,
      };
    }

    default:
      return { columnData: <TitleTypography /> };
  }
};

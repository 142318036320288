import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Paper, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import Form from "./Form";

const EditMember = () => {
  const [status, setStatus] = useState();
  const [fullName, setFullname] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const url = location.pathname;
  const id = Number(url.replace(/\D+/g, ""));
  const { path } = location.state || {} || {};
  const getStatus = (val) => {
    setStatus(val);
  };
  const getFullName = (name) => {
    setFullname(name);
  };

  const handleBack = () => {
    if (/\d+/.test(path)) {
      navigate(`/app/members/${id}`);
    } else {
      navigate("/app/members");
    }
  };
  return (
    <Paper sx={{ p: 2, maxHeight: "calc(100vh - 148px)" }}>
      <Box display="flex" alignItems="center" mb={1}>
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack} />
        <Typography variant="h6" fontWeight={700} ml={1}>
          {`${T.EDIT} ${fullName} ${T.DETAILS} `}
        </Typography>
      </Box>
      <Form getStatus={getStatus} getFullName={getFullName} />
    </Paper>
  );
};

export default EditMember;

export const referralQuestions = [
  "Why Should Netsmartz Hire Him/her?",
  "Any valuable achievement in company/project?",
  "How she/he can stand out amongst others?",
  "What is the candidate's communication and collaboration style?",
  "How does the candidate handle challenges or conflicts?",
];

export const referralAnswers = [
  [
    "Strong Work Ethic",
    "Exceptional Problem-Solving Skills",
    "Leadership Potential",
    "Client Relationship Management",
    "Innovative Thinker",
  ],
  [
    "Client Acquisition or Retention",
    "Increased Revenue or Cost Savings",
    "Leadership in Cross-Functional Teams",
    "Training and Development Initiatives",
    "Process Improvements",
  ],
  [
    "Innovative Thinking",
    "Adaptability and Resilience",
    "Customer-Centric Approach",
    "Continuous Learner and Growth Mindset",
    "Consistent Track Record of Success",
  ],
  [
    "Clear and Concise Communicator",
    "Conflict Resolution Skills",
    "Respectful and Empathetic",
    "Effective in Virtual Settings",
    "Promotes Knowledge Sharing",
  ],
  [
    "Conflict Prevention and Mitigation",
    "Adaptable and Flexible",
    "Effective Communication Under Pressure",
    "Learning from Setbacks",
    "Seeking Guidance and Support",
  ],
];

import PropTypes from "prop-types";
import noop from "lodash/noop";

import { Grid, Box, Button, styled, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import SearchBar from "components/Members/SearchBar";
import MISDatePicker from "components/common/MISDatePicker";
import MISTextField from "components/common/MISTextField";
import XLS from "assets/XLS.png";
// import { canAddMasterSettings, canTrackerView } from "utils/permissions";
import { GET_SIZE } from "utils/responsive";
import { BACKGROUND } from "theme/colors";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import T from "T";
import { useNavigate } from "react-router-dom";
import InsightsIcon from "@mui/icons-material/Insights";
import PreSalesFilters from "../PreSalesFilters";

export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const TopBar = ({
  value = "",
  startDate = null,
  endDate = null,
  filters = {},
  searchInput = "",
  showOptions = false,
  showCancelIcon = false,
  isFilterEmpty = false,
  searchTableData = {},
  skillList = {},
  projectManagers = {},
  preSalesManagersList = [],
  companyNames = [],
  onhandleFilterChange = noop,
  handleFilterSubmit = noop,
  handleFilterClose = noop,
  handleExport = noop,
  onClick = noop,
  handleKeyChange = noop,
  handleChange = noop,
  reset = noop,
  onClickOutside = noop,
  onHandleDateChange = noop,
}) => {
  const navigate = useNavigate();
  const { isLg } = GET_SIZE();
  const handleProjectAdd = () => {
    navigate("project-add");
  };

  const handlePitchedCandidates = () => {
    navigate("/app/pre-sales/interview-tracker", { state: { companyNames: companyNames } });
  };

  const handlePresalesReportCard = () => {
    navigate("/app/pre-sales/report-card", { state: companyNames });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={isLg ? "space-between" : "center"}
      sx={{
        mt: 1,
        p: 1,
        backgroundColor: "background.card",
        "& .MuiOutlinedInput-input": {
          fontSize: 14,
        },
      }}
    >
      <Grid item md={3} xs={12}>
        <SearchBar
          records={searchTableData}
          searchInput={searchInput}
          showOptions={showOptions}
          showCancelIcon={showCancelIcon}
          onClick={onClick}
          handleKeyChange={handleKeyChange}
          handleChange={handleChange}
          reset={reset}
          onClickOutside={onClickOutside}
        />
      </Grid>
      <Grid item md={2} xs={12}>
        <PreSalesFilters
          filters={filters}
          skillList={skillList}
          preSalesManagersList={preSalesManagersList}
          projectManagers={projectManagers}
          isFilterEmpty={isFilterEmpty}
          handleFilterClose={handleFilterClose}
          handleFilterSubmit={handleFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
        />
      </Grid>
      <>
        <Grid item md={1.5} xs={12}>
          <MISDatePicker
            placeholder={T.START_DATE}
            inputFormat="MM/DD/YYYY"
            value={startDate}
            name="startDate"
            maxDate={endDate}
            handleChange={onHandleDateChange}
            renderInput={(params) => <MISTextField {...params} />}
          />
        </Grid>
        <Grid item md={1.5} xs={12}>
          <MISDatePicker
            placeholder={T.END_DATE}
            inputFormat="MM/DD/YYYY"
            value={endDate}
            minDate={startDate}
            name="endDate"
            sx={{ ml: 1, mr: 1 }}
            handleChange={onHandleDateChange}
            renderInput={(params) => <MISTextField {...params} />}
          />
        </Grid>
      </>

      <Grid item>
        {/* {canTrackerView()&& */}
        <Box display="flex" alignItems="center">
          <Tooltip title={T.INTERVIEW_TRACKER} placement="top">
            <InsightsIcon
              onClick={handlePitchedCandidates}
              sx={{
                cursor: "pointer",
                color: "themeColor",
                margin: "auto",
                maxWidth: "inherit",
                width: 40,
                height: 40,
                m: 1,
              }}
            />
          </Tooltip>
          <Tooltip title={T.REPORT_CARD} placement="top">
            <FactCheckOutlinedIcon
              onClick={handlePresalesReportCard}
              sx={{
                cursor: "pointer",
                color: "themeColor",
                margin: "auto",
                maxWidth: "inherit",
                width: 40,
                height: 40,
                m: 1,
                mr: 0,
              }}
            />
          </Tooltip>
        </Box>
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="center">
          <Tooltip title={T.XLS} placement="top">
            <StyledBox component="img" src={XLS} onClick={() => handleExport(T.CSV)} />
          </Tooltip>
        </Box>
      </Grid>

      <Grid item>
        <Button
          size="medium"
          sx={{
            bgcolor: "themeColor",
            whiteSpace: "nowrap",
            color: BACKGROUND.white,

            "& .MuiButton-startIcon": {
              mr: 0,
            },
            "&:hover": {
              bgcolor: "themeColor",
              color: BACKGROUND.white,
            },
          }}
          // disabled={!canAddMasterSettings()}
          startIcon={<AddIcon sx={{ width: 19 }} />}
          onClick={handleProjectAdd}
        >
          {T.PRE_SALES_REQUIREMENT}
        </Button>
      </Grid>
    </Grid>
  );
};

TopBar.propTypes = {
  value: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  searchInput: PropTypes.string,
  filters: PropTypes.object,
  skillList: PropTypes.object,
  projectManagers: PropTypes.object,
  preSalesManagersList: PropTypes.array,
  showOptions: PropTypes.bool,
  showCancelIcon: PropTypes.bool,
  isFilterEmpty: PropTypes.bool,
  searchTableData: PropTypes.object,
  handleExport: PropTypes.func,
  onClick: PropTypes.func,
  handleKeyChange: PropTypes.func,
  handleChange: PropTypes.func,
  reset: PropTypes.func,
  onClickOutside: PropTypes.func,
  onHandleDateChange: PropTypes.func,
  onhandleFilterChange: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleFilterClose: PropTypes.func,
};

export default TopBar;

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveSubRole: build.mutation({
      query: ({ subRoleName, description, status }) => ({
        url: "/role/save/subrole",
        method: "POST",
        body: {
          subRoleName,
          description,
        },
      }),
    }),
  }),
});

export const { useSaveSubRoleMutation } = extendedApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Constants from "Constants";
import { get } from "utils/lodash";
import { CACHE_TAG_TYPES } from "./cacheTagTypes";

const { SERVER_URL, MOCK_INTERVIEW_SERVER_URL, TECH_ASSESSMENT_SERVER_URL, TECHNICAL_INTERVIEW_SERVER_URL } = Constants;

// define how much time in seconds the cached data will be stored before refreshed on next request
// https://redux-toolkit.js.org/rtk-query/usage/cache-behavior#reducing-subscription-time-with-keepunuseddatafor
export const DEFAULT_CACHE_SUBSCRIPTION_DURATION = 0;

export const api = createApi({
  reducerPath: "apiReducer",
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    // how many seconds the data will be cached between requests
    keepUnusedDataFor: DEFAULT_CACHE_SUBSCRIPTION_DURATION,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const token = get(sessionStorage, "token") || get(state, "LoginSlice.loginToken");
      headers.set("X-Content-Type-Options", "nosniff");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: CACHE_TAG_TYPES,
  endpoints: () => ({}),
});
export default api;

export const api2 = createApi({
  reducerPath: "apiReducer1",
  baseQuery: fetchBaseQuery({
    baseUrl: MOCK_INTERVIEW_SERVER_URL,
    // credentials: "include", //This is to manage the token sent in the cookies of the response header
    // how many seconds the data will be cached between requests
    minCacheTime: DEFAULT_CACHE_SUBSCRIPTION_DURATION,
    prepareHeaders: (headers, { getState }) => {
      return headers;
    },
  }),

  tagTypes: CACHE_TAG_TYPES,
  endpoints: () => ({}),
});

export const api3 = createApi({
  reducerPath: "apiReducer2",
  baseQuery: fetchBaseQuery({
    baseUrl: TECH_ASSESSMENT_SERVER_URL,
    // credentials: "include",
    // how many seconds the data will be cached between requests
    minCacheTime: DEFAULT_CACHE_SUBSCRIPTION_DURATION,
    prepareHeaders: (headers, { getState }) => {
      return headers;
    },
  }),

  tagTypes: CACHE_TAG_TYPES,
  endpoints: () => ({}),
});
export const api4 = createApi({
  reducerPath: "apiReducer3",
  baseQuery: fetchBaseQuery({
    baseUrl: TECHNICAL_INTERVIEW_SERVER_URL,
    // credentials: "include",
    // how many seconds the data will be cached between requests
    minCacheTime: DEFAULT_CACHE_SUBSCRIPTION_DURATION,
    prepareHeaders: (headers, { getState }) => {
      return headers;
    },
  }),

  tagTypes: CACHE_TAG_TYPES,
  endpoints: () => ({}),
});

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    addComment: build.mutation({
      query: ({ mock_id, formData }) => ({
        url: "ai/mock/interview/update",
        method: "POST",
        params: { mock_id: mock_id },
        body: formData,
      }),
    }),
  }),
});

export const { useAddCommentMutation } = extendedApi;

import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLicenseById: builder.query({
      query: ({ id }) => ({
        url: `/tools/license/get/assignToolsById?toolsUserMappingId=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetLicenseByIdQuery } = extendedApi;

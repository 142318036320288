import PropTypes from "prop-types";
import { Table as TableView, Paper, TableContainer, Box, Card } from "@mui/material";

import { COMMENTS_MISSING_USER_ACTIVE_NC_HEADER, JIRA_NOT_FILLED_USER_ACTIVE_NC_HEADER } from "settings/constants/members";
import T from "T";
import ActiveNcTableBody from "./ActiveNcTableBody";
import MISNoData from "components/common/MISNoData";
import { useEffect, useState } from "react";
import { handleError } from "utils/error";
import { get } from "lodash";
import { useLazyGetActiveJiraByDateQuery } from "api/Jira/getActiveJiraByDate";
import TableHeader from "./TableHeader";
const activeNcStatus = [0, 1, 2];

const ActiveNcTable = ({ userId = "", selectedMonth = "", endDate = "", startDate = "", typeOfNc = "" }) => {
  const [tableData, setTableData] = useState([]);
  const [getActiveJiraByDate] = useLazyGetActiveJiraByDateQuery();

  useEffect(() => {
    getUserJiraData();
  }, []);

  const getUserJiraData = () => {
    const payload = { id: userId, month: selectedMonth, endDate, startDate, status: activeNcStatus, type: typeOfNc };
    getActiveJiraByDate(payload)
      .unwrap()
      .then((res) => {
        setTableData(get(res, "results", ""));
      })
      .catch(handleError);
  };
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mt: 1.5,
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          component={Paper}
          sx={{ height: "calc(100vh - 290px)", width: "80vw", overflowY: "auto", overflowX: "auto" }}
        >
          {tableData.length > 0 ? (
            <TableView>
              <TableHeader
                columns={
                  typeOfNc === T.JIRA_NOT_FILLED
                    ? JIRA_NOT_FILLED_USER_ACTIVE_NC_HEADER
                    : typeOfNc === T.COMMENTS_MISSING
                    ? COMMENTS_MISSING_USER_ACTIVE_NC_HEADER
                    : []
                }
              />
              <ActiveNcTableBody tableData={tableData} typeOfNc={typeOfNc} />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
      </Box>
    </Card>
  );
};

ActiveNcTable.propTypes = {
  userId: PropTypes.number,
  month: PropTypes.number,
  typeOfNc: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  openActiveNcCountList: PropTypes.bool,
  selectedMonth: PropTypes.string,
};
export default ActiveNcTable;

import T from "T";

export const TRAINING_STATUS_LIST = [T.YET_TO_START, T.IN_PROGRESS, T.COMPLETED, T.REVIEW_PENDING];
export const OFFLINE_TRAINING_STATUS_LIST = [
  T.YET_TO_START,
  T.ABSENT,
  T.PARTIALLY_ATTENDED,
  T.ATTENDED,
  T.REVIEW_PENDING,
  T.ATTENDED_REVIEW_PENDING,
];

export const completionTypeListing = [
  { completionTypeId: 0, completionType: T.COMPLETION_LINK },
  { completionTypeId: 1, completionType: T.COMPLETION_FILE },
  { completionTypeId: 2, completionType: T.BOTH },
];
export const legends = {
  [T.YET_TO_START]: { color: "red" },
  [T.IN_PROGRESS]: { color: "orange" },
  [T.COMPLETED]: { color: "green" },
  [T.REVIEW_PENDING]: { color: "blue" },
  [T.ATTENDED]: { color: "green" },
  [T.PARTIALLY_ATTENDED]: { color: "yellow" },
  [T.ABSENT]: { color: "red" },
  [T.ATTENDED_REVIEW_PENDING]: { color: "grey" },
  default: { color: "grey" },
};
export const attendanceTypeList = [
  { attendanceID: 0, label: T.ABSENT },
  { attendanceID: 1, label: T.PARTIALLY_ATTENDED },
  { attendanceID: 2, label: T.ATTENDED },
];

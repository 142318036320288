import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box } from "@mui/material";
import MicOffIcon from "@mui/icons-material/MicOff";
import MISButton from "components/common/MISButton";
import ThreeDotsLoader from "../../common/ThreeDotsLoader";
import PropTypes from "prop-types";

const SpeechRecognitionDialog = ({ isOpen, listening, onStopListening, transcript }) => {
  return (
    <Dialog open={isOpen} onClose={onStopListening} maxWidth="sm" fullWidth>
      <DialogTitle display={"flex"} justifyContent={"center"} alignItems={"center"}>
        {listening && <span style={{ paddingRight: 10 }}>Listening </span>}
        <ThreeDotsLoader />
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" px={2}>
          <Typography
            variant="subtitle1"
            align="center"
            sx={{
              p: 2,
              border: "1px solid #ccc",
              borderRadius: 1,
              width: "100%",
              minHeight: "70px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {transcript || "Start speaking to see the transcript here..."}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
        <MISButton onClick={onStopListening} startIcon={<MicOffIcon />}>
          Stop Recording
        </MISButton>
      </DialogActions>
    </Dialog>
  );
};
SpeechRecognitionDialog.propTypes = {
  isOpen: PropTypes.bool,
  listening: PropTypes.bool,
  onStopListening: PropTypes.func,
  transcript: PropTypes.object,
};

export default SpeechRecognitionDialog;

import { useEffect, useState } from "react";
import { Typography, Divider, Grid, Card, Box, Stack, Tooltip, IconButton, Paper } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { BACKGROUND, ERROR, NETSMARTZ_THEME_COLOR, SUCCESS } from "theme/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "lodash";
import { handleError } from "utils/error";
import MISNoData from "components/common/MISNoData";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import { useLazyGetResultByMockIdQuery } from "api/MockAssessment/getResultByMockId";

const ViewAssessmentResult = () => {
  const [getResultByMockId] = useLazyGetResultByMockIdQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const [mockData, setMockData] = useState();
  const mock_id = location?.state;
  function convertToTitleCase(str) {
    return str.replace(/_/g, " ").replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  useEffect(() => {
    getResultByMockId(mock_id)
      .unwrap()
      .then((res) => {
        const responseArr = get(res, "mockInterviewLevels", []);
        const createdAt = get(res, "createdAt", []);
        setMockData({ responseArr: responseArr, createdAt: createdAt });
      })
      .catch(handleError);
  }, [mock_id]);

  const handleBack = () => {
    navigate(-1);
  };

  const coloredCard = (item) => {
    return (
      <Box
        sx={{
          minHeight: "106px",
          minWidth: "170px",
        }}
      >
        <Card
          sx={{
            background: "background.white",
            height: "45%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxShadow: "10px 10px 30px -10px rgba(0,0,0,0.2)",
            minHeight: "inherit",
            minWidth: "inherit",
            alignItems: "center",
            position: "relative",
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          <Stack display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Typography variant="h6" margin="auto" textAlign={"center"} sx={{ fontWeight: "bold", fontSize: "20px" }}>
              {convertToTitleCase(item.title)}
            </Typography>
          </Stack>
          <Box width={"100%"}>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 0.8 }}>
              {item.level < 3 ? (
                <Typography sx={{ fontWeight: "bold" }} color={ERROR.main}>
                  Level {item.level}
                </Typography>
              ) : item.level === 3 ? (
                <Typography sx={{ color: NETSMARTZ_THEME_COLOR, fontWeight: "bold" }}>Level {item.level}</Typography>
              ) : (
                <Typography sx={{ fontWeight: "bold" }} color={SUCCESS.main}>
                  Level {item.level}
                </Typography>
              )}
              <Tooltip title={<span style={{ width: "50px", fontSize: "13px" }}>{item.desc}</span>}>
                <InfoIcon className="info_ic" fontSize="medium" sx={{ ml: 1, cursor: "pointer", color: "gray" }} />
              </Tooltip>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  };

  return (
    <Paper sx={{ p: 2, height: "calc(100vh - 155px)" }}>
      <Box display={"flex"} alignItems={"center"} pb={1}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} />
        </IconButton>
        <Typography variant="h5" fontWeight={700} ml={1}>
          {T.MOCK_ASSESSMENT_RESULT}
        </Typography>
      </Box>
      <Box p="10px 20px" bgcolor={BACKGROUND.card} sx={{ height: "calc(100vh - 215px)" }}>
        {mockData && mockData.createdAt && mockData.responseArr ? (
          <>
            {mockData.createdAt && (
              <Box ml={3} mt={2}>
                <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                  Attempted On: {mockData.createdAt}
                </Typography>
              </Box>
            )}
            <Grid container p={3} spacing={3}>
              {mockData.responseArr?.map((item, index) => (
                <Grid item sm={6} lg={3} xs={12} key={index}>
                  {coloredCard(item)}
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <Box height={"70vh"}>
            <MISNoData />{" "}
          </Box>
        )}
      </Box>
    </Paper>
  );
};
export default ViewAssessmentResult;

import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getToolsById: builder.query({
      query: (id) => ({
        url: `/tools/get/toolsById?toolsId=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetToolsByIdQuery } = extendedApi;

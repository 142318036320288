import { createSlice } from "@reduxjs/toolkit";

const campusPlacementFilterSlice = createSlice({
  name: "CampusPlacementFilterSlice",
  initialState: {
    storedFilters: {
      minMockAverage: null,
      maxMockAverage: null,
      minQuestionEngagementScore: null,
      maxQuestionEngagementScore: null,
      minLogicalLevelScore: null,
      maxLogicalLevelScore: null,
      text: "",
      isNaUser: null,
    },
    // storedSearch: "",
  },
  reducers: {
    campusPlacementFilterStore: (state, action) => {
      state.storedFilters = action.payload.storedFilters;
      //   state.storedSearch = action.payload.storedSearch;
    },
  },
});
export const { campusPlacementFilterStore } = campusPlacementFilterSlice.actions;

export default campusPlacementFilterSlice.reducer;

import React from "react";
import PropTypes from "prop-types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import MISTextField from "./MISTextField";
import WithInputLabel from "./WithInputLabel";
import T from "T";
import noop from "lodash/noop";

const MISTimePicker = ({
  label = "",
  disabled,
  required,
  deprecatedLabel = true,
  name = "",
  endIcon,
  error = false,
  placeholder = T.SELECT,
  value = null,
  handleChange = noop,
  ...rest
}) => {
  return (
    <WithInputLabel label={deprecatedLabel && label} required={required} endIcon={endIcon} error={error}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          value={value}
          disabled={disabled}
          onChange={(newValue) => handleChange(newValue, name)}
          {...rest}
          renderInput={(params) => (
            <MISTextField
              size="small"
              fullWidth
              {...params}
              sx={{
                backgroundColor: "background.white",
                "& .MuiOutlinedInput-input": {
                  fontSize: 14,
                },
              }}
              inputProps={{
                ...params.inputProps,
                placeholder: placeholder,
              }}
            />
          )}
        />
      </LocalizationProvider>
    </WithInputLabel>
  );
};

MISTimePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  handleChange: PropTypes.func,
};

export default MISTimePicker;

import { useEffect, useReducer, useState } from "react";
import { Paper, Typography, Grid, IconButton, Divider, Radio, RadioGroup, FormControlLabel, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import MISTextField from "components/common/MISTextField";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "lodash";
import MISButton from "components/common/MISButton";
import { useEditRecommendationDetailsMutation } from "api/Referrals/editRecommendationDetails";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { MISCurrentUser } from "utils/validations";
import MISLoader from "components/common/MISLoader";
import Constants from "Constants";
const { SERVER_URL } = Constants;
import DownloadIcon from "@mui/icons-material/Download";
import { referralQuestions } from "components/Referrals/Form/referralModel";
import EditIcon from "@mui/icons-material/Edit";
import EditDialog from "./EditDialog";
import MISAutocomplete from "components/common/MISAutocomplete";
import { useLazyGetFunctionalManagerQuery } from "api/members/getFunctionalHead";

const CandidateRecommendationDetails = () => {
  const [editRecommendationDetails, { isLoading }] = useEditRecommendationDetailsMutation();
  const location = useLocation();
  const { user } = MISCurrentUser();
  const role = get(user, "role", null);
  const navigate = useNavigate();
  const records = location?.state;

  const record1 = records[0];
  const record2 = records[1];
  const [cvFile, setCvFile] = useState({});
  const [taComment, setTaComment] = useState(record1?.taComment);
  const [vpComment, setVpComment] = useState(record1?.vpComment);
  const [cooComment, setCooComment] = useState(record1?.cooComment);
  const [taRecommended, setTaRecommended] = useState(record1?.taRecommended);
  const [vpRecommended, setVpRecommended] = useState(record1?.vpRecommended);
  const [cooRecommended, setCooRecommended] = useState(record1?.cooRecommended);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    ta: null,

    vpList: [],
  });

  const { ta, vpList } = localState;

  useEffect(() => {
    if (record1) {
      setLocalState({
        ta: record1?.functionalHead ? record1?.functionalHead : null,
      });
    }
  }, [record1]);

  const [getVpList] = useLazyGetFunctionalManagerQuery();

  const getVpListDropDown = () => {
    getVpList({
      page: 0,
      rowPerPage: 10000,
    })
      .unwrap()
      .then((res) => {
        setLocalState({ vpList: get(res, "results", []) });
      })
      .catch(handleError);
  };
  useEffect(() => {
    getVpListDropDown();
  }, []);

  const onHandleAutoCompleteChange = (newValue, item) => {
    setLocalState({ [item]: newValue });
  };

  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  const handleCVUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (allowedTypes.includes(file.type)) {
        const base64 = await convertBase64(file);
        setCvFile({ file: base64, fileType: file.name });
      } else {
        toast.error("Only PDF, DOC, and DOCX files are allowed.");
      }
    }
  };
  const handleFeedbackTypeChange = (event, recommendationType) => {
    const value = event.target.value === "true" ? true : false;
    switch (recommendationType) {
      case "TA":
        setTaRecommended(value);
        break;
      case "VP":
        setVpRecommended(value);
        break;
      case "COO":
        setCooRecommended(value);
        break;
      case "Dynamics VP":
        setVpRecommended(value);
        break;
      default:
        break;
    }
  };
  const handleCommentChange = (role, event) => {
    switch (role) {
      case "TA":
        setTaComment(event.target.value);
        break;
      case "VP":
        setVpComment(event.target.value);
        break;
      case "COO":
        setCooComment(event.target.value);
        break;
      case "Dynamics VP":
        setVpRecommended(value);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    navigate(-1);
  };
  const answers1 = [
    record1?.firstMcq || [],
    record1?.secondMcq || [],
    record1?.thirdMcq || [],
    record1?.fourthMcq || [],
    record1?.fifthMcq || [],
  ];
  const answers2 = [
    record2?.firstMcq || [],
    record2?.secondMcq || [],
    record2?.thirdMcq || [],
    record2?.fourthMcq || [],
    record2?.fifthMcq || [],
  ];

  const handleSubmit = () => {
    if (
      (role === "COO" && cooRecommended === null) ||
      (role === "TA" && (taRecommended === null || !taComment)) ||
      (role === "VP" && (vpRecommended === null || !vpComment)) ||
      (role === "Dynamics VP" && (vpRecommended === null || !vpComment))
    ) {
      toast.error("Please fill in all the required fields.");
      return;
    }

    if (role === "TA" && !(taRecommended && ta !== null)) {
      toast.error("Please fill in all the required fields.");
      return;
    }

    const payload = {
      taRecommended,
      vpRecommended,
      cooRecommended,
      taComment,
      vpComment,
      cooComment,
      file: cvFile.file,
      fileType: cvFile.fileType,
      assignedTo: ta ? ta?.emailId : null,
    };
    editRecommendationDetails({ payload, referral_id: record1.id })
      .unwrap()
      .then(() => {
        toast.success("Recommendation Updated Successfully");
        setTaComment("");
        setVpComment("");

        setTimeout(() => {
          navigate(-1);
        }, 500);
      })
      .catch(handleError);
  };
  const handleDownloadFile = (referral_id, filePath, type) => {
    let endpoint = "";

    if (type === T.RESUME) {
      endpoint = `/talentAcquisition/download/referralFile?referral_id=${referral_id}`;
    } else if (type === T.FILE_1 || type === T.FILE_2) {
      endpoint = `/talentAcquisition/download/referralFile1?filePath=${filePath}`;
    }
    const url = `${SERVER_URL}${endpoint}`;
    try {
      window.location.href = url;
      toast.success(T.FILE_DOWNLOADED_SUCCESSFULLY);
    } catch (error) {
      handleError(error);
    }
  };
  const handleOpenEditDialog = () => {
    setOpenEditDialog(!openEditDialog);
  };

  return (
    <Paper sx={{ padding: 2 }}>
      {isLoading && <MISLoader />}
      <Box display="flex" justifyContent={"space-between"} mb={3}>
        <Box display="flex" alignItems="center">
          <IconButton fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" fontWeight={600} zIndex={99} sx={{ backgroundColor: "white" }}>
            {T.RECOMMENDATION_DETAILS}
          </Typography>
        </Box>
        <IconButton
          sx={{ color: NETSMARTZ_THEME_COLOR }}
          onClick={() => {
            handleOpenEditDialog();
          }}
        >
          <EditIcon />
        </IconButton>
      </Box>
      <Grid container spacing={4} px={3} sx={{ minHeight: "calc(100vh - 185px)" }}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={1} sx={{ border: "1px solid #D3D3D3", borderRadius: 1.5, p: 2 }}>
            <Grid item xs={12} md={3}>
              <Typography variant="body1" gutterBottom>
                <strong>Name:</strong> {get(record2 ? record2 : record1, "name", "-")}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Phone Number:</strong> {get(record2 ? record2 : record1, "phoneNumber", "-")}
              </Typography>

              {(role === "VP" || role === "Dynamics VP") && (
                <Typography variant="body1" gutterBottom>
                  <strong>Assigned: </strong> {ta?.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="body1" gutterBottom>
                <strong>LinkedIn URL:</strong>{" "}
                <a href={get(record2 ? record2 : record1, "linkedinUrl", "-")} target="_blank" rel="noopener noreferrer">
                  {/* <Typography sx={{ wordBreak: "break-all", maxWidth: 270 }}>
                    {get(record2 ? record2 : record1, "linkedinUrl", "-")}
                  </Typography> */}
                  Link
                </a>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Experience:</strong> {get(record2 ? record2 : record1, "experience", "-")} years
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="body1" gutterBottom>
                <strong>Skills:</strong>{" "}
                {get(record2 ? record2 : record1, "skills", [])
                  .map((skill) => skill?.skillName)
                  ?.join(",")}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Email:</strong> {get(record2 ? record2 : record1, "email", "-")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="body1" gutterBottom>
                <strong>Company:</strong> {get(record2 ? record2 : record1, "company.companyName", "-")}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Feedback: </strong> {record2?.feedback ? "Positive" : record2?.feedback === false ? "Negative" : null}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 3 }} />
          <Box sx={{ overflowY: "auto", height: "calc(100vh - 360px)" }}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={20}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" fontWeight="bold" sx={{ color: "gray" }} mb={1}>
                    {record1?.referrerName}'s Recommendation:
                  </Typography>
                  {referralQuestions.map((question, index) => (
                    <Box key={index} mb={2}>
                      <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                        {question}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {answers1[index].join(", ")}
                      </Typography>
                      {index !== referralQuestions.length - 1 && <Divider />}
                    </Box>
                  ))}
                  <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                    Comments:
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {get(record1, "comment", "-")}
                  </Typography>
                </Grid>
                {record2 && (
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" fontWeight="bold" sx={{ color: "gray" }} mb={1}>
                      {record2?.referrerName}'s Recommendation:
                    </Typography>
                    {referralQuestions.map((question, index) => (
                      <Box key={index} mb={2}>
                        <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                          {question}
                        </Typography>
                        <Typography variant="subtitle1" mb={1}>
                          {answers2[index].join(", ")}
                        </Typography>
                        {index !== referralQuestions.length - 1 && <Divider />}
                      </Box>
                    ))}
                    <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                      Comments:
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      {get(record2, "comment", "-")}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Divider sx={{ mb: 2, mt: 2 }} />
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={10}>
                  <Grid item xs={12} md={8}>
                    {record1?.taComment && (
                      <Typography variant="body1" gutterBottom>
                        <strong>TA Comments:</strong> <Typography variant="subtitle1">{get(record1, "taComment", "")}</Typography>
                      </Typography>
                    )}
                    {record1?.vpComment && (
                      <Typography variant="body1" gutterBottom>
                        <strong>VP Comments:</strong>
                        <Typography variant="subtitle1">{get(record1, "vpComment", "")}</Typography>
                      </Typography>
                    )}
                    {record1?.cooComment && (
                      <Typography variant="body1" gutterBottom>
                        <strong>COO Comments:</strong>{" "}
                        <Typography variant="subtitle1"> {get(record1, "cooComment", "")}</Typography>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} display={"flex"} justifyContent={"flex-end"}>
                    <Box>
                      {record1?.filePath && (
                        <Box>
                          <strong>Resume:</strong>
                          <IconButton
                            sx={{ color: NETSMARTZ_THEME_COLOR }}
                            aria-label="Download"
                            onClick={() => handleDownloadFile(record1.id, null, "Resume")}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Box>
                      )}
                      {record2
                        ? record2?.filePath1
                        : record1?.filePath1 && (
                            <Box>
                              <strong>{T.FILE_1}: </strong>

                              <Tooltip title={T.FILE_1} placement="top">
                                <IconButton
                                  sx={{ color: NETSMARTZ_THEME_COLOR }}
                                  aria-label="Download"
                                  onClick={() => handleDownloadFile(record1.id, record2.filePath1, T.FILE_1)}
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                      {record2
                        ? record2?.filePath2
                        : record1?.filePath2 && (
                            <Box>
                              <strong>{T.FILE_2}: </strong>
                              <Tooltip title={T.FILE_2} placement="top">
                                <IconButton
                                  sx={{ color: NETSMARTZ_THEME_COLOR }}
                                  aria-label="Download"
                                  onClick={() => handleDownloadFile(record1.id, record2.filePath2, T.FILE_2)}
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                    </Box>
                  </Grid>
                </Grid>
                <Box display={"flex"} pb={2}>
                  <Box sx={{ px: 2, py: 2, border: `1px solid #D3D3D3` }}>
                    <Typography fontSize={14} gutterBottom>
                      <strong>TA Recommendation</strong>
                    </Typography>
                    <RadioGroup
                      aria-label="feedbackType"
                      name="feedbackType"
                      value={taRecommended}
                      onChange={(e) => handleFeedbackTypeChange(e, "TA")}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        disabled={role !== "TA" || record1?.taRecommended !== null}
                        control={
                          <Radio
                            size="small"
                            style={{
                              color:
                                role === "TA" && record1?.taRecommended === null ? NETSMARTZ_THEME_COLOR : "rgba(0, 0, 0, 0.3)",
                            }}
                          />
                        }
                        label={<Typography fontSize={14}>{"Recommend"}</Typography>}
                      />
                      <FormControlLabel
                        value="false"
                        disabled={role !== "TA" || record1?.taRecommended !== null}
                        control={
                          <Radio
                            size="small"
                            style={{
                              color:
                                role === "TA" && record1?.taRecommended === null ? NETSMARTZ_THEME_COLOR : "rgba(0, 0, 0, 0.3)",
                            }}
                          />
                        }
                        label={<Typography fontSize={14}>{"Don't Recommend"}</Typography>}
                      />
                    </RadioGroup>
                  </Box>
                  <Box sx={{ px: 2, py: 2, border: `1px solid #D3D3D3` }}>
                    <Typography fontSize={14} gutterBottom>
                      <strong>VP Recommendation</strong>
                    </Typography>
                    <RadioGroup
                      aria-label="feedbackType"
                      name="feedbackType"
                      value={vpRecommended}
                      onChange={(e) => handleFeedbackTypeChange(e, "VP")}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        disabled={(role !== "VP" && role !== "Dynamics VP") || record1?.vpRecommended !== null}
                        control={
                          <Radio
                            size="small"
                            style={{
                              color:
                                (role === "Dynamics VP" || role === "Vp") && record1?.vpRecommended === null
                                  ? NETSMARTZ_THEME_COLOR
                                  : "rgba(0, 0, 0, 0.3)",
                            }}
                          />
                        }
                        label={<Typography fontSize={14}>{"Recommend"}</Typography>}
                      />
                      <FormControlLabel
                        value="false"
                        disabled={(role !== "VP" && role !== "Dynamics VP") || record1?.vpRecommended !== null}
                        control={
                          <Radio
                            size="small"
                            style={{
                              color:
                                (role === "VP" || role === "Dynamics VP") && record1?.vpRecommended === null
                                  ? NETSMARTZ_THEME_COLOR
                                  : "rgba(0, 0, 0, 0.3)",
                            }}
                          />
                        }
                        label={<Typography fontSize={14}>{"Don't Recommend"}</Typography>}
                      />
                    </RadioGroup>
                  </Box>
                  <Box sx={{ px: 2, py: 2, border: `1px solid #D3D3D3` }}>
                    <Typography fontSize={14} gutterBottom>
                      <strong>COO Recommendation</strong>
                    </Typography>
                    <RadioGroup
                      aria-label="feedbackType"
                      name="feedbackType"
                      value={cooRecommended}
                      onChange={(e) => handleFeedbackTypeChange(e, "COO")}
                      row
                    >
                      <FormControlLabel
                        value="true"
                        disabled={role !== "COO" || record1?.cooRecommended !== null}
                        control={
                          <Radio
                            size="small"
                            style={{
                              color:
                                role === "COO" && record1?.cooRecommended === null ? NETSMARTZ_THEME_COLOR : "rgba(0, 0, 0, 0.3)",
                            }}
                          />
                        }
                        label={<Typography fontSize={14}>{"Recommend"}</Typography>}
                      />
                      <FormControlLabel
                        disabled={role !== "COO" || record1?.cooRecommended !== null}
                        value="false"
                        control={
                          <Radio
                            size="small"
                            style={{
                              color:
                                role === "COO" && record1?.cooRecommended === null ? NETSMARTZ_THEME_COLOR : "rgba(0, 0, 0, 0.3)",
                            }}
                          />
                        }
                        label={<Typography fontSize={14}>{"Don't Recommend"}</Typography>}
                      />
                    </RadioGroup>
                  </Box>
                </Box>
                <Box display={"flex"} flexDirection={"row"} mb={2.5}>
                  {taRecommended === true && role === "TA" && (
                    <Grid item xs={12} md={6.3} mr={2.5}>
                      <MISAutocomplete
                        label={T.ASSIGN_TO}
                        required
                        multiple={false}
                        listDetails={vpList}
                        value={ta}
                        getByLabelText={(option) => get(option, "name", "")}
                        onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "ta")}
                      />
                    </Grid>
                  )}
                  {/* {
                 vpRecommended === true && <Grid item xs={12} md={6.3}>                 
                <MISAutocomplete

                 label={T.ASSIGNED_TO}
                 required
                 multiple = {false}
                 listDetails={vpList}
                 value={vp}
                 getByLabelText={(option) => get(option, "name", "")}
                 onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "vp")}
               />
                 </Grid> 
                } */}
                </Box>

                {role === "TA" && !record1?.taComment && (
                  <Grid item xs={12} md={6.3}>
                    <MISTextField
                      label={T.COMMENTS}
                      fullWidth
                      required={role !== "COO"}
                      placeholder={T.COMMENTS}
                      size="small"
                      rows={4}
                      multiline
                      variant="outlined"
                      name="experience"
                      onChange={(e) => handleCommentChange("TA", e)}
                    />
                  </Grid>
                )}
                {(role === "VP" || role === "Dynamics VP") && !record1?.vpComment && (
                  <Grid item xs={12} md={6.3}>
                    <MISTextField
                      label={T.COMMENTS}
                      fullWidth
                      required={role !== "COO"}
                      placeholder={T.COMMENTS}
                      size="small"
                      rows={4}
                      multiline
                      variant="outlined"
                      name="experience"
                      onChange={(e) => handleCommentChange("VP", e)}
                    />
                  </Grid>
                )}

                {role === "COO" && !record1?.cooComment && (
                  <Grid item xs={12} md={6.3}>
                    <MISTextField
                      label={T.COMMENTS}
                      fullWidth
                      required={role !== "COO"}
                      placeholder={T.COMMENTS}
                      size="small"
                      rows={4}
                      multiline
                      variant="outlined"
                      name="experience"
                      onChange={(e) => handleCommentChange("COO", e)}
                    />
                  </Grid>
                )}
                {((role === "TA" && (!record1?.taComment || !record1?.filePath)) ||
                  ((role === "VP" || role === "Dynamics VP") && !record1?.vpComment) ||
                  (role === "COO" && !record1?.cooComment)) && (
                  <Grid item xs={12} md={6}>
                    <Box display={"flex"}>
                      <MISButton sx={{ mt: 3 }} onClick={() => handleSubmit()}>
                        {T.SUBMIT}
                      </MISButton>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {role === "TA" && !record1?.filePath && (
                  <Box sx={{ pl: 5, py: 5, mb: 2, width: "45%", border: `1px solid #D3D3D3` }}>
                    <Typography variant="body1" gutterBottom mb={1.5}>
                      <strong>Upload Resume:</strong>
                    </Typography>
                    <input type="file" accept=".pdf,.doc,.docx" onChange={handleCVUpload} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <EditDialog
        openEditDialog={openEditDialog}
        handleOpenEditDialog={handleOpenEditDialog}
        record1={record1}
        record2={record2}
      />
    </Paper>
  );
};

export default CandidateRecommendationDetails;

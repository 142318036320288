import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDeletedJiraByDate: builder.query({
      query: (payload) => ({
        url: `/jira/show/nc/logs`,
        body: payload,
        method: "POST",
      }),
    }),
  }),
});

export const { useLazyGetDeletedJiraByDateQuery } = extendedApi;

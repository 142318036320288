import React, { useState } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import { ASSIGNED_COURSE_TABLE_HEADER } from "settings/constants/members";
import { PAGINATION } from "settings/constants/pagination";
import MasterPagination from "components/MasterSetting/MasterPagination";
import { orderBy } from "lodash";
import T from "T";
import AssignedCourseHeader from "./AssignedCourseHeader";
import AssignedCourseTableBody from "./AssignedCourseTableBody";
import MISNoData from "components/common/MISNoData";
// import AssignHeader from "./AssignHeader";
// import AssignTableBody from "./AssignTableBody";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const AssignedCourseTable = ({
  page = INITIAL_PAGE,
  onPageChange,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  allTableRows = [],
}) => {
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mt: 1.5,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          sx={{
            height: "calc(100vh - 295px)",
            overflow: "auto",
          }}
        >
          {allTableRows.length !== 0 ? (
            <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
              <AssignedCourseHeader columns={ASSIGNED_COURSE_TABLE_HEADER} />
              <AssignedCourseTableBody
                page={page}
                onPageChange={onPageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={onRowsPerPageChange}
                totalTableRowsCount={totalTableRowsCount}
                totalPageCount={totalPageCount}
                allTableRows={allTableRows}
              />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
      </Box>

      <MasterPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

AssignedCourseTable.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  allTableRows: PropTypes.array,
};
export default AssignedCourseTable;

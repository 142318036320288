import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserList: builder.query({
      query: (params) => ({
        url: "/user/get/all",
        params,
      }),
    }),
  }),
});

export const { useLazyGetUserListQuery } = extendedApi;

import { useReducer } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Grid, Typography } from "@mui/material";

import MISFooterButton from "components/common/MISFooterButton";
import MISTextField from "components/common/MISTextField";
import MISDialog from "components/common/MISDialog";
import T from "T";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { useUploadTestimonialLinkMutation } from "api/Testimonial/uploadTestimonialLink";

const TestimonialUploadModal = ({
  openTestimonialLink = false,
  handleTestimonialUploadDialog = noop,
  refreshTable = noop,
  pName = "",
  pId = "",
}) => {
  const [uploadTestimonialLink] = useUploadTestimonialLinkMutation();

  const defaultLocalState = {
    testimonialLink: "",
    comment: "",
    isUrlValid: true,
    project: {},
  };

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), defaultLocalState);

  const { testimonialLink, isUrlValid, comment } = localState;

  // const urlPattern = /^((https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const resetState = () => {
    setLocalState(defaultLocalState);
  };

  const handleClose = () => {
    resetState();
    handleTestimonialUploadDialog();
    refreshTable();
  };

  const handleTestimonialUploadModal = (testimonialLink) => {
    // if (!isUrlValid || !testimonialLink) {
    //   toast.error(T.INVALID_URL_FORMAT);
    //   return;
    // }
    const payload = {
      testimonialLink,
      comment,
    };
    uploadTestimonialLink({ payload, pId })
      .unwrap()
      .then((res) => {
        handleClose();
        toast.success(T.LINK_ADDED_SUCCESSFULLY);
      })
      .catch(handleError);
  };

  return (
    <MISDialog open={openTestimonialLink}>
      <Typography variant="h6" textAlign="center" p="10px 100px" fontWeight="600">
        {T.TESTIMONIAL_LINK}
      </Typography>

      <Grid
        container
        spacing={2}
        sx={{
          p: 7,
          "& .MuiFormLabel-root": {
            "& .MuiTypography-root": {
              color: "text.popupLabel",
              fontSize: 14,
              fontWeight: 600,
            },
          },
          width: "50vw",
        }}
      >
        <Grid item xs={12}>
          <MISTextField
            fullWidth
            label={T.TESTIMONIAL_TEXT_OR_LINK.toUpperCase()}
            placeholder={T.TESTIMONIAL_TEXT_OR_LINK}
            size="small"
            variant="outlined"
            name="testimonialLink"
            value={testimonialLink}
            required
            multiline
            onChange={onHandleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <MISTextField
            fullWidth
            label={T.COMMENT.toUpperCase()}
            placeholder={T.COMMENT}
            size="small"
            variant="outlined"
            name="comment"
            value={comment}
            required
            multiline
            onChange={onHandleChange}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <MISTextField
            fullWidth
            label={T.CASE_STUDY_LINK.toUpperCase()}
            placeholder={T.CASE_STUDY_LINK}
            autoComplete="on"
            size="small"
            required
            variant="outlined"
            name="caseStudyLink"
            // value={localState.caseStudyLink}
            // value={caseStudyLink}
            // onChange={onHandleChange}
            // error={!isUrlValid}
          />
        </Grid> */}
      </Grid>

      <MISFooterButton
        proceedButtonText={T.UPLOAD}
        justify="center"
        size="medium"
        // disableProceed={!caseStudyLink}
        sx={{ p: 1, m: 1 }}
        handleClose={() => {
          handleTestimonialUploadDialog();
          resetState();
        }}
        handleSubmit={() => handleTestimonialUploadModal(testimonialLink)}
      />
    </MISDialog>
  );
};

TestimonialUploadModal.propTypes = {
  openTestimonialUploadModal: PropTypes.bool,
  editId: PropTypes.string,
  handleTestimonialUploadModalDialog: PropTypes.func,
  refreshTable: PropTypes.func,
  onHandleAutoCompleteChange: PropTypes.func,
};

export default TestimonialUploadModal;

import PropTypes from "prop-types";
import { noop } from "lodash";

import { PAGINATION } from "settings/constants/pagination";

import TestimonialTable from "./TestimonialTable";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const TestimonialProject = ({
  page = INITIAL_PAGE,
  onPageChange,
  rowsPerPage = ROWS_PER_PAGE,
  allTableRows = [],
  onRowsPerPageChange,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  handleAddTestimonialDialog = noop,
  pName,
  handleTestimonialUploadDialog = noop,
  handleRejectCaseStudyDialog = noop,
  handleReminderButton = noop,
}) => (
  <TestimonialTable
    page={page}
    onPageChange={onPageChange}
    rowsPerPage={rowsPerPage}
    onRowsPerPageChange={onRowsPerPageChange}
    totalTableRowsCount={totalTableRowsCount}
    totalPageCount={totalPageCount}
    allTableRows={allTableRows}
    pName={pName}
    handleAddTestimonialDialog={handleAddTestimonialDialog}
    handleTestimonialUploadDialog={handleTestimonialUploadDialog}
    handleRejectCaseStudyDialog={handleRejectCaseStudyDialog}
    handleReminderButton={handleReminderButton}
  />
);

TestimonialProject.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  allTableRows: PropTypes.array,
  handleAddTestimonialDialog: PropTypes.func,
  handleCaseStudyUploadLinkDialog: PropTypes.func,
  handleRejectCaseStudyDialog: PropTypes.func,
};

export default TestimonialProject;

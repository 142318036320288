import { InputAdornment } from "@mui/material";
import MISTextField from "./MISTextField";
import { Cancel, Search } from "@mui/icons-material";
import T from "T";
import PropTypes from "prop-types";

const MISSearchBar = ({ searchValue, handleChange, handleSubmit, showCancelIcon }) => {
  return (
    <>
      <MISTextField
        value={searchValue}
        name="search"
        fullWidth
        size="small"
        sx={{
          backgroundColor: "background.white",
          "& .MuiOutlinedInput-input": {
            fontSize: 14,
          },
        }}
        placeholder={T.SEARCH_PLACEHOLDER}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key !== "Enter") {
            return;
          }
          handleSubmit();
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search
                fontSize="small"
                name="searchIcon"
                onClick={handleChange}
                data-val={searchValue}
                sx={{
                  cursor: "pointer",
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {showCancelIcon && <Cancel fontSize="small" sx={{ cursor: "pointer" }} onClick={handleChange} />}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

MISSearchBar.propTypes = {
  searchValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showCancelIcon: PropTypes.bool.isRequired,
};

export default MISSearchBar;

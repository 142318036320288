import { createSlice } from "@reduxjs/toolkit";

const licenseToolsFilterSlice = createSlice({
  name: "LicenseToolsFilterSlice",
  initialState: {
    storedFilters: {
      autoRenewal: null,
      tenure: null,
      costCenter: [],
      purchaseStartDate: null,
      purchaseEndDate: null,
    },
    storedSearch: "",
    storedDate: {
      startDate: null,
      endDate: null,
    },
    storedStatus: true,
  },
  reducers: {
    licenseToolsFilterStore: (state, action) => {
      state.storedFilters = action.payload.storedFilters;
      state.storedSearch = action.payload.storedSearch;
      state.storedDate = action.payload.storedDate;
      state.storedStatus = action.payload.storedStatus;
    },
  },
});
export const { licenseToolsFilterStore } = licenseToolsFilterSlice.actions;

export default licenseToolsFilterSlice.reducer;

import PropTypes from "prop-types";
import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import { noop } from "lodash";
import MISNoData from "components/common/MISNoData";
import { TECHNICAL_INTERVIEW_HEADER } from "settings/constants/members";
import TableBody from "./TableBody";
import TableHeader from "components/MockAssessment/TableHeader";
import { canAddTechnicalInterview } from "utils/permissions";
import T from "T";

const Table = ({ tableRecord, handleViewResult = noop, handleViewDetails = noop }) => (
  <Card
    elevation={0}
    sx={{
      p: "0px 8px",
      mt: 1.5,
      backgroundColor: "background.card",
      "& .add-shadow": {
        boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
      },
    }}
  >
    <Box overflow="hidden">
      <TableContainer
        sx={{
          height: "calc(100vh - 200px)",
          overflow: "auto",
        }}
      >
        {tableRecord?.length > 0 ? (
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            <TableHeader
              columns={
                canAddTechnicalInterview()
                  ? TECHNICAL_INTERVIEW_HEADER.filter((column) => column.label !== T.ACTION_BUTTONS)
                  : TECHNICAL_INTERVIEW_HEADER
              }
            />
            <TableBody allTableRows={tableRecord} handleViewDetails={handleViewDetails} handleViewResult={handleViewResult} />
          </TableView>
        ) : (
          <MISNoData />
        )}
      </TableContainer>
    </Box>
  </Card>
);

Table.propTypes = {
  tableRecord: PropTypes.array,
  handleViewResult: PropTypes.func,
  handleViewDetails: PropTypes.func,
};
export default Table;

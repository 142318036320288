import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    scheduleInterview: build.mutation({
      query: ({ userId, preSaleMappingId, formData }) => ({
        url: `/selected/user/PitchedUser/ScheduleInterview`,
        method: "POST",
        body: formData,
        params: { userId, presalesMappingId: preSaleMappingId },
      }),
    }),
  }),
});

export const { useScheduleInterviewMutation } = extendedApi;

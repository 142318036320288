export const capitalizeWords = (inputString) => {
  return inputString?.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
};

export const turnToArray = (str) => {
  if (str) {
    let conArray = str.split(",");
    return conArray;
  }
};

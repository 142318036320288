import { Children } from "react";
import PropTypes from "prop-types";
import { noop, isEmpty, orderBy } from "lodash";

import { Typography, Divider, Card, Button, Box } from "@mui/material";
import MISAutocomplete from "components/common/MISAutocomplete";
import { ClickAwayListener } from "@mui/material";
import { FILTER_OPTIONS, PRE_SALES_FILTER_OPTIONS } from "settings/constants/filter";
import { EMP_STATUS, PROJECT_MODE, AVAILABILITY, EMP_MODE_LIST } from "components/Members/Member/memberModel";

import { NETSMARTZ_THEME_COLOR, BACKGROUND } from "theme/colors";
import { GET_SIZE } from "utils/responsive";
import { isNumber } from "utils/validations";

import T from "T";

import { get } from "utils/lodash";
import MISTextField from "components/common/MISTextField";
import { toast } from "react-toastify";
import { REQUIREMENT_STATUS_LIST } from "../preSalesModel";

const PreSalesFilterOptions = ({
  filters = {},
  projectManagers = {},
  skillList = {},
  preSalesManagersList = [],
  handleFilterClose = noop,
  handleFilterSubmit = noop,
  onhandleFilterChange = noop,
  handleClose = noop,
}) => {
  const technologyList = get(skillList, "results", []);
  const projManagers = get(projectManagers, "results", []).filter((data) => data.strategy === true);
  const { isXs } = GET_SIZE();

  const getFilterRecords = (val) => {
    switch (val) {
      case T.TECHNOLOGY:
        return technologyList;
      case T.OS_MANAGER:
        return preSalesManagersList;
      case T.ASSIGNED_TO:
        return projManagers;
      case T.STATUS:
        return REQUIREMENT_STATUS_LIST;
      default:
    }
  };

  const getLabelData = (option, index) => {
    const optionVal = isEmpty(option) ? "" : option;

    switch (index) {
      case 0:
        return get(option, "skillName", "");
      case 1:
        return get(option, "name", "");
      case 2:
        return get(option, "name", "");
      case 3:
        return optionVal;
      default:
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Card
        sx={{
          mt: 1,
          position: "absolute",
          width: isXs ? "80%" : "25%",
          zIndex: 400,
        }}
      >
        <Box
          sx={{
            maxHeight: "calc(100vh - 250px)",
            overflowY: "auto",
          }}
        >
          <Typography variant="subtitle1" p={1} textAlign="center">
            {T.FILTER}
          </Typography>
          <Divider />

          {Children.toArray(
            PRE_SALES_FILTER_OPTIONS.map((item, index) => {
              const lists = getFilterRecords(item);

              const multiple = [T.TECHNOLOGY].includes(item);

              return (
                <Box display="flex" p="10px 20px" justifyContent="space-between" pb={index >= 7 ? "15px" : "0px"}>
                  <Typography variant="subtitle1" noWrap>
                    {item}
                  </Typography>

                  {item === `${T.EXPERIENCE} (In Years)` && (
                    <Box display="flex">
                      <MISTextField
                        required
                        placeholder={T.MIN}
                        size="small"
                        variant="outlined"
                        name="minExp"
                        sx={{ width: 60 }}
                        value={Object.keys(filters).length > 0 ? filters.minExp : ""}
                        onChange={(event) => {
                          const { value } = event.target;

                          if (value && !isNumber(value)) {
                            toast.error(T.ONLY_NUMBERS_ALLOWED);
                            return;
                          }

                          onhandleFilterChange(value, "minExp");
                        }}
                      />

                      <MISTextField
                        required
                        placeholder={T.MAX}
                        size="small"
                        variant="outlined"
                        name="maxExp"
                        sx={{ ml: 8, width: 60 }}
                        value={Object.keys(filters).length > 0 ? filters.maxExp : ""}
                        onChange={(event) => {
                          const { value } = event.target;
                          if (value && !isNumber(value)) {
                            toast.error(T.ONLY_NUMBERS_ALLOWED);
                            return;
                          }

                          onhandleFilterChange(value, "maxExp");
                        }}
                      />
                    </Box>
                  )}

                  {item !== `${T.EXPERIENCE} (In Years)` && (
                    <MISAutocomplete
                      multiple={multiple}
                      listDetails={lists}
                      size="small"
                      placeholder={T.SELECT}
                      sx={{
                        width: 185,
                      }}
                      value={Object.keys(filters).length > 0 ? filters[item] : []}
                      getByLabelText={(option) => getLabelData(option, index)}
                      onHandleChange={(event, newValue) => {
                        onhandleFilterChange(newValue, item);
                      }}
                    />
                  )}
                </Box>
              );
            }),
          )}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderColor: BACKGROUND.black,
              color: BACKGROUND.black,

              "&:hover": {
                borderColor: NETSMARTZ_THEME_COLOR,
                color: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterClose}
          >
            {T.RESET}
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterSubmit}
          >
            {T.APPLY}
          </Button>
        </Box>
      </Card>
    </ClickAwayListener>
  );
};

PreSalesFilterOptions.propTypes = {
  projectManagers: PropTypes.object,
  skillList: PropTypes.object,
  preSalesManagersList: PropTypes.array,
  filters: PropTypes.object,
  onhandleFilterChange: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleFilterClose: PropTypes.func,
  handleClose: PropTypes.func,
};

export default PreSalesFilterOptions;

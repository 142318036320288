import { useReducer } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

import {
  Typography,
  Divider,
  Card,
  Button,
  Box,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Tooltip,
} from "@mui/material";
import { ClickAwayListener } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import CancelIcon from "@mui/icons-material/Cancel";
import { NETSMARTZ_THEME_COLOR, BACKGROUND } from "theme/colors";
import { GET_SIZE } from "utils/responsive";
import { MISCurrentUser } from "utils/validations";
import T from "T";
import { toast } from "react-toastify";
import { orange } from "@mui/material/colors";
import Constants from "Constants";
import { handleError } from "utils/error";
const { SERVER_URL } = Constants;
import DownloadIcon from "@mui/icons-material/Download";

const UploadCard = ({
  bulkMember = "",
  handleBulkFileUpload = noop,
  handleDeleteFile = noop,
  handleClose = noop,
  handleSubmitUpload = noop,
}) => {
  const { sessionToken } = MISCurrentUser();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    bulkType: "",
  });
  const { bulkType } = localState;
  const { isXs } = GET_SIZE();
  const bulkList = [
    { value: "ADD", label: T.ADD },
    { value: "EDIT", label: T.EDIT },
    { value: "RELIEVE", label: T.RELIEVE },
    { value: "LEAVE", label: T.LEAVE },
  ];
  const handleBulkType = (event) => {
    setLocalState({ bulkType: event.target.value });
  };

  const downloadCaseStudyFile = async (response, type) => {
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = `Bulk-${type}-format`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(downloadUrl);
  };

  const downloadFileFormat = async (type) => {
    const modFileType = ["ADD", "EDIT", "RELIEVE"].includes(type) ? "ADD" : type;
    const url = `${SERVER_URL}/user/bulk/sample?type=${modFileType}`;
    const headers = {
      Authorization: `Bearer ${sessionToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      downloadCaseStudyFile(response, type);
      toast.success(T.FILE_DOWNLOADED_SUCCESSFULLY);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Card
        sx={{
          mt: 1,
          position: "absolute",
          top: "185px",
          width: isXs ? "80%" : "30%",
          zIndex: 400,
        }}
      >
        <Box
          sx={{
            maxHeight: "calc(100vh - 250px)",
            overflowY: "auto",
          }}
        >
          <Typography variant="subtitle1" p={1} textAlign="center">
            {T.BULK_MEMBER_UPLOAD}
          </Typography>
          <Divider />
          <Grid container>
            <Grid item md={12}>
              <RadioGroup row sx={{ justifyContent: "space-around" }} value={bulkType} onChange={handleBulkType}>
                {bulkList.map((val, index) => (
                  <Tooltip title={`Download File Format for reference`} placement="top">
                    <FormControlLabel
                      key={index}
                      size="small"
                      name={val.label}
                      value={val.value}
                      control={
                        <Radio
                          sx={{
                            "&, &.Mui-checked": {
                              color: orange[700],
                            },
                          }}
                        />
                      }
                      label={val.label}
                      labelPlacement="end"
                    />
                  </Tooltip>
                ))}
              </RadioGroup>
            </Grid>
            <Grid container md={12}>
              <Grid item md={6} sx={{ p: "10px" }}>
                <input
                  name="bulkMember"
                  style={{
                    display: "none",
                  }}
                  type="file"
                  key={bulkMember}
                  onChange={(e) => handleBulkFileUpload(e)}
                  id="bulkFileInput"
                />
                <label htmlFor="bulkFileInput">
                  <Button
                    variant="contained"
                    component="span"
                    style={{
                      backgroundColor: bulkMember ? "transparent" : NETSMARTZ_THEME_COLOR,
                      color: bulkMember ? NETSMARTZ_THEME_COLOR : "white",
                      maxWidth: bulkMember ? "100%" : "",
                      textTransform: "none",
                      borderRadius: "5px",
                    }}
                    startIcon={<UploadIcon />}
                  >
                    {bulkMember ? "Replace File" : "Upload File"}
                  </Button>
                </label>
              </Grid>
              <Grid container md={6} sx={{ p: "10px" }} justifyContent={"space-evenly"}>
                <Grid item>
                  <Typography variant="subtitle1" sx={{ maxWidth: "150px", mt: "3px" }}>
                    {"File Format"}
                  </Typography>
                </Grid>
                <Grid>
                  <Tooltip title={bulkType ? `Bulk-${bulkType}-Format` : "N/A"} placement="top">
                    <IconButton disabled={bulkType === ""} onClick={() => downloadFileFormat(bulkType)}>
                      <DownloadIcon
                        fontSize="small"
                        sx={{
                          cursor: "pointer",
                          color: bulkType === "" ? "grey" : NETSMARTZ_THEME_COLOR,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              {bulkMember && (
                <div style={{ display: "flex", alignItems: "center", color: NETSMARTZ_THEME_COLOR }}>
                  <span style={{ fontSize: 13, margin: 10 }}>{`Chosen File: ${bulkMember}`}</span>
                  <IconButton onClick={(e) => handleDeleteFile(e, "bulkMember")} style={{ color: NETSMARTZ_THEME_COLOR }}>
                    <CancelIcon />
                  </IconButton>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            variant="contained"
            size="small"
            disabled={bulkType === ""}
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={() => handleSubmitUpload(bulkType)}
          >
            {T.APPLY}
          </Button>
        </Box>
      </Card>
    </ClickAwayListener>
  );
};

UploadCard.propTypes = {
  projectManagers: PropTypes.object,
  workLocationList: PropTypes.object,
  skillList: PropTypes.object,
  departmentList: PropTypes.object,
  projectList: PropTypes.object,
  filters: PropTypes.object,
  onhandleFilterChange: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleFilterClose: PropTypes.func,
  handleClose: PropTypes.func,
};

export default UploadCard;

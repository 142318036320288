import { Table, TableContainer, Card, Grid, Typography } from "@mui/material";
import { get } from "lodash";
import InterviewTableBody from "./TableBody";
import InterviewTableHead from "./TableHeader";
import MISNoData from "components/common/MISNoData";
import { Box } from "@mui/system";
import { BACKGROUND } from "theme/colors";
import { MOCK_INTERVEIW_HEADER } from "settings/constants/members";
import T from "T";
import PropTypes from "prop-types";

const InterviewTable = ({ mockData }) => {
  const mockInterviewLevels = get(mockData, "mockInterviewLevels", []);
  return (
    <Card sx={{ mt: 3, p: 2, height: 240, backgroundColor: BACKGROUND.card }} elevation={2}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12} display="flex" alignItems="center" pb={1}>
          <Typography fontSize={17} fontWeight={600}>
            {`${T.MOCK_ASSESSMENT_RESULT}`}
          </Typography>
        </Grid>
      </Grid>
      <TableContainer
        sx={{
          overflow: "auto",
          height: 220,
        }}
      >
        {mockInterviewLevels.length > 0 ? (
          <Table stickyHeader>
            <InterviewTableHead columns={MOCK_INTERVEIW_HEADER} />
            <InterviewTableBody mockInterviewLevels={mockInterviewLevels} />
          </Table>
        ) : (
          <Box>
            <MISNoData />
          </Box>
        )}
      </TableContainer>
    </Card>
  );
};
InterviewTable.propTypes = {
  mockData: PropTypes.array,
};

export default InterviewTable;

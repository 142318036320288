import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getVPRecommendations: builder.query({
      query: ({ page, size }) => ({
        url: `/talentAcquisition/getAllReferralForVp`,
        method: "GET",
        params: { page, size },
      }),
    }),
  }),
});

export const { useLazyGetVPRecommendationsQuery } = extendedApi;

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserTrainingHistory: builder.query({
      query: ({ id }) => ({
        url: `/training/get/trainingAssignmentById?userId=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetUserTrainingHistoryQuery } = extendedApi;

import React from "react";
import ProfitCenterTable from "./ProfitCenterTable";
import { noop } from "lodash";
import PropTypes from "prop-types";
import ProfitCenterMutation from "./ProfitCenterMutation";

const ProfitCenterDialog = ({
  profitCenterList = [],
  isOpenProfitCenterDialog = bool,
  isAddEditProfitCenterOpen = bool,
  addEditProfitCenter = {},
  openAddEditProfitCenterMutation = noop,
  closeAddEditProfitCenterMutation = noop,
  handleClose = noop,
  onHandleChange = noop,
  handleSubmitProfitCenter = noop,
  handleDeleteProfitCenter = noop,
}) => {
  const columns = [
    { label: "S.no", key: "id" },
    { label: "Department Name", key: "profitCenterName" },
    { label: "Created By", key: "createdBy" },
    { label: "Actions", key: "actions" },
  ];
  return (
    <>
      <ProfitCenterTable
        columns={columns}
        profitCenterList={profitCenterList}
        isOpenProfitCenterDialog={isOpenProfitCenterDialog}
        openAddEditProfitCenterMutation={openAddEditProfitCenterMutation}
        handleClose={handleClose}
        handleDeleteProfitCenter={handleDeleteProfitCenter}
      />
      <ProfitCenterMutation
        isAddEditProfitCenterOpen={isAddEditProfitCenterOpen}
        addEditProfitCenter={addEditProfitCenter}
        handleClose={closeAddEditProfitCenterMutation}
        handleSubmitProfitCenter={handleSubmitProfitCenter}
        onHandleChange={onHandleChange}
      />
    </>
  );
};

ProfitCenterDialog.propTypes = {
  profitCenterList: PropTypes.arrayOf(PropTypes.object),
  isOpenProfitCenterDialog: PropTypes.bool,
  isAddEditProfitCenterOpen: PropTypes.bool,
  addEditProfitCenter: PropTypes.object,
  openAddEditProfitCenterMutation: PropTypes.func,
  closeAddEditProfitCenterMutation: PropTypes.func,
  handleClose: PropTypes.func,
  onHandleChange: PropTypes.func,
  handleSubmitProfitCenter: PropTypes.func,
  handleDeleteProfitCenter: PropTypes.func,
};

export default ProfitCenterDialog;

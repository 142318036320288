import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    editDetails: build.mutation({
      query: ({ userId, preSalesMappingId, formData }) => ({
        url: `/selected/user/pitchedUser/editDeatils`,
        method: "POST",
        body: formData,
        params: { userId, presalesMappingId: preSalesMappingId },
      }),
    }),
  }),
});

export const { useEditDetailsMutation } = extendedApi;

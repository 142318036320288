import { Grid, Tooltip, Typography } from "@mui/material";
import { get } from "lodash";
import React from "react";

const PrimaryGrid = ({ record }) => {
  return (
    <Grid container justifyContent={'space-around'}>
      {[
        {
          text: "L1",
          number: get(record, "l1Count", 0),
          color: "#f39c12",
          tooltipContent: get(record, "l1Count", []),
        },
        {
          text: "L2",
          number: get(record, "l2Count", 0),
          color: "#2e86de",
          tooltipContent: get(record, "l2Count", []),
        },
        {
          text: "COO Approved",
          number: get(record, "cooApproved", 0),
          color: "#27ae60",
          tooltipContent: get(record, "cooApproved", []),
        },
        {
          text: "Rejected",
          number: get(record, "rejected", 0),
          color: "#d44c4b",
          tooltipContent: get(record, "rejected", []),
        },
        {
          text: "In Review (TA)",
          number: get(record, "taInReviews", 0),
          color: "#d3a82c",
          tooltipContent: get(record, "taInReviews", []),
        },
        {
          text: "In Review (VP)",
          number: get(record, "vpInReviews", 0),
          color: "#8ea980",
          tooltipContent: get(record, "vpInReviews", []),
        },
        {
          text: "In Review (COO)",
          number: get(record, "cooInReviews", 0),
          color: "#6b8282",
          tooltipContent: get(record, "cooInReviews", []),
        },

      ].map((box, index) => (
        <Tooltip title={box.tooltipContent} arrow key={index} placement="bottom-start">
          <Grid
            item
            xs={12}
            md={1.5}
            sx={{
              height: "70px",
              px: "25px",
              backgroundColor: box.color,
              display: "flex",
              justifyContent: "space-around",
              border: "1px solid #ccc",
              borderRadius: 2,
              alignItems: "center",
              "&:not(:last-child)": {
                marginRight: { md: 1.5 },
                marginBottom: { xs: 1, sm: 1.5 },
              },
            }}
          >
            <Typography fontSize={14} color={"white"}>
              {box.text}
            </Typography>
            <Typography fontSize={14} sx={{ fontWeight: "bold", color: "white" }}>
              {box.number}
            </Typography>
          </Grid>
        </Tooltip>
      ))}
    </Grid>
  );
};

export default PrimaryGrid;

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllReferrals: builder.query({
      query: ({ page, size }) => ({
        url: `/talentAcquisition/getAllReferralByEmplopyees`,
        method: "GET",
        params: { page, size },
      }),
    }),
  }),
});

export const { useLazyGetAllReferralsQuery } = extendedApi;

import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadAssignmentFile: build.mutation({
      query: ({ id, uploadCompletionProofDto }) => ({
        url: `/trainee/upload/completionProof?subCourseAssignmentMappingId=${id}`,
        method: "POST",
        body: uploadCompletionProofDto,
      }),
    }),
  }),
});

export const { useUploadAssignmentFileMutation } = extendedApi;

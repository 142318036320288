import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, TableBody, styled, Tooltip } from "@mui/material";
import { get } from "utils/lodash";
import memo from "utils/react";

import { BACKGROUND } from "theme/colors";
import { NC_STATUS_LIST } from "settings/constants/members";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  whiteSpace: "nowrap",
  paddingTop: "10px",
  paddingBottom: "10px",
  fontSize: 13,
  textOverflow: "ellipsis",
  maxWidth: 100,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));
const DeletedNcTableBody = ({ tableData }) => {
  return (
    <TableBody>
      {Children.toArray(
        tableData.map((record, index) => (
          <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
            <StyledTableCell>{index + 1}.</StyledTableCell>
            <StyledTableCell>{get(record, "dateOfNc", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "projectName", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "projectManager", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "reportingManager", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "functionalHead", "-")}</StyledTableCell>
            <StyledTableCell align="center">{get(record, "typeOfNc", "-")}</StyledTableCell>
            <StyledTableCell align="center">
              {NC_STATUS_LIST.find((item) => item.id === get(record, "ncStatus", 0)).label}
            </StyledTableCell>
            <Tooltip title={get(record, "comments", "")} placement="top">
              <StyledTableCell align="center">{get(record, "comments", "-")}</StyledTableCell>
            </Tooltip>
          </StyledTableRow>
        )),
      )}
    </TableBody>
  );
};

DeletedNcTableBody.propTypes = {
  userId: PropTypes.number,
  month: PropTypes.number,
  typeOfNc: PropTypes.string,
  tableData: PropTypes.array,
};

export default memo(DeletedNcTableBody);

import PropTypes from "prop-types";
import { noop } from "lodash";
import CandidateRecordTable from "./CandidateRecordTable";

const CampusPlacementTable = ({
  totalTableRowsCount,
  totalPageCount,
  page,
  rowsPerPage,
  allTableRows = [],
  onPageChange = noop,
  onRowsPerPageChange = noop,
  handleRecordingDialog = noop,
  handleScreenshotDialog = noop,
  refreshTable = noop,
}) => (
  <CandidateRecordTable
    allTableRows={allTableRows}
    totalTableRowsCount={totalTableRowsCount}
    totalPageCount={totalPageCount}
    page={page}
    rowsPerPage={rowsPerPage}
    onPageChange={onPageChange}
    onRowsPerPageChange={onRowsPerPageChange}
    refreshTable={refreshTable}
    handleRecordingDialog={handleRecordingDialog}
    handleScreenshotDialog={handleScreenshotDialog}
  />
);

CampusPlacementTable.propTypes = {
  allTableRows: PropTypes.array,
  refreshTable: PropTypes.func,
  totalPageCount: PropTypes.number,
};

export default CampusPlacementTable;

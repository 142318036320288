import { Typography, Box, DialogActions, DialogContent } from "@mui/material";
import MISDialog from "components/common/MISDialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import PropTypes from "prop-types";
import { useEffect, useReducer } from "react";
import MISTextField from "components/common/MISTextField";
import MISFooterButton from "components/common/MISFooterButton";
import MISAutocomplete from "components/common/MISAutocomplete";
import { useSaveSpecialIncidentMutation } from "api/members/SIR/saveSIR";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { get, noop } from "lodash";

const AddSIRDialog = ({ openDialog, categories, record, refreshPage = noop, handleEditSIRDialog = noop }) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    category: null,
    comments: "",
  });
  const { category, comments } = localState;

  useEffect(() => {
    setLocalState({
      category: get(record, "sirCategories", null),
      comments: get(record, "description", ""),
    });
  }, [record]);

  const [saveSpecialIncident] = useSaveSpecialIncidentMutation();
  const url = location.pathname;
  const userId = Number(url.replace(/\D+/g, ""));

  const handleChange = (name, value) => {
    setLocalState({ [name]: value });
  };

  const handleSubmit = () => {
    const formData = {
      categoryId: get(category, "special_categories_id", null),
      description: comments,
      sirId: get(record, "id", null),
    };

    saveSpecialIncident({ formData, userId })
      .unwrap()
      .then(() => {
        toast.success("Special Incident Successfully Saved.");
        refreshPage();
        handleClose();
        setLocalState({ category: null, comments: "" });
      })
      .catch(handleError);
  };

  const handleClose = () => {
    handleEditSIRDialog(record);
  };

  return (
    <MISDialog open={openDialog}>
      <Box sx={{ display: "flex", flexDirection: "column", width: 580 }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            pl={1}
            sx={{
              backgroundColor: "background.card",
            }}
            p={1.5}
            pb={1}
          >
            <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleClose} />
            <Typography variant="h6" fontWeight={700} ml={1}>
              {record ? `${T.EDIT} Special Incident` : `${T.ADD} Special Incident`}
            </Typography>
          </Box>
        </Box>
        <DialogContent
          sx={{
            pt: 3,
            pb: 1,
            px: 3.5,
            boxShadow: "none",
            flexGrow: 1,
            borderRadius: 1,
          }}
        >
          <Box mb={2}>
            <MISAutocomplete
              label="Category"
              listDetails={categories}
              value={category}
              placeholder={T.SELECT_OPTION}
              required
              onHandleChange={(event, newValue) => handleChange("category", newValue)}
              getByLabelText={(option) => option.categoryName}
            />
          </Box>
          <MISTextField
            label="Comments"
            fullWidth
            multiline
            rows={7}
            required
            size="small"
            variant="outlined"
            name="comments"
            value={comments}
            onChange={(event) => handleChange("comments", event.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3.5, my: 1 }}>
          <MISFooterButton handleSubmit={handleSubmit} handleClose={handleClose} />
        </DialogActions>
      </Box>
    </MISDialog>
  );
};

AddSIRDialog.propTypes = {
  openDialog: PropTypes.bool,
  handleEditSIRDialog: PropTypes.func,
  refreshPage: PropTypes.func,
  categories: PropTypes.array,
  record: PropTypes.object,
};

export default AddSIRDialog;

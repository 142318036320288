import PropTypes from "prop-types";
import { Table as TableView, Paper, TableContainer, Box, Card } from "@mui/material";

import { COMMENTS_MISSING_USER_ACTIVE_NC_HEADER, JIRA_NOT_FILLED_USER_ACTIVE_NC_HEADER } from "settings/constants/members";
import T from "T";
import TableHeader from "../TableHeader";
import ActiveNcTableBody from "./ActiveNcTableBody";

const ActiveNcTable = ({ userId = "", selectedMonth = "", endDate = "", startDate = "", typeOfNc = "" }) => (
  <Card
    elevation={0}
    sx={{
      p: "0px 8px",
      mt: 1.5,
    }}
  >
    <Box overflow="hidden">
      <TableContainer
        component={Paper}
        sx={{ height: "calc(100vh - 290px)", width: "80vw", overflowY: "auto", overflowX: "auto" }}
      >
        <TableView>
          <TableHeader
            columns={
              typeOfNc === T.JIRA_NOT_FILLED
                ? JIRA_NOT_FILLED_USER_ACTIVE_NC_HEADER
                : typeOfNc === T.COMMENTS_MISSING
                ? COMMENTS_MISSING_USER_ACTIVE_NC_HEADER
                : []
            }
          />
          <ActiveNcTableBody
            userId={userId}
            selectedMonth={selectedMonth}
            endDate={endDate}
            startDate={startDate}
            typeOfNc={typeOfNc}
          />
        </TableView>
      </TableContainer>
    </Box>
  </Card>
);

ActiveNcTable.propTypes = {
  userId: PropTypes.number,
  month: PropTypes.number,
  typeOfNc: PropTypes.string,
};
export default ActiveNcTable;

import { Children } from "react";
import PropTypes from "prop-types";
import { noop, isEmpty, orderBy, camelCase } from "lodash";

import { Typography, Divider, Card, Button, Box, Grid, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import MISAutocomplete from "components/common/MISAutocomplete";
import { ClickAwayListener } from "@mui/material";
import { EMP_STATUS, PROJECT_MODE, AVAILABILITY, EMP_MODE_LIST } from "components/Members/Member/memberModel";

import { NETSMARTZ_THEME_COLOR, BACKGROUND } from "theme/colors";
import { GET_SIZE } from "utils/responsive";
import { isNumber } from "utils/validations";

import T from "T";

import { get } from "utils/lodash";
import MISTextField from "components/common/MISTextField";
import { toast } from "react-toastify";

const CampusPlacementFilterOptions = ({
  filters = {},
  storedFilters = {},
  handleFilterClose = noop,
  handleFilterSubmit = noop,
  onhandleFilterChange = noop,
  handleCheckboxChange = noop,
  handleClose = noop,
}) => {
  const { isXs } = GET_SIZE();

  const getFilterRecords = (val) => {
    switch (val) {
      case T.MOCK_AVERAGE:
        return { min: 0, max: 5 };
      case T.QUESTION_ENGAGEMENT_SCORE:
      case T.LOGICAL_LEVEL_SCORE:
        return { min: 0, max: 5 };
      default:
        return [];
    }
  };

  const FILTER_OPTIONS = [T.MOCK_AVERAGE, T.QUESTION_ENGAGEMENT_SCORE, T.LOGICAL_LEVEL_SCORE];

  const validateFilters = () => {
    let isValid = true;

    FILTER_OPTIONS.forEach((item) => {
      const minKey = camelCase(`${T.MIN} ${item}`);
      const maxKey = camelCase(`${T.MAX} ${item}`);
      const minValue = filters[minKey];
      const maxValue = filters[maxKey];
      // If either min or max is filled, both must be present
      if ((minValue || maxValue) && (minValue === null || maxValue === null)) {
        isValid = false;
        toast.error(`Both Min and Max values are required for ${item}`);
      }

      if (minValue && maxValue && minValue > maxValue) {
        isValid = false;
        toast.error(`Min value should be less than Max value for ${item}`);
      }
      const filterRange = getFilterRecords(item);
      if (minValue < filterRange.min || minValue > filterRange.max) {
        isValid = false;
        toast.error(`Min value for ${item} must be between ${filterRange.min} and ${filterRange.max}`);
      }

      if (maxValue < filterRange.min || maxValue > filterRange.max) {
        isValid = false;
        toast.error(`Max value for ${item} must be between ${filterRange.min} and ${filterRange.max}`);
      }
    });

    return isValid;
  };

  const onSubmit = () => {
    if (validateFilters()) {
      handleFilterSubmit();
    }
  };
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Card
        sx={{
          mt: 1,
          position: "absolute",
          width: isXs ? "80%" : "30%",
          zIndex: 400,
        }}
      >
        <Box
          sx={{
            maxHeight: "calc(100vh - 250px)",
            overflowY: "auto",
          }}
        >
          <Typography variant="subtitle1" p={1} textAlign="center">
            {T.FILTER}
          </Typography>
          <Divider />
          <Box display={"flex"} fullWidth py={"5px"} justifyContent={"space-between"}>
            <Grid container justifyContent={"space-around"}>
              <Grid md={"auto"}>
                <FormControl size="small">
                  <FormControlLabel
                    sx={{ color: "text.label" }}
                    control={
                      <Checkbox
                        checked={storedFilters?.isNaUser}
                        onChange={(e) => handleCheckboxChange(e.target.checked, "isNaUser")}
                        size="small"
                      />
                    }
                    label="Show unevaluated candidates"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Divider />

          {Children.toArray(
            FILTER_OPTIONS.map((item, index) => {
              const filterRange = getFilterRecords(item);
              const minValue = filters[camelCase(`${T.MIN} ${item}`)] || "";
              const maxValue = filters[camelCase(`${T.MAX} ${item}`)] || "";
              return (
                <Box
                  display="flex"
                  p="10px 20px"
                  justifyContent="space-between"
                  pb={index === FILTER_OPTIONS.length - 1 && "10px"}
                >
                  <Typography variant="subtitle1" noWrap>
                    {item === T.MOCK_AVERAGE ? T.COMMUNICATION_SCORE : item} ({filterRange.min}-{filterRange.max})
                  </Typography>

                  {
                    <Box display="flex">
                      <MISTextField
                        required
                        placeholder={T.MIN}
                        size="small"
                        variant="outlined"
                        name={camelCase(`${T.MIN} ${item}`)}
                        sx={{ width: 60 }}
                        value={Object.keys(filters).length > 0 ? filters[camelCase(`${T.MIN} ${item}`)] : ""}
                        onChange={(event) => {
                          const { value } = event.target;
                          const numberValue = Number(value) || null;
                          onhandleFilterChange(numberValue ?? 0, camelCase(`${T.MIN} ${item}`));
                          if (value === "") {
                            onhandleFilterChange("", camelCase(`${T.MIN} ${item}`));
                            return;
                          }

                          if (!isNumber(value)) {
                            toast.error(T.ONLY_POSITIVE_NUMBERS_ALLOWED);
                            return;
                          }
                          if (numberValue < filterRange.min || numberValue > filterRange.max) {
                            toast.error(`Value must be between ${filterRange.min} and ${filterRange.max}`);
                            return;
                          }
                          if (maxValue && numberValue > maxValue) {
                            toast.error(`Minimum value should be less than maximum value`);
                            return;
                          }
                        }}
                      />

                      <MISTextField
                        required
                        placeholder={T.MAX}
                        size="small"
                        variant="outlined"
                        name={camelCase(`${T.MAX} ${item}`)}
                        sx={{ ml: 3, width: 60 }}
                        value={Object.keys(filters).length > 0 ? filters[camelCase(`${T.MAX} ${item}`)] : ""}
                        onChange={(event) => {
                          const { value } = event.target;
                          const numberValue = Number(value) || null;
                          onhandleFilterChange(numberValue ?? 0, camelCase(`${T.MAX} ${item}`));
                          if (value === "") {
                            onhandleFilterChange("", camelCase(`${T.MAX} ${item}`));
                            return;
                          }

                          if (!isNumber(value)) {
                            toast.error(T.ONLY_POSITIVE_NUMBERS_ALLOWED);
                            return;
                          }
                          if (numberValue < filterRange.min || numberValue > filterRange.max) {
                            toast.error(`Value must be between ${filterRange.min} and ${filterRange.max}`);
                            return;
                          }
                          if (minValue && numberValue < minValue) {
                            toast.error(`Maximum value should be greater than minimum value`);
                            return;
                          }
                        }}
                      />
                    </Box>
                  }
                </Box>
              );
            }),
          )}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderColor: BACKGROUND.black,
              color: BACKGROUND.black,

              "&:hover": {
                borderColor: NETSMARTZ_THEME_COLOR,
                color: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterClose}
          >
            {T.RESET}
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={onSubmit}
          >
            {T.APPLY}
          </Button>
        </Box>
      </Card>
    </ClickAwayListener>
  );
};

CampusPlacementFilterOptions.propTypes = {
  filters: PropTypes.object,
  onhandleFilterChange: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleFilterClose: PropTypes.func,
  handleClose: PropTypes.func,
};

export default CampusPlacementFilterOptions;

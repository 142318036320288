import { Dialog, IconButton, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import T from "T";
import MISButton from "components/common/MISButton";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useReducer } from "react";
import AssignDialog from "./Dialog/AssignDialog";
import { useAssignTechnicalInterviewMutation } from "api/TechnicalInterview/assignTechnicalInterview";
import { handleError } from "utils/error";
import usePMFetch from "hooks/usePMFetch";
import { get } from "lodash";
import { toast } from "react-toastify";
import { useLazyGetAllInterviewsQuery } from "api/TechnicalInterview/getAllInterviews";
import { PAGINATION } from "settings/constants/pagination";
import Table from "./Table/Table";
import { MISCurrentUser } from "utils/validations";
import InstructionsModal from "./Dialog/InstructionsModal";
import { useNavigate } from "react-router-dom";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useGetInterviewQuestionsMutation } from "api/TechnicalInterview/getQuestions";
import MISLoader from "components/common/MISLoader";
import { useSaveInterviewResultMutation } from "api/TechnicalInterview/saveResult";
import { useGenerateResultsMutation } from "api/TechnicalInterview/generateResullts";
import { useLazyStartInterviewQuery } from "api/TechnicalInterview/startInterview";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import InterviewQuestions from "./Questions/InterviewPage";
import SpeechRecognitionDialog from "./Dialog/SpeechRecognitionDialog";
import InterviewSummaryDialog from "./Dialog/ResultDialog";
import { canAddTechnicalInterview } from "utils/permissions";

const TechnicalInterview = () => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    openDialog: false,
    topics: "",
    experience: "",
    techStack: "",
    tableRecord: [],
    totalPageCount: INITIAL_PAGE,
    totalTableRowsCount: INITIAL_PAGE,
    page: INITIAL_PAGE,
    size: ROWS_PER_PAGE,
    openInstructionModal: false,
    record: {},
    questionDict: {},
    solutionDict: {},
    finalResult: null,
    openRecordingDialog: false,
    interviewStarted: false,
    query: "",
    currentQuestionIndex: 0,
    openResultDialog: false,
  });

  const {
    openDialog,
    topics,
    experience,
    techStack,
    tableRecord,
    page,
    size,
    // totalPageCount,
    // totalTableRowsCount,
    openInstructionModal,
    record,
    questionDict,
    // solutionDict,
    finalResult,
    openRecordingDialog,
    query,
    interviewStarted,
    currentQuestionIndex,
    openResultDialog,
  } = localState;
  const { user } = MISCurrentUser();
  const navigate = useNavigate();
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition, isMicrophoneAvailable } =
    useSpeechRecognition();

  const loggedUserId = get(user, "user.id", null);
  const empCode = get(user, "user.employeeCode", "");
  const [assignTechnicalInterview] = useAssignTechnicalInterviewMutation();
  const [getAllInterviews, { isLoading: isLoadingAllInterviews }] = useLazyGetAllInterviewsQuery();
  const [getInterviewQuestions, { isLoading: isLoadingQuestions }] = useGetInterviewQuestionsMutation();
  const [saveInterviewResult, { isLoading: isSavingResult }] = useSaveInterviewResultMutation();
  const [generateResults, { isLoading: isGeneratingResults }] = useGenerateResultsMutation();
  const [startInterview, { isLoading: isStartingInterview }] = useLazyStartInterviewQuery();

  useEffect(() => {
    if (interviewStarted) {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = "";
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [interviewStarted]);
  const { skillList } = usePMFetch();
  const techStackList = get(skillList, "results", []);
  const url = location.pathname;
  const userId = Number(url.replace(/\D+/g, ""));
  const assessmentId = get(record, "id", null);
  useEffect(() => {
    refreshPage();
  }, []);
  const notifyError = (message) => toast.error(message);
  const handleAssignDialog = () => {
    setLocalState({ openDialog: !openDialog, topics: "", experience: "", techStack: "" });
  };
  const refreshPage = () => {
    getAllInterviews({ page, size, userId: userId ? userId : loggedUserId })
      .unwrap()
      .then((res) => {
        setLocalState({ tableRecord: res });
      })
      .catch(handleError);
  };

  const handleAssignInterview = () => {
    const payload = {
      techStack: get(techStack, "skillName", ""),
      topics: [topics],
      yearOfExperience: parseFloat(experience),
    };
    assignTechnicalInterview({ userId, payload })
      .unwrap()
      .then(() => {
        handleAssignDialog();
        refreshPage();
        toast.success(T.TECHNICAL_INTERVIEW_ASSIGNED_SUCCESSFULLY);
      })
      .catch(handleError);
  };

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };
  const onHandleAutoCompleteChange = (type, value) => {
    setLocalState({ [type]: value });
  };

  const handleValidate = () => {
    return topics && experience && techStack;
  };

  // const handleRowsPerPageChange = (event) => {
  //   const { value } = event.target;
  //   setLocalState({ page: INITIAL_PAGE, size: value });
  // };

  // const handlePageChange = (newPage) => {
  //   setLocalState({ page: newPage });
  //   document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  // };
  const handleStartInterview = () => {
    try {
      handleViewDetails(record);

      const payload = {
        emp_id: empCode,
        YOE: get(record, "experience", ""),
        tech_stack: get(record, "techStack", ""),
        topics: get(record, "topics", []),
      };

      getInterviewQuestions({ payload })
        .unwrap()
        .then((res) => {
          const { "Question Dict": questions, Experience, employee_id } = res;
          setLocalState({ questionDict: questions, experience: Experience, emp_id: employee_id });

          startInterview(assessmentId)
            .unwrap()
            .then(() => {
              toast.success(T.TECHNICAL_INTERVIEW_STARTED_SUCCESSFULLY);
              handleInterviewStarted();
            })
            .catch((error) => {
              console.error("Error starting interview:", error);
              handleError(error);
            });
        })
        .catch((error) => {
          console.error("Error fetching interview questions:", error);
          handleError(error);
        });
    } catch (error) {
      console.error("Error in handleStartInterview:", error);
      handleError(error);
    }
  };

  const handleViewDetails = (record) => {
    setLocalState({ openInstructionModal: !openInstructionModal, record: record });
  };
  const handleBack = () => {
    navigate(-1);
  };

  const handleStartListening = () => {
    if (!isMicrophoneAvailable) {
      notifyError("Microphone is not available.");
      return;
    }
    if (!browserSupportsSpeechRecognition) {
      notifyError("Feature not supported in this browser.");
      return;
    }
    setLocalState({ openRecordingDialog: true });
    SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
  };

  const handleStopListening = () => {
    SpeechRecognition.stopListening();
    setLocalState({ query: transcript, openRecordingDialog: false });
    resetTranscript();
  };
  const handleInterviewStarted = () => {
    setLocalState({ interviewStarted: !interviewStarted });
  };

  const handleSubmit = () => {
    const { query, currentQuestionIndex, questionDict, solutionDict } = localState;
    const questions = Object.values(questionDict);

    // Ensure the last question's response is added to solutionDict
    const finalSolutionDict = { ...solutionDict };
    if (currentQuestionIndex < questions.length) {
      finalSolutionDict[`Q${currentQuestionIndex + 1}`] = query;
    }

    const payload = {
      emp_id: empCode,
      experience: `${experience} years`,
      Question_dict: questionDict,
      solution_dict: finalSolutionDict,
    };

    generateResults(payload)
      .unwrap()
      .then((res) => {
        const savePayload = {
          finalResult: res.Result,
          questionDict: questionDict,
          solutionDict: finalSolutionDict,
        };
        saveInterviewResult({ assessmentId, savePayload })
          .unwrap()
          .then(() => {
            toast.success(T.TECHNICAL_INTERVIEW_SUBMITTED_SUCCESSFULLY);
            setLocalState({
              // Resetting state values after submission
              topics: "",
              experience: "",
              techStack: "",
              questionDict: {},
              solutionDict: {},
              currentQuestionIndex: 0,
              interviewStarted: "false",
            });
            setLocalState({ finalResult: res.Result });
            handleInterviewStarted();
            refreshPage();
          })
          .catch(handleError);
      })
      .catch(handleError);
  };

  const handleNextClick = () => {
    const { currentQuestionIndex, query, questionDict, solutionDict } = localState;
    const questions = Object.values(questionDict);
    // Ensure we are within the bounds of questions array
    if (currentQuestionIndex < questions.length) {
      // Create a new object for the solutionDict with existing data
      const newSolutionDict = { ...solutionDict };

      // Update the solutionDict in the newSolutionDict
      newSolutionDict[`Q${currentQuestionIndex + 1}`] = query;

      // Update localState with the newSolutionDict and increment currentQuestionIndex
      setLocalState({
        solutionDict: newSolutionDict,
        currentQuestionIndex: currentQuestionIndex + 1,
        query: "",
      });

      resetTranscript();
    }
  };

  const handleViewResult = (finalResult) => {
    setLocalState({ openResultDialog: !openResultDialog, finalResult: finalResult });
  };
  return (
    <>
      {(isLoadingQuestions || isLoadingAllInterviews || isSavingResult || isGeneratingResults || isStartingInterview) && (
        <MISLoader />
      )}
      {interviewStarted ? (
        <>
          <InterviewQuestions
            handleInterviewStarted={handleInterviewStarted}
            handleSubmit={handleSubmit}
            handleStartListening={handleStartListening}
            handleStopListening={handleStopListening}
            handleNextClick={handleNextClick}
            quesStep={currentQuestionIndex + 1}
            questionDict={questionDict}
            query={query}
          />
          <SpeechRecognitionDialog
            isOpen={openRecordingDialog}
            listening={listening}
            onStopListening={handleStopListening}
            transcript={transcript}
          />
        </>
      ) : (
        <Paper display="block" justifycontent="flex-start" sx={{ borderRadius: 2, p: 2 }}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Box display={"flex"} alignItems={"center"}>
              {userId ? (
                <IconButton onClick={handleBack}>
                  <ArrowBackIcon />
                </IconButton>
              ) : null}
              <Typography variant="h5" fontWeight={600}>
                {T.TECHNICAL_INTERVIEW}
              </Typography>
            </Box>
            {canAddTechnicalInterview() && (
              <MISButton sx={{ height: 35 }} size="small" startIcon={<AddIcon />} onClick={handleAssignDialog}>
                {"Assign Interview"}
              </MISButton>
            )}
          </Box>
          <Box
            sx={{
              "& .MuiTabPanel-root": {
                p: 2,
                pt: 0,
              },
            }}
          >
            <Table tableRecord={tableRecord} handleViewDetails={handleViewDetails} handleViewResult={handleViewResult} />
          </Box>
          <Dialog open={openDialog} onClose={handleAssignDialog} maxWidth="md" fullWidth>
            <AssignDialog
              onClose={handleAssignDialog}
              topics={topics}
              experience={experience}
              techStack={techStack}
              techStackList={techStackList}
              handleValidate={handleValidate}
              handleSubmit={handleAssignInterview}
              onHandleChange={onHandleChange}
              onHandleAutoCompleteChange={onHandleAutoCompleteChange}
            />
          </Dialog>
          <Dialog open={openResultDialog} onClose={handleViewResult} maxWidth="md" fullWidth>
            <InterviewSummaryDialog finalResult={finalResult} handleViewResult={handleViewResult} />
          </Dialog>

          {/* <MasterPagination
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={size}
        onRowsPerPageChange={handleRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      /> */}
          <InstructionsModal
            openInstructionModal={openInstructionModal}
            handleStartInterview={handleStartInterview}
            handleViewDetails={handleViewDetails}
            handleSubmit={handleSubmit}
          />
        </Paper>
      )}
    </>
  );
};

export default TechnicalInterview;

import { Children, useEffect, useReducer } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import T from "T";
import { ArrowBack } from "@mui/icons-material";
import MISTextField from "components/common/MISTextField";
import MISAutocomplete from "components/common/MISAutocomplete";
import { get } from "utils/lodash";
import { isEmpty } from "lodash";
import MISFooterButton from "components/common/MISFooterButton";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { GET_SIZE } from "utils/responsive";
import { isEmail } from "utils/validations";
import { toast } from "react-toastify";
import { CONFIG_TYPE, tenureList } from "components/ISTools/toolsModel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import { useUpdateConfiguratorMutation } from "api/ISTools/ToolsLicence/updateConfigurator";
import { handleError } from "utils/error";

const EmailConfigurator = ({
  isOpenEmailConfigurator = bool,
  configuratorData = {},
  handleClose = noop,
  refreshOnStatusChange = noop,
}) => {
  const { isMd, isLg } = GET_SIZE();
  const [updateConfigurator] = useUpdateConfiguratorMutation();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    configId: "",
    configurationName: "",
    configurationValue: "true",
    configurationType: "boolean",
    emailCC: "",
    emailCCArray: [],
    emailTo: "",
    emailToArray: [],
    description: "",
    remainderFreq1: [{ tenure: "", freq: 0 }],
    remainderFreq2: [{ tenure: "", freq: 0 }],
  });
  const {
    configId,
    configurationName,
    configurationValue,
    configurationType,
    emailCC,
    emailCCArray,
    emailTo,
    emailToArray,
    description,
    remainderFreq1,
    remainderFreq2,
  } = localState;

  const emptyForm = {
    configId: "",
    configurationName: "",
    configurationValue: "true",
    configurationType: "",
    emailCC: "",
    emailCCArray: [],
    emailTo: "",
    emailToArray: [],
    description: "",
    remainderFreq1: [{ tenure: "", freq: 0 }],
    remainderFreq2: [{ tenure: "", freq: 0 }],
  };
  const emptyFreqMap = { tenure: "", freq: 0 };

  useEffect(() => {
    if (Object.keys(configuratorData).length != 0) {
      const { configId, configName, configValue, configType, additionalSettings, description } = configuratorData;
      const { MailCC, MailTo, MailReminderFrequency1, MailReminderFrequency2 } = JSON.parse(additionalSettings);
      const freqMap = (remainderFreq) => {
        const freqMapArray = Object.entries(remainderFreq).map(([key, value]) => ({
          tenure: key,
          freq: value,
        }));
        return freqMapArray;
      };

      const prevData = {
        configId: configId,
        configurationName: configName,
        configurationValue: configValue,
        configurationType: configType,
        emailCC: "",
        emailCCArray: MailCC,
        emailTo: "",
        emailToArray: MailTo,
        description: description,
        remainderFreq1: isEmpty(MailReminderFrequency1) ? remainderFreq1 : freqMap(MailReminderFrequency1),
        remainderFreq2: isEmpty(MailReminderFrequency2) ? remainderFreq2 : freqMap(MailReminderFrequency2),
      };
      setLocalState(prevData);
    }
  }, [configuratorData]);

  const renderIcons = (index, type) => {
    const freqencyMap = type === "remainderFreq1" ? remainderFreq1 : remainderFreq2;
    return (
      <>
        {(index > 0 || (freqencyMap.length > 1 && index >= 0)) && (
          <RemoveCircleOutlineIcon
            onClick={() => {
              onHandleReqRemove(index, type);
            }}
            fontSize="small"
            sx={{
              cursor: "pointer",
              color: NETSMARTZ_THEME_COLOR,
            }}
          />
        )}
        {index === freqencyMap.length - 1 && freqencyMap.length <= 3 && (
          <AddCircleOutlineIcon
            onClick={() => {
              onHandleReqAddMore(type);
            }}
            fontSize="small"
            sx={{
              cursor: "pointer",
              color: NETSMARTZ_THEME_COLOR,
            }}
          />
        )}
      </>
    );
  };

  const onHandleReqAddMore = (type) => {
    const freqencyMap = type === "remainderFreq1" ? remainderFreq1 : remainderFreq2;
    freqencyMap.push(emptyFreqMap);
    setLocalState({ [type]: freqencyMap });
  };

  const onHandleReqRemove = (index, type) => {
    const freqencyMap = type === "remainderFreq1" ? remainderFreq1 : remainderFreq2;
    freqencyMap.splice(index, 1);
    setLocalState({ [type]: freqencyMap });
  };

  const onHandleChange = (e, index, type) => {
    const { name, value } = e.target;
    if (index === null && type == null) {
      setLocalState({ [name]: value });
    } else {
      const freqencyMap = type === "remainderFreq1" ? remainderFreq1 : remainderFreq2;
      freqencyMap[index][name] = parseInt(value);
      setLocalState({ [type]: freqencyMap });
    }
  };

  const onHandleAutoCompleteChange = (index, newValue, type) => {
    const freqencyMap = type === "remainderFreq1" ? remainderFreq1 : remainderFreq2;
    freqencyMap[index]["tenure"] = get(newValue, "tenureName", "");
    setLocalState({ [type]: freqencyMap });
  };

  const handleKeyPress = (e, type) => {
    const { name, value } = e.target;
    if (e.key === "Enter" || e.code === "Enter") {
      const existingEmails = name === "emailCC" ? emailCCArray : emailToArray || [];
      if (!isEmail(value)) {
        toast.error(T.INVALID_EMAIL);
      } else if (!existingEmails.includes(value)) {
        setLocalState({ [type]: [value, ...existingEmails], [name]: "" });
      }
    }
  };

  const handleButtonClick = (e, type) => {
    const { name, value } = e.target;
    if (value === "") {
      return;
    } else if (!isEmail(value)) {
      toast.error(T.INVALID_EMAIL);
    } else {
      const existingEmails = name === "emailCC" ? emailCCArray : emailToArray || [];
      if (!existingEmails.includes(value)) {
        setLocalState({ [type]: [value, ...existingEmails], [name]: "" });
      }
    }
  };

  const handleDeleteChip = (chipIndex, array) => {
    const updatedArray = localState[array].filter((_, j) => j !== chipIndex);
    setLocalState({ [array]: updatedArray });
  };

  const handleStatusSwitch = () => {
    const newValue = configurationValue === "true" ? "false" : "true";
    setLocalState({ configurationValue: newValue });
  };

  const onHandleClose = () => {
    setLocalState(emptyForm);
    handleClose();
  };
  const validation = () => {
    if (
      !configurationValue ||
      !configurationType ||
      // (emailCCArray.length <= 0 && emailCCArray.every((item) => !isEmail(item))) ||
      (emailToArray.length <= 0 && emailToArray.every((item) => !isEmail(item))) ||
      !remainderFreq1.every((item) => item.tenure) ||
      !remainderFreq2.every((item) => item.tenure)
      // ||
      // !remainderFreq1.every((item) => item.freq) ||
      // !remainderFreq2.every((item) => item.freq)
    ) {
      toast.error(T.REQUIRED_FIELDS_EMPTY);
      return false;
    }

    if (!remainderFreq1.every((item) => item.freq) || !remainderFreq2.every((item) => item.freq)) {
      toast.error(T.FREQUENCY_CAN_NOT_BE_ZERO);
      return false;
    }

    return true;
  };

  const handleSubmitEmailConfig = () => {
    if (!validation()) return;
    const convertArrayToObject = (array) => {
      return array.reduce((acc, item) => {
        if (item.freq > 0) {
          acc[item.tenure] = item.freq;
        }
        return acc;
      }, {});
    };
    const additionalSettings = {
      MailCC: emailCCArray,
      MailTo: emailToArray,
      MailReminderFrequency1: convertArrayToObject(remainderFreq1),
      MailReminderFrequency2: convertArrayToObject(remainderFreq2),
    };
    const payload = {
      configId: configId,
      configName: configurationName,
      configType: configurationType,
      configValue: configurationValue,
      description: description,
      additionalSettings: JSON.stringify(additionalSettings),
    };
    updateConfigurator(payload)
      .then((res) => {
        toast.success(T.EMAIL_CONFIGURATOR_UPDATED_SUCCESSFULLY);
        refreshOnStatusChange();
        onHandleClose();
      })
      .catch(handleError);
  };

  return (
    <Dialog onClose={onHandleClose} open={isOpenEmailConfigurator} maxWidth={isLg ? "lg" : isMd ? "md" : "sm"}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            <IconButton onClick={onHandleClose}>
              <ArrowBack />
            </IconButton>
            {T.EMAIL_CONFIGURATOR}
            <Tooltip placement="top" title={JSON.parse(configurationValue) ? T.OFF_EMAIL : T.ON_EMAIL}>
              <Switch name="show_active" color="warning" checked={JSON.parse(configurationValue)} onChange={handleStatusSwitch} />
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: "inherit" }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <MISTextField
              label={T.CONFIGURATION_NAME}
              fullWidth
              disabled={true}
              placeholder={T.CONFIGURATION_NAME}
              autoComplete="on"
              size="small"
              variant="outlined"
              name="configurationName"
              value={configurationName}
              onChange={(e) => onHandleChange(e, null, null)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <MISTextField
              label={T.DESCRIPTION}
              placeholder={T.DESCRIPTION}
              value={description}
              required
              autoComplete="on"
              size="small"
              variant="outlined"
              name="description"
              fullWidth
              onChange={(e) => onHandleChange(e, null, null)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <MISTextField
              placeholder={T.ADD_EMAIL}
              label={T.EMAIL_IN_CC}
              value={emailCC}
              fullWidth
              name="emailCC"
              autoComplete="on"
              size="small"
              variant="outlined"
              onChange={(e) => onHandleChange(e, null, null)}
              onBlur={(e) => handleButtonClick(e, "emailCCArray")}
              onKeyDown={(e) => handleKeyPress(e, "emailCCArray")}
            />
            <div>
              {emailCCArray && emailCCArray.length > 0
                ? emailCCArray.map((email, emailIndex) => (
                    <Chip
                      key={emailIndex}
                      label={email}
                      onDelete={() => handleDeleteChip(emailIndex, "emailCCArray")}
                      style={{ margin: 5 }}
                    />
                  ))
                : null}
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <MISTextField
              placeholder={T.ADD_EMAIL}
              label={T.EMAIL_IN_TO}
              value={emailTo}
              fullWidth
              required
              name="emailTo"
              autoComplete="on"
              size="small"
              variant="outlined"
              onChange={(e) => onHandleChange(e, null, null)}
              onBlur={(e) => handleButtonClick(e, "emailToArray")}
              onKeyDown={(e) => handleKeyPress(e, "emailToArray")}
            />
            <div>
              {emailToArray && emailToArray.length > 0
                ? emailToArray.map((email, emailIndex) => (
                    <Chip
                      key={emailIndex}
                      label={email}
                      onDelete={() => handleDeleteChip(emailIndex, "emailToArray")}
                      style={{ margin: 5 }}
                    />
                  ))
                : null}
            </div>
          </Grid>
          <Grid container md={12} xs={12} mt={2}>
            <Grid container md={6} xs={12}>
              <Grid item md={12} xs={12}>
                <Typography>{T.REMAINDER_FREQUENCY_1}</Typography>
              </Grid>
              {Children.toArray(
                remainderFreq1.map((data, index) => {
                  return (
                    <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
                      <Grid item md={6} xs={12}>
                        <MISAutocomplete
                          label={T.TENURE}
                          fullWidth
                          required
                          listDetails={tenureList.filter(
                            (tenure) => !remainderFreq1.some((item) => tenure.tenureName === item.tenure),
                          )}
                          getByLabelText={(option) => get(option, "label", "")}
                          value={tenureList.find((item) => item.tenureName === remainderFreq1[index].tenure) || null}
                          onHandleChange={(event, newValue) => onHandleAutoCompleteChange(index, newValue, "remainderFreq1")}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <MISTextField
                          placeholder={T.FREQUENCY}
                          label={T.FREQUENCY}
                          value={remainderFreq1[index].freq}
                          fullWidth
                          required
                          name="freq"
                          type="number"
                          size="small"
                          variant="outlined"
                          onChange={(e) => onHandleChange(e, index, "remainderFreq1")}
                        />
                      </Grid>
                      <Grid item md={2} xs={12} mt={4}>
                        {renderIcons(index, "remainderFreq1")}
                      </Grid>
                    </Grid>
                  );
                }),
              )}
            </Grid>
            <Grid container md={6} xs={12}>
              <Grid item md={12} xs={12}>
                <Typography>{T.REMAINDER_FREQUENCY_2}</Typography>
              </Grid>
              {Children.toArray(
                remainderFreq2.map((data, index) => {
                  return (
                    <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
                      <Grid item md={6} xs={12}>
                        <MISAutocomplete
                          label={T.TENURE}
                          fullWidth
                          required
                          listDetails={tenureList.filter(
                            (tenure) => !remainderFreq2.some((item) => tenure.tenureName === item.tenure),
                          )}
                          getByLabelText={(option) => get(option, "label", "")}
                          value={tenureList.find((item) => item.tenureName === remainderFreq2[index].tenure) || null}
                          onHandleChange={(event, newValue) => onHandleAutoCompleteChange(index, newValue, "remainderFreq2")}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <MISTextField
                          placeholder={T.FREQUENCY}
                          label={T.FREQUENCY}
                          value={remainderFreq2[index].freq}
                          fullWidth
                          required
                          name="freq"
                          type="number"
                          size="small"
                          variant="outlined"
                          onChange={(e) => onHandleChange(e, index, "remainderFreq2")}
                        />
                      </Grid>
                      <Grid item md={2} xs={12} mt={4}>
                        {renderIcons(index, "remainderFreq2")}
                      </Grid>
                    </Grid>
                  );
                }),
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MISFooterButton
          proceedButtonText={T.SUBMIT}
          justify="end"
          sx={{ pb: 0.5 }}
          handleClose={onHandleClose}
          handleSubmit={() => handleSubmitEmailConfig()}
        />
      </DialogActions>
    </Dialog>
  );
};

export default EmailConfigurator;

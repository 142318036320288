import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getResultByMockId: builder.query({
      query: (mock_id) => ({
        url: `/ai/mock/interview/findByMockId?mock_id=${mock_id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetResultByMockIdQuery } = extendedApi;

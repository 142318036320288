import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography, Box, IconButton } from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import memo from "utils/react";
import { get } from "utils/lodash";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import T from "T";
import { noop, camelCase } from "lodash";

const TableHeader = ({ columns = [], sorting = {}, handlePreSalesSorting = noop }) => (
  <TableHead>
    <TableRow sx={{ height: 30 }}>
      {Children.toArray(
        columns.map((column) => {
          const columnName = get(column, "label", "").toUpperCase();
          const isDisabled = get(sorting[camelCase(columnName)], "disable", false);
          const hasSorting = [T.DAYS_IN_OPERATION.toUpperCase(), T.ASSIGNED_TO.toUpperCase(), T.LEAD_DATE.toUpperCase()].includes(
            columnName,
          );
          return (
            <TableCell
              sx={{
                border: "none",
                backgroundColor: BACKGROUND.header,
                paddingY: 0,
              }}
            >
              {hasSorting ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body1" fontSize={14} noWrap fontWeight={600}>
                    {column.label.toUpperCase()}
                  </Typography>

                  <IconButton
                    onClick={() => handlePreSalesSorting(camelCase(columnName))}
                    aria-label="Sort"
                    size="small"
                    disabled={isDisabled}
                    sx={{ color: NETSMARTZ_THEME_COLOR }}
                  >
                    <SwapVertIcon fontSize="22" />
                  </IconButton>
                </Box>
              ) : columnName === T.ACTION_BUTTONS.toUpperCase() ? (
                <Box sx={{ ml: 1.5 }}>
                  <Typography variant="body1" fontSize={14} noWrap fontWeight={600}>
                    {column.label.toUpperCase()}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="body1" fontSize={14} noWrap fontWeight={600}>
                  {columnName}
                </Typography>
              )}
            </TableCell>
          );
        }),
      )}
    </TableRow>
  </TableHead>
);

TableHeader.propTypes = {
  columns: PropTypes.array,
  sorting: PropTypes.object,
  handlePreSalesSorting: PropTypes.func,
  locked_columns: PropTypes.array,
  handleDaysSorting: PropTypes.func,
};

export default memo(TableHeader);

import PropTypes from "prop-types";
import { TableRow, TableCell, TableBody, styled, Tooltip, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import T from "T";
import MISNoData from "components/common/MISNoData";
import { MainTitleTypography, SubTitleTypography, TitleTypography } from "utils/members";
import { Box } from "@mui/system";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 24px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "white",
}));

const FeedbackTableBody = ({ tableRecord = [] }) => {
  const reversedTableRecord = [...tableRecord].reverse();
  const getLabelText = (value) => `${value}`;
  return (
    <TableBody>
      {reversedTableRecord.length === 0 ? (
        <StyledTableRow>
          <StyledTableCell colSpan="10%" sx={{ border: "none" }}>
            <MISNoData />
          </StyledTableCell>
        </StyledTableRow>
      ) : (
        reversedTableRecord.map((entry) => (
          <StyledTableRow
            key={entry.id}
            // style={{ height: "100px", justifyContent: "center" }}
          >
            <StyledTableCell>
              <Tooltip title={entry.createdBy} placement="top-start">
                <TitleTypography>{entry.createdBy}</TitleTypography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell>
              <Rating
                name={`${"technicalFeedback"}-${entry.id}`}
                precision={0.5}
                value={entry.technicalFeedback}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
              <Tooltip title={entry.techComment} placement="top-start">
                <TitleTypography>{entry.techComment}</TitleTypography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Tooltip title={entry.clientComment} placement="top-start">
                <TitleTypography>{entry.clientComment}</TitleTypography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"communicationSkill"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.communicationSkill}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
              <Tooltip title={entry.communicationComment} placement="top-start">
                <TitleTypography>{entry.communicationComment}</TitleTypography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"availability"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.availability}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"teamPlayer"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.teamPlayer}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"enhancingSkill"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.enhancingSkill}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
              <Tooltip title={entry.enhancingSkillComment} placement="top-start">
                <TitleTypography>{entry.enhancingSkillComment}</TitleTypography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"takingInitiative"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.takingInitiative}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
              <Tooltip title={entry.takingInitiativeComment} placement="top-start">
                <TitleTypography>{entry.takingInitiativeComment}</TitleTypography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              {entry.employeeOnEDP === true && (
                <>
                  <Box display="flex" whiteSpace="nowrap">
                    <SubTitleTypography> From:</SubTitleTypography>
                    <TitleTypography ml={1}>{entry.startDate}</TitleTypography>
                  </Box>
                  <Box display="flex" whiteSpace="nowrap">
                    <SubTitleTypography> To: </SubTitleTypography>
                    <TitleTypography ml={1}> {entry.endDate}</TitleTypography>
                  </Box>
                </>
              )}
              {entry.employeeOnEDP === false && <TitleTypography>No</TitleTypography>}
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"jiraFilling"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.jiraFilling}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
              <Tooltip title={entry.jiraFillingComment} placement="top-start">
                <TitleTypography>{entry.jiraFillingComment}</TitleTypography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"discipline"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.discipline}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
              <Tooltip title={entry.disciplineComment} placement="top-start">
                <TitleTypography>{entry.disciplineComment}</TitleTypography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Tooltip title={entry.comments} placement="top">
                <TitleTypography>{entry.comments}</TitleTypography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Tooltip title={entry.createdAt} placement="top-start">
                <TitleTypography>{entry.createdAt}</TitleTypography>
              </Tooltip>
            </StyledTableCell>
          </StyledTableRow>
        ))
      )}
    </TableBody>
  );
};

FeedbackTableBody.propTypes = {
  tableRecord: PropTypes.array,
};

export default FeedbackTableBody;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Paper, Menu, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { get, isEqual } from "lodash";
import { format, isValid } from "date-fns";
import { INTERVIEW_TRACKER } from "settings/constants/members";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import T from "T";
import Table from "components/PreSales/InterviewTracker/Table";
import { useGetAllPitchedMembersFilterMutation } from "api/pitchedCandidates/getAllPitchedMembers";
import { useGetPitchedUserStatusMutation } from "api/preSales/interviewTracker/getPitchedUserStatus";
import MISLoader from "components/common/MISLoader";
import { PAGINATION } from "settings/constants/pagination";
import { handleError } from "utils/error";
import TopBar from "components/PreSales/InterviewTracker/Topbar";
import usePMFetch from "hooks/usePMFetch";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { interviewTrackerFilterStore } from "slices/interviewTrackerFilterSlice";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import Constants from "Constants";
import { MISCurrentUser } from "utils/validations";
import { toast } from "react-toastify";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const { SERVER_URL } = Constants;

const AllPitchedMembers = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sessionToken } = MISCurrentUser();

  const { storedFilters } = useSelector(
    (state) => ({
      storedFilters: get(state, "InterviewTrackerFilterSlice.storedFilters", {}),
    }),
    shallowEqual,
  );
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    filters: storedFilters,
    startDate: null,
    endDate: null,
    pitchedUserStatus: [],
    searchInput: "",
    showCancelIcon: false,
    isOpenConfigurator: false,
    configuratorColumns: cloneDeep(INTERVIEW_TRACKER),
    pastConfiguratorColumns: cloneDeep(INTERVIEW_TRACKER),
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    showActive: get(location, "state.showActive", true),
    allPitchedMemberData: { allTableRows: [], totalTableRowsCount: null, totalPageCount: null },
    jdId: get(location, "state.jdId", ""),
    isSvgClicked: false,
    exportLoading: false,
  });

  const {
    filters,
    startDate,
    endDate,
    searchInput,
    isOpenConfigurator,
    pastConfiguratorColumns,
    page,
    showCancelIcon,
    rowsPerPage,
    allPitchedMemberData,
    shortListedChecks,
    selectAllCheck,
    jdId,
    isSvgClicked,
    pitchedUserStatus,
    exportLoading,
  } = localState;

  const [getAllPitchedMembersFilter, { isLoading }] = useGetAllPitchedMembersFilterMutation();
  const [getPitchedUserStatus] = useGetPitchedUserStatusMutation();
  const { projectManagers } = usePMFetch();
  const pMResults = get(projectManagers, "results", []);
  const companyNames = location.state && location.state.companyNames;

  const emptyFilters = {
    companyName: "",
    reportingManager: "",
    bytManager: "",
    status: "",
  };

  const isFilterEmpty = isEqual(storedFilters, emptyFilters);

  useEffect(() => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      getAllPitchedMembersTableData(page, rowsPerPage);
    }
  }, [page, rowsPerPage, startDate, endDate, isSvgClicked]);

  useEffect(() => {
    getAllPitchedMembersTableData(page, rowsPerPage);
    getPitchedUserStatusData();
  }, [filters]);

  const getAllPitchedMembersTableData = (page, rowsPerPage) => {
    getAllPitchedMembersFilter({
      page,
      size: rowsPerPage,
      interviewTrackerFilterDto: {
        clientName: get(filters, "companyName", ""),
        endDate: endDate,
        reportingManager: get(filters, "reportingManager.name", ""),
        bytManager: get(filters, "bytManager.name", ""),
        startDate: startDate,
        status: get(filters, "status", ""),
        text: searchInput,
      },
    })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({
          allPitchedMemberData: { allTableRows, totalTableRowsCount, totalPageCount },
          isSvgClicked: false,
        });
      })
      .catch(() => {
        handleError();
      });
  };

  const getPitchedUserStatusData = () => {
    getPitchedUserStatus()
      .unwrap()
      .then((response) => {
        setLocalState({ pitchedUserStatus: response });
      });
  };

  const handleEditAllPitched = (id, jdId, endDate, status, feedback, reasonForRejection) => {
    navigate(`/app/pre-sales/interview-tracker/${id}/edit`, {
      state: { id, jdId, endDate, status, feedback, reasonForRejection },
    });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
  };

  const handleBack = () => {
    navigate("/app/pre-sales");
  };
  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const handleSearchChange = (event) => {
    const { value, dataset } = event.currentTarget;
    const searchValue = value || get(dataset, "val", "");
    if (searchValue === "") {
      getAllPitchedMembersTableData(page, rowsPerPage);
    }
    setLocalState({
      showOptions: event.key !== "Enter",
      showCancelIcon: searchValue !== "",
      searchInput: searchValue,
    });
    if (event.key === "Enter" || event.currentTarget.nodeName === "svg" || searchValue === "") {
      getAllPitchedMembersTableData(page, rowsPerPage);
      setLocalState({ isSvgClicked: true });
    }
  };

  const handleSearchKeyChange = () => {
    setLocalState({
      page: INITIAL_PAGE,
    });
    getAllPitchedMembersTableData(INITIAL_PAGE, rowsPerPage);
  };

  const handleSearchClick = (value) => {
    setLocalState({
      showOptions: false,
      showCancelIcon: false,
      searchInput: value,
      page: INITIAL_PAGE,
    });
  };

  const onHandleDateChange = (newValue, type) => {
    const validDate = newValue ? new Date(newValue) : null;
    setLocalState({
      [type]: validDate && isValid(validDate) ? getBEDateFormat(validDate) : null,
    });
    // getAllPitchedMembersTableData(page, rowsPerPage);
  };

  const onhandleFilterChange = (newValue, item) => {
    setLocalState({ filters: { ...filters, [item]: newValue } });
  };

  const handleFilterSubmit = () => {
    dispatch(interviewTrackerFilterStore({ storedFilters: filters }));
    getAllPitchedMembersTableData(page, rowsPerPage);
  };

  const handleFilterClose = () => {
    dispatch(interviewTrackerFilterStore({ storedFilters: emptyFilters }));
    setLocalState({ filters: emptyFilters, startDate: null, endDate: null });
    getAllPitchedMembersTableData(page, rowsPerPage);
  };

  const handleExport = async () => {
    setLocalState({ exportLoading: true });
    const currentDate = new Date().toISOString().slice(0, 10);
    const formData = {
      clientName: get(filters, "companyName", ""),
      endDate: endDate,
      reportingManager: get(filters, "reportingManager.name", ""),
      bytManager: get(filters, "bytManager.name", ""),
      startDate: startDate,
      status: get(filters, "status", ""),
      text: searchInput,
    };

    fetch(`${SERVER_URL}/preSalesMapping/export/csv/pitchedUser`, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "content-type": "application/json",
      },
    })
      .then(async (res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await res.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `PitchedUsers_${currentDate}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLocalState({ exportLoading: false });
        toast.success(T.FILE_DOWNLOADED_SUCCESSFULLY);
      })
      .catch(handleError);
  };

  const handleCvDownload = async (filePath) => {
    const url = `${SERVER_URL}/preSalesMapping/download/anyFile?path=${filePath}`;
    const headers = {
      Authorization: `Bearer ${sessionToken}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = filePath.split("/").pop();
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downloadUrl);

      toast.success(T.FILE_DOWNLOADED_SUCCESSFULLY);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      {(isLoading || exportLoading) && <MISLoader />}
      <Box display="flex" alignItems="center" mb={1}>
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack} />
        <Typography variant="h5" ml={1}>
          {T.INTERVIEW_TRACKER}
        </Typography>
      </Box>
      <TopBar
        startDate={startDate}
        endDate={endDate}
        filters={filters}
        searchInput={searchInput}
        companyNames={companyNames}
        projectManagersList={pMResults}
        pitchedUserStatusList={pitchedUserStatus}
        showCancelIcon={showCancelIcon}
        isFilterEmpty={isFilterEmpty}
        onClick={handleSearchClick}
        handleKeyChange={handleSearchKeyChange}
        handleChange={handleSearchChange}
        onHandleDateChange={onHandleDateChange}
        onhandleFilterChange={onhandleFilterChange}
        handleFilterSubmit={handleFilterSubmit}
        handleFilterClose={handleFilterClose}
        handleExport={handleExport}
      />
      <Table
        columns={pastConfiguratorColumns.filter((column) => column.checked)}
        lockedColumns={pastConfiguratorColumns.filter((column) => column.locked && column.checked)}
        jdId={jdId}
        page={page}
        rowsPerPage={rowsPerPage}
        allTableRows={allPitchedMemberData.allTableRows}
        totalPageCount={allPitchedMemberData.totalPageCount}
        totalTableRowsCount={allPitchedMemberData.totalTableRowsCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        shortListedChecks={shortListedChecks}
        selectAllCheck={selectAllCheck}
        handleEditAllPitched={handleEditAllPitched}
        handleExport={handleCvDownload}
      />
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setLocalState({ isOpenConfigurator: null })}
        open={Boolean(isOpenConfigurator)}
        anchorEl={isOpenConfigurator}
        PaperProps={{
          style: {
            left: "50%",
            transform: "translateX(65%) translateY(-15%)",
          },
        }}
      />
    </Paper>
  );
};

export default AllPitchedMembers;

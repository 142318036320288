import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    editBasicInfo: build.mutation({
      query: ({ payload }) => ({
        url: "talentAcquisition/editReferralBasicInfo",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useEditBasicInfoMutation } = extendedApi;

import { Box, Button } from "@mui/material";
import PropTypes from "prop-types";
import MicIcon from "@mui/icons-material/Mic";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { GET_SIZE } from "utils/responsive";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import T from "T";
import { noop } from "lodash";

const QuestionsBoxButtons = ({
  handleNextClick = noop,
  quesStep,
  handleStartListening = noop,
  // handleStopListening = noop,
  handleSubmit = noop,
  query,
}) => {
  const { isXs } = GET_SIZE();

  return (
    <Box>
      <Box
        sx={{
          display: isXs ? "block" : "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          startIcon={
            <MicIcon
              sx={{
                fontSize: "16px !important",
                borderRadius: 50,
                p: 0.2,
                backgroundColor: "white",
                color: NETSMARTZ_THEME_COLOR,
              }}
            />
          }
          sx={{
            fontSize: 16,
            px: 3,
            width: isXs ? "100%" : 125,
            minWidth: "90px",
            py: 1.3,
            mb: 2,
            backgroundColor: "themeColor",
            borderRadius: 2.1,
            "&:hover": {
              backgroundColor: "themeColor",
            },
          }}
          onClick={handleStartListening}
        >
          {T.ANSWER}
        </Button>

        {quesStep === 8 && (
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: "themeColor",
              fontWeight: "bold",
              borderRadius: 2,
              width: isXs ? "100%" : 100,
              fontSize: "15px",
              px: 1.4,
              py: 1.3,
              mb: 2,
              "&:hover": {
                backgroundColor: "themeColor",
              },
            }}
            disabled={!query}
          >
            {T.SUBMIT}
          </Button>
        )}

        {quesStep !== 0 && quesStep < 8 && (
          <Button
            variant="outlined"
            onClick={handleNextClick}
            endIcon={<ArrowForwardIcon />}
            disabled={!query}
            sx={{
              borderColor: "themeColor",
              color: "black",
              fontWeight: "bold",
              borderRadius: 2,
              width: isXs ? "100%" : 100,
              fontSize: "15px",
              px: 1.4,
              py: 1.3,
              mb: 2,
              "&:hover": {
                borderColor: "themeColor",
              },
            }}
          >
            {T.NEXT}
          </Button>
        )}
      </Box>
    </Box>
  );
};

QuestionsBoxButtons.propTypes = {
  quesStep: PropTypes.number.isRequired,
  handleStopListening: PropTypes.func,
  handleStartListening: PropTypes.func,
  handleNextClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  query: PropTypes.string,
};

export default QuestionsBoxButtons;

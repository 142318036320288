import { Typography, Box, IconButton, Grid, DialogContent, DialogActions, Tooltip } from "@mui/material";
import MISDialog from "components/common/MISDialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import T from "T";
import PropTypes from "prop-types";
import { noop, get } from "lodash";
import { toast } from "react-toastify";
import Constants from "Constants";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { useState } from "react";
const { SERVER_URL } = Constants;

const ViewClientDialog = ({ openDialog, record, handleViewClientDialog = noop }) => {
  const handleClose = () => {
    handleViewClientDialog();
  };

  const [downloadedStates, setDownloadedStates] = useState({});

  const handleDownload = () => {
    const URL = `${SERVER_URL}/preSales/download/presalesClientFile?preSalesId=${record.id}`;
    setTimeout(() => {
      setDownloadedStates((prevState) => ({
        ...prevState,
        [record.id]: true,
      }));
    }, 800);
    window.location.href = URL;
    toast.success(T.FILE_DOWNLOADED_SUCCESSFULLY);
  };

  return (
    <MISDialog open={openDialog}>
      <Box sx={{ display: "flex", flexDirection: "column", width: 580, minHeight: 370 }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            pl={1}
            pt={2}
            pb={1}
            sx={{
              backgroundColor: "background.card",
            }}
          >
            <IconButton sx={{ cursor: "pointer", pl: 1 }} onClick={() => handleClose()}>
              <ArrowBackIcon fontSize="small" />
            </IconButton>
            <Typography variant="h6" fontWeight={700}>
              {T.PROJECT_DETAILS}
            </Typography>
          </Box>
        </Box>
        <DialogContent
          sx={{
            m: 1,
            backgroundColor: "background.white",
            boxShadow: "none",
            flexGrow: 1,
            borderRadius: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                Company Name:
              </Typography>
              <Typography>{get(record, "companyName", "N/A")}</Typography>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2, mb: 1 }}>
                Source:
              </Typography>
              <Typography>{get(record, "source.sourceName", "N/A")}</Typography>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2, mb: 1 }}>
                Client Region:
              </Typography>
              <Typography>{get(record, "clientRegion.regionName", "N/A")}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                Requirement For:
              </Typography>
              <Typography>{get(record, "requirementFor", "N/A")}</Typography>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2, mb: 1 }}>
                Preferred Tech:
              </Typography>
              <Typography>{record?.preferredTech.join(", ")}</Typography>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2, mb: 1 }}>
                Assigned To:
              </Typography>
              <Typography>{get(record, "projectManager.name", "N/A")}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        {record?.clientFileLink && (
          <DialogActions sx={{ backgroundColor: BACKGROUND.card }}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-evenly"}>
              <Typography variant="subtitle1" fontWeight="bold">
                Files:
              </Typography>

              {downloadedStates[record.id] ? (
                <FileDownloadDoneIcon sx={{ color: NETSMARTZ_THEME_COLOR, p: 1 }} />
              ) : (
                <Tooltip
                  placement="top"
                  title={record.clientFileLink.split("/").pop().split("_").slice(1).join("_").split(".")[0]}
                >
                  <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }} aria-label="Download" onClick={handleDownload}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </DialogActions>
        )}
      </Box>
    </MISDialog>
  );
};

ViewClientDialog.propTypes = {
  handleViewClientDialog: PropTypes.func,
  record: PropTypes.object,
  openDialog: PropTypes.bool,
};

export default ViewClientDialog;

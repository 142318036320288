import PropTypes from "prop-types";
import noop from "lodash/noop";
import { orderBy } from "lodash";

import { Table as TableView, Paper, TableContainer, Box, Card } from "@mui/material";

import CandidateRecordTableBody from "./CandidateRecordTableBody";
import MISNoData from "components/common/MISNoData";
import { CAMPUS_PLACEMENT_HEADER } from "../campusPlacementModel";
import MasterPagination from "components/MasterSetting/MasterPagination";
import CandidateRecordHeader from "./CandidateRecordHeader";
import { useEffect, useState } from "react";

const CandidateRecordTable = ({
  totalTableRowsCount,
  totalPageCount,
  page,
  rowsPerPage,
  allTableRows = [],
  onPageChange = noop,
  onRowsPerPageChange = noop,
  refreshTable = noop,
  handleRecordingDialog = noop,
  handleScreenshotDialog = noop,
}) => {
  const [sortedData, setSortedData] = useState(allTableRows); // Your data
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSorting = (item) => {
    let direction = "asc";

    // Check if the same column is being clicked; if so, toggle the direction
    if (sortConfig.key === item && sortConfig.direction === "asc") {
      direction = "desc";
    } else if (sortConfig.key === item && sortConfig.direction === "desc") {
      direction = "asc";
    }

    const customOrder = allTableRows.map((row) => {
      if (item === "communicationScore") {
        return {
          ...row,
          ["mockAverage"]: row["mockAverage"] === null ? 0 : row["mockAverage"], // Replace null with 0
        };
      } else {
        return {
          ...row,
          [item]: row[item] === null ? 0 : row[item], // Replace null with 0
        };
      }
    });

    const sorted = orderBy(customOrder, [item === "communicationScore" ? "mockAverage" : item], [direction]);
    setSortedData(sorted);

    // Update the sorting config state
    setSortConfig({ key: item, direction });
  };
  useEffect(() => {
    setSortedData([]);
  }, [allTableRows]);
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
      }}
    >
      <Box overflow="hidden">
        <TableContainer component={Paper} sx={{ height: "calc(100vh - 290px)", overflowY: "auto" }}>
          {allTableRows.length !== 0 ? (
            <TableView stickyHeader>
              <CandidateRecordHeader columns={CAMPUS_PLACEMENT_HEADER} handleSorting={handleSorting} />
              <CandidateRecordTableBody
                allTableRows={sortedData.length > 0 ? sortedData : allTableRows}
                page={page}
                rowsPerPage={rowsPerPage}
                refreshTable={refreshTable}
                handleRecordingDialog={handleRecordingDialog}
                handleScreenshotDialog={handleScreenshotDialog}
              />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
      </Box>
      <MasterPagination
        page={page}
        rowsPerPage={rowsPerPage}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Card>
  );
};

CandidateRecordTable.propTypes = {
  totalTableRowsCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  allTableRows: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  refreshTable: PropTypes.func,
  handleRecordingDialog: PropTypes.func,
  handleScreenshotDialog: PropTypes.func,
};

export default CandidateRecordTable;

import { Children } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Box, TableRow, TableCell, Typography, TableBody, Tooltip, styled, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TitleTypography, StyledContentCopyIcon } from "utils/members";
import EditIcon from "@mui/icons-material/Edit";
import { BACKGROUND, BORDER, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import { capitalizeWords } from "utils/commonFunction";
import T from "T";

const SubTitleTypography = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 14,
  lineHeight: "22px",
  whiteSpace: "nowrap",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 0px",
  textAlign: "center",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const DashboardTableBody = ({ dashboardData = [], profitCenterList = [], toolsList = [], handleLicenseDetail = noop }) => {
  const getProfitCenterLabel = (data) => {
    return { [data.profitCenterName]: 0 };
  };
  const initialList = profitCenterList.map(getProfitCenterLabel);
  const totals = {
    costTotal: 0,
    total: 0,
    monthlyCost: 0,
    quarterlyCost: 0,
    biannuallyCost: 0,
    annuallyCost: 0,
  };

  const getTotalCounts = (data) => {
    return data.reduce((totals, record) => {
      const { profitCenters } = record;
      for (const key in profitCenters) {
        totals[key] = (totals[key] || 0) + profitCenters[key];
      }
      return totals;
    }, {});
  };

  const getTotalCost = (data) => {
    return data.reduce((totals, record) => {
      const { profitCenterCost } = record;
      for (const key in profitCenterCost) {
        totals[key] = (totals[key] || 0) + profitCenterCost[key];
      }
      return totals;
    }, {});
  };

  const totalCostCenters = Object.assign({}, ...initialList, getTotalCounts(dashboardData));
  const totalCostCount = Object.assign({}, ...initialList, getTotalCost(dashboardData));

  return (
    <TableBody>
      {Children.toArray(
        dashboardData.map((record, index) => {
          const { toolsName, profitCenters, monthlyCost, quarterlyCost, biannuallyCost, annuallyCost } = record;
          const profitCenterCost = get(record, "profitCenterCost", {});
          const total = Object.values(profitCenters).reduce((acc, curr) => acc + curr, 0);
          const costTotal = Object.values(profitCenterCost).reduce((acc, curr) => acc + curr, 0);
          totals.total += total;
          totals.costTotal += costTotal;
          totals.monthlyCost += monthlyCost;
          totals.quarterlyCost += quarterlyCost;
          totals.biannuallyCost += biannuallyCost;
          totals.annuallyCost += annuallyCost;
          const profitCenterCountsList = Object.assign({}, ...initialList, profitCenters);
          const toolsId = toolsList.find((tools) => tools.toolsName === toolsName).toolsId;
          return (
            <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
              <StyledTableCell>
                <TitleTypography>{toolsName}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{total}</TitleTypography>
              </StyledTableCell>
              {Object.entries(profitCenterCountsList).map(([key, value]) => {
                const profitCenterId = profitCenterList.find(
                  (profitCenter) => profitCenter.profitCenterName === key,
                ).profitCenterId;
                return (
                  <StyledTableCell>
                    {value > 0 ? (
                      <SubTitleTypography
                        sx={{ color: NETSMARTZ_THEME_COLOR, cursor: "pointer" }}
                        onClick={() => handleLicenseDetail(toolsId, profitCenterId)}
                      >
                        {value}
                      </SubTitleTypography>
                    ) : (
                      <TitleTypography>{value}</TitleTypography>
                    )}
                  </StyledTableCell>
                );
              })}
              <StyledTableCell>
                <TitleTypography>{parseFloat(monthlyCost).toFixed(2)}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{parseFloat(quarterlyCost).toFixed(2)}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{parseFloat(biannuallyCost).toFixed(2)}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{parseFloat(annuallyCost).toFixed(2)}</TitleTypography>
              </StyledTableCell>
            </StyledTableRow>
          );
        }),
      )}
      {dashboardData.length !== 0 && (
        <>
          <StyledTableRow style={{ position: "sticky", bottom: 0 }}>
            <StyledTableCell>
              <SubTitleTypography sx={{ color: NETSMARTZ_THEME_COLOR }}>{"Total License"}</SubTitleTypography>
            </StyledTableCell>
            <StyledTableCell>
              <SubTitleTypography>{totals.total}</SubTitleTypography>
            </StyledTableCell>
            {Object.entries(totalCostCenters).map(([key, value]) => {
              return (
                <StyledTableCell>
                  <SubTitleTypography>{value}</SubTitleTypography>
                </StyledTableCell>
              );
            })}
            <StyledTableCell>
              <SubTitleTypography>{`$${totals.monthlyCost.toFixed(2)}`}</SubTitleTypography>
            </StyledTableCell>
            <StyledTableCell>
              <SubTitleTypography>{`$${totals.quarterlyCost.toFixed(2)}`}</SubTitleTypography>
            </StyledTableCell>
            <StyledTableCell>
              <SubTitleTypography>{`$${totals.biannuallyCost.toFixed(2)}`}</SubTitleTypography>
            </StyledTableCell>
            <StyledTableCell>
              <SubTitleTypography>{`$${totals.annuallyCost.toFixed(2)}`}</SubTitleTypography>
            </StyledTableCell>
          </StyledTableRow>
        </>
      )}
    </TableBody>
  );
};

export default DashboardTableBody;

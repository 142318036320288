import PropTypes from "prop-types";
import { Card, CardContent, Grid, Typography, Box, useTheme, IconButton, Tooltip } from "@mui/material";
import { CheckCircleOutline, EmojiObjects, ReportProblem } from "@mui/icons-material";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { format } from "date-fns";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { noop } from "lodash";
import T from "T";
import ScheduleIcon from "@mui/icons-material/Schedule";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";

const SwotTableBody = ({ tableRecord, handleAssignedCourseDialog = noop }) => {
  const theme = useTheme();
  const strengths = tableRecord.filter((item) => item.type === "S");
  const weaknesses = tableRecord.filter((item) => item.type === "W");
  const opportunities = tableRecord.filter((item) => item.type === "O");
  const threats = tableRecord.filter((item) => item.type === "T");

  const findLatestUpdate = () => {
    let latestUpdate = { createdAt: null, createdBy: null };

    tableRecord.forEach((item) => {
      const itemUpdatedAt = new Date(item.createdAt);

      if (!isNaN(itemUpdatedAt.getTime())) {
        if (!latestUpdate.createdAt || itemUpdatedAt > latestUpdate.createdAt) {
          latestUpdate = {
            createdAt: itemUpdatedAt,
            createdBy: item.createdBy,
          };
        }
      }
    });

    return latestUpdate;
  };

  const latestUpdate = findLatestUpdate();

  const renderCard = (data, title, icon, boxShadowColor) => (
    <Card
      sx={{
        height: "100%",
        borderRadius: 5,
        transition: "box-shadow 0.3s",
        border: `1px solid ${boxShadowColor}`,
        "&:hover": {
          boxShadow: `0 2px 4px ${boxShadowColor}`,
          // border: `1px solid ${boxShadowColor}`,
        },
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: 1,
          }}
        >
          {icon}
          <Typography
            variant="h6"
            sx={{
              marginLeft: 2,
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            height: 180,
            overflow: "auto",
            marginTop: 2,
          }}
        >
          {data.length > 0 ? (
            data.map((item) => {
              const { swotAnalysisId, description, type, swotTrainingAssignmentMapping } = item;
              const trainingStatus = swotTrainingAssignmentMapping[0]?.trainingAssignment?.trackStatus;
              return (
                <ul key={swotAnalysisId}>
                  <li style={{ fontSize: 15 }}>
                    <Box display={"flex"} alignItems={"center"}>
                      {description}
                      {type === "W" && (
                        <React.Fragment key={swotAnalysisId}>
                          {!trainingStatus && (
                            <Tooltip title={T.ASSIGN_TRAINING} placement="top">
                              <IconButton
                                sx={{ color: NETSMARTZ_THEME_COLOR, cursor: "pointer" }}
                                onClick={() => handleAssignedCourseDialog(swotAnalysisId)}
                              >
                                <AddBoxIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {trainingStatus === T.YET_TO_START && (
                            <Tooltip title={T.YET_TO_START} placement="top">
                              <IconButton disableRipple sx={{ fontSize: 20, color: NETSMARTZ_THEME_COLOR, cursor: "default" }}>
                                <ScheduleIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {trainingStatus === T.IN_PROGRESS && (
                            <Tooltip title={T.IN_PROGRESS} placement="top">
                              <IconButton disableRipple sx={{ fontSize: 20, color: NETSMARTZ_THEME_COLOR, cursor: "default" }}>
                                <HourglassTopIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {trainingStatus === T.REVIEW_PENDING && (
                            <Tooltip title={T.REVIEW_PENDING} placement="top">
                              <IconButton disableRipple sx={{ fontSize: 20, color: NETSMARTZ_THEME_COLOR, cursor: "default" }}>
                                <HourglassBottomIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {trainingStatus === T.COMPLETED && (
                            <React.Fragment>
                              <Tooltip title={T.COMPLETED} placement="top">
                                <IconButton disableRipple sx={{ fontSize: 20, color: NETSMARTZ_THEME_COLOR, cursor: "default" }}>
                                  <CheckCircleIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Add" placement="top">
                                <IconButton
                                  sx={{ color: NETSMARTZ_THEME_COLOR, cursor: "pointer" }}
                                  onClick={() => handleAssignedCourseDialog(swotAnalysisId)}
                                >
                                  <AddBoxIcon />
                                </IconButton>
                              </Tooltip>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </Box>
                  </li>
                </ul>
              );
            })
          ) : (
            <Box height={180} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Box sx={{ textAlign: "center" }}>
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                  <Grid item>
                    <ErrorOutlineIcon sx={{ fontSize: 48, color: NETSMARTZ_THEME_COLOR, opacity: 0.6 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="textSecondary">
                      No Records Found
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary" sx={{ color: "#616161", opacity: 0.8 }}>
                      Sorry, but there are no records matching your criteria.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <Typography variant="subtitle1" ml={1} mb={0.5} color="textSecondary">
        Last updated on{" "}
        {latestUpdate.createdAt && latestUpdate.createdBy ? (
          <Typography component={"span"} variant="subtitle1" color="textSecondary">
            {latestUpdate.createdAt && (
              <>
                {format(latestUpdate.createdAt, "MMM dd")} at {format(latestUpdate.createdAt, "h:mm a")}
              </>
            )}{" "}
            by {latestUpdate.createdBy}.
          </Typography>
        ) : (
          <Typography component="span" variant="subtitle1" sx={{ color: "text.disabled" }}>
            Not updated yet
          </Typography>
        )}
      </Typography>

      <Grid container spacing={2} sx={{ height: "100%", px: 1 }}>
        <Grid item xs={12} sm={6}>
          {renderCard(
            strengths,
            "Strengths",
            <CheckCircleOutline sx={{ fontSize: 40, color: theme.palette.success.main }} />,
            theme.palette.success.main,
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderCard(
            weaknesses,
            "Weaknesses",
            <TrendingDownIcon sx={{ fontSize: 40, color: theme.palette.error.main }} />,
            theme.palette.error.main,
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderCard(
            opportunities,
            "Opportunities",
            <EmojiObjects sx={{ fontSize: 40, color: theme.palette.info.main }} />,
            theme.palette.info.main,
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderCard(
            threats,
            "Threats",
            <ReportProblem sx={{ fontSize: 40, color: theme.palette.warning.main }} />,
            theme.palette.warning.main,
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

SwotTableBody.propTypes = {
  tableRecord: PropTypes.arrayOf(
    PropTypes.shape({
      swotAnalysisId: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["S", "W", "O", "T"]).isRequired,
      trainingStatus: PropTypes.string,
      createdAt: PropTypes.instanceOf(Date).isRequired,
      createdBy: PropTypes.string.isRequired,
    }),
  ),
  handleAssignedCourseDialog: PropTypes.func,
};

export default SwotTableBody;

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getReferralStats: builder.query({
      query: () => ({
        url: `/talentAcquisition/l1Andl2/stats`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetReferralStatsQuery } = extendedApi;

import styled from "@emotion/styled";
import { TableBody, TableRow, TableCell } from "@mui/material";
import PropTypes from "prop-types";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  borderBottom: "none",
  overflow: "hidden",
  fontSize: 13,
  paddingBottom: 0,
}));
const InterviewTableBody = ({ mockInterviewLevels }) => (
  <TableBody>
    {mockInterviewLevels.map((row, index) => (
      <TableRow key={row.id}>
        <StyledTableCell>{index + 1}.</StyledTableCell>
        <StyledTableCell>{row.title}</StyledTableCell>
        <StyledTableCell>{row.desc}</StyledTableCell>
        <StyledTableCell sx={{ textAlign: "center" }}>{row.level}</StyledTableCell>
      </TableRow>
    ))}
  </TableBody>
);

InterviewTableBody.propTypes = {
  mockInterviewLevels: PropTypes.array,
};

export default InterviewTableBody;

import PropTypes from "prop-types";
import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import { noop } from "lodash";
import MISNoData from "components/common/MISNoData";
import { MOCK_ASSESSMENT_TABLE_HEADER } from "settings/constants/members";
import TableHeader from "./TableHeader";
import MISTableBody from "./TableBody";

const MISTable = ({
  tableRecord,
  openDeleteModal,
  handleDownloadRecordings = noop,
  handleViewAssessmentResults = noop,
  handleDeleteRecordings = noop,
  handleAddComment = noop,
  handleDeleteModal = noop,
}) => (
  <Card
    elevation={0}
    sx={{
      p: "0px 8px",
      mt: 1.5,
      backgroundColor: "background.card",
      "& .add-shadow": {
        boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
      },
    }}
  >
    <Box overflow="hidden">
      <TableContainer
        sx={{
          height: "calc(100vh - 205px)",
          overflow: "auto",
        }}
      >
        {tableRecord?.length > 0 ? (
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            <TableHeader columns={MOCK_ASSESSMENT_TABLE_HEADER} />
            <MISTableBody
              allTableRows={tableRecord}
              handleDownloadRecordings={handleDownloadRecordings}
              handleViewAssessmentResults={handleViewAssessmentResults}
              handleDeleteRecordings={handleDeleteRecordings}
              handleAddComment={handleAddComment}
              handleDeleteModal={handleDeleteModal}
              openDeleteModal={openDeleteModal}
            />
          </TableView>
        ) : (
          <MISNoData />
        )}
      </TableContainer>
    </Box>
  </Card>
);

MISTable.propTypes = {
  tableRecord: PropTypes.array,
  handleDownloadRecordings: PropTypes.func,
  handleViewAssessmentResults: PropTypes.func,
  handleDeleteRecordings: PropTypes.func,
  handleAddComment: PropTypes.func,
  openDeleteModal: PropTypes.bool,
  handleDeleteModal: PropTypes.func,
};
export default MISTable;

import { Box } from "@mui/system";
import { useEffect, useReducer, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get, isNull } from "lodash";
import { Paper, Typography } from "@mui/material";
import T from "T";
import { PAGINATION } from "settings/constants/pagination";
import { useLazyGetUserViewByIdQuery } from "api/members/getUserViewById";
import { handleError } from "utils/error";
import usePMFetch from "hooks/usePMFetch";
import { useLazyGetProjectManagerQuery } from "api/projects/getProjectManager";
import { useLazyGetFeedbackByIdQuery } from "api/members/getFeedbacks";
import TopBar from "./TopBar";
import FeedbackListTable from "./FeedbackTableList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const { INITIAL_PAGE } = PAGINATION;
const FULL_ROWS_PER_PAGE = 10000;
const Feedback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [FeedbackData, setFeedbackData] = useState([]);
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    projectManager: {},
  });
  const { projectManager } = localState;
  const [getProjectManager, { data: projectManagers }] = useLazyGetProjectManagerQuery();
  useEffect(() => {
    getProjectManager({ page: INITIAL_PAGE, rowPerPage: FULL_ROWS_PER_PAGE });
    refreshView();
    refreshViewFeedback();
  }, []);
  const pMResults = get(projectManagers, "results", []);
  const selectedPM = pMResults.find((res) => res.id === projectManager) || {};

  const url = location.pathname;
  const id = Number(url.replace(/\D+/g, ""));
  const [getUserViewById, { isFetching, data: viewData }] = useLazyGetUserViewByIdQuery();
  const [getFeedbackById, { data: DataFeed }] = useLazyGetFeedbackByIdQuery();
  const tableRecord = get(DataFeed, "results", []);
  const record = get(viewData, "results", []);
  const fullName = get(record, "userName", "");

  const refreshView = () => {
    getUserViewById({ id }).unwrap().catch(handleError);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const refreshViewFeedback = async (pmId = "") => {
    const userId = id;
    try {
      await getFeedbackById({ userId, pmId });
    } catch (error) {
      handleError(error);
    }
  };
  const onHandlePMChange = (type, pmId) => {
    setLocalState({ [type]: pmId });
    refreshViewFeedback(pmId);
  };

  return (
    <Paper display="block" sx={{ p: 2 }}>
      <Box display="flex" alignItems="center" mb={1}>
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack} />
        <Typography variant="h6" fontWeight={700} ml={1}>
          {` ${fullName} ${T.FEEDBACK} `}
        </Typography>
      </Box>
      <TopBar
        fullName={fullName}
        id={id}
        projectManagers={projectManagers}
        onHandlePMChange={onHandlePMChange}
        selectedPM={selectedPM}
        pMResults={pMResults}
        tableRecord={tableRecord}
      />
      <FeedbackListTable tableRecord={tableRecord} />
    </Paper>
  );
};
export default Feedback;

import PropTypes from "prop-types";
import { TableCell, TableBody, styled, TableRow, IconButton, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import memo from "utils/react";
import get from "lodash/get";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import { noop } from "lodash";
import DownloadIcon from "@mui/icons-material/Download";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";
import T from "T";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  backgroundColor: "white",
  padding: "7px 8px 7px 24px",
  maxWidth: 130,
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  fontSize: 13.5,
}));

const MISTableBody = ({
  allTableRows,
  handleDownloadRecordings = noop,
  handleViewAssessmentResults = noop,
  handleDeleteRecordings = noop,
  handleAddComment = noop,
  handleDeleteModal = noop,
  openDeleteModal,
}) => {
  const reversedTableRecord = [...allTableRows].reverse();
  return (
    <TableBody>
      {reversedTableRecord.map((record, index) => {
        const mock_id = get(record, "mockId", null);
        const comments = get(record, "comment", "-");
        const emailId = get(record, "emailId", "-");
        return (
          <TableRow key={index}>
            <StyledTableCell sx={{ pl: 4.5 }}>{index + 1}.</StyledTableCell>
            <StyledTableCell>{get(record, "empName", "-")}</StyledTableCell>
            <Tooltip placement="top-start" title={emailId}>
              <StyledTableCell>{get(record, "emailId", "-")}</StyledTableCell>
            </Tooltip>
            <StyledTableCell>{get(record, "attemptedOn", "-")}</StyledTableCell>
            {get(record, "comment") ? (
              <>
                <Tooltip placement="top-start" title={comments}>
                  <StyledTableCell sx={{ pr: 0 }}>{get(record, "comment", "-")}</StyledTableCell>
                </Tooltip>
                <StyledTableCell sx={{ pl: 0 }}>
                  <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }} onClick={() => handleAddComment(mock_id, comments)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </StyledTableCell>
              </>
            ) : (
              <>
                <StyledTableCell sx={{ pr: 0 }}>{"-"}</StyledTableCell>
                <StyledTableCell sx={{ pl: 0 }}>
                  <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }} onClick={() => handleAddComment(mock_id, comments)}>
                    <AddCircleOutlineIcon fontSize="small" />
                  </IconButton>
                </StyledTableCell>
              </>
            )}
            <StyledTableCell sx={{ textAlign: "center", width: 0 }}>
              <Tooltip placement="top-start" title="View Assessment">
                <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }} onClick={() => handleViewAssessmentResults(mock_id)}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Tooltip placement="top-start" title="Download Recordings">
                <IconButton
                  sx={{ color: NETSMARTZ_THEME_COLOR }}
                  disabled={!record.audioPath}
                  onClick={() => handleDownloadRecordings(mock_id)}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip placement="top-start" title="Delete Recordings">
                <IconButton
                  disabled={!record.audioPath}
                  sx={{ color: NETSMARTZ_THEME_COLOR }}
                  onClick={() => handleDeleteModal(mock_id)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </StyledTableCell>
          </TableRow>
        );
      })}
      <ConfirmSubmit
        cancelButtonText={T.NO}
        proceedButtonText={T.YES}
        dialogMessageText={T.DELETE_RECORDINGS}
        openConfirmSubmit={openDeleteModal}
        handleClose={handleDeleteModal}
        handleSubmit={handleDeleteRecordings}
      />
    </TableBody>
  );
};

MISTableBody.propTypes = {
  allTableRows: PropTypes.array,
  handleDownloadRecordings: PropTypes.func,
  handleViewAssessmentResults: PropTypes.func,
  handleAddComment: PropTypes.func,
  handleDeleteRecordings: PropTypes.func,
  openDeleteModal: PropTypes.bool,
  handleDeleteModal: PropTypes.func,
};

export default memo(MISTableBody);

import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteTools: builder.mutation({
      query: (id) => ({
        url: `/tools/delete?toolId=${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useDeleteToolsMutation } = extendedApi;

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => ({
        url: `/specialIncidentRegister/fetchAll/sirCategories`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetCategoriesQuery } = extendedApi;

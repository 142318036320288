import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography, IconButton } from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";

import T from "T";

import memo from "utils/react";
import { get } from "utils/lodash";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { Box } from "@mui/system";
import noop from "lodash/noop";
import { camelCase } from "lodash";

const CandidateRecordHeader = ({ columns = [], handleSorting = noop }) => {
  return (
    <TableHead>
      <TableRow>
        {Children.toArray(
          columns.map((column, index) => {
            return (
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: BACKGROUND.cardDefault,
                  padding: 1,
                }}
              >
                {column.label === T.COMMUNICATION_SCORE ||
                column.label === T.QUESTION_ENGAGEMENT_SCORE ||
                column.label === T.LOGICAL_LEVEL_SCORE ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body1" fontSize={14} noWrap fontWeight={600}>
                      {column.label}
                    </Typography>
                    <IconButton
                      onClick={() => handleSorting(camelCase(column.label))}
                      aria-label="Sort"
                      size="small"
                      // disabled={isDisabled}
                      sx={{ color: NETSMARTZ_THEME_COLOR }}
                    >
                      <SwapVertIcon fontSize="22" />
                    </IconButton>
                  </Box>
                ) : (
                  <Typography variant="body1" fontSize={14} noWrap fontWeight={600}>
                    {column.label}
                  </Typography>
                )}
              </TableCell>
            );
          }),
        )}
      </TableRow>
    </TableHead>
  );
};

CandidateRecordHeader.propTypes = {
  columns: PropTypes.array,
  handleSorting: PropTypes.func,
};

export default memo(CandidateRecordHeader);

import PropTypes from "prop-types";
import { DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from "@mui/material";
import { noop } from "lodash";

const InterviewSummaryDialog = ({ handleViewResult = noop, finalResult }) => {
  const finalResultSections = finalResult?.split("\n\n").map((section, index) => (
    <Typography
      key={index}
      variant="body1"
      sx={{
        textAlign: "start",
        marginBottom: 2,
        lineHeight: 1.6,
        "&:not(:last-child)": { borderBottom: "1px solid #e0e0e0", paddingBottom: 2 },
      }}
    >
      {section}
    </Typography>
  ));

  return (
    <>
      <DialogTitle>Interview Summary</DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: "center" }}>{finalResultSections}</Box>
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: "themeColor" }} onClick={() => handleViewResult(null)}>
          Close
        </Button>
      </DialogActions>
    </>
  );
};

InterviewSummaryDialog.propTypes = {
  handleViewResult: PropTypes.func.isRequired,
  finalResult: PropTypes.string.isRequired,
};

export default InterviewSummaryDialog;

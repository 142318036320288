import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Table as TableView, TableContainer } from "@mui/material";

import { NC_TABLE_HEADER } from "settings/constants/members";

import TableHeader from "../Interview/TableHeader";
import NCTableBody from "./NCTableBody";
import MISNoData from "components/common/MISNoData";
import { Box } from "@mui/system";

const NCTable = ({ allTableRows, refreshTable = noop, handleActiveNcCountDialog = noop, handleDeleteNcCountDialog = noop }) => (
  <TableContainer
    sx={{
      overflow: "auto",
      height: 220,
    }}
  >
    {allTableRows.length > 0 ? (
      <TableView stickyHeader>
        <TableHeader columns={NC_TABLE_HEADER} />
        <NCTableBody
          records={allTableRows}
          refreshTable={refreshTable}
          handleActiveNcCountDialog={handleActiveNcCountDialog}
          handleDeleteNcCountDialog={handleDeleteNcCountDialog}
        />
      </TableView>
    ) : (
      <Box pt={5}>
        <MISNoData />
      </Box>
    )}
  </TableContainer>
);

NCTable.propTypes = {
  allTableRows: PropTypes.array,
  refreshTable: PropTypes.func,
  handleActiveNcCountDialog: PropTypes.func,
  handleDeleteNcCountDialog: PropTypes.func,
};

export default NCTable;

import React from "react";
import CostCenterTable from "./CostCenterTable";
import { noop } from "lodash";
import PropTypes from "prop-types";
import CostCenterMutation from "./CostCenterMutation";

const CostCenterDialog = ({
  costCenterList = [],
  isOpenCostCenterDialog = bool,
  isAddEditCostCenterOpen = bool,
  addEditCostCenter = {},
  openAddEditCostCenterMutation = noop,
  closeAddEditCostCenterMutation = noop,
  handleClose = noop,
  onHandleChange = noop,
  handleSubmitCostCenter = noop,
  handleDeleteCostCenter = noop,
}) => {
  const columns = [
    { label: "S.no", key: "id" },
    { label: "Cost Center", key: "costCenterName" },
    { label: "Created By", key: "createdBy" },
    { label: "Actions", key: "actions" },
  ];
  return (
    <>
      <CostCenterTable
        columns={columns}
        costCenterList={costCenterList}
        isOpenCostCenterDialog={isOpenCostCenterDialog}
        openAddEditCostCenterMutation={openAddEditCostCenterMutation}
        handleClose={handleClose}
        handleDeleteCostCenter={handleDeleteCostCenter}
      />
      <CostCenterMutation
        isAddEditCostCenterOpen={isAddEditCostCenterOpen}
        addEditCostCenter={addEditCostCenter}
        handleClose={closeAddEditCostCenterMutation}
        handleSubmitCostCenter={handleSubmitCostCenter}
        onHandleChange={onHandleChange}
      />
    </>
  );
};

CostCenterDialog.propTypes = {
  costCenterList: PropTypes.arrayOf(PropTypes.object),
  isOpenCostCenterDialog: PropTypes.bool,
  isAddEditCostCenterOpen: PropTypes.bool,
  addEditCostCenter: PropTypes.object,
  openAddEditCostCenterMutation: PropTypes.func,
  closeAddEditCostCenterMutation: PropTypes.func,
  handleClose: PropTypes.func,
  onHandleChange: PropTypes.func,
  handleSubmitCostCenter: PropTypes.func,
  handleDeleteCostCenter: PropTypes.func,
};

export default CostCenterDialog;

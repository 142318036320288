import PropTypes from "prop-types";
import { noop } from "lodash";
import ToolsLicenceTable from "./ToolsLicenceTable";

const ToolsLicenceTab = ({
  totalTableRowsCount,
  totalPageCount,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  allTableRows = [],
  handleOpenContractHistory = noop,
  refreshOnStatusChange = noop,
  //   handleExport = noop,
}) => (
  <ToolsLicenceTable
    allTableRows={allTableRows}
    totalTableRowsCount={totalTableRowsCount}
    totalPageCount={totalPageCount}
    page={page}
    rowsPerPage={rowsPerPage}
    onPageChange={onPageChange}
    onRowsPerPageChange={onRowsPerPageChange}
    handleOpenContractHistory={handleOpenContractHistory}
    refreshOnStatusChange={refreshOnStatusChange}
  />
);

ToolsLicenceTab.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalPageCount: PropTypes.number,
  totalTableRowsCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  allTableRows: PropTypes.array,
  handleOpenContractHistory: PropTypes.array,
  handleExport: PropTypes.func,
};

export default ToolsLicenceTab;

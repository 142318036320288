import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadLicense: build.mutation({
      query: ({ file }) => ({
        url: `/tools/license/import`,
        body: file,
        method: "POST",
      }),
    }),
  }),
});

export const { useUploadLicenseMutation } = extendedApi;

import { createSlice } from "@reduxjs/toolkit";

const interviewTrackerFilterSlice = createSlice({
  name: "InterviewTrackerFilterSlice",
  initialState: {
    storedFilters: {
      companyName: "",
      reportingManager: "",
      bytManager: "",
      status: "",
    },
  },
  reducers: {
    interviewTrackerFilterStore: (state, action) => {
      state.storedFilters = action.payload.storedFilters;
      // state.storedStartDate = action.payload.storedStartDate;
      // state.storeEndDate = action.payload.storeEndDate;
    },
  },
});
export const { interviewTrackerFilterStore } = interviewTrackerFilterSlice.actions;

export default interviewTrackerFilterSlice.reducer;

import { Box } from "@mui/system";
import { useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "lodash";
import { Paper, Typography } from "@mui/material";
import T from "T";
import { handleError } from "utils/error";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SwotListTable from "./SwotTableList";
import { useLazyGetUserByIdQuery } from "api/members/getUserById";
import MISLoader from "components/common/MISLoader";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import MISButton from "components/common/MISButton";
import ActivityLog from "./ActivityLog";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useLazyGetSwotLogQuery } from "api/members/getSwotLog";
import { PAGINATION } from "settings/constants/pagination";
import AssignedCourseDialog from "components/Training/AssignedTraining/AssignedCourseDialog";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const SwotAnalysis = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    fullName: "",
    swotFilterData: [],
    openModal: false,
    openTrainingDialog: false,
    swotLog: [],
    page: INITIAL_PAGE,
    size: ROWS_PER_PAGE,
    totalPageCount: INITIAL_PAGE,
    totalTableRowsCount: INITIAL_PAGE,
    swotId: null,
  });
  const {
    fullName,
    swotAnalysisResponseDto,
    openModal,
    swotLog,
    page,
    size,
    totalPageCount,
    totalTableRowsCount,
    openTrainingDialog,
    swotId,
  } = localState;

  const url = location.pathname;
  const id = Number(url.replace(/\D+/g, ""));
  const [getUserById, { isFetching: isUserFetching }] = useLazyGetUserByIdQuery();
  const [getSwotLog] = useLazyGetSwotLogQuery();

  const refreshPage = () => {
    getUserById({ id })
      .unwrap()
      .then((res) => {
        const results = get(res, "results", []);
        const fullName = get(results, "userName", "");
        const swotAnalysisResponseDto = get(results, "swotAnalysisResponseDto", null);
        setLocalState({ fullName, swotAnalysisResponseDto });
      })
      .catch(handleError);
  };
  const handleFetchSwotLog = () => {
    getSwotLog({ userId: id, page, size })
      .unwrap()
      .then((res) => {
        const results = get(res, "results", []);
        setLocalState({
          swotLog: results,
          totalTableRowsCount: get(res, "numberOfRecords", 0),
          totalPageCount: get(res, "numberOfPages", 0),
        });
      })
      .catch(handleError);
  };

  useEffect(() => {
    refreshPage();
  }, []);

  useEffect(() => {
    handleFetchSwotLog();
  }, [page, size]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleEditSWOT = () => {
    navigate(`/app/member/swot-analysis/form/edit/${id}`, { state: { swotAnalysisResponseDto } });
  };

  const handleToggleDialog = () => {
    setLocalState({ openModal: !openModal });
  };
  const handleAddSWOT = () => {
    navigate(`/app/member/swot-analysis/form/${id}`);
  };
  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, size: value });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };
  const handleAssignedCourseDialog = (swotId) => {
    setLocalState({ openTrainingDialog: !openTrainingDialog, swotId: swotId });
  };
  return (
    <Paper display="block" sx={{ p: 2 }}>
      {isUserFetching && <MISLoader />}
      <Box display="flex" mb={1} justifyContent={"space-between"}>
        <Box display="flex" alignItems="center">
          <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack} />
          <Typography variant="h6" fontWeight={700} ml={1}>
            {`${fullName}'s ${T.SWOT_ANALYSIS} `}
          </Typography>
        </Box>
        <Box display="flex" justifyContent={"flex-end"} alignItems="center">
          {swotAnalysisResponseDto && swotAnalysisResponseDto.length > 0 ? (
            <>
              {swotLog.length > 0 && (
                <MISButton sx={{ mr: 2 }} size="medium" startIcon={<DescriptionOutlinedIcon />} onClick={handleToggleDialog}>
                  {T.ACTIVITY_LOG}
                </MISButton>
              )}
              <MISButton size="medium" startIcon={<EditIcon />} onClick={handleEditSWOT}>
                {T.EDIT}
              </MISButton>
            </>
          ) : (
            <MISButton size="medium" startIcon={<AddIcon />} onClick={handleAddSWOT}>
              {T.SWOT_ANALYSIS}
            </MISButton>
          )}
        </Box>
      </Box>
      <SwotListTable
        tableRecord={swotAnalysisResponseDto}
        handleEditSWOT={handleEditSWOT}
        handleAssignedCourseDialog={handleAssignedCourseDialog}
      />
      <ActivityLog
        handleToggleDialog={handleToggleDialog}
        openModal={openModal}
        swotLog={swotLog}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
        page={page}
        size={size}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
      <AssignedCourseDialog
        selectedUserName={fullName}
        isDialogOpen={openTrainingDialog}
        handleAssignedCourseDialog={handleAssignedCourseDialog}
        swotId={swotId}
        userId={id}
        refreshPage={refreshPage}
      />
    </Paper>
  );
};
export default SwotAnalysis;

import PropTypes from "prop-types";
import noop from "lodash/noop";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { Typography, Grid, IconButton, Dialog, DialogTitle, DialogContent, Box, Tooltip, Paper } from "@mui/material";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'; // example icon
import MISDialog from "components/common/MISDialog";
import T from "T";
import { get } from "utils/lodash";
import { GET_SIZE } from "utils/responsive";
import { handleError } from "utils/error";
import { useState } from "react";
import { ScreenshotMonitorSharp } from "@mui/icons-material";
import MISNoData from "components/common/MISNoData";


const ScreenshotListDialog = ({
	handleRecordingDialog = noop,
	handleScreenshotDialog = noop,
	openScreenshotDialog = false,
	screenShotData = [],
	recordingUser = ""
}) => {
	const { isMd, isLg } = GET_SIZE();
	const handleScreenshotClick = async (video) => {
		window.open(video, "_blank");
	};
	return (
		<Dialog
			open={openScreenshotDialog}
			onClose={() => handleScreenshotDialog("", [])}
			maxWidth={isLg ? "lg" : isMd ? "md" : "sm"}
			fullWidth
		>
			<DialogTitle>
				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item xs={3} display="flex" alignItems="center">
						<ScreenshotMonitorSharp sx={{ fontSize: 30, color: BACKGROUND.black, mr: 1 }} />
						<Typography
							variant="body1"
							sx={{ fontWeight: 650, fontSize: 20, color: BACKGROUND.black }}
						>
							Screenshots
						</Typography>
					</Grid>
					<Grid item xs display="flex" justifyContent="center">
						<Typography
							variant="body1"
							textAlign="center"
							sx={{ color: BACKGROUND.black, fontSize: 30, fontWeight: 650 }}
						>
							{recordingUser}
						</Typography>
					</Grid>

					<Grid item xs={3}></Grid>
				</Grid>
			</DialogTitle>

			<DialogContent>
				<Grid
					container
					spacing={3}
					justifyContent="center"
					alignItems="center"
					style={{ marginLeft: "0px", width: "100%" }}
				>
					{screenShotData?.length > 0 ? (
						screenShotData.map((record, index) => {
							const screenshot = get(record, "viewScreenshot", "");
							// const question = get(record, "question", "");

							return (
								<Grid
									item
									xs={12}
									sm={6}
									md={4}
									lg={2.4}
									key={index}
									display="flex"
									justifyContent="center"
									alignItems="center"
									sx={{ textAlign: "center", paddingLeft: "0px !important" }}
								>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											p: 2,
											textAlign: "center",
											width: "100%",
										}}
									>
										<Paper
											component="img"
											elevation={4}
											src={screenshot}
											// alt={question}
											onClick={() => handleScreenshotClick(screenshot)}
											sx={{
												maxWidth: "100%",
												maxHeight: 200,
												cursor: "pointer",
												objectFit: "contain",
												// border: `3px solid ${NETSMARTZ_THEME_COLOR}`,
												display: "block",
											}}
										/>
									</Box>
								</Grid>
							);
						})
					) :
						// (
						// 	<Typography
						// 		variant="body1"
						// 		align="center"
						// 		sx={{ color: BACKGROUND.black }}
						// 	>
						// 		No screenshots available.
						// 	</Typography>
						// )
						<Grid item xs={12}>

							<MISNoData />
						</Grid>
					}
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

ScreenshotListDialog.propTypes = {
	openRecordingDialog: PropTypes.bool,
	openScreenshotDialog: PropTypes.bool,
	confrmCancelModal: PropTypes.func,
	handleScreenshotDialog: PropTypes.func,
	screenShotData: PropTypes.array,
	recordingUser: PropTypes.string,
};

export default ScreenshotListDialog;

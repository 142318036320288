import { Children, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, Typography, TableBody, styled } from "@mui/material";
import { get } from "utils/lodash";
import memo from "utils/react";

import T from "T";
import { BACKGROUND } from "theme/colors";
import { handleError } from "utils/error";
import { useLazyGetActiveJiraByDateQuery } from "api/Jira/getActiveJiraByDate";
import MISNoData from "components/common/MISNoData";
import { NC_STATUS_LIST } from "settings/constants/members";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 24px",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));
const activeNcStatus = [0, 1, 2];
const ActiveNcTableBody = ({ userId = "", selectedMonth = "", endDate = "", startDate = "", typeOfNc = "" }) => {
  const [getActiveJiraByDate] = useLazyGetActiveJiraByDateQuery();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getUserJiraData();
  }, []);
  const getUserJiraData = () => {
    const payload = { id: userId, month: selectedMonth, endDate, startDate, status: activeNcStatus, type: typeOfNc };
    getActiveJiraByDate(payload)
      .unwrap()
      .then((res) => {
        setTableData(get(res, "results", ""));
      })
      .catch(handleError);
  };
  return (
    <TableBody>
      {Children.toArray(
        tableData.map((record, index) => (
          <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
            <StyledTableCell>{index + 1}.</StyledTableCell>
            <StyledTableCell>{get(record, "dateOfNc", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "projectName", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "projectManager", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "reportingManager", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "functionalHead", "-")}</StyledTableCell>
            <StyledTableCell align="center">{get(record, "typeOfNc", "-")}</StyledTableCell>
            <StyledTableCell align="center">
              {NC_STATUS_LIST.find((item) => item.id === get(record, "ncStatus", 0)).label}
            </StyledTableCell>
            {typeOfNc === T.JIRA_NOT_FILLED && (
              <StyledTableCell align="center">{get(record, "timeSpentHours", "-")}</StyledTableCell>
            )}
          </StyledTableRow>
        )),
      )}

      {tableData && tableData.length === 0 && (
        <TableRow>
          <StyledTableCell colSpan="10%" sx={{ border: "none" }}>
            <MISNoData />
          </StyledTableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

ActiveNcTableBody.propTypes = {
  userId: PropTypes.number,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  typeOfNc: PropTypes.string,
};

export default memo(ActiveNcTableBody);

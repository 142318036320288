import PropTypes from "prop-types";
import { Table as TableView, Paper, TableContainer, Box, Card, Typography } from "@mui/material";

import { MEMBER_FEEDBACK_TABLE } from "settings/constants/members";

import T from "T";
import FeedbackHistoryBody from "./FeedbackHistoryTablebody";
import FeedbackHistoryHeader from "./FeedbackHistoryHeader";

const FeedbackHistory = ({ tableRecord = [] }) => (
  <Card elevation={2} sx={{ p: 2, mt: 3 }}>
    <Typography fontSize={18} fontWeight={600} mb={1.5}>
      {T.FEEDBACK_HISTORY}
    </Typography>
    <Box overflow="hidden">
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "300px",
          width: "inherit",
          overflowY: "auto",
        }}
      >
        <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
          <FeedbackHistoryHeader columns={MEMBER_FEEDBACK_TABLE} />
          <FeedbackHistoryBody tableRecord={tableRecord} />
        </TableView>
      </TableContainer>
    </Box>
  </Card>
);

FeedbackHistory.propTypes = {
  tableRecord: PropTypes.array,
};
export default FeedbackHistory;

import T from "T";
import { toast } from "react-toastify";
import { loginStore } from "slices/loginSlice";
import { store } from "providers/store";
import {
  manageMockInterviewUserLogin,
  mockInterviewInstructionModal,
  mockInterviewResponseArrStore,
  mockInterviewStatus,
  mockInterviewStore,
  mockInterviewStore1,
  setStartTime,
  updateTimeLeft,
} from "slices/mockInterviewSlice";
import { loadingStore } from "slices/loadingSlice";

export const handleLogout = async (msg = T.LOGOUT_SUCCESS) => {
  store.dispatch(loadingStore({ storedLoading: true }));
  await new Promise((resolve) => {
    setTimeout(() => {
      store.dispatch(loginStore({ token: "", user: {} }));
      resolve();
    }, 0);
  });
  store.dispatch(mockInterviewStore({ mockResponses: [] }));
  store.dispatch(mockInterviewStore1({ formSubmitted: false }));
  store.dispatch(manageMockInterviewUserLogin({ mockInterviewUserRegistered: false }));
  store.dispatch(mockInterviewResponseArrStore({ responseArr: [] }));
  store.dispatch(mockInterviewInstructionModal({ openInstructionModal: false }));
  store.dispatch(setStartTime({ startTime: null }));
  store.dispatch(updateTimeLeft({ timeLeft: 20 * 60 }));
  store.dispatch(mockInterviewStatus({ isMockInterviewAssigned: null }));
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = "/app/login";
  toast.success(msg);
};

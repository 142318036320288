import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllMockInterviews: builder.query({
      query: () => ({
        url: `ai/mock/interview/getAllMockInterviews`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetAllMockInterviewsQuery } = extendedApi;

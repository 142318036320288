import PropTypes from "prop-types";
import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import { PRE_SALES_REPORT_CARD_HEADER } from "settings/constants/members";
import MasterPagination from "components/MasterSetting/MasterPagination";
import MISNoData from "components/common/MISNoData";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Table = ({ allTableRows, page, onPageChange, rowsPerPage, onRowsPerPageChange, totalTableRowsCount, totalPageCount }) => {
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mx: 1.4,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          sx={{
            height: "calc(100vh - 430px)",
            overflowY: "scroll",
          }}
        >
          {allTableRows.length !== 0 ? (
            <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
              <TableHeader columns={PRE_SALES_REPORT_CARD_HEADER} />
              <TableBody allTableRows={allTableRows} columns={PRE_SALES_REPORT_CARD_HEADER} />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
      </Box>
      <MasterPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

Table.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  totalTableRowsCount: PropTypes.number,
  allTableRows: PropTypes.array,
  handleJdDialog: PropTypes.func,
  handleDeleteProjectDialog: PropTypes.func,
};
export default Table;

import MISDialog from 'components/common/MISDialog'
import MISTextField from 'components/common/MISTextField'
import {
    Typography,
    Grid,
} from "@mui/material";
import MISFooterButton from 'components/common/MISFooterButton';
import T from 'T';
import { noop } from 'lodash';
const PortfolioDialog = ({ handleSubmitPortal = noop, confrmCancelPortModal = noop, onHandleChange = noop, portfolioLink, openPortfolioLink, disableCondition }) => {

    return (
        <MISDialog open={openPortfolioLink}>
            <Typography variant="h6" fontWeight={500} textAlign="center" p="5px 30px" mt={2}>
                {T.USER_PORTFOLIO}
            </Typography>

            <Grid container sx={{ p: 2 }} spacing={2}>
                <Grid item xs={12} sx={{ pt: 4 }}>
                    <MISTextField
                        label={`${T.PORTFOLIO} `}
                        fullWidth
                        placeholder={`${T.USER_PORTFOLIO} `}
                        size="small"
                        required
                        disabled={disableCondition()}
                        value={portfolioLink}
                        variant="outlined"
                        onChange={onHandleChange}
                        name="portfolioLink"
                        autoFocus
                    />
                </Grid>
            </Grid>
            <MISFooterButton
                proceedButtonText={T.PROCEED}
                justify="center"
                size="medium"
                sx={{ mb: 2 }}
                handleClose={() => {
                    confrmCancelPortModal();
                }}
                handleSubmit={() => {
                    handleSubmitPortal(portfolioLink);
                }}
            />

        </MISDialog>
    )
}

export default PortfolioDialog

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSwotLog: builder.query({
      query: ({ userId, page, size }) => ({
        url: `/swotAnalysis/employee/find/swotHistory`,
        params: { userId, page, size },
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetSwotLogQuery } = extendedApi;

import { ArrowBack } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from "@mui/material";
import T from "T";
import MISButton from "components/common/MISButton";
import PropTypes from "prop-types";
import { GET_SIZE } from "utils/responsive";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { noop } from "lodash";
import { get } from "utils/lodash";
import SearchBar from "components/Members/SearchBar";

const ToolsTable = ({
  toolsSearch = "",
  columns = [],
  toolsList = [],
  isOpenToolsDialog = bool,
  openAddEditToolsMutation = noop,
  handleClose = noop,
  handleDeleteTools = noop,
  handleSearchChange = noop,
  handleSearchKeyChange = noop,
}) => {
  const { isMd, isLg } = GET_SIZE();
  return (
    <Dialog onClose={handleClose} open={isOpenToolsDialog} maxWidth={isLg ? "lg" : isMd ? "md" : "sm"}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            <IconButton onClick={handleClose}>
              <ArrowBack />
            </IconButton>
            {T.ADD_EDIT_TOOLS}
          </Box>
          <Grid item md={2.5} xs={12}>
            <SearchBar searchInput={toolsSearch} handleChange={handleSearchChange} handleKeyChange={handleSearchKeyChange} />
          </Grid>
          <MISButton onClick={() => openAddEditToolsMutation()} startIcon={<AddIcon sx={{ width: 19 }} />}>
            {T.NEW}
          </MISButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: "inherit" }}>
        <TableContainer component={Paper} sx={{ maxHeight: "calc(100svh - 350px)", overflow: "auto" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                {columns &&
                  columns.length &&
                  columns.map((column, index) => {
                    return <TableCell key={column.key}>{column.label}</TableCell>;
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {toolsList.map((tools, index) => (
                <TableRow key={tools.toolsId} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  {columns.map((column) => (
                    <TableCell key={`${tools.toolsId}-${column.key}`}>
                      {column.key === "actions" ? (
                        <>
                          <IconButton
                            sx={{ color: NETSMARTZ_THEME_COLOR }}
                            onClick={() => {
                              openAddEditToolsMutation(tools.toolsId);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            sx={{ color: NETSMARTZ_THEME_COLOR }}
                            onClick={() => {
                              handleDeleteTools(tools.toolsId);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      ) : column.key === "id" ? (
                        index + 1
                      ) : column.key === "createdBy" ? (
                        get(tools[column.key], "userName", "")
                      ) : (
                        tools[column.key]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

ToolsTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  toolsList: PropTypes.arrayOf(PropTypes.object),
  isOpenToolsDialog: PropTypes.bool,
  openAddEditToolsMutation: PropTypes.func,
  handleClose: PropTypes.func,
  handleDeleteTools: PropTypes.func,
};

export default ToolsTable;

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFeedbackById: builder.query({
      query: (data) => ({
        url: `/feedback/employee/find?userId=${data.userId}&projectManagerId=${data.pmId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetFeedbackByIdQuery } = extendedApi;

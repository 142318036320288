import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography, Box, IconButton, Tooltip } from "@mui/material";
import memo from "utils/react";
import { get } from "utils/lodash";
import { MISCurrentUser } from "utils/validations";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import T from "T";
import { camelCase, noop } from "lodash";

const CSVersionTableHeader = ({columns=[]}) => {
  return (
    <TableHead>
      <TableRow>
        {Children.toArray(
          columns.map((column, index) => {
            return (
              <TableCell
                align={get(column, "align", "")}
                sx={{
                  border: "none",
                  backgroundColor: BACKGROUND.cardDefault,
                  padding: 1,
                }}
              >
                <Typography variant="body1" fontSize={14} noWrap fontWeight={600}>
                  {column.label}
                </Typography>
              </TableCell>
            );
          }),
        )}
      </TableRow>
    </TableHead>
  );
};

export default CSVersionTableHeader;

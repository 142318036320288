import { useEffect, useReducer, useState } from "react";
import { Paper, ListItem, ListItemText, Checkbox, List, Button, Typography, DialogContent } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { StyledTab } from "components/MasterSetting";
import T from "T";
import { Box } from "@mui/system";
import { useSaveDeveloperChecklistMutation } from "api/pitchedCandidates/saveDeveloperChecklist";
import { handleError } from "utils/error";
import { useLocation, useNavigate } from "react-router-dom";
import { useSaveManagerChecklistMutation } from "api/pitchedCandidates/saveManagerChecklist";
import { useLazyGetCandidateChecklistQuery } from "api/pitchedCandidates/getCandidateChecklist";
import { useLazyGetManagerChecklistQuery } from "api/pitchedCandidates/getManagerChecklist";
import MISDialog from "components/common/MISDialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toast } from "react-toastify";
import MISFooterButton from "components/common/MISFooterButton";
import { useLazyGetInterviewDetailsQuery } from "api/pitchedCandidates/getInterviewDetails";
import { isEqual } from "lodash";
import { Info } from "@mui/icons-material";
import PropTypes from "prop-types";

const initialDeveloperChecklist = {
  resumeStatus: false,
  techStackAwareness: false,
  projectsAndSkillsAwareness: false,
  interviewInviteReceived: false,
  interviewToolsDownloaded: false,
  systemSetupCompleted: false,
  zoomBackgroundSet: false,
  zoomBackgroundLink: false,
  interviewPreparationCompleted: false,
  techStackHandsOn: false,
  mockInterviewCompleted: false,
  projectOfferingsReviewed: false,
  stationaryReady: false,
};

const initialManagerChecklist = {
  dressedProfessionally: false,
  mobileSilentMode: false,
  noChewingGumOrEating: false,
  cleanTaskbar: false,
  politeAndConfident: false,
  smileAndGreet: false,
  goodPosture: false,
  noMisconduct: false,
  efficientInterview: false,
  honestAndAuthentic: false,
  prepareQuestions: false,
  noOneWordedAnswers: false,
  workWithPM: false,
  updateMasterSheet: false,
  provideFeedback: false,
};

const InterviewChecklist = ({ disableFirstTab, disableSecondTab, defaultTab }) => {
  const [tabValue, setTabValue] = useState(defaultTab);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [candidateChecklistVisible, setCandidateChecklistVisible] = useState();
  const [candidateSubmit, setCandidateSubmit] = useState(true);
  const [managerSubmit, setManagerSubmit] = useState(false);
  const [saveDeveloperChecklist] = useSaveDeveloperChecklistMutation();
  const [saveManagerChecklist] = useSaveManagerChecklistMutation();
  const [getCandidateChecklist] = useLazyGetCandidateChecklistQuery();
  const [getManagerChecklist] = useLazyGetManagerChecklistQuery();
  const [getInterviewDetails] = useLazyGetInterviewDetailsQuery();
  const location = useLocation();
  const id = location.state ? location.state.id : null;
  const jdId = location.state ? location.state.jdId : null;
  const managerChecklistVisible = location.state ? location.state.managerChecklistVisible : null;
  const navigate = useNavigate();

  const getQueryParameter = (name) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  };

  const userId = getQueryParameter("userId");
  const presalesMappingId = getQueryParameter("presalesMappingId");

  const [developerChecklist, setDeveloperChecklist] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    initialDeveloperChecklist,
  );

  const [managerChecklist, setManagerChecklist] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    initialManagerChecklist,
  );
  useEffect(() => {
    if (userId && presalesMappingId) {
      getInterviewDetails({ preSalesMappingId: presalesMappingId, userId })
        .unwrap()
        .then((res) => {
          const currentDateTime = new Date();
          const interviewDate = res ? res.interviewDate : null;
          const interviewTime = res ? res.interviewTime : null;
          const interviewDateTime = new Date(interviewDate + " " + interviewTime);
          if (interviewDateTime < currentDateTime && !candidateSubmit) {
            toast.error("Candidate can't fill out this checklist after the interview.", { autoClose: 5000 });
          }
          setCandidateChecklistVisible(currentDateTime < interviewDateTime);
        })
        .catch((error) => {
          handleError(error);
        });
    }
  }, [userId, presalesMappingId, candidateSubmit]);

  useEffect(() => {
    getChecklist();
  }, [userId, presalesMappingId, id, jdId]);

  const getChecklist = () => {
    const queryUserId = userId || id;
    const queryPresalesMappingId = presalesMappingId || jdId;
    if (queryUserId && queryPresalesMappingId) {
      getCandidateChecklist({ userId: queryUserId, presalesMappingId: queryPresalesMappingId })
        .unwrap()
        .then((result) => {
          setCandidateSubmit(true);
          setDeveloperChecklist(result);
        })
        .catch((error) => {
          handleError(error);
          setCandidateSubmit(false);
        });

      getManagerChecklist({ userId: queryUserId, presalesMappingId: queryPresalesMappingId })
        .unwrap()
        .then((result) => {
          setManagerSubmit(true);
          setManagerChecklist(result);
        })
        .catch((error) => {
          if (tabValue === "2") {
            toast.info("The checklist must be completed within 24 hours after the interview", { autoClose: 5000 });
          }
          handleError(error);
        });
    }
  };

  const devChecklistItems = [
    {
      label: "resumeStatus",
      value: "Your resume is updated in the correct format and reviewed.",
    },
    {
      label: "techStackAwareness",
      value: "You are aware of the tech stacks and the experience that are pitched to the end customer.",
    },
    {
      label: "projectsAndSkillsAwareness",
      value: "You are aware of all projects and skills listed in the resume.",
    },
    {
      label: "interviewInviteReceived",
      value: "You have the invite for the interview.",
    },
    {
      label: "interviewToolsDownloaded",
      value: "You have downloaded necessary Tool/Software/Plugin required for Interview support. [Remote]",
    },
    {
      label: "systemSetupCompleted",
      value:
        "You have set up your system and tested joining with Zoom / GTM / Skype / WebEx / phone number details (based on the invite), at least 45 minutes before the interview. You have ensured that the mic and camera are working fine.",
    },
    {
      label: "zoomBackgroundSet",
      value: "You have completed the system set-up in a neat & quite room, with ample lighting. [Remote]",
    },
    {
      label: "zoomBackgroundLink",
      value: (
        <span>
          You have set up the zoom background housed on{" "}
          <a
            href="https://netsmartzllc.sharepoint.com/:i:/s/BYT929-Strategy/ETkV8_CTsLBBo_cVM-GohUIBJh40GQdar9ZR7kU-uXyH8w?e=yofauQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            this link
          </a>
          .
        </span>
      ),
    },
    {
      label: "interviewPreparationCompleted",
      value:
        "You have reviewed and covered the Code Tests, algorithmic tests or the topics that the PM had shared before the interview.",
    },
    {
      label: "techStackHandsOn",
      value: "You are hands on to the subject/tech stack for which you are being interviewed for.",
    },
    {
      label: "mockInterviewCompleted",
      value: "You have done a query session/mock interview session with the PM conducting the interview.",
    },
    {
      label: "projectOfferingsReviewed",
      value: "You have reviewed the client's current project offerings or the details about the company.",
    },
    {
      label: "stationaryReady",
      value: "You have the basic stationary (notepad/pen) ready beforehand.",
    },
  ];

  const managerChecklistItems = [
    {
      label: "dressedProfessionally",
      value: "Candidate is dressed professionally (wears Netsmartz T-shirt or formal attire before the interview).",
    },
    {
      label: "mobileSilentMode",
      value: "Candidate has mobile and other sound-emitting devices on silent mode.",
    },
    {
      label: "noChewingGumOrEating",
      value: "Candidate should not chew gum, eat, or drink during the interview.",
    },
    {
      label: "cleanTaskbar",
      value: "Candidate keeps the system’s Taskbar clean with the least or no files open (for remote interviews).",
    },
    {
      label: "politeAndConfident",
      value: "Candidate speaks in a polite and kind, but confident tone.",
    },
    {
      label: "smileAndGreet",
      value: "Candidate smiles, greets, listens, and interacts with the interviewer.",
    },
    {
      label: "goodPosture",
      value: "Candidate sits with good posture and leans slightly forward.",
    },
    {
      label: "noMisconduct",
      value: "Candidate should not engage in misconduct during the interview, especially no cheating in remote interviews.",
    },
    {
      label: "efficientInterview",
      value: "Candidate should be efficient and close the assessment on or before the scheduled time.",
    },
    {
      label: "honestAndAuthentic",
      value: "Candidate should be honest and authentic throughout the interview.",
    },
    {
      label: "prepareQuestions",
      value:
        "Candidate should always end up with reasonable questions for the interviewer, prepared in advance, and thank them afterward.",
    },
    {
      label: "noOneWordedAnswers",
      value: "Candidate should not provide one-worded answers and should engage in meaningful responses.",
    },
    {
      label: "workWithPM",
      value: "Candidate should work with the PM to fill up the questions in the BYT playbook.",
    },
    {
      label: "updateMasterSheet",
      value: "PM should update the report in the Master sheet for interviews.",
    },
    {
      label: "provideFeedback",
      value:
        "PM should give written feedback to the candidate in case of rejection and advise on the points that need improvement (if applicable).",
    },
  ];

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const toggleChecklistItem = (itemName, isManagerTab) => {
    if (isManagerTab) {
      setManagerChecklist({ [itemName]: !managerChecklist[itemName] });
    } else {
      setDeveloperChecklist({ [itemName]: !developerChecklist[itemName] });
    }
  };

  const handleSubmit = (checklistType) => {
    if (checklistType === "developer") {
      saveDeveloperChecklist({
        userId: userId,
        presalesMappingId: presalesMappingId,
        developerChecklist,
      }).then((res) => {
        if (res.error) {
          handleError(res.error);
        } else {
          setDialogOpen(true);
        }
      });
    } else if (checklistType === "manager") {
      saveManagerChecklist({
        userId: id,
        presalesMappingId: jdId,
        managerChecklist,
      }).then((res) => {
        if (res.error) {
          handleError(res.error);
        } else {
          toast.success(T.RESPONSE_SUBMITTED_SUCCESSFULLY);
          navigate(-1);
        }
      });
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    getChecklist();
  };

  const openConfirmationDialog = () => {
    setConfirmationDialogOpen(true);
  };

  const handleConfirmSubmit = () => {
    if (tabValue === "1") {
      handleSubmit("developer");
    } else if (tabValue === "2") {
      handleSubmit("manager");
    }
    setConfirmationDialogOpen(false);
  };

  const canSubmit = () => {
    if (tabValue === "1") {
      return isEqual(developerChecklist, initialDeveloperChecklist);
    } else if (tabValue === "2") {
      return isEqual(managerChecklist, initialManagerChecklist);
    }
  };

  return (
    <Paper sx={{ borderRadius: 2, maxHeight: "100vh", width: "100%" }}>
      <TabContext value={tabValue}>
        <TabList
          variant="fullWidth"
          indicatorColor="false"
          onChange={handleChangeTab}
          aria-label="lab API tabs example"
          sx={{
            "& .MuiTab-root.Mui-selected": {
              color: "white",
              backgroundColor: NETSMARTZ_THEME_COLOR,
            },
            "& .MuiTab-root": {
              fontWeight: 600,
            },
          }}
        >
          <StyledTab sx={{ borderTopLeftRadius: "10px" }} label={T.CANDIDATE.toUpperCase()} value="1" />
          <StyledTab label={T.INTERVIEW_COORDINATOR.toUpperCase()} value="2" disabled={disableSecondTab} />
        </TabList>

        {/* Developer Tab */}

        <TabPanel sx={{ px: "16px", pt: 2, pb: 2 }} value="1">
          <Box
            sx={{ backgroundColor: "background.card", height: "calc(100vh - 262px)", overflow: "auto", p: 1, borderRadius: 2 }}
          >
            <Box sx={{ color: "gray", opacity: 0.7, display: "flex", alignItems: "center" }}>
              <Info sx={{ ml: 3.2 }} />
              <Typography ml={1} fontSize="14px">
                {T.CHECKLIST_CANDIDATE_WARNING}
              </Typography>
            </Box>
            <List>
              {devChecklistItems.map((item, index) => {
                const itemName = item.label;
                return (
                  <Paper
                    sx={{
                      p: 1,
                      px: 2,
                      mb: 1,
                      borderRadius: 0,
                      boxShadow:
                        "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0.8px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)",
                    }}
                    key={index}
                  >
                    <ListItem sx={{ backgroundColor: BACKGROUND.white }} disablePadding>
                      <Checkbox
                        checked={developerChecklist[itemName]}
                        onChange={() => toggleChecklistItem(itemName, false)}
                        inputProps={{ "aria-label": item }}
                        disabled={disableFirstTab || !candidateChecklistVisible || candidateSubmit}
                        style={{
                          opacity: disableFirstTab || !candidateChecklistVisible || candidateSubmit ? 0.45 : 1,
                          color: disableFirstTab || !candidateChecklistVisible || candidateSubmit ? "gray" : "inherit",
                        }}
                      />
                      <ListItemText primaryTypographyProps={{ fontSize: "14px" }} sx={{ px: 1 }} primary={item.value} />
                    </ListItem>
                  </Paper>
                );
              })}
            </List>
          </Box>
        </TabPanel>

        {/* Manager Tab*/}
        <TabPanel sx={{ px: "16px", pt: 2, pb: 2 }} value="2">
          <Box
            sx={{
              backgroundColor: BACKGROUND.card,
              height: "calc(100vh - 262px)",
              overflow: "auto",
              p: 1,
              borderRadius: 2,
            }}
          >
            <List>
              {managerChecklistItems.map((item, index) => {
                const itemName = item.label;
                return (
                  <Paper
                    sx={{
                      p: 1,
                      px: 2,
                      mb: 1,
                      borderRadius: 0,
                      boxShadow:
                        "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0.8px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)",
                    }}
                    key={index}
                  >
                    <ListItem sx={{ backgroundColor: BACKGROUND.white }} disablePadding>
                      <Checkbox
                        checked={!!managerChecklist[itemName]}
                        onChange={() => toggleChecklistItem(itemName, true)}
                        inputProps={{ "aria-label": item }}
                        disabled={!managerChecklistVisible || managerSubmit}
                        style={{
                          opacity: !managerChecklistVisible || managerSubmit ? 0.45 : 1,
                          color: !managerChecklistVisible || managerSubmit ? "gray" : "inherit",
                        }}
                      />
                      <ListItemText primaryTypographyProps={{ fontSize: "14px" }} sx={{ px: 1 }} primary={item.value} />
                    </ListItem>
                  </Paper>
                );
              })}
            </List>
          </Box>
        </TabPanel>
      </TabContext>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{
            ml: 2,
            mr: 4,
            mb: 2,
            minWidth: 80,
            bgcolor: NETSMARTZ_THEME_COLOR,
            "&:hover": {
              bgcolor: NETSMARTZ_THEME_COLOR,
            },
            textTransform: "none",
          }}
          onClick={() => {
            openConfirmationDialog();
          }}
          disabled={
            (tabValue === "1" && (candidateSubmit || disableFirstTab)) || (tabValue === "2" && managerSubmit) || canSubmit()
          }
        >
          {T.SUBMIT}
        </Button>
      </Box>
      <MISDialog open={dialogOpen}>
        <Box m={1} mx={2}>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <IconButton onClick={() => handleClose()} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              p: 3,
              mb: 1,
              pt: 0,
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              width: 340,
              height: 160,
            }}
          >
            <CheckCircleIcon color="success" sx={{ fontSize: 55, mb: 2 }} />
            <Typography variant="h6" fontWeight={600} pb={1}>
              {T.SUCCESS}
            </Typography>
            <Typography fontSize={"16px"} variant="body1">
              {T.RESPONSE_SUBMITTED}
            </Typography>
            <Typography fontSize={"16px"} variant="body1">
              {T.RECIEVE_INTERVIEW_DETAILS}
            </Typography>
          </Box>
        </Box>
      </MISDialog>
      <MISDialog open={confirmationDialogOpen} onClose={() => setConfirmationDialogOpen(false)}>
        <DialogContent>
          <Typography mt={1}>{T.CONFIRM_SUBMIT_ASSESSMENT}</Typography>
          <MISFooterButton
            proceedButtonText="Yes"
            justify="center"
            size="small"
            sx={{ mt: 2 }}
            handleClose={() => {
              setConfirmationDialogOpen(false);
            }}
            handleSubmit={handleConfirmSubmit}
          />
        </DialogContent>
      </MISDialog>
    </Paper>
  );
};
InterviewChecklist.propTypes = {
  disableFirstTab: PropTypes.bool,
  disableSecondTab: PropTypes.bool,
  defaultTab: PropTypes.string,
};

export default InterviewChecklist;

import { get } from "./lodash";
import T from "T";
import { camelCase } from "lodash";

// For applying filters from API that is saved as a JSON string, storedFilters needs to be parsed
export const loadFetchedFilters = (storedFilters, filterData) => {
  const { projectManagers, workLocationList, skillList, projectList, departmentList } = filterData;
  const filters = {};

  const careerExp = get(storedFilters, "totalExp", "").split("-");
  const inHouseExp = get(storedFilters, "tenureExp", "").split("-");
  filters[camelCase(T.PRIMARY_SKILL)] = get(skillList, "results", []).filter((rec) =>
    get(storedFilters, "primarySkillName", []).includes(rec.skillName),
  );

  filters[camelCase(T.SECONDARY_SKILL)] = get(skillList, "results", []).filter((rec) =>
    get(storedFilters, "secondarySkillName", []).includes(rec.skillName),
  );

  filters[camelCase(T.WORK_LOCATION)] = get(workLocationList, "results", []).filter((rec) =>
    get(storedFilters, "workLocationName", []).includes(rec.workLocationName),
  );
  filters[camelCase(T.EMP_MODE)] = get(storedFilters, "empMode", "");
  filters[camelCase(T.EMP_STATUS)] = get(storedFilters, "empStatus", "");
  filters[camelCase(T.DEPARTMENT)] = get(departmentList, "results", []).filter((rec) =>
    get(storedFilters, "departmentName", []).includes(rec.departmentName),
  );
  filters[camelCase(T.PROJECT)] = get(projectList, "results", []).filter((rec) =>
    get(storedFilters, "projectName", []).includes(rec.name),
  );
  filters[camelCase(T.PROJECT_MODE)] = get(storedFilters, "projectMode", "");
  filters[camelCase(T.AVAILABILITY)] = getAvailabilityValById(get(storedFilters, "availability", ""));
  filters[camelCase(T.PROJECT_MANAGER)] = get(projectManagers, "results", []).filter((rec) =>
    get(storedFilters, "projectManagerName", []).includes(rec.name),
  );
  filters[camelCase(`${T.EXPERIENCE} (In Years) minExp`)] = careerExp[0] || "";
  filters[camelCase(`${T.EXPERIENCE} (In Years) maxExp`)] = careerExp[1] || "";
  filters[camelCase(`${T.TENURE_WITH_NETSMARTZ} minExp`)] = inHouseExp[0] || "";
  filters[camelCase(`${T.TENURE_WITH_NETSMARTZ} maxExp`)] = inHouseExp[1] || "";

  return filters;
};

export const getFilterPayload = (page, rowsPerPage, filtersApplied) => {
  const totalMinExp = get(filtersApplied, camelCase(`${T.EXPERIENCE} (In Years) minExp`), "");
  const totalMaxExp = get(filtersApplied, camelCase(`${T.EXPERIENCE} (In Years) maxExp`), "");
  const tenureMin = get(filtersApplied, camelCase(`${T.TENURE_WITH_NETSMARTZ} minExp`), "");
  const tenureMax = get(filtersApplied, camelCase(`${T.TENURE_WITH_NETSMARTZ} maxExp`), "");

  const payload = {
    primarySkillName: get(filtersApplied, camelCase(T.PRIMARY_SKILL), []).map((data) => data.skillName),
    secondarySkillName: get(filtersApplied, camelCase(T.SECONDARY_SKILL), []).map((data) => data.skillName),
    workLocationName: get(filtersApplied, camelCase(T.WORK_LOCATION), []).map((data) => data.workLocationName),
    departmentName: get(filtersApplied, camelCase(T.DEPARTMENT), []).map((data) => data.departmentName),
    empMode: get(filtersApplied, camelCase(T.EMP_MODE), ""),
    empStatus: get(filtersApplied, camelCase(T.EMP_STATUS), ""),
    projectName: get(filtersApplied, camelCase(T.PROJECT), []).map((data) => data.name),
    projectMode: get(filtersApplied, camelCase(T.PROJECT_MODE), ""),
    availability: getAvailabilityVal(get(filtersApplied, camelCase(T.AVAILABILITY), "")),
    projectManagerName: get(filtersApplied, camelCase(T.PROJECT_MANAGER), []).map((data) => data.name),
    totalExp: totalMinExp && totalMaxExp ? `${totalMinExp}-${totalMaxExp}` : "",
    tenureExp: tenureMin && tenureMax ? `${tenureMin}-${tenureMax}` : "",
    status: T.ACTIVE,
    page,
    size: rowsPerPage,
  };
  return payload;
};

const getAvailabilityVal = (availability) => {
  if (availability === T.FULL) {
    return 1;
  }
  if (availability === T.NO) {
    return 0;
  }
  if (availability === T.PARTIAL) {
    return 2;
  }
  return "";
};

const getAvailabilityValById = (availability) => {
  if (availability === 1) {
    return T.FULL;
  }
  if (availability === 0) {
    return T.NO;
  }
  if (availability === 2) {
    return T.PARTIAL;
  }
  return "";
};

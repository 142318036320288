import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    createGroup: build.mutation({
      query: (body) => ({
        url: "/group/save",
        method: "POST",
        body,
      }),
    }),
  }),
});
export const { useCreateGroupMutation } = extendedApi;

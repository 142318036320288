import { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { format, isValid } from "date-fns";
import { Box, DialogTitle, IconButton } from "@mui/material";
import MISDialog from "components/common/MISDialog";
// import { useLazyGetCourseListQuery } from "api/training/courseDetail/getCourseList";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { useAssignCourseMutation } from "api/training/assignCourse/assignUserCourse";
import { useLazyGetUserListQuery } from "api/members/getUserList";
import T from "T";
import { toast } from "react-toastify";
// import { PAGINATION } from "settings/constants/pagination";
import { get } from "utils/lodash";
import { handleError } from "utils/error";
import Form from "./Form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { handleConfiguratorDragEnd, handleConfigurationChange } from "data/configurator/subCourseSelector";
import usePMFetch from "hooks/usePMFetch";
import MISLoader from "components/common/MISLoader";
import { useAddSwotTrainingMutation } from "api/members/addSwotTraining";
// const { INITIAL_PAGE } = PAGINATION;
// const ROWS_PER_PAGE = 10000;

const AssignedCourseDialog = ({
  isDialogOpen = false,
  userId,
  handleAssignedCourseDialog = noop,
  selectedUserName = "",
  swotId,
  refreshPage,
}) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    course: "",
    courseId: NaN,
    startDate: null,
    priority: false,
    department: null,
    subCourses: [],
    selectedSubCourse: [],
    tableData: [],
    courseDuration: "",
    usersList: [],
    reviewerId: "",
    subCourseData: [],
  });
  const {
    courseId,
    priority,
    startDate,
    department,
    subCourses,
    selectedSubCourse,
    tableData,
    courseDuration,
    subCourseData,
    reviewerId,
    usersList,
  } = localState;

  // ==================================Getting Course List api===============================
  // const [getCourseList] = useLazyGetCourseListQuery();
  const [assignCourse, { isLoading }] = useAssignCourseMutation();
  const [getUserList] = useLazyGetUserListQuery();
  const { courseList, departmentList } = usePMFetch();
  const [addSwotTraining] = useAddSwotTrainingMutation();

  useEffect(() => {
    const { allTableRows } = getCurrentTableParams(courseList);
    setLocalState({
      tableData: allTableRows,
    });
    getUsersList();
  }, [courseList]);

  const getUsersList = () => {
    getUserList({ page: 0, size: 10000, status: T.ACTIVE })
      .unwrap()
      .then((res) => {
        const { allTableRows } = getCurrentTableParams(res);
        setLocalState({
          usersList: allTableRows,
        });
      })
      .catch(handleError);
  };

  const duration = tableData.find((course) => course.trainingCourseId === courseId);
  const courseType = duration ? get(duration.assignmentType, "courseAssignmentType", "") : {};
  // ============================Handle The state of Course and subCourse====================
  const handleChange = (event, newValue) => {
    const courseId = get(newValue, "id", NaN);
    const courseLabel = get(newValue, "label", "");
    const selectedCourse = get(
      tableData.find((course) => course.trainingCourseId === courseId),
      "subsCourses",
      [],
    );
    if (selectedCourse.length > 0) {
      const configData = selectedCourse.map((subCourse, index) => {
        return {
          id: subCourse.training_sub_course_id,
          position: index,
          label: subCourse.subsCourseName,
          checked: true,
        };
      });
      setLocalState({ subCourseData: configData });
    } else {
      setLocalState({ subCourseData: [] });
    }
    setLocalState({
      courseId: courseId,
      course: courseLabel,
      subCourses: courseId !== NaN ? selectedCourse : [],
      department: newValue
        ? get(departmentList, "results", []).find((item) => item.id === get(newValue, "department.id", ""))
        : null,
    });
  };

  const handleReviwer = (event, newValue) => {
    const reviewerID = get(newValue, "id", "");
    setLocalState({ reviewerId: reviewerID });
  };

  const handleDepartment = (event, newValue) => {
    setLocalState({ department: newValue });
  };

  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const onHandleDateChange = (newValue) => {
    const validDate = new Date(newValue);
    setLocalState({
      startDate: newValue && isValid(validDate) ? validDate : null,
    });
  };

  const handlePriority = (priorityValue) => {
    setLocalState({ priority: priorityValue });
  };

  const handleBack = () => {
    setLocalState({ courseId: "", subCourseData: [] });
    handleAssignedCourseDialog();
  };

  const handleAssign = () => {
    if (!courseId || !startDate) {
      toast.error(T.PLEASE_FILL_ALL_REQUIRED_FEILDS);
    } else if (courseType !== T.OFFLINE_TRAINING && subCourseData.every((record) => !record.checked)) {
      toast.error("Select Atleast One SubCourse");
    } else {
      let subCourse = [];
      subCourseData.forEach((req) => {
        if (req.checked) {
          subCourse.push({
            subCourseId: get(req, "id", ""),
          });
        }
      });
      const payload = {
        userIds: typeof userId === "number" ? [userId] : userId,
        trainingType: "Training",

        courseDetailsList: [
          {
            courseId,
            courseDuration: parseInt(duration.courseDuration),
            subCoursePriorityStatus: priority,
            reviewerId,
            subsCourses: subCourse,
            completionDate: getBEDateFormat(startDate),
          },
        ],
      };
      if (swotId) {
        addSwotTraining({ userId, swotId, payload })
          .unwrap()
          .then((res) => {
            setLocalState({ courseId: "", subCourseData: [] });
            toast.success(res.message);
            // toast.success(T.COURSE_ASSIGNED_SUCCESSFULLY);
            handleAssignedCourseDialog();
            refreshPage();
            setLocalState({
              course: "",
              courseId: NaN,
              startDate: null,
              priority: false,
              subCourses: [],
              selectedSubCourse: [],
              tableData: [],
              courseDuration: "",
              usersList: [],
              reviewerId: "",
              subCourseData: [],
            });
          })
          .catch((error) => {
            handleError(error);
          });
      } else {
        assignCourse(payload)
          .unwrap()
          .then((res) => {
            setLocalState({ courseId: "", subCourseData: [] });
            toast.success(res.message);
            // toast.success(T.COURSE_ASSIGNED_SUCCESSFULLY);
            handleAssignedCourseDialog();
          })
          .catch((error) => {
            handleError(error);
          });
      }
    }
  };

  const handleSubCourseSelect = (subCourseId) => {
    const isSelected = selectedSubCourse.includes(subCourseId);
    let updatedSelectedSubCourse;
    if (isSelected) {
      updatedSelectedSubCourse = selectedSubCourse.filter((id) => id !== subCourseId);
    } else {
      updatedSelectedSubCourse = courseId === "" ? [] : [...selectedSubCourse, subCourseId];
    }
    setLocalState({
      selectedSubCourse: updatedSelectedSubCourse,
    });
  };
  const handleOnDragEnd = (result) => {
    setLocalState({
      subCourseData: handleConfiguratorDragEnd({
        result,
        subCourseData,
      }),
    });
  };
  const handleConfChange = (index, type, confData) => {
    setLocalState({
      subCourseData: handleConfigurationChange({
        index,
        type,
        confData,
      }),
    });
  };
  const onHandleClose = () => {
    setLocalState({ courseId: NaN, startDate: null, reviewerId: NaN, department: null, subCourseData: [], subCourses: [] });
    handleAssignedCourseDialog();
  };
  // =======================================Get Course Id and Name=======================
  const filtered = tableData.map((courseData) => ({
    label: courseData.courseName,
    id: courseData.trainingCourseId,
    department: courseData.department,
  }));

  return (
    <MISDialog open={isDialogOpen}>
      {isLoading && <MISLoader />}
      <Box sx={{ width: "600px" }}>
        <DialogTitle display={"inline-flex"} alignItems={"center"}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          {`${T.ASSIGN_COURSE} ${T.TO} ${selectedUserName} `}
        </DialogTitle>

        <Form
          courseId={courseId}
          priority={priority}
          courseDuration={tableData.courseDuration}
          allTableRows={tableData}
          filtered={filtered}
          subCourses={subCourses}
          startDate={startDate}
          subCourseData={subCourseData}
          reviewerId={reviewerId}
          usersList={usersList}
          department={department}
          departmentList={get(departmentList, "results", [])}
          handleAssignedCourseDialog={handleAssignedCourseDialog}
          handleSubCourseSelect={handleSubCourseSelect}
          handleChange={handleChange}
          handleReviwer={handleReviwer}
          handleDepartment={handleDepartment}
          handlePriority={handlePriority}
          handleAssign={handleAssign}
          handleConfChange={handleConfChange}
          handleOnDragEnd={handleOnDragEnd}
          onHandleClose={onHandleClose}
          onHandleDateChange={onHandleDateChange}
        />
      </Box>
    </MISDialog>
  );
};

AssignedCourseDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  handleAssignedCourseDialog: PropTypes.func,
  selectedUserName: PropTypes.string,
  swotId: PropTypes.number,
  refreshPage: PropTypes.func,
};

export default AssignedCourseDialog;

import { useReducer } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Menu } from "@mui/material";
import { SHORTLISTED_COLUMNS } from "settings/constants/members";
import { handleError } from "utils/error";
import T from "T";
import { toast } from "react-toastify";
import Table from "components/PreSales/ShortlistedMembers/Table";
import PropTypes from "prop-types";
import { useUploadCVMutation } from "api/preSales/addCV";
import { useDeleteShortlistedCandidateMutation } from "api/preSales/deleteShortListCandidate";
import { useSavePitchedCandidatesMutation } from "api/pitchedCandidates/savePitchedCandidate";
import { noop } from "lodash";

const INITIAL_PAGE = 0;
const ROWS_PER_PAGE = 10000;

const ShortlistedMembers = ({
  jdId,
  allTableRows,
  pitchedUsersCount,
  getSelectedMembers = noop,
  getPitchedMembers = noop,
  getRecommendedMembers = noop,
}) => {
  const [uploadCV] = useUploadCVMutation();
  const [savePitchedCandidates] = useSavePitchedCandidatesMutation();
  const [deleteShortlistedCandidate] = useDeleteShortlistedCandidateMutation();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isOpenConfigurator: false,
    pastConfiguratorColumns: cloneDeep(SHORTLISTED_COLUMNS),
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    selectedMembers: [],
    allTableRows: [],
    exportLoading: false,
    currentScrollPosition: 0,
    selectAllCheck: false,
    jdId: null,
  });

  const { isOpenConfigurator, pastConfiguratorColumns, page, rowsPerPage, selectedMembers } = localState;

  const handleUnshortlist = (record) => {
    deleteShortlistedCandidate({
      presaleMapping_id: jdId,
      id: record.id,
    })
      .unwrap()
      .then(() => {
        toast.success(T.UNSHORTLISTED_SUCCESSFULLY);

        const updatedSelectedMembers = selectedMembers.filter((member) => member.id !== record.id);
        setLocalState({
          selectedMembers: updatedSelectedMembers,
        });

        const updatedAllTableRows = allTableRows.filter((row) => row.id !== record.id);
        setLocalState({
          allTableRows: updatedAllTableRows,
        });
        getSelectedMembers();
        getRecommendedMembers();
      })
      .catch(handleError);
  };

  // uploading CV
  const handleFileUpload = (e, record) => {
    const data = new FormData();
    data.append("file", e.target.files[0], e.target.files[0].name);
    uploadCV({ id: record.id, preSaleMappingId: jdId, cvFile: data })
      .unwrap()
      .then(() => {
        getSelectedMembers();
        toast.success(T.CV_UPLOADED_SUCCESSFULLY);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  // Saving the pitched candidates
  const handlePitchCandidate = () => {
    savePitchedCandidates({ preSalesMappingId: jdId })
      .unwrap()
      .then(() => {
        toast.success(T.PITCHED_SUCCESSFULLY);
        getSelectedMembers();
        getPitchedMembers();
      })
      .catch(handleError);
  };

  return (
    <>
      <Table
        columns={pastConfiguratorColumns.filter((column) => column.checked)}
        lockedColumns={pastConfiguratorColumns.filter((column) => column.locked && column.checked)}
        jdId={jdId}
        page={page}
        rowsPerPage={rowsPerPage}
        selectedMembers={selectedMembers}
        handleUnshortlist={handleUnshortlist}
        handleFileUpload={handleFileUpload}
        handlePitchCandidate={handlePitchCandidate}
        pitchedUsersCount={pitchedUsersCount}
        allTableRows={allTableRows}
      />

      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setLocalState({ isOpenConfigurator: null })}
        open={Boolean(isOpenConfigurator)}
        anchorEl={isOpenConfigurator}
        PaperProps={{
          style: {
            left: "50%",
            transform: "translateX(65%) translateY(-15%)",
          },
        }}
      />
    </>
  );
};

ShortlistedMembers.propTypes = {
  jdId: PropTypes.number,
  pitchedUsersCount: PropTypes.number,
  allTableRows: PropTypes.array,
  getSelectedMembers: PropTypes.func,
  getPitchedMembers: PropTypes.func,
  getRecommendedMembers: PropTypes.func,
};

export default ShortlistedMembers;

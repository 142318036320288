import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    reminderButtom: builder.query({
      query: ({ id }) => ({
        url: `testimonial/testimonial/reminder?testimonialId=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyReminderButtomQuery } = extendedApi;

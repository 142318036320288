import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { get } from "lodash";
import PropTypes from "prop-types";

const CostGrid = ({ record = {} }) => {
  return (
    <Grid container justifyContent={"flex-end"}>
      {[
        { text: "Monthly Total Cost", number: get(record, "monthlyCost", 0).toFixed(2), color: "#d3a82c" },
        { text: "Quarterly Total Cost", number: get(record, "quarterlyCost", 0).toFixed(2), color: "#d3a82c" },
        { text: "Bi Annually Total Cost", number: get(record, "biAnnuallyCost", 0).toFixed(2), color: "#d3a82c" },
        { text: "Yearly Total Cost", number: get(record, "yearlyCost", 0).toFixed(2), color: "#d3a82c" },
        { text: "One Time Total Cost", number: get(record, "oneTimeCost", 0).toFixed(2), color: "#d44c4b" },
      ].map((box, index) => (
        <Grid
          item
          xs={12}
          md={"auto"}
          key={index}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            height: "60px",
            px: "5px",
            alignItems: "center",
            // backgroundColor: box.color,
            border: "1px solid #ccc",
            borderRadius: 2,
            "&:not(:last-child)": {
              marginRight: { md: 1 },
            },
          }}
        >
          <Typography fontSize={13} color={"black"} mr={1}>
            {box.text}
          </Typography>
          <Typography fontSize={16} sx={{ fontWeight: "bold", color: "#33333" }}>
            <span>
              <span style={{ fontSize: 16 }}>$</span>
              {box.number}
            </span>
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
CostGrid.propTypes = {
  record: PropTypes.object,
};
export default CostGrid;

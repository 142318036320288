import { Typography, Box } from "@mui/material";
import MISDialog from "components/common/MISDialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import PropTypes from "prop-types";
import { noop } from "lodash";
import Table from "./Table";

const ViewEmployees = ({
  userId,
  id,
  openEmpDialog,
  handleViewEmployees = noop,
  prevCompany,
  handleAddRecommendation = noop,
}) => {
  return (
    <MISDialog open={openEmpDialog}>
      <Box sx={{ display: "flex", flexDirection: "column", p: 3, width: 1050 }}>
        <Box display="flex" alignItems="center">
          <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={() => handleViewEmployees("")} />
          <Typography variant="h6" fontWeight={600} ml={1}>
            {T.EMPLOYEE_LIST}
            {prevCompany}
          </Typography>
        </Box>
        <Table id={id} userId={userId} prevCompany={prevCompany} handleAddRecommendation={handleAddRecommendation} />
      </Box>
    </MISDialog>
  );
};

ViewEmployees.propTypes = {
  handleViewEmployees: PropTypes.func,
  handleAddRecommendation: PropTypes.func,
  openEmpDialog: PropTypes.bool,
  userId: PropTypes.number,
  prevCompany: PropTypes.string,
  id: PropTypes.number,
};
export default ViewEmployees;

import { useReducer, useState } from "react";
import { Grid, Paper, Box, Typography } from "@mui/material";
import MISTextField from "components/common/MISTextField";
import { useNavigate } from "react-router-dom";
import MISDatePicker from "components/common/MISDatePicker";
import MISTimePicker from "components/common/MISTimePicker";
import { toast } from "react-toastify";
import MISFooterButton from "components/common/MISFooterButton";
import { format, isValid } from "date-fns";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useScheduleInterviewMutation } from "api/preSales/scheduleInterview";
import { useLocation } from "react-router-dom";
import T from "T";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { handleError } from "utils/error";
import { isUrl } from "utils/validations";
import MISAutocomplete from "components/common/MISAutocomplete";
import { useScheduleInterview2Mutation } from "api/preSales/scheduleInterview2";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";
import { ERROR, SUCCESS } from "theme/colors";

const ScheduleInterviewForm = () => {
  const navigate = useNavigate();
  const [scheduleInterview] = useScheduleInterviewMutation();
  const [scheduleInterview2] = useScheduleInterview2Mutation();
  const [openConfirmSubmit, setOpenConfirmSubmit] = useState(false);

  const location = useLocation();
  const jdId = location.state ? location.state.jdId : null;
  const userId = location.state ? location.state.userId : null;
  const interviewType = location.state ? location.state.type : null;

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    date: null,
    time: null,
    platform: "",
    link: "",
    instructions: "",
    openModal: false,
  });

  const { date, time, platform, link, instructions } = localState;
  const platformOptions = ["Zoom", "Teams", "Skype", "Google Meet", "Others"];

  const getFormattedDate = (val) => {
    if (!val) {
      return null;
    }

    const formattedDate = new Date(val);

    if (isValid(formattedDate)) {
      return format(formattedDate, BACKEND_DATE_FORMAT);
    }

    return null;
  };

  const getFormattedTime = (val) => {
    if (!val) {
      return null;
    }

    const formattedTime = new Date(val);

    if (isValid(formattedTime)) {
      const hours = formattedTime.getHours();
      const minutes = formattedTime.getMinutes();
      return `${hours}:${minutes}`;
    }

    return null;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "date") {
      setLocalState({ [name]: getFormattedDate(value) });
    } else if (name === "time") {
      setLocalState({ [name]: value });
    } else if (name === "link") {
      setLocalState({ [name]: value.trim() });
    } else {
      setLocalState({ [name]: value });
    }
  };

  const handleSubmit = () => {
    const formattedTime = getFormattedTime(time);

    const formData = {
      interviewDate: date,
      interviewTime: `${formattedTime}:00`,
      medium: platform === "others" ? "Other" : platform,
      link: link,
      instruction: instructions,
    };

    let schedulePromise;

    if (interviewType === "schedule1" || interviewType === "reschedule1") {
      schedulePromise = scheduleInterview({
        userId: userId,
        preSaleMappingId: jdId,
        formData,
      });
    }
    if (interviewType === "schedule2" || interviewType === "reschedule2") {
      schedulePromise = scheduleInterview2({
        userId: userId,
        presalesMappingId: jdId,
        formData,
      });
    }

    schedulePromise
      .then(() => {
        if (interviewType === "schedule1") {
          toast.success("Interview Round 1 scheduled successfully.");
        }
        if (interviewType === "schedule2") {
          toast.success("Interview Round 2 scheduled successfully.");
        }
        if (interviewType === "reschedule1") {
          toast.success("Interview Round 1 rescheduled successfully.");
        }
        if (interviewType === "reschedule2") {
          toast.success("Interview Round 2 rescheduled successfully.");
        }
        setTimeout(() => {
          navigate(-1);
        }, 400);
      })
      .catch(handleError);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleConfirmSubmit = () => {
    if (!isUrl(link)) {
      toast.error("Please enter a valid URL");
      return;
    }

    if (date && time && platform) {
      setOpenConfirmSubmit(true);
    } else {
      toast.error("Please fill all the Required Fields");
    }
  };

  const handleCloseConfirmSubmit = () => {
    setOpenConfirmSubmit(false);
  };

  return (
    <Paper sx={{ p: 3, height: "74vh" }}>
      <Box display="flex" alignItems="center" mb={2}>
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer", mr: 1 }} onClick={handleBack} />
        <Typography variant="h5" fontWeight={600}>
          {interviewType === "schedule1" && <span>{T.SCHEDULE_INTERVIEW}</span>}
          {interviewType === "reschedule1" && <span>{T.RESCHEDULE_INTERVIEW}</span>}
          {interviewType === "schedule2" && <span>{T.SCHEDULE_INTERVIEW_2}</span>}
          {interviewType === "reschedule2" && <span>{T.RESCHEDULE_INTERVIEW_2}</span>}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <MISDatePicker
            placeholder="Select Date"
            inputFormat="MM/DD/YYYY"
            value={date ? new Date(date) : null}
            label="Date"
            name="date"
            disablePast
            handleChange={(newValue) => handleChange({ target: { name: "date", value: newValue } })}
            required
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <MISTimePicker
            placeholder="Select Time"
            value={time}
            label="Time"
            name="time"
            disabled={!date}
            handleChange={(newValue) => setLocalState({ time: newValue })}
            required
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <MISAutocomplete
            label="Platform"
            options={platformOptions}
            value={platform}
            placeholder="Platform"
            required
            getByLabelText={(option) => option || ""}
            onChange={(event, newValue) =>
              handleChange({
                target: { name: "platform", value: newValue },
              })
            }
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <MISTextField
            label="Link"
            fullWidth
            size="small"
            variant="outlined"
            name="link"
            value={link}
            placeholder="Enter link URL"
            onChange={handleChange}
            required
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderBottom: link && `3px solid ${isUrl(link) ? SUCCESS.main : ERROR.main}`,
              },
            }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <MISTextField
            label="Instructions"
            fullWidth
            multiline
            rows={7}
            size="small"
            variant="outlined"
            name="instructions"
            value={instructions}
            onChange={handleChange}
          />
        </Grid>
        <Grid display="flex" sx={{ mt: 2 }} justifyContent="flex-end" item md={12} xs={12}>
          <MISFooterButton handleSubmit={handleConfirmSubmit} handleClose={handleBack} />
        </Grid>
        <ConfirmSubmit
          cancelButtonText={T.NO}
          proceedButtonText={T.YES}
          dialogMessageText={T.CONFIRM_SUBMIT}
          openConfirmSubmit={openConfirmSubmit}
          handleClose={handleCloseConfirmSubmit}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </Paper>
  );
};

export default ScheduleInterviewForm;

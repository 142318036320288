import { Typography, Box, DialogActions, DialogContent } from "@mui/material";
import MISDialog from "components/common/MISDialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import PropTypes from "prop-types";
import { useEffect, useReducer } from "react";
import MISTextField from "components/common/MISTextField";
import MISFooterButton from "components/common/MISFooterButton";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { noop } from "lodash";
import { useAddCommentMutation } from "api/MockAssessment/addComment";

const MockCommentsDialog = ({ openDialog, mock_id, refreshPage = noop, handleAddComment = noop, existingComment }) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    comments: existingComment,
    openConfirmModal: false,
  });

  const { comments } = localState;

  useEffect(() => {
    if (existingComment) {
      setLocalState({ comments: existingComment });
    }
  }, [existingComment]);

  const [addComment] = useAddCommentMutation();

  const handleChange = (name, value) => {
    setLocalState({ [name]: value });
  };

  const handleSubmit = () => {
    if (comments) {
      const formData = {
        comment: comments,
      };

      addComment({ formData, mock_id })
        .unwrap()
        .then(() => {
          existingComment ? toast.success("Comment Updated Successfully.") : toast.success("Comment Added Successfully.");
          handleClose();
          refreshPage();
          setLocalState({ comments: "" });
        })
        .catch(handleError);
    } else {
      toast.error("Please add a comment!");
    }
  };

  const handleClose = () => {
    handleAddComment(mock_id, comments);
    setLocalState({ comments: "" });
  };

  return (
    <MISDialog open={openDialog}>
      <Box sx={{ display: "flex", flexDirection: "column", width: 580 }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            pl={1}
            sx={{
              backgroundColor: "background.card",
            }}
            p={1.5}
            pb={1}
          >
            <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleClose} />
            <Typography variant="h6" fontWeight={700} ml={1}>
              {existingComment ? `${T.EDIT} ${T.COMMENT}` : `${T.ADD} ${T.COMMENT}`}
            </Typography>
          </Box>
        </Box>
        <DialogContent
          sx={{
            pt: 3,
            pb: 1,
            px: 3.5,
            boxShadow: "none",
            flexGrow: 1,
            borderRadius: 1,
          }}
        >
          <MISTextField
            label="Comment"
            fullWidth
            multiline
            rows={7}
            required
            size="small"
            variant="outlined"
            name="comments"
            value={comments}
            onChange={(event) => handleChange("comments", event.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3.5, my: 1 }}>
          <MISFooterButton handleSubmit={handleSubmit} handleClose={handleClose} />
        </DialogActions>
      </Box>
    </MISDialog>
  );
};

MockCommentsDialog.propTypes = {
  openDialog: PropTypes.bool,
  mock_id: PropTypes.number,
  handleAddComment: PropTypes.func,
  refreshPage: PropTypes.func,
  record: PropTypes.object,
  existingComment: PropTypes.string,
};

export default MockCommentsDialog;

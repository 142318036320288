import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, TableBody, styled } from "@mui/material";
import { get } from "utils/lodash";
import memo from "utils/react";

import T from "T";
import { BACKGROUND } from "theme/colors";
import { NC_STATUS_LIST } from "settings/constants/members";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  fontSize: 13,
  p: 0,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));
const ActiveNcTableBody = ({ tableData = [], typeOfNc }) => {
  return (
    <TableBody>
      {Children.toArray(
        tableData.map((record, index) => (
          <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
            <StyledTableCell>{index + 1}.</StyledTableCell>
            <StyledTableCell>{get(record, "dateOfNc", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "projectName", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "projectManager", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "reportingManager", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "functionalHead", "-")}</StyledTableCell>
            <StyledTableCell align="center">{get(record, "typeOfNc", "-")}</StyledTableCell>
            <StyledTableCell align="center">
              {NC_STATUS_LIST.find((item) => item.id === get(record, "ncStatus", 0)).label}
            </StyledTableCell>
            {typeOfNc === T.JIRA_NOT_FILLED && (
              <StyledTableCell align="center">{get(record, "timeSpentHours", "-")}</StyledTableCell>
            )}
          </StyledTableRow>
        )),
      )}
    </TableBody>
  );
};

ActiveNcTableBody.propTypes = {
  userId: PropTypes.number,
  month: PropTypes.number,
  typeOfNc: PropTypes.string,
  tableData: PropTypes.array,
};

export default memo(ActiveNcTableBody);

import { Box } from "@mui/system";
import MISFooterButton from "components/common/MISFooterButton";
import { capitalize, get, noop } from "lodash";
import { useReducer, useEffect } from "react";
import T from "T";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmCancel from "components/ISTools/ConfirmCancel";
import { format, isValid } from "date-fns";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { useAddLicenseMutation } from "api/ISTools/ToolsLicence/addLicense";
import { useGetToolsListMutation } from "api/ISTools/getToolsList";
import { useLazyGetProfitCenterListQuery } from "api/ISTools/getProfitCenterList";
import { useLazyGetCostCenterListQuery } from "api/ISTools/getCostCenterList";
import { useLazyGetLicenseByIdQuery } from "api/ISTools/ToolsLicence/getLicenseById";
import { useUploadLicenseMutation } from "api/ISTools/ToolsLicence/uploadLicense";
import CreateLicense from "./CreateLicense";
import { PAGINATION } from "settings/constants/pagination";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { YES_NO_VALUES } from "components/ISTools/toolsModel";
import { useUpdateLicenseMutation } from "api/ISTools/ToolsLicence/updateLicense";
import { isDecimal, isEmail } from "utils/validations";
import ToolsDialog from "components/ISTools/ISToolsDialogs/ToolsDialog";
import { useLazyGetToolsByIdQuery } from "api/ISTools/ListMutation/getToolsById";
import { useSaveToolsMutation } from "api/ISTools/ListMutation/saveTools";
import { useUpdateToolsMutation } from "api/ISTools/ListMutation/updateTools";
import { useDeleteToolsMutation } from "api/ISTools/ListMutation/deleteTools";
import ProfitCenterDialog from "components/ISTools/ISToolsDialogs/ProfitCenterDialog";
import { useLazyGetProfitCenterByIdQuery } from "api/ISTools/ListMutation/getProfitCenterById";
import { useSaveProfitCenterMutation } from "api/ISTools/ListMutation/saveProfitCenter";
import { useUpdateProfitCenterMutation } from "api/ISTools/ListMutation/updateProfitCenter";
import { useDeleteProfitCenterMutation } from "api/ISTools/ListMutation/deleteProfitCenter";
import CostCenterDialog from "components/ISTools/ISToolsDialogs/CostCenterDialog";
import { useLazyGetCostCenterByIdQuery } from "api/ISTools/ListMutation/getCostCenterById";
import { useSaveCostCenterMutation } from "api/ISTools/ListMutation/saveCostCenter";
import { useUpdateCostCenterMutation } from "api/ISTools/ListMutation/updateCostCenter";
import { useDeleteCostCenterMutation } from "api/ISTools/ListMutation/deleteCostCenter";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";

const { INITIAL_PAGE } = PAGINATION;
const FULL_ROWS_PER_PAGE = 10000;

const Form = ({ handleClose = noop, file = {}, fileName = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const id = url.includes("edit") && Number(url.replace(/\D+/g, ""));

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    toolsId: "",
    profitCenterId: "",
    purchaseDate: null,
    costPrice: "",
    licenseHolder: "",
    licenseHolderEmail: "",
    remarks: "",
    tenure: "",
    renewalDate: null,
    autoRenewal: {},
    contractFile: null, // ""
    contractFileName: null, // ""
    costCenterMap: [{ costCenterId: "", percentage: 100 }],
    updateCostCenterMap: [{ id: null, costCenterId: "", percentage: 100 }],
    savedCostCenters: [],
    isOpenConfirmDelete: false,
    openConfirmCancel: false,
    isOpenToolsDialog: false,
    isAddEditToolsOpen: false,
    addEditTools: {
      toolsId: "",
      toolsName: "",
      toolsHeadName: "",
    },
    isOpenProfitCenterDialog: false,
    isAddEditProfitCenterOpen: false,
    addEditProfitCenter: {
      profitCenterId: "",
      profitCenterName: "",
      profitCenterHeadName: "",
    },
    isOpenCostCenterDialog: false,
    isAddEditCostCenterOpen: false,
    addEditCostCenter: {
      costCenterId: "",
      costCenterName: "",
      costCenterHeadName: "",
    },
    toolsSearch: "",
    toolsList: [],
    profitCenterList: [],
    costCenterList: [],
    contractHistoryList: [],
    deletedContractList: [],
    selectedContract: "",
  });
  const {
    toolsId,
    profitCenterId,
    purchaseDate,
    costPrice,
    licenseHolder,
    licenseHolderEmail,
    remarks,
    tenure,
    autoRenewal,
    contractFile,
    contractFileName,
    renewalDate,
    costCenterMap,
    updateCostCenterMap,
    savedCostCenters,
    openConfirmCancel,
    isOpenToolsDialog,
    isAddEditToolsOpen,
    addEditTools,
    isOpenProfitCenterDialog,
    isAddEditProfitCenterOpen,
    addEditProfitCenter,
    isOpenCostCenterDialog,
    isAddEditCostCenterOpen,
    addEditCostCenter,
    toolsSearch,
    toolsList,
    profitCenterList,
    costCenterList,
    contractHistoryList,
    isOpenConfirmDelete,
    deletedContractList,
    selectedContract,
  } = localState;

  const emptyCostCenter = { costCenterId: "", percentage: 0 };
  const emptyUpdateCostCenter = { id: null, costCenterId: "", percentage: 0 };
  const costCenterArray = id ? updateCostCenterMap : costCenterMap;
  const stateKey = id ? "updateCostCenterMap" : "costCenterMap";

  const [getToolsList] = useGetToolsListMutation();
  const [getProfitCenterList] = useLazyGetProfitCenterListQuery();
  const [getCostCenterList] = useLazyGetCostCenterListQuery();
  const [getLicenseById] = useLazyGetLicenseByIdQuery();
  const [updateLicense] = useUpdateLicenseMutation();
  const [addLicense] = useAddLicenseMutation();
  const [uploadLicense] = useUploadLicenseMutation();
  const [getToolsById] = useLazyGetToolsByIdQuery();
  const [saveTools] = useSaveToolsMutation();
  const [updateTools] = useUpdateToolsMutation();
  const [deleteTools] = useDeleteToolsMutation();
  const [getProfitCenterById] = useLazyGetProfitCenterByIdQuery();
  const [saveProfitCenter] = useSaveProfitCenterMutation();
  const [updateProfitCenter] = useUpdateProfitCenterMutation();
  const [deleteProfitCenter] = useDeleteProfitCenterMutation();
  const [getCostCenterById] = useLazyGetCostCenterByIdQuery();
  const [saveCostCenter] = useSaveCostCenterMutation();
  const [updateCostCenter] = useUpdateCostCenterMutation();
  const [deleteCostCenter] = useDeleteCostCenterMutation();

  useEffect(() => {
    getToolsListData();
    getProfitCenterListData();
    getCostCenterListData();
  }, []);

  useEffect(() => {
    if (id) {
      getLicenseById({ id })
        .unwrap()
        .then((res) => {
          const {
            toolsId,
            profitCenterId,
            licenseHolder,
            licenseHolderEmail,
            remarks,
            cost,
            autoRenewal,
            purchaseDate,
            renewalDate,
            tenure,
            costCenter,
          } = res;
          const transformCostCenter = (data) => {
            const { id, costCenter, percentage } = data;
            return {
              id: id,
              costCenterId: costCenter.costCenterId,
              percentage: parseInt(percentage),
            };
          };
          const contractHistoryList = get(res, "isContractHistories", []);
          const prevRequirements = {
            toolsId: toolsId.toolsId,
            profitCenterId: profitCenterId.profitCenterId,
            licenseHolder: licenseHolder,
            licenseHolderEmail: licenseHolderEmail,
            remarks: remarks,
            costPrice: cost,
            purchaseDate: purchaseDate,
            renewalDate: renewalDate,
            autoRenewal: YES_NO_VALUES.find((val) => val.value === autoRenewal),
            tenure: tenure,
            updateCostCenterMap: costCenter.map(transformCostCenter),
            savedCostCenters: costCenter.map(transformCostCenter),
            contractFile: null, // ""
            contractFileName: null, //contractFileName
            contractHistoryList: contractHistoryList,
          };
          setLocalState(prevRequirements);
        })
        .catch(handleError);
    }
  }, [id]);

  const disableField = () => {
    return fileName !== "";
  };

  const getListData = (getListFunction, listType, searchInput) => {
    getListFunction({ page: INITIAL_PAGE, rowsPerPage: FULL_ROWS_PER_PAGE, search: searchInput })
      .unwrap()
      .then((res) => {
        const { allTableRows } = getCurrentTableParams(res);
        setLocalState({ [`${listType}List`]: allTableRows });
      })
      .catch(handleError);
  };

  const getToolsListData = (searchInput) => getListData(getToolsList, "tools", searchInput);
  const getProfitCenterListData = () => getListData(getProfitCenterList, "profitCenter");
  const getCostCenterListData = () => getListData(getCostCenterList, "costCenter");

  const validation = () => {
    if (
      !toolsId ||
      !profitCenterId ||
      !(costPrice && isDecimal(costPrice)) ||
      !purchaseDate ||
      !licenseHolder.trim() ||
      !autoRenewal.label ||
      !tenure ||
      !renewalDate ||
      (licenseHolderEmail && !isEmail(licenseHolderEmail)) ||
      (!id && !costCenterMap.every((item) => item.costCenterId)) ||
      (id && !updateCostCenterMap.every((item) => item.costCenterId)) ||
      (!id && !costCenterMap.every((item) => item.percentage)) ||
      (id && !updateCostCenterMap.every((item) => item.percentage))
    ) {
      toast.error(T.REQUIRED_FIELDS_EMPTY);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (fileName === "") {
      if (!validation()) return;

      const payload = {
        toolsId: toolsId,
        autoRenewal: autoRenewal.value,
        cost: parseInt(costPrice),
        costCenters: costCenterMap,
        profitCenterId: profitCenterId,
        purchaseDate,
        renewalDate,
        tenure,
        licenseHolder,
        licenseHolderEmail,
        remarks,
        deletedContractsIdList: [],
        contractFileName,
        contractFile,
      };

      addLicense(payload)
        .unwrap()
        .then((res) => {
          toast.success(T.LICENSE_ASSIGNED_SUCCESSFULLY);
          handleClose();
        })
        .catch(handleError);
    } else {
      uploadLicense({ file })
        .unwrap()
        .then((res) => {
          toast.success(T.LICENSE_ASSIGNED_SUCCESSFULLY);
          handleClose();
        })
        .catch(handleError);
    }
  };
  const handleUpdate = () => {
    if (!validation()) return;

    const toolsDto = {
      toolsUserMappingId: id,
      toolsId: toolsId,
      autoRenewal: autoRenewal.value,
      cost: parseInt(costPrice),
      costCenters: updateCostCenterMap,
      profitCenterId: profitCenterId,
      purchaseDate,
      renewalDate,
      tenure,
      licenseHolder,
      licenseHolderEmail,
      remarks,
      deletedContractsIdList: deletedContractList,
      contractFileName,
      contractFile,
    };
    updateLicense({ toolsDto })
      .unwrap()
      .then((res) => {
        toast.success(T.LICENSE_UPDATED_SUCCESSFULLY);
        handleClose();
      })
      .catch(handleError);
  };

  const confrmCancelModal = () => {
    setLocalState({ openConfirmCancel: !openConfirmCancel });
  };

  const onHandleChange = (event, index) => {
    const { name, value } = event.target;
    if (index === null) {
      setLocalState({ [name]: value });
    } else {
      costCenterArray[index][name] = parseFloat(value);
      setLocalState({ [stateKey]: [...costCenterArray] });
    }
  };

  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const onHandleDateChange = (newValue, type) => {
    if (newValue !== null) {
      const validDate = new Date(newValue);
      if (isValid(validDate)) {
        setLocalState({ [type]: getBEDateFormat(validDate) });
      }
    } else {
      setLocalState({ [type]: null });
    }
  };

  const onHandleAutoCompleteChange = (type, value, index) => {
    if (type === "tenure" && purchaseDate !== null) {
      const NewDate = (value) => {
        const newDate = new Date(purchaseDate);
        newDate.setMonth(newDate.getMonth() + value);
        setLocalState({ renewalDate: getBEDateFormat(newDate) });
      };
      switch (value) {
        case T.MONTHLY:
          NewDate(1);
          break;
        case T.QUARTERLY:
          NewDate(3);
          break;
        case T.BI_ANNUALLY:
          NewDate(6);
          break;
        case T.YEARLY:
          NewDate(12);
          break;
        default:
      }
    }
    if (index === null) {
      setLocalState({ [type]: value });
    } else {
      costCenterArray[index][type] = value;
      setLocalState({ [stateKey]: [...costCenterArray] });
    }
  };

  const onHandleReqAddMore = () => {
    const emptyCostCenterReq = id ? emptyUpdateCostCenter : emptyCostCenter;
    costCenterArray.push(emptyCostCenterReq);
    const newArray = costCenterArray.map((item) => ({
      ...item,
      percentage: (100 / costCenterArray.length).toFixed(2),
    }));

    setLocalState({ [stateKey]: [...newArray] });
  };

  const onHandleReqRemove = (index) => {
    costCenterArray.splice(index, 1);
    const newArray = costCenterArray.map((item) => ({
      ...item,
      percentage: (100 / costCenterArray.length).toFixed(2),
    }));
    setLocalState({ [stateKey]: [...newArray] });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const formatFileForBE = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    const base64 = await convertBase64(file);
    return { fileName, base64 };
  };

  const handleFileUpload = async (event) => {
    const { name } = event.target;
    const { fileName, base64 } = await formatFileForBE(event);
    const parts = fileName.split(".");
    const extension = parts[parts.length - 1];
    const allowedExtensions = ["DOC", "doc", "DOCX", "docx", "PDF", "pdf"];
    if (!allowedExtensions.includes(extension)) {
      toast.error("Please upload a DOC, DOCX or PDF file.");
      event.target.value = null;
      return;
    }
    setLocalState({ [name]: fileName, contractFile: base64 });
  };

  const handleDeleteFile = (event) => {
    event.target.value = null;
    setLocalState({ contractFileName: "", contractFile: "" });
  };

  const onHandleOpenConfirmDelete = (id) => {
    setLocalState({ isOpenConfirmDelete: true, selectedContract: id });
  };

  const onHandleCloseConfirmDelete = () => {
    setLocalState({ isOpenConfirmDelete: false, selectedContract: "" });
  };

  const onHandleDeleteContract = () => {
    const updatedContracts = contractHistoryList.filter((contract) => contract.id !== selectedContract);
    deletedContractList.push(selectedContract);
    setLocalState({ deletedContractList: deletedContractList, contractHistoryList: updatedContracts });
    onHandleCloseConfirmDelete();
  };

  // ##############################Tools Search#############################

  const handleSearchChange = (event) => {
    const { value, dataset } = event.currentTarget;
    const searchValue = value || get(dataset, "val", "");
    setLocalState({
      toolsSearch: searchValue,
    });
    if (event.key === "Enter" || event.currentTarget.nodeName === "svg" || searchValue === "") {
      getToolsListData(searchValue);
    }
  };

  const handleSearchKeyChange = () => {
    setLocalState({
      page: INITIAL_PAGE,
    });
    getToolsListData(toolsSearch);
  };
  //############################New Dialog Function##########################
  const onHandleDialogChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "toolsName":
        setLocalState({ addEditTools: { ...addEditTools, toolsName: value } });
        break;
      case "profitCenterName":
        setLocalState({ addEditProfitCenter: { ...addEditProfitCenter, profitCenterName: value } });
        break;
      case "costCenterName":
        setLocalState({ addEditCostCenter: { ...addEditCostCenter, costCenterName: value } });
        break;
      default:
    }
  };

  //============================Tools Dialog========================================
  const handleOpenToolsDialog = () => {
    setLocalState({ isOpenToolsDialog: true });
  };
  const handleCloseToolsDialog = () => {
    setLocalState({ isOpenToolsDialog: false, toolsSearch: "" });
  };
  const openAddEditToolsMutation = (id) => {
    if (id) {
      getToolsById(id)
        .unwrap()
        .then((res) => {
          const { toolsId, toolsName } = res;
          setLocalState({ addEditTools: { toolsId: toolsId, toolsName: toolsName, toolsHeadName: toolsName } });
        })
        .catch(handleError);
    }
    setLocalState({ isAddEditToolsOpen: true, isOpenToolsDialog: false });
  };

  const closeAddEditToolsMutation = () => {
    setLocalState({
      addEditTools: { toolsId: "", toolsName: "", toolsHeadName: "" },
      isAddEditToolsOpen: false,
      isOpenToolsDialog: true,
    });
    getToolsListData();
  };

  const handleSubmitTools = (id) => {
    const payload = {
      toolName: addEditTools.toolsName,
    };
    if (id) {
      updateTools({ toolsId: id, ...payload })
        .unwrap()
        .then((res) => {
          toast.success(T.TOOL_UPDATED_SUCCESSFULLY);
          closeAddEditToolsMutation();
        })
        .catch(handleError);
    } else {
      saveTools(payload)
        .unwrap()
        .then((res) => {
          toast.success(T.TOOL_ADDED_SUCCESSFULLY);
          closeAddEditToolsMutation();
        })
        .catch(handleError);
    }
  };
  const handleDeleteTools = (id) => {
    deleteTools(id)
      .unwrap()
      .then(() => {
        toast.success(T.TOOL_DELETED_SUCCESSFULLY);
        getToolsListData(toolsSearch);
      })
      .catch(handleError);
  };

  //============================Profit Center Dialog========================================
  const handleOpenProfitCenterDialog = () => {
    setLocalState({ isOpenProfitCenterDialog: true });
  };
  const handleCloseProfitCenterDialog = () => {
    setLocalState({ isOpenProfitCenterDialog: false });
  };
  const openAddEditProfitCenterMutation = (id) => {
    if (id) {
      getProfitCenterById(id)
        .unwrap()
        .then((res) => {
          const { profitCenterId, profitCenterName } = res;
          setLocalState({
            addEditProfitCenter: {
              profitCenterId: profitCenterId,
              profitCenterName: profitCenterName,
              profitCenterHeadName: profitCenterName,
            },
          });
        })
        .catch(handleError);
    }
    setLocalState({ isAddEditProfitCenterOpen: true, isOpenProfitCenterDialog: false });
  };

  const closeAddEditProfitCenterMutation = () => {
    setLocalState({
      addEditProfitCenter: { profitCenterId: "", profitCenterName: "", profitCenterHeadName: "" },
      isAddEditProfitCenterOpen: false,
      isOpenProfitCenterDialog: true,
    });
    getProfitCenterListData();
  };

  const handleSubmitProfitCenter = (id) => {
    const payload = {
      profitCenterName: addEditProfitCenter.profitCenterName,
    };
    if (id) {
      updateProfitCenter({ profitCenterId: id, ...payload })
        .unwrap()
        .then((res) => {
          toast.success(T.PROFIT_CENTER_UPDATED_SUCCESSFULLY);
          closeAddEditProfitCenterMutation();
        })
        .catch(handleError);
    } else {
      saveProfitCenter(payload)
        .unwrap()
        .then((res) => {
          toast.success(T.PROFIT_CENTER_ADDED_SUCCESSFULLY);
          closeAddEditProfitCenterMutation();
        })
        .catch(handleError);
    }
  };

  const handleDeleteProfitCenter = (id) => {
    deleteProfitCenter(id)
      .unwrap()
      .then(() => {
        toast.success(T.PROFIT_CENTER_DELETED_SUCCESSFULLY);
        getProfitCenterListData();
      })
      .catch(handleError);
  };

  //============================Cost Center Dialog========================================
  const handleOpenCostCenterDialog = () => {
    setLocalState({ isOpenCostCenterDialog: true });
  };
  const handleCloseCostCenterDialog = () => {
    setLocalState({ isOpenCostCenterDialog: false });
  };
  const openAddEditCostCenterMutation = (id) => {
    if (id) {
      getCostCenterById(id)
        .unwrap()
        .then((res) => {
          const { costCenterId, costCenterName } = res;
          setLocalState({
            addEditCostCenter: {
              costCenterId: costCenterId,
              costCenterName: costCenterName,
              costCenterHeadName: costCenterName,
            },
          });
        })
        .catch(handleError);
    }
    setLocalState({ isAddEditCostCenterOpen: true, isOpenCostCenterDialog: false });
  };

  const closeAddEditCostCenterMutation = () => {
    setLocalState({
      addEditCostCenter: { costCenterId: "", costCenterName: "", costCenterHeadName: "" },
      isAddEditCostCenterOpen: false,
      isOpenCostCenterDialog: true,
    });
    getCostCenterListData();
  };
  const handleSubmitCostCenter = (id) => {
    const payload = {
      costCenterName: addEditCostCenter.costCenterName,
    };
    if (id) {
      updateCostCenter({ costCenterId: id, ...payload })
        .unwrap()
        .then((res) => {
          toast.success(T.COST_CENTER_UPDATED_SUCCESSFULLY);
          closeAddEditCostCenterMutation();
        })
        .catch(handleError);
    } else {
      saveCostCenter(payload)
        .unwrap()
        .then((res) => {
          toast.success(T.COST_CENTER_ADDED_SUCCESSFULLY);
          closeAddEditCostCenterMutation();
        })
        .catch(handleError);
    }
  };

  const handleDeleteCostCenter = (id) => {
    deleteCostCenter(id)
      .unwrap()
      .then(() => {
        toast.success(T.COST_CENTER_DELETED_SUCCESSFULLY);
        getCostCenterListData();
      })
      .catch(handleError);
  };

  return (
    <>
      <Box
        sx={{
          height: fileName ? "calc(100vh - 235px)" : "calc(100vh - 205px)",
          overflowY: "auto",
        }}
      >
        <CreateLicense
          id={id}
          toolsId={toolsId}
          profitCenterId={profitCenterId}
          purchaseDate={purchaseDate}
          costPrice={costPrice}
          licenseHolder={licenseHolder}
          licenseHolderEmail={licenseHolderEmail}
          remarks={remarks}
          autoRenewal={autoRenewal}
          tenure={tenure}
          renewalDate={renewalDate}
          contractFile={contractFile}
          contractFileName={contractFileName}
          costCenterMap={id ? updateCostCenterMap : costCenterMap}
          savedCostCenters={savedCostCenters}
          toolsList={toolsList}
          profitCenterList={profitCenterList}
          costCenterList={costCenterList}
          contractHistoryList={contractHistoryList}
          onHandleOpenConfirmDelete={onHandleOpenConfirmDelete}
          disableField={disableField}
          onHandleReqAddMore={onHandleReqAddMore}
          onHandleReqRemove={onHandleReqRemove}
          onHandleChange={onHandleChange}
          onHandleDateChange={onHandleDateChange}
          handleFileUpload={handleFileUpload}
          handleDeleteFile={handleDeleteFile}
          onHandleAutoCompleteChange={onHandleAutoCompleteChange}
          handleOpenToolsDialog={handleOpenToolsDialog}
          handleOpenProfitCenterDialog={handleOpenProfitCenterDialog}
          handleOpenCostCenterDialog={handleOpenCostCenterDialog}
        />
      </Box>
      <MISFooterButton
        proceedButtonText={id ? T.UPDATE : T.SUBMIT}
        justify="end"
        sx={{ pb: 0.5 }}
        handleClose={confrmCancelModal}
        handleSubmit={id ? handleUpdate : handleSubmit}
      />
      <ConfirmCancel openConfirmCancel={openConfirmCancel} confrmCancelModal={confrmCancelModal} handleClose={handleClose} />

      <ToolsDialog
        toolsList={toolsList}
        isOpenToolsDialog={isOpenToolsDialog}
        isAddEditToolsOpen={isAddEditToolsOpen}
        addEditTools={addEditTools}
        toolsSearch={toolsSearch}
        openAddEditToolsMutation={openAddEditToolsMutation}
        closeAddEditToolsMutation={closeAddEditToolsMutation}
        handleClose={handleCloseToolsDialog}
        onHandleChange={onHandleDialogChange}
        handleDeleteTools={handleDeleteTools}
        handleSubmitTools={handleSubmitTools}
        handleSearchChange={handleSearchChange}
        handleSearchKeyChange={handleSearchKeyChange}
      />
      <ProfitCenterDialog
        profitCenterList={profitCenterList}
        isOpenProfitCenterDialog={isOpenProfitCenterDialog}
        isAddEditProfitCenterOpen={isAddEditProfitCenterOpen}
        addEditProfitCenter={addEditProfitCenter}
        openAddEditProfitCenterMutation={openAddEditProfitCenterMutation}
        closeAddEditProfitCenterMutation={closeAddEditProfitCenterMutation}
        handleClose={handleCloseProfitCenterDialog}
        onHandleChange={onHandleDialogChange}
        handleDeleteProfitCenter={handleDeleteProfitCenter}
        handleSubmitProfitCenter={handleSubmitProfitCenter}
      />
      <CostCenterDialog
        costCenterList={costCenterList}
        isOpenCostCenterDialog={isOpenCostCenterDialog}
        isAddEditCostCenterOpen={isAddEditCostCenterOpen}
        addEditCostCenter={addEditCostCenter}
        openAddEditCostCenterMutation={openAddEditCostCenterMutation}
        closeAddEditCostCenterMutation={closeAddEditCostCenterMutation}
        handleClose={handleCloseCostCenterDialog}
        onHandleChange={onHandleDialogChange}
        handleDeleteCostCenter={handleDeleteCostCenter}
        handleSubmitCostCenter={handleSubmitCostCenter}
      />
      <ConfirmSubmit
        openConfirmSubmit={isOpenConfirmDelete}
        handleClose={onHandleCloseConfirmDelete}
        handleSubmit={() => onHandleDeleteContract()}
        proceedButtonText={T.DELETE}
      />
    </>
  );
};

export default Form;

import { Children } from "react";
import PropTypes from "prop-types";

import { TableRow, TableHead, TableCell, Typography } from "@mui/material";

import memo from "utils/react";
import { BACKGROUND } from "theme/colors";

const InterviewTableHead = ({ columns }) => (
  <TableHead>
    <TableRow>
      {Children.toArray(
        columns.map((column) => (
          <TableCell
            sx={{
              border: "none",
              backgroundColor: BACKGROUND.cardDefault,
              paddingY: "5px",
              zIndex: 99,
            }}
          >
            <Typography fontSize={13.5} noWrap fontWeight={600}>
              {column}
            </Typography>
          </TableCell>
        )),
      )}
    </TableRow>
  </TableHead>
);

InterviewTableHead.propTypes = {
  columns: PropTypes.array,
};

export default memo(InterviewTableHead);

import { createSlice } from "@reduxjs/toolkit";

const courseDetailFilterSlice = createSlice({
  name: "courseDetailFilterSlice",
  initialState: {
    storedFilters: {
      department: null,

      trainingType: null,
    },
  },
  reducers: {
    courseDetailFilterStore: (state, action) => {
      state.storedFilters = action.payload.storedFilters;
      // state.storedStartDate = action.payload.storedStartDate;
      // state.storeEndDate = action.payload.storeEndDate;
    },
  },
});
export const { courseDetailFilterStore } = courseDetailFilterSlice.actions;

export default courseDetailFilterSlice.reducer;

import { Box, Card, Grid, IconButton, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useReducer, useRef, useState } from "react";
import T from "T";
import { TEXT } from "theme/colors";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { GET_SIZE } from "utils/responsive";
import { handleError } from "utils/error";
import { useLazyGetQuestionQuery } from "api/MockInterview/getQuestion";
import { useSubmitAudioMutation } from "api/MockInterview/submitAudio";
import { shallowEqual, useDispatch } from "react-redux";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import {
  manageMockInterviewUserLogin,
  mockInterviewInstructionModal,
  mockInterviewResponseArrStore,
  mockInterviewStatus,
  mockInterviewStore,
  mockInterviewStore1,
  setStartTime,
  updateTimeLeft,
} from "slices/mockInterviewSlice";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { usePostUserInfoMutation } from "api/MockInterview/postMockInterviewUserInfo";
import { MISCurrentUser } from "utils/validations";
import QuestionsBoxButtons from "./QuestionsBoxButtons";
import MISLoader from "components/common/MISLoader";
import InstructionsModal from "./InstructionsModal";
import { useSubmitResponsesMutation } from "api/MockInterview/submitResponses";
import { useLazyGetExistingResponsesQuery } from "api/MockInterview/getExistingResponses";
const APP_PREFIX = "/app";
export const convertBase64ToBlob = (base64String) => {
  const binaryData = atob(base64String);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob object from the binary data
  const blob = new Blob([uint8Array], { type: "application/octet-stream" });
  return blob;
};
const InterviewQuestions = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = MISCurrentUser();
  const userId = get(user, "user.id", "");
  const userName = get(user, "user.userName", "");
  const empCode = get(user, "user.employeeCode", "");
  const { mockResponses, mockInterviewUserRegistered, responseArr, startTime, timeLeft, openInstructionModal } = useSelector(
    (state) => ({
      mockResponses: get(state, "MockInterviewSlice.mockResponses", []),
      mockInterviewUserRegistered: get(state, "MockInterviewSlice.mockInterviewUserRegistered", false),
      responseArr: get(state, "MockInterviewSlice.responseArr", []),
      startTime: get(state, "MockInterviewSlice.startTime", null),
      openInstructionModal: get(state, "MockInterviewSlice.openInstructionModal", null),
      timeLeft: get(state, "MockInterviewSlice.timeLeft", null),
    }),
    shallowEqual,
  );
  const { isXs, isMd } = GET_SIZE();
  const [getQuestion, { data: question, isFetching }] = useLazyGetQuestionQuery();
  const [submitResponses, { isFetching: submitResponseFetching }] = useSubmitResponsesMutation();
  const [getExistingResponses] = useLazyGetExistingResponsesQuery();
  const [postUserInfo] = usePostUserInfoMutation();
  const [submitAudio, audioData] = useSubmitAudioMutation();
  const { isLoading: submitIsLoading } = audioData;
  const navigate = useNavigate();
  const mimeType = "audio/webm";
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioFormData, setAudioFormData] = useState(new FormData());

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    questionsList: [],
    answersList: [],
    questionStep: 0,
    isLoading: true,
    isMockInterviewAssigned: location?.state?.isMockInterviewAssigned ? location?.state?.isMockInterviewAssigned : null,
    startTimer: false,
  });
  const { questionsList, isLoading, questionStep, isMockInterviewAssigned, startTimer } = localState;

  useEffect(() => {
    setLocalState({ isLoading: true });
    if (responseArr.length === 0) {
      getExistingResponses({ userId })
        .unwrap()
        .then((res) => {
          const isMockInterviewAssigned = get(res, "isMockInterviewAssigned", null);
          setLocalState({ isMockInterviewAssigned: isMockInterviewAssigned });
          const responseArr = get(res, "mockInterviewLevels", []);
          const createdAt = get(res, "createdAt", []);
          const resLength = responseArr.length;
          if (resLength > 0) {
            navigate(`${APP_PREFIX}/mock-interview-responses`, { state: { responseArr: responseArr, createdAt: createdAt } });
          }
        })
        .catch(handleError)
        .finally(() => {
          setLocalState({ isLoading: false });
        });
    } else {
      setLocalState({ isLoading: false });
    }
  }, [responseArr.length]);

  useEffect(() => {
    if (timeLeft === 0) {
      handleSubmit();
    }
  }, [timeLeft]);

  // open the info dialog
  useEffect(() => {
    if (isMockInterviewAssigned && timeLeft === 20 * 60) {
      dispatch(mockInterviewInstructionModal({ openInstructionModal: true }));
    } else {
      dispatch(mockInterviewInstructionModal({ openInstructionModal: false }));
      setLocalState({ openInitialDialog: true });
    }
  }, [isMockInterviewAssigned]);

  useEffect(() => {
    if ((!openInstructionModal || openInstructionModal !== null) && !startTime && startTimer) {
      const now = new Date().getTime();
      dispatch(setStartTime({ startTime: now }));
    }
  }, [openInstructionModal, startTime, dispatch, startTimer]);
  useEffect(() => {
    if (!openInstructionModal && startTime) {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const elapsedTime = Math.floor((now - startTime) / 1000);
        const remainingTime = Math.max(0, 20 * 60 - elapsedTime);
        dispatch(updateTimeLeft({ timeLeft: remainingTime }));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [openInstructionModal, startTime, dispatch]);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };
  const [searchParams] = useSearchParams();
  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  const quesStep = +searchParams.get("ques");

  useEffect(() => {
    if (isMockInterviewAssigned && quesStep === 0 && quesStep !== null && !mockInterviewUserRegistered) {
      const userInfoPayload = {
        user_name: userName,
        employee_code: empCode,
      };
      postUserInfo(userInfoPayload)
        .unwrap()
        .then(() => {
          dispatch(manageMockInterviewUserLogin({ mockInterviewUserRegistered: true }));
          getQuestion(quesStep)
            .unwrap()
            .then((res) => {
              const questions = [...questionsList];
              questions.push(res?.question);
              setLocalState({ questionsList: questions });
            })
            .catch(handleError);
        })
        .catch(handleError);
    }
  }, [isMockInterviewAssigned]);

  useEffect(() => {
    if (quesStep > 0 || mockInterviewUserRegistered) {
      if (quesStep === null) {
        return;
      }
      getQuestion(quesStep)
        .unwrap()
        .then((res) => {
          const questions = [...questionsList];
          questions.push(res?.question);
          setLocalState({ questionsList: questions });
        })
        .catch(handleError);
    }
  }, [quesStep]);

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(streamData);
      } catch (err) {
        alert("Microphone access permission required !");
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  useEffect(() => {
    if (!isLoading) {
      getMicrophonePermission();
    }
  }, [isLoading]);
  const startRecording = async () => {
    setRecordingStatus("recording");
    //create new Media recorder instance using the stream
    const media = new MediaRecorder(stream, { type: mimeType });
    //set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") {
        return;
      }
      if (event.data.size === 0) {
        return;
      }
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };

  const stopRecording = () => {
    setRecordingStatus("inactive");
    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = async () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      const blobBase64 = await convertBase64(audioBlob);
      audioFormData.append(`audio_${questionStep}`, audioBlob, "recording.wav");
      let answersListCopy = [...mockResponses];
      answersListCopy.push(blobBase64);
      setAudioFormData(audioFormData);
      setLocalState({ answersList: answersListCopy });
      dispatch(mockInterviewStore({ mockResponses: answersListCopy }));
      setAudioChunks([]);
    };
  };
  const handleNext = () => {
    setLocalState({ questionStep: quesStep + 1 });
    navigate(`${APP_PREFIX}/mock-interview?ques=${quesStep + 1}`);
  };
  const handleSubmit = () => {
    let finalAudioFormData = new FormData();
    mockResponses.map((data, index) => finalAudioFormData.append(`audio_${index}`, convertBase64ToBlob(data), "recording.wav"));
    submitAudio(finalAudioFormData)
      .unwrap()
      .then((res) => {
        let audioFiles = [];
        mockResponses.map((data, index) =>
          audioFiles.push({
            file: data,
            fileName: `audio${index}.wav`,
          }),
        );
        const saveDataPayload = {
          mockInterviewLevelDto: res,
          audioFiles: audioFiles,
        };
        const finalPayload = {
          userId,
          saveDataPayload,
        };
        submitResponses(finalPayload)
          .unwrap()
          .then(() => {
            setLocalState({ startTimer: false });
            dispatch(mockInterviewStore1({ formSubmitted: true }));
            dispatch(mockInterviewResponseArrStore({ responseArr: [] }));
            dispatch(mockInterviewInstructionModal({ openInstructionModal: false }));
            dispatch(updateTimeLeft({ timeLeft: 20 * 60 }));
            dispatch(mockInterviewStatus({ isMockInterviewAssigned: null }));
            dispatch(mockInterviewStore({ mockResponses: [] }));
            localStorage.setItem("feedback", res);
            toast.success(T.ANSWERS_SUBMITTED_SUCCESSFULLY);
            setTimeout(() => {
              dispatch(setStartTime({ startTime: null }));
            }, 200);
            navigate(`${APP_PREFIX}/mock-interview-responses`, {
              state: { responseArr: res, createdAt: null },
            });
          })
          .catch(handleError);
      })
      .catch(handleError);
  };

  const handleInstructionModal = () => {
    dispatch(mockInterviewInstructionModal({ openInstructionModal: false }));
    setLocalState({ startTimer: true });
  };

  return submitIsLoading || submitResponseFetching || isLoading ? (
    <MISLoader />
  ) : (
    <Grid container>
      {(!isMockInterviewAssigned || isMockInterviewAssigned === null) && responseArr.length === 0 ? (
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            zIndex: 9999,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Mock Interview Not Assigned
          </Typography>
          <Typography variant="body1">
            The mock interview has not been assigned to you. Please contact your manager to get it assigned.
          </Typography>
        </Box>
      ) : (
        <>
          <Grid item display={"flex"} justifyContent={"flex-end"} width={"100%"} sx={{ zIndex: 99 }}>
            {timeLeft < 60 ? (
              <Card
                variant="outlined"
                sx={{ p: 1, display: "flex", alignItems: "center", justifyContent: "center", bgcolor: "red", color: "white" }}
              >
                <TimerOutlinedIcon fontSize="small" sx={{ pr: 0.5 }} />
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                  Your assessment will be submitted in {formatTime(timeLeft)?.split(":")[1]} seconds.
                </Typography>
              </Card>
            ) : (
              <Card variant="outlined" sx={{ width: 150, p: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <TimerOutlinedIcon fontSize="small" sx={{ pr: 0.5 }} />
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                  {`Time Left: ${formatTime(timeLeft)}`}
                </Typography>
              </Card>
            )}
          </Grid>
          <Grid item xs={isMd ? 2 : 3.25} />
          <Grid
            item
            xs={isMd ? 8 : 5.5}
            className="questionDiv"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minHeight: "270px",
              width: "inherit",
              minWidth: "300px",
              maxHeight: "650px",
              backgroundColor: "background.white",
              borderRadius: "8px",
              padding: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow: "3px 3px 30px -10px rgba(0,0,0,0.3)",
              overflowY: "auto",
            }}
          >
            {quesStep < 5 ? (
              <Stack>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography
                    variant="subtitle-2"
                    sx={{
                      flexWrap: "wrap",
                      textAlign: "start",
                      color: TEXT.grayBlue,
                      marginBottom: 1,
                    }}
                  >
                    {`Question ${quesStep + 1}/5`}
                  </Typography>
                  <Tooltip title={T.INSTRUCTIONS} placement="top">
                    <IconButton aria-label="info" onClick={handleInstructionModal}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Typography
                  variant="h6"
                  sx={{
                    flexWrap: "wrap",
                    textAlign: "start",
                    fontSize: isXs ? "1.3rem" : "1.6rem",
                    lineHeight: isXs ? "1.3" : "1.6",
                    fontWeight: "bold",
                    mb: 1.5,
                  }}
                >
                  {!isFetching ? question?.question : <Skeleton variant="rectangular" width={"inherit"} height={30} />}
                </Typography>
              </Stack>
            ) : (
              <Typography
                variant="h2"
                sx={{
                  flexWrap: "wrap",
                  textAlign: "center",
                }}
              >
                Thank You!
              </Typography>
            )}
            <QuestionsBoxButtons
              quesStep={quesStep}
              permission={permission}
              recordingStatus={recordingStatus}
              mockResponses={mockResponses}
              startRecording={startRecording}
              questionStep={questionStep}
              stopRecording={stopRecording}
              handleNext={handleNext}
              handleSubmit={handleSubmit}
              audioData={audioData}
            />
          </Grid>
        </>
      )}
      <Grid item xs={isMd ? 2 : 3.25} />
      {openInstructionModal && quesStep === 0 && (
        <InstructionsModal
          openInstructionModal={openInstructionModal}
          micPermission={permission}
          handleInstructionModal={handleInstructionModal}
        />
      )}
    </Grid>
  );
};

export default InterviewQuestions;

import { useEffect, useReducer } from "react";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Menu } from "@mui/material";
import { PITCHED_COLUMNS } from "settings/constants/members";
import Constants from "Constants";
import { handleError } from "utils/error";
import T from "T";
import { toast } from "react-toastify";
import Table from "components/PreSales/PitchedMembers/Table";
import { useCancelInterviewMutation } from "api/preSales/cancelInterview";
import SubmitAssessment from "components/PreSales/PitchedMembers/Table/SubmitAssessment";
import ViewAssessment from "components/PreSales/PitchedMembers/Table/ViewAssessment";
import { useSubmitCodeAssessmentMutation } from "api/pitchedCandidates/submitCodeAssessment";
import { useLazyGetCodeAssessmentDetailsQuery } from "api/pitchedCandidates/getCodeAssesmentDetails";
import InterviewStatus from "components/PreSales/PitchedMembers/Table/InterviewStatus";
import PreInterviewNotification from "components/PreSales/PitchedMembers/Table/PreInterviewNotification";
import { noop } from "lodash";
import { useCancelInterview2Mutation } from "api/preSales/cancelInterview2";

const INITIAL_PAGE = 0;
const ROWS_PER_PAGE = 10000;
const { SERVER_URL } = Constants;
const PitchedMembers = ({ jdId, allTableRows, getPitchedMembers = noop }) => {
  const navigate = useNavigate();

  const [submitCodeAssessment] = useSubmitCodeAssessmentMutation();
  const [cancelInterview] = useCancelInterviewMutation();
  const [cancelInterview2] = useCancelInterview2Mutation();
  const [getAssessmentDetails] = useLazyGetCodeAssessmentDetailsQuery();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isOpenConfigurator: false,
    pastConfiguratorColumns: cloneDeep(PITCHED_COLUMNS),
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    allTableRows: [],
    jdId: null,
    selectedFile: null,
    comments: "",
    exportLoading: false,
  });

  const {
    isOpenConfigurator,
    pastConfiguratorColumns,
    page,
    rowsPerPage,
    openViewDialog,
    openStepperDialog,
    openDialog,
    comments,
    selectedFile,
    openPreInterviewDialog,
  } = localState;

  const handleSubmitAssessmentDialog = (userId) => {
    setLocalState({ openDialog: !openDialog, userId: userId });
  };

  const handleViewAssessmentDialog = (pitchedUserId) => {
    setLocalState({
      openViewDialog: !openViewDialog,
      pitchedUserId: pitchedUserId,
      assessmentDetails: null,
    });
  };
  const handleTrackStatusDialog = (interviewSteps, currentStatus) => {
    setLocalState({
      openStepperDialog: !openStepperDialog,
      interviewSteps: interviewSteps,
      activeStep: currentStatus,
    });
  };

  const handleClose = () => {
    setLocalState({ file: [], comments: "" });
    getPitchedMembers();
    handleSubmitAssessmentDialog();
  };

  const handleExport = async (userId, exportFormat, email) => {
    const pdfUrl = `${SERVER_URL}/selected/user/download/CVFile?userId=${userId}&preSalesMappingId=${jdId}`;
    const docxUrl = `${SERVER_URL}/selected/user/download/CVDocFile?userId=${userId}&preSalesMappingId=${jdId}`;
    const profileDocFile = `${SERVER_URL}/user/download/profileDocFile?userEmail=${email}`;
    const pptUrl = `${SERVER_URL}/selected/user/download/CvPptFile?userId=${userId}&preSalesMappingId=${jdId}`;
    try {
      setLocalState({ exportLoading: true });

      let url;
      if (exportFormat === "pdf") {
        url = pdfUrl;
      } else if (exportFormat === "docx") {
        url = docxUrl;
      } else if (exportFormat === "ppt") {
        url = pptUrl;
      } else {
        url = profileDocFile;
      }
      const response = await fetch(url);
      if (response.ok) {
        window.location.href = url;
        toast.success("CV downloaded successfully.");
      } else {
        throw new Error("An error occurred while fetching the CV.");
      }
      setLocalState({ exportLoading: false });
    } catch (error) {
      handleError(error);
      setLocalState({ exportLoading: false });
    }
  };

  const handleScheduleInterview = (userId, type) => {
    navigate("/app/pre-sales/pitched-members/schedule-interview", {
      state: { jdId, userId, type },
    });
  };

  const handleScheduleMockInterview = (userId) => {
    navigate("/app/pre-sales/pitched-members/schedule-mock-interview", { state: { jdId, userId } });
  };

  const handlePreInterviewDialog = (pitchedUserId) => {
    setLocalState({
      openPreInterviewDialog: !openPreInterviewDialog,
      pitchedUserId: pitchedUserId,
    });
  };
  const handleCodeAssessment = (userId) => {
    navigate("/app/pre-sales/pitched-members/code-assessment", { state: { userId } });
  };

  // function to cancel interview
  const handleCancelInterview = (id, round) => {
    const cancelInterviewFunction = round === "1" ? cancelInterview : cancelInterview2;
    const message = round === "1" ? "Round 1 Interview" : "Round 2 Interview";

    cancelInterviewFunction({ userId: id, presalesMappingId: jdId })
      .unwrap()
      .then(() => {
        toast.success(`${message} cancelled successfully`);
        getPitchedMembers();
      })
      .catch(handleError);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setLocalState({ selectedFile: file });
  };

  const handleInterviewChecklist = (id, managerChecklistVisible) => {
    navigate("/app/interview-checklist", { state: { jdId, id, managerChecklistVisible } });
  };

  const handleSubmitAssessment = (userId) => {
    if (comments) {
      const formData = new FormData();
      formData.append("file", selectedFile ? selectedFile : new Blob());
      formData.append("otherResponse", comments);
      submitCodeAssessment({
        userId: userId,
        formData,
      })
        .then((res) => {
          if (res.error) {
            handleError(res.error);
          } else {
            handleClose();
            toast.success("Code Assesment successfully submitted");
          }
        })
        .catch(handleError);
    } else {
      toast.error("Fill the required fields");
    }
  };

  const updateComments = (newComments) => {
    setLocalState({ comments: newComments });
  };

  useEffect(() => {
    if (openViewDialog) {
      getAssessmentDetails({ pitchedUserId: localState.pitchedUserId })
        .unwrap()
        .then((res) => {
          setLocalState({ assessmentDetails: res });
        })
        .catch((error) => {
          handleError(error);
        });
    }
  }, [localState.pitchedUserId, openViewDialog]);

  const handleCodeAssessmentFile = (pitchedUserId) => {
    const URL = `${SERVER_URL}/pitchedUser/codeAssessment/download/SubmittedCodeAssessmentFile?pitchedUserId=${pitchedUserId}`;

    fetch(URL)
      .then((response) => {
        if (response.ok) {
          window.location.href = URL;
          toast.success(T.FILE_DOWNLOADED_SUCCESSFULLY);
        } else {
          throw new Error("An error occurred while fetching the file.");
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <>
      <Table
        columns={pastConfiguratorColumns.filter((column) => column.checked)}
        lockedColumns={pastConfiguratorColumns.filter((column) => column.locked && column.checked)}
        jdId={jdId}
        page={page}
        rowsPerPage={rowsPerPage}
        handleExport={handleExport}
        handleScheduleInterview={handleScheduleInterview}
        handleScheduleMockInterview={handleScheduleMockInterview}
        handleSubmitAssessmentDialog={handleSubmitAssessmentDialog}
        handleViewAssessmentDialog={handleViewAssessmentDialog}
        handleCodeAssessment={handleCodeAssessment}
        handleCancelInterview={handleCancelInterview}
        handleInterviewChecklist={handleInterviewChecklist}
        navigate={navigate}
        allTableRows={allTableRows}
        handleTrackStatusDialog={handleTrackStatusDialog}
        handlePreInterviewDialog={handlePreInterviewDialog}
      />
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setLocalState({ isOpenConfigurator: null })}
        open={Boolean(isOpenConfigurator)}
        anchorEl={isOpenConfigurator}
        PaperProps={{
          style: {
            left: "50%",
            transform: "translateX(65%) translateY(-15%)",
          },
        }}
      />
      <SubmitAssessment
        handleSubmitAssessmentDialog={handleSubmitAssessmentDialog}
        handleFileChange={handleFileChange}
        handleSubmitAssessment={handleSubmitAssessment}
        selectedFile={selectedFile}
        openDialog={openDialog}
        userId={localState.userId}
        comments={comments}
        updateComments={updateComments}
        handleClose={handleClose}
      />
      <ViewAssessment
        openViewDialog={openViewDialog}
        assessmentDetails={localState.assessmentDetails}
        pitchedUserId={localState.pitchedUserId}
        handleViewAssessmentDialog={handleViewAssessmentDialog}
        handleCodeAssessmentFile={handleCodeAssessmentFile}
      />
      <InterviewStatus
        openStepperDialog={openStepperDialog}
        handleTrackStatusDialog={handleTrackStatusDialog}
        interviewSteps={localState.interviewSteps}
        activeStep={localState.activeStep}
      />
      <PreInterviewNotification
        openPreInterviewDialog={openPreInterviewDialog}
        handlePreInterviewDialog={handlePreInterviewDialog}
        presalesMappingId={jdId}
        userId={localState.pitchedUserId}
        getPitchedMembers={getPitchedMembers}
      />
    </>
  );
};
PitchedMembers.propTypes = {
  jdId: PropTypes.number,
  allTableRows: PropTypes.array,
  getPitchedMembers: PropTypes.func,
};
export default PitchedMembers;

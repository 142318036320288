import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography } from "@mui/material";
import memo from "utils/react";
import { BACKGROUND } from "theme/colors";
import { Box } from "@mui/system";

const DashboardTableHeader = ({ columns = [] }) => (
  <TableHead>
    <TableRow sx={{ height: 30 }}>
      {Children.toArray(
        columns.map((column) => (
          <TableCell
            sx={{
              textAlign: "center",
              border: "none",
              backgroundColor: BACKGROUND.header,
              paddingY: 0,
              paddingX: 2,
            }}
          >
            <Typography fontSize={14} noWrap fontWeight={600} variant="body1" textTransform={"uppercase"}>
              {column.label}
            </Typography>
          </TableCell>
        )),
      )}
    </TableRow>
  </TableHead>
);

export default DashboardTableHeader;

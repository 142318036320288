import { Children } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { TableRow, TableCell, TableBody, styled } from "@mui/material";
import { handleHeaderClass } from "utils/members";
import { BACKGROUND } from "theme/colors";
import { get } from "utils/lodash";
import { GET_SIZE } from "utils/responsive";
import { getMemberColumnData } from "utils/insideSales";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 24px",
}));

const InsideSalesTableBody = ({
  columns = [],
  page = null,
  rowsPerPage = null,
  lockedColumns = [],
  records = [],
  handleExport = noop,
}) => {
  const { isXs } = GET_SIZE();

  return (
    <TableBody>
      {Children.toArray(
        records.map((record, rindex) => {
          return (
            <TableRow sx={{ background: BACKGROUND.white }}>
              {Children.toArray(
                columns.map((column, index) => {
                  const colKey = get(column, "columnKey", "");

                  const { columnData } = getMemberColumnData(record, isXs, colKey, rindex, handleExport, page, rowsPerPage);
                  const isRowLocked = get(column, "locked", false);
                  const isSticky = index < 2;
                  return (
                    <>
                      <StyledTableCell
                        className={handleHeaderClass(index, isRowLocked, lockedColumns)}
                        sx={{
                          position: isSticky ? "sticky" : "inherit",
                          left: isSticky ? `${index * 69}px` : "inherit",
                          zIndex: isSticky ? 2 : "inherit",
                          backgroundColor: "white",
                        }}
                      >
                        {columnData}
                      </StyledTableCell>
                    </>
                  );
                }),
              )}
            </TableRow>
          );
        }),
      )}
    </TableBody>
  );
};

InsideSalesTableBody.propTypes = {
  records: PropTypes.array,
  lockedColumns: PropTypes.array,
  columns: PropTypes.array,
  handleExport: PropTypes.func,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
};

export default InsideSalesTableBody;

import T from "T";
import { format } from "date-fns";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";

// export const downloadCaseStudyFile = async (response) => {
//   const date = format(new Date(), BACKEND_DATE_FORMAT);
//   const blob = await response.blob();
//   const downloadUrl = window.URL.createObjectURL(blob);
//   const a = document.createElement("a");
//   a.href = downloadUrl;
//   a.download = `MIS-${date}`;
//   document.body.appendChild(a);
//   a.click();
//   a.remove();
//   window.URL.revokeObjectURL(downloadUrl);
// };

export const CAMPUS_PLACEMENT_HEADER = [
  { label: T.S_NO },
  { label: T.CANDIDATE_NAME },
  { label: T.CANDIDATE_EMAIL },
  { label: T.CANDIDATE_COLLEGE_NAME },
  { label: T.CANDIDATE_STREAM },
  { label: T.COMMUNICATION_SCORE },
  { label: T.QUESTION_ENGAGEMENT_SCORE },
  { label: T.LOGICAL_LEVEL_SCORE },
  { label: T.NUMBER_OF_QUESTIONS_ATTEMPTED },
  { label: T.CAMPUS_PLACEMENT_ACTIONS },
];

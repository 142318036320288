import { Drawer, Card, CardContent, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useLazyGetReferralStatsQuery } from "api/Referrals/stats";
import { handleError } from "utils/error";
import PropTypes from "prop-types";

const StatsSidebar = ({ open, onClose }) => {
  const [getStats] = useLazyGetReferralStatsQuery();
  const [count, setCount] = useState({ l1Count: 0, l2Count: 0 });
  const [displayCount, setDisplayCount] = useState({ l1Count: 0, l2Count: 0 });

  useEffect(() => {
    if (open) {
      getStats()
        .unwrap()
        .then((res) => {
          setCount({ l1Count: res.l1Count, l2Count: res.l2Count });
        })
        .catch(handleError);
    }
  }, [open]);

  useEffect(() => {
    let interval;
    if (open) {
      interval = setInterval(() => {
        setDisplayCount((prevCount) => {
          const updatedL1Count = Math.min(prevCount.l1Count + 1, count.l1Count);
          const updatedL2Count = Math.min(prevCount.l2Count + 1, count.l2Count);

          if (updatedL1Count >= count.l1Count && updatedL2Count >= count.l2Count) {
            clearInterval(interval);
          }

          return { l1Count: updatedL1Count, l2Count: updatedL2Count };
        });
      }, 30); // Adjust the interval speed if needed
    }

    return () => clearInterval(interval);
  }, [count, open]);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} m={2} mb={1}>
        <Typography variant="h6" fontWeight={"bold"}>
          Recommendation Stats
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Card sx={{ width: 300, margin: 2, bgcolor: "#FF884B", color: "white" }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            L1 Candidates
          </Typography>
          <Typography variant="h4">{displayCount.l1Count}</Typography>
        </CardContent>
      </Card>
      <Card sx={{ width: 300, margin: 2, bgcolor: "#d3a82c", color: "white" }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            L2 Candidates
          </Typography>
          <Typography variant="h4">{displayCount.l2Count}</Typography>
        </CardContent>
      </Card>
    </Drawer>
  );
};
StatsSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.func,
};
export default StatsSidebar;

import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, TableBody, styled } from "@mui/material";
import { BACKGROUND } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import MISNoData from "components/common/MISNoData";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  borderBottom: "none",
  overflow: "hidden",
  fontSize: 14,
  textAlign: "center",
}));
const InterviewTableBody = ({ records = [] }) => {
  return (
    <TableBody>
      {Children.toArray(
        records.map((record, index) => (
          <TableRow sx={{ background: BACKGROUND.white }}>
            <StyledTableCell>{index + 1}.</StyledTableCell>
            <StyledTableCell>{get(record, "clientName", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "startDate", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "endDate", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "platform", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "status", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "rejectedResponse", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "bytManager", "-")}</StyledTableCell>
          </TableRow>
        )),
      )}

      {records && records.length === 0 && (
        <TableRow>
          <StyledTableCell colSpan="10%" sx={{ border: "none" }}>
            <MISNoData />
          </StyledTableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

InterviewTableBody.propTypes = {
  records: PropTypes.array,
};

export default memo(InterviewTableBody);

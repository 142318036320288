import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography, IconButton } from "@mui/material";
import memo from "utils/react";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { Box } from "@mui/system";
import T from "T";
import { camelCase, get, noop } from "lodash";
import NorthSharpIcon from "@mui/icons-material/NorthSharp";

const ToolsLicenceTableHeader = ({ columns = [], activeSort = "", sortDirection = 0, handleToolsSorting = noop }) => (
  <TableHead>
    <TableRow sx={{ height: 30 }}>
      {Children.toArray(
        columns.map((column) => {
          const columnName = get(column, "label", "").toUpperCase();
          const hasSorting = [
            T.TOOLS_NAME.toUpperCase(),
            T.DEPARTMENT.toUpperCase(),
            T.TENURE.toUpperCase(),
            T.RENEWAL_DATE.toUpperCase(),
          ].includes(columnName);

          return (
            <TableCell
              sx={{
                border: "none",
                backgroundColor: BACKGROUND.header,
                paddingY: 0,
              }}
            >
              {hasSorting ? (
                <Box
                  sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                  onClick={() => handleToolsSorting(camelCase(columnName))}
                >
                  <Typography variant="body1" align="center" fontSize={14} noWrap fontWeight={600}>
                    {column.label.toUpperCase()}
                  </Typography>
                  <NorthSharpIcon
                    style={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                      fill: NETSMARTZ_THEME_COLOR,
                      opacity: activeSort === camelCase(columnName) ? (sortDirection === 0 ? 0.3 : 1) : 0.3,
                      transition: "all 500ms",
                      transform:
                        activeSort === camelCase(columnName)
                          ? sortDirection === 1 || sortDirection === 0
                            ? "rotateX(0deg)"
                            : "rotateX(180deg)"
                          : "rotateX(0deg)",
                    }}
                  />
                </Box>
              ) : (
                <Typography variant="body1" align="center" fontSize={14} noWrap fontWeight={600}>
                  {columnName}
                </Typography>
              )}
            </TableCell>
          );
        }),
      )}
    </TableRow>
  </TableHead>
);

export default ToolsLicenceTableHeader;

import PropTypes from "prop-types";
import noop from "lodash/noop";

import { Table as TableView, Paper, TableContainer, Box, Card } from "@mui/material";

import { TESTIMONIAL_TABLE_HEADER } from "settings/constants/members";
import { PAGINATION } from "settings/constants/pagination";

import TestimonialTableBody from "./TestimonialTableBody";
import TableHeader from "./TableHeader";
import MISNoData from "components/common/MISNoData";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const TestimonialTable = ({
  page = INITIAL_PAGE,
  onPageChange = noop,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  allTableRows = [],
  pName = "",
  handleAddEditCaseStudyDialog = noop,
  handleTestimonialUploadDialog = noop,
  handleRejectCaseStudyDialog = noop,
  handleReminderButton = noop,
}) => (
  <Card
    elevation={0}
    sx={{
      p: "0px 8px",
      mt: 1.8,
    }}
  >
    <Box overflow="hidden">
      <TableContainer component={Paper} sx={{ height: "calc(100vh - 180px)", overflowY: "auto" }}>
        {allTableRows.length !== 0 ? (
          <TableView stickyHeader>
            <TableHeader columns={TESTIMONIAL_TABLE_HEADER} />
            <TestimonialTableBody
              page={page}
              onPageChange={page}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={onRowsPerPageChange}
              totalTableRowsCount={totalTableRowsCount}
              totalPageCount={totalPageCount}
              allTableRows={allTableRows}
              handleAddEditCaseStudyDialog={handleAddEditCaseStudyDialog}
              pName
              handleTestimonialUploadDialog={handleTestimonialUploadDialog}
              handleRejectCaseStudyDialog={handleRejectCaseStudyDialog}
              handleReminderButton={handleReminderButton}
            />
          </TableView>
        ) : (
          <MISNoData />
        )}
      </TableContainer>
    </Box>
  </Card>
);

TestimonialTable.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  allTableRows: PropTypes.array,
  handleAddEditCaseStudyDialog: PropTypes.func,
  handleCaseStudyUploadLinkDialog: PropTypes.func,
  handleRejectCaseStudyDialog: PropTypes.func,
};
export default TestimonialTable;

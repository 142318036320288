import { Children } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { TableRow, TableCell, Typography, TableBody, styled } from "@mui/material";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import T from "T";
import { get } from "utils/lodash";
import memo from "utils/react";
import { MONTH_LIST } from "components/Members/Member/memberModel";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  borderBottom: "none",
  overflow: "hidden",
  fontSize: 13,
  paddingBottom: 0,
}));

const NCTableBody = ({ records = [], handleActiveNcCountDialog = noop, handleDeleteNcCountDialog = noop }) => (
  <TableBody>
    {Children.toArray(
      records.map((record, index) => {
        const ncMonth = MONTH_LIST[get(record, "ncMonth", "") - 1];
        const ncMonthCount = MONTH_LIST.indexOf(ncMonth) + 1;
        const typeOfNc = get(record, "typeOfNc", "");
        return (
          <TableRow>
            <StyledTableCell>{index + 1}.</StyledTableCell>
            <StyledTableCell>{ncMonth}</StyledTableCell>
            <StyledTableCell>{typeOfNc}</StyledTableCell>
            <StyledTableCell
              sx={{ color: NETSMARTZ_THEME_COLOR, cursor: "pointer", pl: 5 }}
              onClick={() => (record?.ncCount ? handleActiveNcCountDialog(ncMonthCount, typeOfNc) : null)}
            >
              {get(record, "ncCount", "-")}
            </StyledTableCell>
            <StyledTableCell
              sx={{ color: NETSMARTZ_THEME_COLOR, cursor: "pointer", pl: 9 }}
              onClick={() => (record?.deletedNcCount ? handleDeleteNcCountDialog(ncMonthCount, typeOfNc) : null)}
            >
              {get(record, "deletedNcCount", "-")}
            </StyledTableCell>
          </TableRow>
        );
      }),
    )}

    {records && records.length === 0 && (
      <TableRow>
        <StyledTableCell colSpan="10%" sx={{ border: "none" }}>
          <Typography variant="body1" textAlign="center">
            {T.NC_COMING_SOON}
          </Typography>
        </StyledTableCell>
      </TableRow>
    )}
  </TableBody>
);

NCTableBody.propTypes = {
  records: PropTypes.array,
  refreshMemberTable: PropTypes.func,
  handleActiveNcCountDialog: PropTypes.func,
  handleDeleteNcCountDialog: PropTypes.func,
};

export default memo(NCTableBody);

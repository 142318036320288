import { Children } from "react";
import { Typography, Box, styled, Badge, Link, Tooltip, IconButton } from "@mui/material";
import { COL_KEYS } from "settings/constants/members";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import InfoIcon from "@mui/icons-material/Info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PhoneIcon from "@mui/icons-material/Phone";
import { FRONTEND_DATE_FORMAT } from "settings/constants/date";
import { intervalToDuration, format, isValid, addHours } from "date-fns";
import PDF from "assets/PDF.png";
import DOC from "assets/DOC.png";
import PPT from "assets/PPT.png";
import { get } from "utils/lodash";
import T from "T";
import { toast } from "react-toastify";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import TimelineIcon from "@mui/icons-material/Timeline";
import ActionIconButton from "components/PreSales/PitchedMembers/Table/ActionButton";

export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

export const MainTitleTypography = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 14,
  lineHeight: "22px",
  whiteSpace: "nowrap",
}));

export const SubTitleTypography = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "22px",
  whiteSpace: "nowrap",
}));

export const TitleTypography = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "22px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: 200,
}));

const copyContent = (val, name) => {
  navigator.clipboard.writeText(val);

  toast.success(`${name} ${T.COPIED}`, {
    position: toast.POSITION.BOTTOM_LEFT,
  });
};

export const StyledContentCopyIcon = (val, name) => (
  <ContentCopyIcon fontSize="small" sx={{ ml: 1, cursor: "pointer" }} onClick={() => copyContent(val, name)} />
);

export const handleHeaderClass = (ind, isLocked, lockedColumns) => {
  if (lockedColumns.length === ind + 1) {
    return "sticky-col add-shadow";
  }

  if (isLocked) {
    return "sticky-col";
  }

  return "";
};

const getEmpStatus = (status) => {
  switch (status) {
    case T.STABLE:
      return "success";
    case T.DID_NOT_JOIN:
      return "secondary";
    case T.RESIGNED:
      return "warning";
    case T.ABSCONDED:
      return "error";
    case T.YET_TO_JOIN:
      return "primary";
    default:
      return "primary";
  }
};

export const getFEDateFormat = (val) => {
  if (val) {
    return format(new Date(val.split(" ")[0]), FRONTEND_DATE_FORMAT);
  }
  return "-";
};

export const getMemberColumnData = (
  record,
  isXs,
  column,
  handleExport,
  handleCodeAssessment,
  handleScheduleInterview,
  handleCancelInterview,
  handleSubmitAssessmentDialog,
  handleInterviewChecklist,
  handleViewAssessmentDialog,
  handleTrackStatusDialog,
  handlePreInterviewDialog,
  handleScheduleMockInterview,
) => {
  const joiningDate = get(record, "joiningDateTime", null);
  const empStatus = get(record, "empStatus", "");
  const projects = get(record, "projects", []);
  switch (column) {
    case COL_KEYS.info: {
      const fullName = get(record, "userName", "");
      const empCode = get(record, "employeeCode", "");
      const department = get(record, "userDepartment.departmentName", "");
      const designation = get(record, "userDesignation", "");
      const linkedInUrl = get(record, "linkedInUrl", "");
      const isBytLead = get(record, "isBytLead", false);
      const statusTracker = get(record, "recommendedUser.statusTracker", "");
      const interviewSteps = statusTracker.split(", ").map((status) => status.trim());
      const currentStatus = get(record, "recommendedUser.status.status", "");
      return {
        columnData: (
          <>
            <MainTitleTypography sx={{ display: "inline-flex", alignItems: "center" }}>
              {fullName}
              <Tooltip title={empStatus} placement="top">
                <Badge variant="dot" color={getEmpStatus(empStatus)} sx={{ ml: 1, cursor: "pointer" }} />
              </Tooltip>
              {isBytLead && <StarOutlinedIcon fontSize="small" sx={{ ml: 0.5, color: NETSMARTZ_THEME_COLOR }} />}
              {interviewSteps.length > 1 && (
                <Tooltip title="Track Status" placement="right">
                  <IconButton
                    sx={{ color: NETSMARTZ_THEME_COLOR }}
                    onClick={() => handleTrackStatusDialog(interviewSteps, currentStatus)}
                  >
                    <TimelineIcon />
                  </IconButton>
                </Tooltip>
              )}
            </MainTitleTypography>

            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{T.EMP_CODE}:</SubTitleTypography>
              <TitleTypography ml={1}>{empCode}</TitleTypography>

              {StyledContentCopyIcon(empCode, T.EMP_CODE)}
            </Box>

            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{T.DEPARTMENT}:</SubTitleTypography>
              <TitleTypography ml={1}>{department}</TitleTypography>
            </Box>

            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{T.DESIGNATION}:</SubTitleTypography>
              <Tooltip title={designation} placement="top">
                <TitleTypography ml={1}>{designation}</TitleTypography>
              </Tooltip>
            </Box>
            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{T.LINKED_IN_URL}:</SubTitleTypography>
              <Tooltip title={linkedInUrl} placement="top">
                <TitleTypography ml={1}>{linkedInUrl}</TitleTypography>
              </Tooltip>
              {linkedInUrl && StyledContentCopyIcon(linkedInUrl, T.LINKED_IN_URL)}
            </Box>
          </>
        ),
      };
    }
    case COL_KEYS.contact: {
      const userEmailId = get(record, "userEmailId", "");
      const userMobileNo = get(record, "userMobileNo", "");
      const workLocation = get(record, "workLocation.workLocationName", "");
      const workMode = get(record, "workMode", "");
      const wfoExceptionReason = get(record, "wfoExceptionReason", "");
      return {
        columnData: (
          <>
            <Box display="flex" alignItems="center">
              <Tooltip title={userEmailId} placement="top">
                <TitleTypography>{userEmailId}</TitleTypography>
              </Tooltip>
              {StyledContentCopyIcon(userEmailId, T.EMAIL_ID)}
            </Box>
            <Box display="flex" alignItems="center">
              <TitleTypography>{userMobileNo}</TitleTypography>

              {StyledContentCopyIcon(userMobileNo, T.PHONE)}

              {isXs && (
                <Link href={`tel:${userMobileNo}`} ml={0.8}>
                  <PhoneIcon fontSize="small" />
                </Link>
              )}
            </Box>

            <TitleTypography>{workLocation}</TitleTypography>

            <TitleTypography display="flex" alignItems="center">
              {workMode}

              {wfoExceptionReason && (
                <Tooltip title={wfoExceptionReason} placement="top">
                  <InfoIcon fontSize="small" sx={{ ml: 1, cursor: "pointer" }} />
                </Tooltip>
              )}
            </TitleTypography>
          </>
        ),
      };
    }
    case COL_KEYS.primaryProject: {
      const primaryProjects = projects.filter((data) => data.primary);
      return {
        columnData: (
          <>
            {Children.toArray(
              primaryProjects.map((project) => {
                const projectName = get(project, "project.name", "");
                const projEndDate = new Date(get(project, "endDate", ""));
                const isProjectActive =
                  (isValid(projEndDate) && new Date() < projEndDate) || new Date().getDate() === projEndDate.getDate();
                return (
                  <MainTitleTypography display="flex" alignItems="center">
                    {isProjectActive && projectName}

                    {isProjectActive && projectName && (
                      <Tooltip
                        placement="top"
                        title={
                          <>
                            <Box display="flex" whiteSpace="nowrap">
                              <SubTitleTypography>{T.START_DATE}:</SubTitleTypography>
                              <TitleTypography ml={1}>{getFEDateFormat(get(project, "startDate", null))}</TitleTypography>
                            </Box>

                            <Box display="flex" whiteSpace="nowrap">
                              <SubTitleTypography>{T.END_DATE}:</SubTitleTypography>
                              <TitleTypography ml={1}>{getFEDateFormat(get(project, "endDate", null))}</TitleTypography>
                            </Box>

                            <Box display="flex" whiteSpace="nowrap">
                              <SubTitleTypography>{T.PM}:</SubTitleTypography>
                              <TitleTypography ml={1}>{get(project, "project.projectManager", {}).name}</TitleTypography>
                            </Box>
                          </>
                        }
                      >
                        <InfoIcon fontSize="small" sx={{ ml: 1, cursor: "pointer" }} />
                      </Tooltip>
                    )}
                  </MainTitleTypography>
                );
              }),
            )}
          </>
        ),
      };
    }
    case COL_KEYS.secondaryProject: {
      const secondaryProjects = projects.filter((data) => data.secondary);

      return {
        columnData: (
          <>
            {Children.toArray(
              secondaryProjects.map((project) => {
                const secProjectName = get(project, "project.name", "");
                const projEndDate = new Date(get(project, "endDate", ""));
                const isProjectActive =
                  (isValid(projEndDate) && new Date() < projEndDate) || new Date().getDate() === projEndDate.getDate();

                return (
                  <MainTitleTypography display="flex" alignItems="center">
                    {isProjectActive && secProjectName}

                    {isProjectActive && secProjectName && (
                      <Tooltip
                        placement="top"
                        title={
                          <>
                            <Box display="flex" whiteSpace="nowrap">
                              <SubTitleTypography>{T.START_DATE}:</SubTitleTypography>
                              <TitleTypography ml={1}>{getFEDateFormat(get(project, "startDate", null))}</TitleTypography>
                            </Box>

                            <Box display="flex" whiteSpace="nowrap">
                              <SubTitleTypography>{T.END_DATE}:</SubTitleTypography>
                              <TitleTypography ml={1}>{getFEDateFormat(get(project, "endDate", null))}</TitleTypography>
                            </Box>

                            <Box display="flex" whiteSpace="nowrap">
                              <SubTitleTypography>{T.PM}:</SubTitleTypography>
                              <TitleTypography ml={1}>{get(project, "project.projectManager", {}).name}</TitleTypography>
                            </Box>
                          </>
                        }
                      >
                        <InfoIcon fontSize="small" sx={{ ml: 1, cursor: "pointer" }} />
                      </Tooltip>
                    )}
                  </MainTitleTypography>
                );
              }),
            )}
          </>
        ),
      };
    }
    case COL_KEYS.experience: {
      const totalDuration = joiningDate
        ? intervalToDuration({
            start: new Date(joiningDate.split(" ")[0]),
            end: new Date(),
          })
        : "";

      return {
        columnData: (
          <>
            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{T.TOTAL_EXP_AS_ON_DATE}:</SubTitleTypography>
              <TitleTypography ml={1}>
                {`${get(record, "expInYears", 0)}.${get(record, "expInMonths", 0)} ${T.YEARS.toLowerCase()}`}
              </TitleTypography>
            </Box>

            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{T.TENURE_WITH_NETSMARTZ}:</SubTitleTypography>
              <TitleTypography ml={1}>{`${get(totalDuration, "years", "")}.${get(
                totalDuration,
                "months",
                "",
              )} years`}</TitleTypography>
            </Box>
          </>
        ),
      };
    }
    case COL_KEYS.technology: {
      const skills = get(record, "userSkills", []);
      const primarySkills = skills
        .filter((skill) => get(skill, "primary", false))
        .map((skill) => get(skill, "skills.skillName", "-"))
        .join(", ");
      const secondarySkills = skills
        .filter((skill) => get(skill, "secondary", false))
        .map((skill) => get(skill, "skills.skillName", "-"))
        .join(", ");
      return {
        columnData: (
          <>
            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{`${T.PRIMARY_SKILL}s`}:</SubTitleTypography>
              <Tooltip title={primarySkills} placement="top">
                <TitleTypography ml={1}>{primarySkills}</TitleTypography>
              </Tooltip>
            </Box>
            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{T.SECONDARY_SKILLS}:</SubTitleTypography>
              <Tooltip title={secondarySkills} placement="top">
                <TitleTypography ml={1}>{secondarySkills}</TitleTypography>
              </Tooltip>
            </Box>
          </>
        ),
      };
    }

    case COL_KEYS.reportingManager: {
      const reportingManager = get(record, "reportingManager.name", "-");

      return {
        columnData: (
          <Box mr={6}>
            <TitleTypography>{reportingManager}</TitleTypography>
          </Box>
        ),
      };
    }

    case COL_KEYS.interviewSchedule: {
      const pitchedUserInterviewSchedule = get(record, "pitchedUserInterviewSchedule", "-");

      return {
        columnData: (
          <>
            {pitchedUserInterviewSchedule.length > 0 ? (
              <Box mr={4}>
                {pitchedUserInterviewSchedule[0]?.interviewDate && (
                  <Box display="flex" whiteSpace="nowrap">
                    <SubTitleTypography>Round 1 Interview Date:</SubTitleTypography>
                    <TitleTypography ml={1}>{pitchedUserInterviewSchedule[0].interviewDate}</TitleTypography>
                  </Box>
                )}
                {pitchedUserInterviewSchedule[0]?.interviewTime && (
                  <Box display="flex" whiteSpace="nowrap">
                    <SubTitleTypography>Round 1 Interview Time:</SubTitleTypography>
                    <TitleTypography ml={1}>{pitchedUserInterviewSchedule[0].interviewTime}</TitleTypography>
                  </Box>
                )}
                {pitchedUserInterviewSchedule[1]?.interviewDate && (
                  <Box display="flex" whiteSpace="nowrap">
                    <SubTitleTypography>Round 2 Interview Date:</SubTitleTypography>
                    <TitleTypography ml={1}>{pitchedUserInterviewSchedule[1].interviewDate}</TitleTypography>
                  </Box>
                )}
                {pitchedUserInterviewSchedule[1]?.interviewTime && (
                  <Box display="flex" whiteSpace="nowrap">
                    <SubTitleTypography>Round 2 Interview Time:</SubTitleTypography>
                    <TitleTypography ml={1}>{pitchedUserInterviewSchedule[1].interviewTime}</TitleTypography>
                  </Box>
                )}
              </Box>
            ) : (
              <TitleTypography ml={8}>-</TitleTypography>
            )}
          </>
        ),
      };
    }

    case COL_KEYS.cvDownload: {
      const cvDownload = get(record, "cvDownload", "");
      const email = get(record, "userEmailId", "");
      const userId = get(record, "id", "");
      const pdfLink = get(record, "pdfLink", "");
      const docLink = get(record, "docLink", "");
      const pptLink = get(record, "pptLink", "");
      const profileDocFile = get(record, "profileDocFile", "");
      return {
        columnData: (
          <>
            <TitleTypography sx={{ width: "104px" }}>{cvDownload}</TitleTypography>

            <Box display="flex" whiteSpace="nowrap">
              {pdfLink && (
                <Tooltip title={T.PDF} placement="top">
                  <StyledBox
                    component="img"
                    src={PDF}
                    pr={1}
                    onClick={() => {
                      handleExport(userId, "pdf", email);
                    }}
                  />
                </Tooltip>
              )}
              {docLink && (
                <Tooltip title={T.DOC} placement="top">
                  <StyledBox
                    component="img"
                    src={DOC}
                    pr={1}
                    onClick={() => {
                      handleExport(userId, "docx", email);
                    }}
                  />
                </Tooltip>
              )}
              {pptLink && (
                <Tooltip title={T.PPT} placement="top">
                  <StyledBox
                    component="img"
                    src={PPT}
                    pr={1}
                    onClick={() => {
                      handleExport(userId, "ppt", email);
                    }}
                  />
                </Tooltip>
              )}
              {!pdfLink && !docLink && profileDocFile ? (
                <>
                  {profileDocFile && (
                    <Tooltip title={T.DOC} placement="top">
                      <StyledBox
                        component="img"
                        src={DOC}
                        pr={1}
                        onClick={() => {
                          handleExport(userId, null, email);
                        }}
                      />
                    </Tooltip>
                  )}
                </>
              ) : null}
            </Box>
          </>
        ),
      };
    }

    case COL_KEYS.action: {
      const id = get(record, "id", "");
      const userId = get(record, "recommendedUser.id", "");
      const interviewSchedules = get(record, "recommendedUser.interviewSchedules", "");
      const interviewRound = interviewSchedules?.map((item) => item.round);
      const hasInterviewSchedules = interviewSchedules.length > 0;
      const codeAssessment = get(record, "recommendedUser.codeAssessment", "");
      const hasCodeAssessment = codeAssessment !== "";
      const codeAssessmentSubmission = get(record, "recommendedUser.codeAssessmentSubmission");
      const interview1Date = get(record, "pitchedUserInterviewSchedule[0].interviewDate");
      const interview1Time = get(record, "pitchedUserInterviewSchedule[0].interviewTime");
      const interview2Date = get(record, "pitchedUserInterviewSchedule[1].interviewDate");
      const interview2Time = get(record, "pitchedUserInterviewSchedule[1].interviewTime");
      const currentDateTime = new Date();
      const interview1DateTime = new Date(interview1Date + " " + interview1Time);
      const interview2DateTime = new Date(interview2Date + " " + interview2Time);
      const managerChecklistVisible = interview1DateTime < currentDateTime && currentDateTime < addHours(interview1DateTime, 24);
      const pitchedUserNotified = get(record, "pitchedUserNotified", null);
      const hasMockInterviewSchedule = get(record, "pitchedUserScheduleMockInterview", null);
      const hasRound2 = interviewRound && interviewRound.includes(2);

      return {
        columnData: (
          <Box display={"flex"} justifyContent={"center"}>
            <ActionIconButton
              handleCodeAssessment={handleCodeAssessment}
              handleSubmitAssessmentDialog={handleSubmitAssessmentDialog}
              handleViewAssessmentDialog={handleViewAssessmentDialog}
              handleScheduleInterview={handleScheduleInterview}
              handleCancelInterview={handleCancelInterview}
              handleInterviewChecklist={handleInterviewChecklist}
              handlePreInterviewDialog={handlePreInterviewDialog}
              handleScheduleMockInterview={handleScheduleMockInterview}
              userId={userId}
              id={id}
              hasCodeAssessment={hasCodeAssessment}
              codeAssessmentSubmission={codeAssessmentSubmission}
              hasInterviewSchedules={hasInterviewSchedules}
              interviewRound={interviewRound}
              currentDateTime={currentDateTime}
              interview1DateTime={interview1DateTime}
              interview2DateTime={interview2DateTime}
              managerChecklistVisible={managerChecklistVisible}
              pitchedUserNotified={pitchedUserNotified}
              hasMockInterviewSchedule={hasMockInterviewSchedule}
              hasRound2={hasRound2}
            />
          </Box>
        ),
      };
    }

    default:
      return { columnData: <TitleTypography /> };
  }
};

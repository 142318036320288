import { Children, useReducer } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Box, TableRow, TableCell, Typography, TableBody, Tooltip, styled, IconButton, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SubTitleTypography, TitleTypography, StyledContentCopyIcon } from "utils/members";
import { BACKGROUND, BORDER, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import { capitalizeWords } from "utils/commonFunction";
import T from "T";
import { useLicenseStatusMutation } from "api/ISTools/ToolsLicence/licenseStatus";
import { useRenewLicenseMutation } from "api/ISTools/ToolsLicence/renewLicense";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { getFEDateFormat } from "utils/members";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 16px",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const ToolsLicenceTableBody = ({ allTableRows = [], handleOpenContractHistory = noop, refreshOnStatusChange = noop }) => {
  const navigate = useNavigate();
  const [licenseStatus] = useLicenseStatusMutation();
  const [renewLicense] = useRenewLicenseMutation();

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    openStatusConfirmSubmit: false,
    openRenewConfirmSubmit: false,
    licenseId: "",
    active: true,
  });
  const { openStatusConfirmSubmit, openRenewConfirmSubmit, licenseId, active } = localState;
  const handleEditLicense = (id, toolName, licenseHolder) => {
    navigate(`/app/is-tools/${id}/edit-license`, { state: { toolName, licenseHolder } });
  };

  const handleLicenseStatus = (id, status) => {
    licenseStatus({ id, status })
      .unwrap()
      .then((res) => {
        toast.success(active ? T.LICENSE_DEACTIVATED_SUCCESSFULLY : T.LICENSE_ACTIVATED_SUCCESSFULLY);
        setLocalState({ openStatusConfirmSubmit: false });
        refreshOnStatusChange();
      })
      .catch(handleError);
  };

  const handleRenewLicense = (id) => {
    renewLicense(id)
      .unwrap()
      .then((res) => {
        toast.success(T.LICENSE_RENEWED_SUCCESSFULLY);
        setLocalState({ openRenewConfirmSubmit: false });
        refreshOnStatusChange();
      })
      .catch(handleError);
  };

  const getColor = (days) => {
    switch (true) {
      case days >= 14:
        return "#40c55e";
      case days < 14 && days >= 7:
        return "#d3a82c";
      case days < 7:
        return "#d44c4b";
      default:
        return "#d44c4b";
    }
  };
  const handleOpenStatusConfirmSubmit = (id, active) => {
    setLocalState({ openStatusConfirmSubmit: true, active: active, licenseId: id });
  };

  const handleCloseStatusConfirmSubmit = () => {
    setLocalState({ openStatusConfirmSubmit: false, active: true, licenseId: "" });
  };

  const handleOpenRenewConfirmSubmit = (id) => {
    setLocalState({ openRenewConfirmSubmit: true, licenseId: id });
  };

  const handleCloseRenewConfirmSubmit = () => {
    setLocalState({ openRenewConfirmSubmit: false, licenseId: "" });
  };

  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const {
            toolsUserMappingId,
            autoRenewal,
            cost,
            costCenter,
            createdBy,
            profitCenterId,
            purchaseDate,
            renewalDate,
            tenure,
            toolsId,
            remarks,
            licenseHolder,
            active,
          } = record;
          const toolName = get(toolsId, "toolsName", "");
          const profitCenterName = get(profitCenterId, "profitCenterName", "");
          const createdByName = get(createdBy, "userName", "");
          const differenceInDays = (new Date(renewalDate) - new Date()) / (1000 * 60 * 60 * 24);
          const contractHistoryList = get(record, "isContractHistories", []);
          return (
            <StyledTableRow key={index}>
              <StyledTableCell
                sx={{
                  borderLeft: `5px solid ${tenure === T.ONE_TIME ? "#40c55e" : getColor(differenceInDays)}`,
                  borderRadius: "5px 0px 0px 5px",
                }}
              >
                <TitleTypography>{toolName}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{capitalizeWords(profitCenterName)}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                {Children.toArray(
                  costCenter.map((data) => {
                    const { costCenter } = data;
                    return <TitleTypography>{costCenter.costCenterName}</TitleTypography>;
                  }),
                )}
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{`$ ${parseFloat(cost).toFixed(2)} `}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{capitalizeWords(licenseHolder)}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{autoRenewal ? T.YES : T.NO}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{tenure}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{getFEDateFormat(renewalDate)}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <Tooltip title={remarks ? remarks : "N/A"} placement="top">
                  <TitleTypography sx={{ color: remarks ? NETSMARTZ_THEME_COLOR : "", cursor: "pointer" }}>
                    {remarks ? remarks : "N/A"}
                  </TitleTypography>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{createdByName}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <Grid display={"inline-flex"} alignItems={"center"}>
                  <Tooltip title={"Contract History"}>
                    <IconButton
                      sx={{ color: NETSMARTZ_THEME_COLOR }}
                      onClick={() => handleOpenContractHistory(toolsUserMappingId)}
                      disabled={contractHistoryList.length === 0}
                    >
                      <BackupTableIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Renew Tool"}>
                    <IconButton
                      sx={{ color: NETSMARTZ_THEME_COLOR }}
                      disabled={differenceInDays > 0 || tenure === T.ONE_TIME}
                      onClick={() => handleOpenRenewConfirmSubmit(toolsUserMappingId)}
                    >
                      <AutorenewIcon />
                    </IconButton>
                  </Tooltip>

                  <IconButton
                    sx={{ color: NETSMARTZ_THEME_COLOR }}
                    onClick={() => handleEditLicense(toolsUserMappingId, toolName, licenseHolder)}
                  >
                    <EditIcon />
                  </IconButton>

                  <Tooltip title={active ? T.DEACTIVATE : T.ACTIVATE} placement="top">
                    <IconButton
                      sx={{ color: active ? NETSMARTZ_THEME_COLOR : BORDER.tabsGrey }}
                      onClick={() => handleOpenStatusConfirmSubmit(toolsUserMappingId, active)}
                    >
                      {active ? <ToggleOnIcon /> : <ToggleOffIcon />}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </StyledTableCell>
            </StyledTableRow>
          );
        }),
      )}
      <ConfirmSubmit
        dialogMessageText={active ? T.WANT_TO_DEACTIVATE_LICENSE : T.WANT_TO_ACTIVATE_LICENSE}
        proceedButtonText={active ? T.DEACTIVATE : T.ACTIVATE}
        openConfirmSubmit={openStatusConfirmSubmit}
        handleClose={handleCloseStatusConfirmSubmit}
        handleSubmit={() => handleLicenseStatus(licenseId, !active)}
      />
      <ConfirmSubmit
        dialogMessageText={T.WANT_TO_RENEW_LICENSE}
        proceedButtonText={T.RENEW}
        openConfirmSubmit={openRenewConfirmSubmit}
        handleClose={handleCloseRenewConfirmSubmit}
        handleSubmit={() => handleRenewLicense(licenseId)}
      />
    </TableBody>
  );
};

export default ToolsLicenceTableBody;

import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getManagerChecklist: builder.query({
      query: ({ userId, presalesMappingId }) => ({
        url: `/pitchedUser/managerChecklist/get`,
        method: "GET",
        params: { userId, presalesMappingId },
      }),
    }),
  }),
});

export const { useLazyGetManagerChecklistQuery } = extendedApi;

import last from "lodash/last";
import capitalize from "lodash/capitalize";
import T from "T";
import routes from "router/routes";
import system from "settings/system";
import matchPathWithParams from "./matchPathWithParams";

const { title: systemTitle } = system;
const allRoutes = Object.keys(routes.app);

// eslint-disable-next-line no-undef
const PAGE_TITLE = new Map([
  [routes.app.forgotPwd, T.FORGOT_PWD],
  [routes.app.changePwd, T.CHANGE_PWD],
  [routes.app.addRoles, T.ADD_ROLE],
  [routes.app.viewMembers, T.VIEW_MEMBER],
  [routes.app.addMembers, T.ADD_MEMBER],
  [routes.app.addConsultant, T.ADD_CONSULTANT],
  [routes.app.editMembers, T.EDIT_MEMBER],
  [routes.app.members, T.MEMBERS],
  [routes.app.nonCompliance, T.NON_COMPLIANCE],
  [routes.app.masterSettings, T.MASTER_SETTINGS],
  [routes.app.preSales, T.PRE_SALES],
  [routes.app.editPreSalesProject, T.EDIT_PRE_SALES_REQUIREMENT],
  [routes.app.recommendedMembers, T.RECOMMENDATIONS],
  [routes.app.shortlistedMembers, T.SHORTLISTED_CANDIDATES],
  [routes.app.pitchedMembers, T.PITCHED_CANDIDATES],
  [routes.app.allPitchedMembers, T.INTERVIEW_TRACKER],
  [routes.app.editInterviewTracker, T.EDIT_ALL_PITCHED],
  [routes.app.scheduleInterview, T.SCHEDULE_INTERVIEW],
  [routes.app.presalesReportCard, T.REPORT_CARD],
  [routes.app.scheduleMockInterview, T.SCHEDULE_MOCK_INTERVIEW],
  [routes.app.codeAssessment, T.CODE_ASSESSMENT],
  [routes.app.interviewChecklist, T.INTERVIEW_CHECKLIST],
  [routes.app.viewClient, T.VIEW_CLIENT],
  [routes.app.createNewProject, T.CREATE_REQUIREMENT],
  [routes.app.caseStudy, T.CASE_STUDY],
  [routes.app.csHistory, T.VERSIONS_HISTORY],
  [routes.app.training, T.TRAINING],
  [routes.app.editCourse, T.EDIT_COURSE],
  [routes.app.addCourse, T.ADD_NEW_COURSE],
  [routes.app.traineeCourse, T.COURSE_DETAIL],
  [routes.app.insideSales, T.INSIDE_SALES],
  [routes.app.feedback, T.FEEDBACK],
  [routes.app.swotAnalysis, T.SWOT_ANALYSIS],
  [routes.app.swotForm, T.ADD_SWOT_ANALYSIS],
  [routes.app.editSwotForm, T.EDIT_SWOT],
  [routes.app.feedbackForm, T.ADD_FEEDBACK],
  [routes.app.groups, T.GROUPS],
  [routes.app.memberAssignments, T.MEMBER_ASSIGNMENTS],
  [routes.app.addAssignment, T.ADD_ASSIGNMENT],
  [routes.app.assignedTopics, T.ASSIGNMENT_DETAILS],
  [routes.app.mockInterview, T.MOCK_INTERVIEW],
  [routes.app.mockAssessment, T.MOCK_ASSESSMENT],
  [routes.app.referrals, T.REFERRALS],
  [routes.app.mockAssessmentResult, T.MOCK_ASSESSMENT_RESULT],
  [routes.app.mockInterviewReponses, T.AI_ASSESSMENT],
  [routes.app.specialIncidentRegister, T.SIR],
  [routes.app.isTools, T.IS_TOOLS],
  [routes.app.addISLicense, T.ADD_LICENCE],
  [routes.app.editISLicense, T.EDIT_TOOLS_LICENCE],
  [routes.app.refer, T.REFER],
  [routes.app.referralRecommendationForm, T.EMPLOYEE_RECOMMENDATION_FORM],
  [routes.app.recommendationDetails, T.RECOMMENDATION_DETAILS],
  [routes.app.employeeProfile, T.EMPLOYEE_PROFILE],
  [routes.app.technicalInterview, T.TECHNICAL_INTERVIEW],
  [routes.app.technicalInterviewUserPage, T.TECHNICAL_INTERVIEW],
  [routes.app.intervieweeLogin, T.INTERVIEW_TOOL],
  [routes.app.screenTest, T.INTERVIEW_TOOL],
  [routes.app.campusPlacement, T.CAMPUS_PLACEMENT],
  [routes.app.assessmentEvaluation, T.CAMPUS_PLACEMENT],


  [("default", T.ERROR_PAGE)],
]);

const getPageTitle = (page) => {
  const result = Object.values(routes.app).find((path) => path && matchPathWithParams(page, path));
  let title = PAGE_TITLE.get(result);
  if (!title) {
    title = allRoutes.includes(last(page.split("/"))) ? capitalize(last(page.split("/"))) : PAGE_TITLE.get("default");
  }
  return `${systemTitle} - ${title}`;
};

export default getPageTitle;

import PropTypes from "prop-types";
import { TableCell, TableBody, styled, TableRow, IconButton, Tooltip, Typography } from "@mui/material";
import memo from "utils/react";
import get from "lodash/get";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import { noop } from "lodash";
import T from "T";
import EditIcon from "@mui/icons-material/Edit";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Description } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  backgroundColor: "white",
  padding: "8px 8px 8px 24px",
  fontSize: 13.2,
  maxWidth: 150,
  textOverflow: "ellipsis",
  overflowWrap: "break-word",
}));
const MISTableBody = ({
  allTableRows,
  page,
  size,
  handleFormToggleDialog = noop,
  handleViewEmployees = noop,
  handleLogDialog = noop,
}) => {
  return (
    <TableBody>
      {allTableRows?.map((record, index) => {
        const serialNumber = index + 1 + page * size;
        return (
          <TableRow key={index}>
            <StyledTableCell sx={{ pl: 4.5 }}>{serialNumber}.</StyledTableCell>
            <StyledTableCell>
              {get(record, "companyName", "-") ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {get(record, "companyName", "-")}
                  <Typography
                    variant="body2"
                    sx={{ color: NETSMARTZ_THEME_COLOR, textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => handleViewEmployees(record.companyName, record.id)}
                  >
                    <span style={{ display: "flex", alignItems: "center", marginTop: "2px" }}>
                      View Employees <ArrowOutwardIcon style={{ fontSize: "12px", marginLeft: "2px" }} />
                    </span>
                  </Typography>
                </div>
              ) : (
                "-"
              )}
            </StyledTableCell>
            <StyledTableCell sx={{ maxWidth: 350 }}>{record.techStack?.map((it) => it?.skillName).toString()}</StyledTableCell>
            <StyledTableCell>{record.minEmp && record.maxEmp ? `${record.minEmp}-${record.maxEmp}` : "-"}</StyledTableCell>
            <StyledTableCell>{get(record, "location", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "createdBy", "-")}</StyledTableCell>
            <StyledTableCell sx={{ textAlign: "center" }}>
              <Tooltip placement="top" title={T.ACTIVITY_LOG}>
                <IconButton
                  sx={{ color: NETSMARTZ_THEME_COLOR }}
                  disabled={record === null}
                  onClick={() => handleLogDialog(record.id)}
                >
                  <Description fontSize="small" />
                </IconButton>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell sx={{ textAlign: "center" }}>
              <Tooltip placement="top" title={T.EDIT}>
                <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }} onClick={() => handleFormToggleDialog(record)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </StyledTableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

MISTableBody.propTypes = {
  page: PropTypes.number,
  size: PropTypes.number,
  handleAddRecommendation: PropTypes.func,
  handleViewEmployees: PropTypes.func,
  allTableRows: PropTypes.array,
  handleFormToggleDialog: PropTypes.func,
  handleLogDialog: PropTypes.func,
};

export default memo(MISTableBody);

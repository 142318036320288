import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography } from "@mui/material";
import memo from "utils/react";
import { BACKGROUND } from "theme/colors";
import { Box } from "@mui/system";

const InsideSalesTableHeader = ({ columns = [] }) => (
  <TableHead>
    <TableRow sx={{ height: 30 }}>
      {Children.toArray(
        columns.map((column) => (
          <TableCell
            sx={{
              border: "none",
              backgroundColor: BACKGROUND.header,
              padding: "0px 8px 0px 24px",
              position: column.label === "S.No." || column.label === "Personal Info" ? "sticky" : "static",
              left: column.label === "S.No." ? 0 : column.label === "Personal Info" ? 70 : "auto",
              zIndex: column.label === "S.No." ? 2 : column.label === "Personal Info" ? 1 : "auto",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography fontSize={14} noWrap fontWeight={600} variant="body1" textTransform={"uppercase"}>
                {column.label}
              </Typography>
            </Box>
          </TableCell>
        )),
      )}
    </TableRow>
  </TableHead>
);

InsideSalesTableHeader.propTypes = {
  columns: PropTypes.array,
};

export default memo(InsideSalesTableHeader);

import PropTypes from "prop-types";
import { get, noop } from "lodash";
import { PAGINATION } from "settings/constants/pagination";
import { useEffect, useReducer } from "react";
import { Table as TableView, TableContainer, Card } from "@mui/material";
import MasterPagination from "components/MasterSetting/MasterPagination";
import MISNoData from "components/common/MISNoData";
import { REFERRALS_TABLE_HEADER } from "settings/constants/members";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import ViewEmployees from "./ViewEmployees";
import { handleError } from "utils/error";
import { useLazyGetNewJoinersQuery } from "api/Referrals/getNewJoiners";
import MISLoader from "components/common/MISLoader";
import T from "T";
import routes from "router/routes";
import { useNavigate } from "react-router-dom";
import { MISCurrentUser } from "utils/validations";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const RecentJoinersTable = ({ handleAskForReferrals = noop }) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    page: INITIAL_PAGE,
    size: ROWS_PER_PAGE,
    totalPageCount: INITIAL_PAGE,
    totalTableRowsCount: INITIAL_PAGE,
    openEmpDialog: false,
    prevCompany: "",
    tableData: [],
    userId: null,
  });
  const { page, size, openEmpDialog, prevCompany, totalPageCount, totalTableRowsCount, tableData, userId } = localState;
  const [getNewJoiners, { isLoading }] = useLazyGetNewJoinersQuery();
  const { user } = MISCurrentUser();
  const role = get(user, "role", null);
  const navigate = useNavigate();
  useEffect(() => {
    getNewJoiners({ page, size })
      .unwrap()
      .then((res) => {
        const results = get(res, "results", []);
        setLocalState({
          tableData: results,
          totalTableRowsCount: get(res, "numberOfRecords", 0),
          totalPageCount: get(res, "numberOfPages", 0),
        });
      })
      .catch(handleError);
  }, [page, size]);

  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, size: value });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const handleViewEmployees = (prevCompany, userId) => {
    setLocalState({ openEmpDialog: !openEmpDialog, prevCompany: prevCompany, userId: userId });
  };
  const handleAddRecommendation = (isSecondLevel, user_id) => {
    navigate(routes.app.referralRecommendationForm, { state: { isSecondLevel: isSecondLevel, user_id: user_id } });
  };

  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mt: 1.5,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      {isLoading && <MISLoader />}
      <TableContainer
        sx={{
          height: "calc(100vh - 310px)",
          overflow: "auto",
        }}
      >
        {tableData.length !== 0 ? (
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            {role === T.TA ? (
              <TableHeader
                columns={REFERRALS_TABLE_HEADER.filter(
                  (column) => column.label !== T.LAST_REQUEST,
                )}
              />
            ) : (
              <TableHeader
                columns={REFERRALS_TABLE_HEADER.filter(
                  (column) => column.label !== T.LAST_REQUEST && column.label !== "",
                )}
              />
            )}

            <TableBody
              allTableRows={tableData}
              handleViewEmployees={handleViewEmployees}
              handleAddRecommendation={handleAddRecommendation}
              page={page}
              size={size}
            />
          </TableView>
        ) : (
          <MISNoData />
        )}
      </TableContainer>
      <ViewEmployees
        openEmpDialog={openEmpDialog}
        handleViewEmployees={handleViewEmployees}
        prevCompany={prevCompany}
        handleAskForReferrals={handleAskForReferrals}
        handleAddRecommendation={handleAddRecommendation}
        userId={userId}
      />
      <MasterPagination
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
        page={page}
        rowsPerPage={size}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Card>
  );
};

RecentJoinersTable.propTypes = {
  handleAskForReferrals: PropTypes.func,
};

export default RecentJoinersTable;

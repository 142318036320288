import { useState } from "react";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { Card, Typography } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";

import T from "T";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { savedFilterStore } from "slices/savedFilterSlice";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import InterviewTrackerFilterOptions from "./InterviewTrackerFilterOptions";

const InterviewTrackerFilters = ({
  projectManagers = {},
  workLocationList = {},
  skillList = {},
  preSalesManagersList = [],
  projectManagersList = [],
  companyNames = [],
  pitchedUserStatusList = [],
  projectList = {},
  filters = {},
  isFilterEmpty = false,
  handleFilterClose = noop,
  handleFilterSubmit = noop,
  onhandleFilterChange = noop,
}) => {
  const dispatch = useDispatch();
  const [filterOpen, setFilterOpen] = useState(false);

  const handleFilterClick = () => {
    setFilterOpen(!filterOpen);
  };

  const onFilterClose = () => {
    dispatch(savedFilterStore({ selectedFilterId: "" }));
    setFilterOpen(false);
    handleFilterClose();
  };

  const onFilterSubmit = () => {
    // dispatch(savedFilterStore({selectedFilterId:""}))
    handleFilterSubmit();
    setFilterOpen(false);
  };
  return (
    <Box>
      <Card
        elevation={0}
        variant="outlined"
        onClick={handleFilterClick}
        sx={{
          p: 1,
          backgroundColor: "background.white",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {isFilterEmpty ? (
          <>
            <Typography variant="body1" fontSize={14}>{`${T.FILTER}s`}</Typography>
            <FilterAltOutlinedIcon fontSize="small" />
          </>
        ) : (
          <>
            <Typography
              variant="body1"
              fontWeight={600}
              sx={{ color: NETSMARTZ_THEME_COLOR }}
              fontSize={14}
            >{`${T.FILTER}s`}</Typography>
            <FilterAltOffOutlinedIcon fontSize="small" />
          </>
        )}
      </Card>

      {filterOpen && (
        <InterviewTrackerFilterOptions
          filters={filters}
          projectManagers={projectManagers}
          workLocationList={workLocationList}
          skillList={skillList}
          companyNames={companyNames}
          projectManagersList={projectManagersList}
          pitchedUserStatusList={pitchedUserStatusList}
          preSalesManagersList={preSalesManagersList}
          projectList={projectList}
          handleFilterClose={onFilterClose}
          handleFilterSubmit={onFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
          handleClose={() => setFilterOpen(false)}
        />
      )}
    </Box>
  );
};

InterviewTrackerFilters.propTypes = {
  projectManagers: PropTypes.object,
  workLocationList: PropTypes.object,
  skillList: PropTypes.object,
  preSalesManagersList: PropTypes.array,
  departmentList: PropTypes.object,
  projectList: PropTypes.object,
  filters: PropTypes.object,
  isFilterEmpty: PropTypes.bool,
  onhandleFilterChange: PropTypes.func,
  handleFilterClose: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
};

export default InterviewTrackerFilters;

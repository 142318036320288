import { Table, TableContainer, Card, Typography, Grid } from "@mui/material";
import SIRTableHead from "./TableHeader";
import MISNoData from "components/common/MISNoData";
import { Box } from "@mui/system";
import { BACKGROUND } from "theme/colors";
import { SIR_HEADER } from "settings/constants/members";
import SIRTableBody from "./TableBody";
import T from "T";
import PropTypes from "prop-types";

const SIRTable = ({ sirData }) => {
  return (
    <Card sx={{ mt: 3, p: 2, height: 240, backgroundColor: BACKGROUND.card }} elevation={2}>
      <Grid item sm={6} xs={12} display="flex" alignItems="center" pb={1}>
        <Typography fontSize={17} fontWeight={600}>
          {T.SPECIAL_INCIDENT_REGISTER}
        </Typography>
      </Grid>
      <TableContainer
        sx={{
          overflow: "auto",
          height: 220,
        }}
      >
        {sirData.length > 0 ? (
          <Table>
            <SIRTableHead columns={SIR_HEADER} />
            <SIRTableBody sirData={sirData} />
          </Table>
        ) : (
          <Box>
            <MISNoData />
          </Box>
        )}
      </TableContainer>
    </Card>
  );
};
SIRTable.propTypes = {
  sirData: PropTypes.array,
};
export default SIRTable;

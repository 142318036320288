import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTestimonialByFilter: build.mutation({
      query: ({ testimonialFilterRequestDto }) => ({
        url: `/testimonial/testimonial/filter`,
        method: "POST",
        body: testimonialFilterRequestDto,
      }),
    }),
  }),
});

export const { useGetTestimonialByFilterMutation } = extendedApi;

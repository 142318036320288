import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCaseStudyByFilter: build.mutation({
      query: ({ page, rowsPerPage, caseStudyFilterRequestDto }) => ({
        url: `/caseStudy/filter?page=${page}&size=${rowsPerPage}`,
        method: "POST",
        body: caseStudyFilterRequestDto,
      }),
    }),
  }),
});

export const { useGetCaseStudyByFilterMutation } = extendedApi;

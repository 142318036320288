import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Typography, Card, Grid } from "@mui/material";
import T from "T";
import XLS from "assets/XLS.png";
import InterviewTable from "./InterviewTable";

const InterviewHistory = ({ allTableRows = [], handleAddFeedback = noop }) => (
  <Card sx={{ mt: 3, p: 2 }} elevation={2}>
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12} display="flex" alignItems="center">
        <Typography fontSize={18} fontWeight={600}>
          {`${T.INTERVIEW_HISTORY}`}
        </Typography>
      </Grid>

      {/* <Grid item sm={6} xs={12} display="flex" columnGap={2}>
        <Tooltip title={T.XLS} placement="top">
          <StyledBox component="img" src={XLS} sx={{ margin: "0 0 0 auto" }} onClick={() => handleExport(T.XL)} />
        </Tooltip>
      </Grid> */}
    </Grid>

    <InterviewTable allTableRows={allTableRows} handleAddFeedback={handleAddFeedback} />
  </Card>
);

InterviewHistory.propTypes = {
  allTableRows: PropTypes.array,
  handleAddFeedback: PropTypes.func,
};

export default InterviewHistory;

import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography } from "@mui/material";
import memo from "utils/react";
import { get } from "utils/lodash";
import { BACKGROUND } from "theme/colors";
import { Box } from "@mui/system";

const FeedbackHistoryHeader = ({ columns = [] }) => (
  <TableHead>
    <TableRow>
      {Children.toArray(
        columns.map((column, index) => (
          <TableCell
            align={get(column, "align", "")}
            sx={{
              border: "none",
              backgroundColor: BACKGROUND.cardDefault,
              padding: "10px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" align="left" fontSize={14} noWrap fontWeight={600} style={{ width: "150px" }}>
                {column.label}
              </Typography>
            </Box>
          </TableCell>
        )),
      )}
    </TableRow>
  </TableHead>
);

FeedbackHistoryHeader.propTypes = {
  columns: PropTypes.array,
};

export default memo(FeedbackHistoryHeader);

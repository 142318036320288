import React, { Children, memo } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { get } from "utils/lodash";
import { TableRow, TableCell, Typography, TableBody, styled, Rating, Tooltip, Badge, IconButton, Button } from "@mui/material";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import { MainTitleTypography, SubTitleTypography, TitleTypography } from "utils/members";
import { MISCurrentUser } from "utils/validations";
import { BACKGROUND } from "theme/colors";
import MISButton from "components/common/MISButton";
import { NotificationsOutlined } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  backgroundColor: "white",
  padding: "12px 8px 12px 24px",
  fontSize: 13.2,
  maxWidth: 130,
  overflowWrap: "break-word",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const TaskboxTablebody = ({ allTableRows = [], handleAct = noop, setCurrentFeedBack = noop }) => {
  const { user } = MISCurrentUser();
  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const serialNumber = index + 1;
          const name = get(record, "userId.userName", "-");
          const type = get(record, "trainingType", "-");
          const task = get(record, "feedback.feedbackName", "-");
          const triggerDate = get(record, "triggerDate", "-");
          const dueDate = get(record, "dueDate", "-");
          const feedback = get(record, "feedback", null);
          const courseName = get(record, "courseName", "-");
          return (
            <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
              <StyledTableCell
                sx={{
                  backgroundColor: "white",
                  pl: 4,
                }}
              >
                <Typography>{serialNumber}</Typography>
              </StyledTableCell>

              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <Tooltip title={name} placement="top">
                  <Typography>{name}</Typography>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <Tooltip title={type} placement="top">
                  <Typography>{type}</Typography>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <Tooltip title={courseName} placement="top">
                  <Typography>{courseName}</Typography>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <Tooltip title={task} placement="top">
                  <Typography>{task}</Typography>
                </Tooltip>
              </StyledTableCell>

              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <Tooltip title={triggerDate} placement="top">
                  <Typography>{triggerDate}</Typography>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <Tooltip title={dueDate} placement="top">
                  <Typography>{dueDate}</Typography>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <MISButton
                  startIcon={<NotificationsOutlined sx={{ width: 19 }} />}
                  onClick={() => {
                    setCurrentFeedBack(record);
                    handleAct();
                  }}
                >
                  Act
                </MISButton>
              </StyledTableCell>
            </StyledTableRow>
          );
        }),
      )}
    </TableBody>
  );
};

TaskboxTablebody.propTypes = {
  allTableRows: PropTypes.array,
};

export default memo(TaskboxTablebody);

import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, TableBody, styled } from "@mui/material";
import { BACKGROUND } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import { getMemberColumnData, handleHeaderClass } from "utils/interviewTracker";
import { noop } from "lodash";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 24px",
}));

const MISTableBody = ({
  columns = [],
  lockedColumns = [],
  records = [],
  handleEditAllPitched = noop,
  page = null,
  rowsPerPage = null,
  handleExport = -noop,
}) => {
  return (
    <TableBody>
      {Children?.toArray(
        records?.map((record, recordIndex) => {
          return (
            <TableRow sx={{ background: BACKGROUND.white }}>
              {Children?.toArray(
                columns?.map((column, index) => {
                  const { columnData } = getMemberColumnData(
                    record,
                    column,
                    handleEditAllPitched,
                    recordIndex,
                    page,
                    rowsPerPage,
                    handleExport,
                  );
                  const isRowLocked = get(column, "locked", false);

                  return (
                    <StyledTableCell className={handleHeaderClass(index, isRowLocked, lockedColumns)}>
                      {columnData}
                    </StyledTableCell>
                  );
                }),
              )}
            </TableRow>
          );
        }),
      )}
    </TableBody>
  );
};

MISTableBody.propTypes = {
  records: PropTypes.array,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  columns: PropTypes.array,
  lockedColumns: PropTypes.array,
  handleEditAllPitched: PropTypes.func,
  handleExport: PropTypes.func,
};

export default memo(MISTableBody);

import { useEffect, useReducer } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Typography, Box, Card, CardActions } from "@mui/material";
import MISTextField from "components/common/MISTextField";
import T from "T";
import MISFooterButton from "components/common/MISFooterButton";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAddSwotAnalysisMutation } from "api/members/addSwotAnalysis";
import ConfirmSubmit from "../ConfirmSubmit";
import { BACKGROUND } from "theme/colors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
const CHARACTER_LIMIT = 250;

const SwotForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [submitSwotAnalysis, { isLoading }] = useAddSwotAnalysisMutation();
  const url = location.pathname;
  const id = Number(url.replace(/\D+/g, ""));
  const swotAnalysisResponseDto = location.state && location.state.swotAnalysisResponseDto;

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    strengths: [""],
    weaknesses: [""],
    opportunities: [""],
    threats: [""],
    openModal: false,
    swotAnalysisId: null,
  });
  const { strengths, weaknesses, opportunities, threats, openModal, swotAnalysisId } = localState;

  useEffect(() => {
    if (swotAnalysisResponseDto && swotAnalysisResponseDto.length > 0) {
      const typeHandlers = {
        S: (formattedData, description) => formattedData.strengths.push(description),
        W: (formattedData, description) => formattedData.weaknesses.push(description),
        O: (formattedData, description) => formattedData.opportunities.push(description),
        T: (formattedData, description) => formattedData.threats.push(description),
      };

      const formattedData = {
        strengths: [],
        weaknesses: [],
        opportunities: [],
        threats: [],
        swotAnalysisId,
      };

      swotAnalysisResponseDto.forEach((item) => {
        const { type, description } = item;
        const handler = typeHandlers[type];
        if (handler) {
          handler(formattedData, description);
        }
      });

      setLocalState({
        strengths: formattedData.strengths.length > 0 ? formattedData.strengths : [""],
        weaknesses: formattedData.weaknesses.length > 0 ? formattedData.weaknesses : [""],
        opportunities: formattedData.opportunities.length > 0 ? formattedData.opportunities : [""],
        threats: formattedData.threats.length > 0 ? formattedData.threats : [""],
        swotAnalysisId,
      });
    }
  }, [swotAnalysisResponseDto]);

  const handleAddEditSWOT = () => {
    const formattedData = [];

    const mapSwotAnalysisResponseDto = {};

    swotAnalysisResponseDto &&
      swotAnalysisResponseDto.forEach((item) => {
        const { type, description, swotAnalysisId } = item;
        mapSwotAnalysisResponseDto[`${type}-${description}`] = swotAnalysisId;
      });

    const addToFormattedData = (items, type) => {
      items.forEach((item, index) => {
        const description = item.trim();
        if (description) {
          const swotAnalysisId = mapSwotAnalysisResponseDto[`${type}-${description}`] || null;

          formattedData.push({
            swotAnalysisId,
            type,
            description,
          });
        }
      });
    };

    addToFormattedData(strengths, "S");
    addToFormattedData(weaknesses, "W");
    addToFormattedData(opportunities, "O");
    addToFormattedData(threats, "T");

    const formData = {
      swotAnalysisRequestDtos: formattedData,
    };

    if (formattedData.length > 0) {
      submitSwotAnalysis({ id, formData })
        .unwrap()
        .then(() => {
          navigate(-1);
          setTimeout(function () {
            if (swotAnalysisResponseDto && swotAnalysisResponseDto.length) {
              toast.success(T.SWOT_ANALYSIS_UPDATED_SUCCESSFULLY);
            } else {
              toast.success(T.SWOT_ANALYSIS_SUBMITTED_SUCCESSFULLY);
            }
          }, 300);
        })
        .catch(handleError);
    }
  };

  const handleValidation = () => {
    return (
      strengths.some((item) => item.trim() !== "") ||
      weaknesses.some((item) => item.trim() !== "") ||
      opportunities.some((item) => item.trim() !== "") ||
      threats.some((item) => item.trim() !== "")
    );
  };

  const handleSubmitModal = () => {
    if (handleValidation()) {
      setLocalState({ openModal: true });
    } else {
      toast.error("Please fill at least one field.");
    }
  };
  const handleCloseModal = () => {
    setLocalState({ openModal: false });
  };

  const handleClose = () => {
    navigate(-1);
  };
  const handleAddStrength = () => {
    setLocalState({ strengths: [...strengths, ""] });
  };
  const handleRemoveStrength = (index) => {
    if (strengths.length === 1) {
      const updatedStrengths = [""];
      setLocalState({ strengths: updatedStrengths });
    } else {
      const updatedStrengths = [...strengths];
      updatedStrengths.splice(index, 1);
      setLocalState({ strengths: updatedStrengths });
    }
  };

  const handleAddWeakness = () => {
    setLocalState({ weaknesses: [...weaknesses, ""] });
  };

  const handleRemoveWeakness = (index) => {
    if (weaknesses.length === 1) {
      const updatedWeaknesses = [""];
      setLocalState({ weaknesses: updatedWeaknesses });
    } else {
      const updatedWeaknesses = [...weaknesses];
      updatedWeaknesses.splice(index, 1);
      setLocalState({ weaknesses: updatedWeaknesses });
    }
  };
  const handleAddOpportunity = () => {
    setLocalState({ opportunities: [...opportunities, ""] });
  };

  const handleRemoveOpportunity = (index) => {
    if (opportunities.length === 1) {
      const updatedOpportunities = [""];
      setLocalState({ opportunities: updatedOpportunities });
    } else {
      const updatedOpportunities = [...opportunities];
      updatedOpportunities.splice(index, 1);
      setLocalState({ opportunities: updatedOpportunities });
    }
  };

  const handleAddThreat = () => {
    setLocalState({ threats: [...threats, ""] });
  };
  const handleRemoveThreat = (index) => {
    if (threats.length === 1) {
      const updatedThreats = [""];
      setLocalState({ threats: updatedThreats });
    } else {
      const updatedThreats = [...threats];
      updatedThreats.splice(index, 1);
      setLocalState({ threats: updatedThreats });
    }
  };
  const handleInputChange = (index, type, value) => {
    const updatedData = [...localState[type]];
    updatedData[index] = value;
    setLocalState({ [type]: updatedData });
  };
  const isFieldInDto = (type, fieldData) => {
    return swotAnalysisResponseDto?.some((item) => item.type === type && item.description === fieldData);
  };
  return (
    <Card>
      <Box sx={{ p: "20px", pb: 0, height: "calc(100vh - 190px)", overflowY: "auto" }}>
        <Box display="flex" alignItems="center">
          <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleClose} />
          <Typography variant="h6" fontWeight={700} ml={1}>
            {`${swotAnalysisResponseDto ? T.EDIT : T.ADD} ${T.SWOT_ANALYSIS}`}
          </Typography>
        </Box>
        <Box mb={2} m={1.5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ backgroundColor: BACKGROUND.header, mb: 1 }} fontSize={16} fontWeight={600}>
                {T.STRENGTHS}
              </Typography>
              {strengths.map((data, index) => (
                <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
                  <Grid item xs={11.3}>
                    <MISTextField
                      fullWidth
                      required
                      placeholder={index === 0 ? "Highlight employee's notable strengths and skills..." : "Add more..."}
                      size="small"
                      multiline
                      rows={2}
                      variant="outlined"
                      inputProps={{
                        maxLength: CHARACTER_LIMIT,
                      }}
                      name={`strengths-${index}`}
                      value={data || ""}
                      disabled={isFieldInDto("S", data)}
                      onChange={(event) => handleInputChange(index, "strengths", event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={0.5} display={"flex"} alignItems={"center"}>
                    {index === strengths.length - 1 && (
                      <AddCircleOutlineIcon
                        onClick={handleAddStrength}
                        fontSize="medium"
                        sx={{
                          cursor: "pointer",
                          color: "themeColor",
                        }}
                      />
                    )}

                    {/* {strengths.length > 1 && ( */}
                    <RemoveCircleOutlineIcon
                      onClick={() => handleRemoveStrength(index)}
                      fontSize="medium"
                      sx={{
                        cursor: "pointer",
                        color: "themeColor",
                      }}
                    />
                    {/* )} */}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ backgroundColor: BACKGROUND.header, mb: 1 }} fontSize={16} fontWeight={600}>
                {T.WEAKNESSES}
              </Typography>
              {weaknesses.map((data, index) => (
                <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
                  <Grid item xs={11.3}>
                    <MISTextField
                      fullWidth
                      required
                      placeholder={
                        index === 0 ? "Identify areas where the employee may benefit from improvement..." : "Add more..."
                      }
                      size="small"
                      multiline
                      rows={2}
                      variant="outlined"
                      inputProps={{
                        maxLength: CHARACTER_LIMIT,
                      }}
                      name={`weaknesses-${index}`}
                      value={data || ""}
                      disabled={isFieldInDto("W", data)}
                      onChange={(event) => handleInputChange(index, "weaknesses", event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={0.5} display={"flex"} alignItems={"center"}>
                    {index === weaknesses.length - 1 && (
                      <AddCircleOutlineIcon
                        onClick={handleAddWeakness}
                        fontSize="medium"
                        sx={{
                          cursor: "pointer",
                          color: "themeColor",
                        }}
                      />
                    )}

                    {/* {weaknesses.length > 1 && ( */}
                    <RemoveCircleOutlineIcon
                      onClick={() => handleRemoveWeakness(index)}
                      fontSize="medium"
                      sx={{
                        cursor: "pointer",
                        color: "themeColor",
                      }}
                    />
                    {/* )} */}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ backgroundColor: BACKGROUND.header, mb: 1 }} fontSize={16} fontWeight={600}>
                {T.OPPORTUNITIES}
              </Typography>
              {opportunities.map((data, index) => (
                <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
                  <Grid item xs={11.3}>
                    <MISTextField
                      fullWidth
                      required
                      placeholder={index === 0 ? "Suggest potential growth opportunities for the employee..." : "Add more..."}
                      size="small"
                      multiline
                      rows={2}
                      variant="outlined"
                      inputProps={{
                        maxLength: CHARACTER_LIMIT,
                      }}
                      name={`opportunities-${index}`}
                      value={data || ""}
                      disabled={isFieldInDto("O", data)}
                      onChange={(event) => handleInputChange(index, "opportunities", event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={0.5} display={"flex"} alignItems={"center"}>
                    {index === opportunities.length - 1 && (
                      <AddCircleOutlineIcon
                        onClick={handleAddOpportunity}
                        fontSize="medium"
                        sx={{
                          cursor: "pointer",
                          color: "themeColor",
                        }}
                      />
                    )}

                    {/* {opportunities.length > 1 && ( */}
                    <RemoveCircleOutlineIcon
                      onClick={() => handleRemoveOpportunity(index)}
                      fontSize="medium"
                      sx={{
                        cursor: "pointer",
                        color: "themeColor",
                      }}
                    />
                    {/* )} */}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ backgroundColor: BACKGROUND.header, mb: 1 }} fontSize={16} fontWeight={600}>
                {T.THREATS}
              </Typography>
              {threats.map((data, index) => (
                <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
                  <Grid item xs={11.3}>
                    <MISTextField
                      fullWidth
                      required
                      placeholder={index === 0 ? "Specify challenges or obstacles affecting performance..." : "Add more..."}
                      size="small"
                      multiline
                      rows={2}
                      variant="outlined"
                      inputProps={{
                        maxLength: CHARACTER_LIMIT,
                      }}
                      name={`threats-${index}`}
                      value={data || ""}
                      disabled={isFieldInDto("T", data)}
                      onChange={(event) => handleInputChange(index, "threats", event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={0.5} display={"flex"} alignItems={"center"}>
                    {index === threats.length - 1 && (
                      <AddCircleOutlineIcon
                        onClick={handleAddThreat}
                        fontSize="medium"
                        sx={{
                          cursor: "pointer",
                          color: "themeColor",
                        }}
                      />
                    )}

                    {/* {threats.length > 1 && ( */}
                    <RemoveCircleOutlineIcon
                      onClick={() => handleRemoveThreat(index)}
                      fontSize="medium"
                      sx={{
                        cursor: "pointer",
                        color: "themeColor",
                      }}
                    />
                    {/* )} */}
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12}>
              <ConfirmSubmit
                cancelButtonText={T.NO}
                proceedButtonText={T.YES}
                dialogMessageText={T.CONFIRM_SUBMIT}
                openConfirmSubmit={openModal}
                handleClose={handleCloseModal}
                handleSubmit={handleAddEditSWOT}
                showLoader={isLoading}
                disableSubmit={isLoading}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CardActions sx={{ justifyContent: "flex-end", pb: 2, pr: 3 }}>
        <MISFooterButton justify="end" handleSubmit={handleSubmitModal} handleClose={handleClose} />
      </CardActions>
    </Card>
  );
};

export default SwotForm;

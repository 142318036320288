import { Typography, Box, styled, IconButton, Tooltip } from "@mui/material";
import { COL_KEYS } from "settings/constants/members";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FRONTEND_DATE_FORMAT } from "settings/constants/date";
import { format } from "date-fns";
import { get } from "utils/lodash";
import T from "T";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import PDF from "assets/PDF.png";
import DOC from "assets/DOC.png";
import PPT from "assets/PPT.png";

export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

export const MainTitleTypography = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 14,
  lineHeight: "22px",
  whiteSpace: "nowrap",
}));

const SubTitleTypography = styled(Typography)(() => ({
  fontSize: 14,
  lineHeight: "22px",
  maxWidth: 200,
}));

export const TitleTypography = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "22px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: 200,
}));

const copyContent = (val, name) => {
  navigator.clipboard.writeText(val);

  toast.success(`${name} ${T.COPIED}`, {
    position: toast.POSITION.BOTTOM_LEFT,
  });
};

export const StyledContentCopyIcon = (val, name) => (
  <ContentCopyIcon fontSize="small" sx={{ ml: 1, cursor: "pointer" }} onClick={() => copyContent(val, name)} />
);

export const handleHeaderClass = (ind, isLocked, lockedColumns) => {
  if (lockedColumns.length === ind + 1) {
    return "sticky-col add-shadow";
  }

  if (isLocked) {
    return "sticky-col";
  }

  return "";
};
const getEmpStatus = (status) => {
  switch (status) {
    case true:
      return "Existing";
    case false:
      return "New";
    default:
      return " ";
  }
};

export const getFEDateFormat = (val) => {
  if (val) {
    return format(new Date(val.split(" ")[0]), FRONTEND_DATE_FORMAT);
  }
  return "-";
};

export const getMemberColumnData = (record, column, handleEditAllPitched, index, page, rowsPerPage, handleExport) => {
  switch (column.columnKey) {
    case COL_KEYS.serialnumber: {
      const serialNumber = index + 1 + page * rowsPerPage;
      return {
        columnData: (
          <>
            <SubTitleTypography sx={{ pl: 1.5 }}> {serialNumber}.</SubTitleTypography>
          </>
        ),
      };
    }

    case COL_KEYS.info: {
      const fullName = get(record, "employeeName", "");
      const empCode = get(record, "employeeId", "");

      return {
        columnData: (
          <>
            <MainTitleTypography sx={{ display: "inline-flex", alignItems: "center" }}>{fullName}</MainTitleTypography>

            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{T.EMP_CODE}:</SubTitleTypography>
              <TitleTypography ml={1}>{empCode}</TitleTypography>
              {StyledContentCopyIcon(empCode, T.EMP_CODE)}
            </Box>
          </>
        ),
      };
    }

    case COL_KEYS.technology: {
      const technology = get(record, "jobTitle", "-");
      return {
        columnData: <TitleTypography sx={{ whiteSpace: "normal", width: "150px" }}>{technology}</TitleTypography>,
      };
    }

    case COL_KEYS.reportingManager: {
      const reportingManager = get(record, "projectManager", "-");
      return {
        columnData: <TitleTypography>{reportingManager}</TitleTypography>,
      };
    }

    case COL_KEYS.bytManager: {
      const bytManager = get(record, "bytManager", "-");
      return {
        columnData: <TitleTypography>{bytManager}</TitleTypography>,
      };
    }

    case COL_KEYS.empMode: {
      const empMode = get(record, "empMode", "-");

      return {
        columnData: <TitleTypography>{empMode}</TitleTypography>,
      };
    }

    case COL_KEYS.clientName: {
      const clientName = get(record, "clientName", "");

      return {
        columnData: <TitleTypography>{clientName}</TitleTypography>,
      };
    }

    case COL_KEYS.jdid: {
      const jobDescription = get(record, "jobDescription", "");

      const toggleContent = (text) => {
        const maxWords = 5;

        const words = text.split(" ");
        if (words.length > maxWords) {
          return (
            <Box sx={{ width: "200px" }}>
              {words.slice(0, maxWords).join(" ")}
              <span
                style={{ color: NETSMARTZ_THEME_COLOR, cursor: "pointer" }}
                onClick={(e) => {
                  e.target.parentNode.innerHTML = text;
                }}
              >
                {" "}
                ..more
              </span>
            </Box>
          );
        } else {
          return text;
        }
      };

      return {
        columnData: (
          <Box>
            <SubTitleTypography>{toggleContent(jobDescription)}</SubTitleTypography>
          </Box>
        ),
      };
    }

    case COL_KEYS.existingNew: {
      const existingNew = get(record, "existingClient", false);
      const user = getEmpStatus(existingNew);
      return {
        columnData: <TitleTypography>{user}</TitleTypography>,
      };
    }

    case COL_KEYS.startDate: {
      const startDate = get(record, "startedDate", null);
      const formattedStartDate = getFEDateFormat(startDate);

      return {
        columnData: <TitleTypography>{formattedStartDate}</TitleTypography>,
      };
    }

    case COL_KEYS.endDate: {
      const endDate = get(record, "endDate", null);
      const formattedEndDate = getFEDateFormat(endDate);

      return {
        columnData: <TitleTypography>{formattedEndDate}</TitleTypography>,
      };
    }

    case COL_KEYS.interviewPlatform: {
      const interviewPlatform = get(record, "interviewPlatform", "-");

      return {
        columnData: <TitleTypography>{interviewPlatform}</TitleTypography>,
      };
    }

    case COL_KEYS.status: {
      const status = get(record, "status", "-");

      return {
        columnData: <TitleTypography>{status}</TitleTypography>,
      };
    }

    case COL_KEYS.reasonsForRejection: {
      const reasonForRejection = get(record, "reasonForRejection", "-");

      return {
        columnData: <TitleTypography sx={{ width: "200px" }}>{reasonForRejection}</TitleTypography>,
      };
    }
    case COL_KEYS.cvDownload: {
      const pdfFile = get(record, "pdfFile", "");
      const docFile = get(record, "docFile", "");
      const pptFile = get(record, "pptFile", "");
      return {
        columnData: (
          <>
            <Box display="flex" whiteSpace="nowrap">
              {pdfFile && (
                <Tooltip title={T.PDF} placement="top">
                  <StyledBox
                    component="img"
                    src={PDF}
                    pr={1}
                    onClick={() => {
                      handleExport(pdfFile);
                    }}
                  />
                </Tooltip>
              )}
              {docFile && (
                <Tooltip title={T.DOC} placement="top">
                  <StyledBox
                    component="img"
                    src={DOC}
                    pr={1}
                    onClick={() => {
                      handleExport(docFile);
                    }}
                  />
                </Tooltip>
              )}
              {pptFile && (
                <Tooltip title={T.PPT} placement="top">
                  <StyledBox
                    component="img"
                    src={PPT}
                    pr={1}
                    onClick={() => {
                      handleExport(pptFile);
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          </>
        ),
      };
    }

    case COL_KEYS.editInterviewTracker: {
      const id = get(record, "userId", "");
      const jdId = get(record, "preSalesMappingId", "");
      const endDate = get(record, "endDate", "");
      const status = get(record, "status", "");
      const feedback = get(record, "feedback", "");
      const reasonForRejection = get(record, "reasonForRejection", "");
      return {
        columnData: (
          <>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                disabled={["Rejected", "Selected"].includes(status)}
                onClick={() => {
                  handleEditAllPitched(id, jdId, endDate, status, feedback, reasonForRejection);
                }}
                sx={{
                  mr: 1,
                  cursor: "pointer",
                  color: NETSMARTZ_THEME_COLOR,
                }}
              >
                <EditIcon sx={{ height: 17 }} />
              </IconButton>
            </Box>
          </>
        ),
      };
    }

    default:
      return { columnData: <TitleTypography /> };
  }
};
